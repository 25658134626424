import * as React from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import Search from "../../../images/search.svg";
import CloseIcon from "../../../images/close.svg";
import EditIcon from "../../../images/edit.svg";
import { visuallyHidden } from "@mui/utils";
import {
	Button,
	FormControl,
	Grid,
	InputAdornment,
	Menu,
	MenuItem,
	OutlinedInput,
	Select,
} from "@mui/material";
import { useEffect, useRef, useState, KeyboardEvent } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { format } from "date-fns";
import { useUserManageFilter } from "./UserManagementProvider/useUserManageFilter";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeviceActions from "./DeviceActions";
import ArrowRight from "../../../images/arrow-right.svg";
import { all } from "q";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		folderDropdown: {
			background: "#FFF",
			border: "1.5px solid #0273E6",
			borderRadius: "15px !important",
			"& #demo-simple-select": {
				color: "#0273E6",
			},
			"& .MuiOutlinedInput-notchedOutline": {
				border: "none",
			},
			"& .MuiSelect-icon": {
				color: "#0273E6",
			},
		},
		recordingBtn: {
			width: "100%",
			background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
			borderRadius: "18px !important",
			textAlign: "center",
			color: "white",
			fontSize: "0.9rem",
			fontWeight: "var(--joy-fontWeight-lg)",
			padding: "2% 0 !important",
			cursor: "pointer",
		},
		searchField: {
			// border: "1px solid #C5C5C5",
			borderRadius: "17px",
			"& fieldset": {
				border: "1px solid #C5C5C5",
				borderRadius: "17px",
			},
		},
		optionButtons: {
			border: "1.5px solid #0273E6 !important",
			borderRadius: "15px !important",
			color: "#0273E6 !important",
			// fontFamily: 'Lexend !important',
			fontStyle: "normal !important",
			fontWeight: "bold !important",
			fontSize: "16px !important",
			lineHeight: "20px !important",
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
		checkBoxFill: {
			color: "#1D2D53 !important",
		},
		actionButtons: {
			fontSize: "1rem",
			fontWeight: "400",
			fontFamily: "Lexend",
			color: "#1D2D53",
		},
	})
);

interface Data {
	userName: string;
	// city: string;
	joinDate: string;
	planExpiry: string;
	subscription: string;
	addOn: string;
	userStatus: string;
	actions: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string }
) => number {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
	array: readonly T[],
	comparator: (a: T, b: T) => number
) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
	disablePadding: boolean;
	id: keyof Data;
	label: string;
	numeric: boolean;
	width: string;
}

const headCells: readonly HeadCell[] = [
	{
		id: "userName",
		numeric: false,
		disablePadding: true,
		label: "USER NAME",
		width: "30%",
	},
	// {
	// 	id: "city",
	// 	numeric: true,
	// 	disablePadding: false,
	// 	label: "CITY",
	// 	width: "25%",
	// },
	{
		id: "joinDate",
		numeric: false,
		disablePadding: false,
		label: "JOIN DATE",
		width: "16%",
	},
	{
		id: "planExpiry",
		numeric: false,
		disablePadding: false,
		label: "PLAN EXPIRY",
		width: "12%",
	},
	{
		id: "subscription",
		numeric: false,
		disablePadding: false,
		label: "SUBSCRIPTION",
		width: "12%",
	},
	{
		id: "addOn",
		numeric: false,
		disablePadding: false,
		label: "ADD ON",
		width: "10%",
	},
	{
		id: "userStatus",
		numeric: false,
		disablePadding: false,
		label: "USER STATUS",
		width: "13%",
	},
	{
		id: "actions",
		numeric: false,
		disablePadding: false,
		label: "ACTIONS",
		width: "7%",
	},
];

interface EnhancedTableProps {
	numSelected: number;
	onRequestSort: (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => void;
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
	isHeadCkeckBoxChecked: any;
	isHeadCheckBoxIndeterminate: any;
}

function EnhancedTableHead(props: EnhancedTableProps) {
	const [transferDevice, setTransferDevice] = useState(false);
	const { userId, deviceId } = useParams();
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort,
		isHeadCkeckBoxChecked,
		isHeadCheckBoxIndeterminate,
	} = props;
	const createSortHandler =
		(property: keyof Data) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	const {
		setSelectedUserIds,
		selectedUserIds,
		rowData,
		setOrderByField,
		handleFilterReset,
		orderByField,
	} = useUserManageFilter();

	const classes = useStyles();

	useEffect(() => {
		if (userId) {
			setTransferDevice(true);
		}
	}, []);

	const handleSorting = (field: any) => {
		setOrderByField((prevValue: any) => {
			const newOrderByField = orderByField === field ? `${field}Desc` : field;
			return newOrderByField;
		});
	};

	useEffect(() => {
		handleFilterReset();
	}, [orderByField]);

	return (
		<TableHead sx={{backgroundColor: "#C2C2C3"}}>
			<TableRow>
				{!transferDevice && (
					<TableCell padding="checkbox" sx={{bgcolor:'#F5F5F5',borderRight: '1px solid #C2C2C3 !important',}}>
						{/* <Checkbox
							color="primary"
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{
								"aria-label": "select all desserts",
							}}
						/> */}
						<Checkbox
							className={classes.checkBoxFill}
							color="primary"
							indeterminate={isHeadCheckBoxIndeterminate}
							checked={isHeadCkeckBoxChecked}
							onChange={onSelectAllClick}
							inputProps={{
								"aria-label": "select all desserts",
							}}
						/>
					</TableCell>
				)}
				{headCells.map((headCell:any,index:number) => (
					<TableCell
						key={headCell.id}
						align={headCell.numeric ? "right" : "left"}
						padding={headCell.disablePadding ? "none" : "normal"}
						sortDirection={orderBy === headCell.id ? order : false}
						width={headCell.width}
						sx={{ span:{ 
							fontWeight: 500,
							//fontSize: "14px !important",
							color: "#000",
							fontFamily: "Lexend",
						},
						bgcolor:'#F5F5F5',
						borderRight: index < headCells.length - 1 ? '1px solid #C2C2C3 !important' : 'none',
						paddingLeft: '8px !important',
						}}
					>
						{headCell.id !== "actions" ? (
							<TableSortLabel
								// active={orderBy === headCell.id}
								active={orderByField === headCell.id}
								// direction={orderBy === headCell.id ? order : "asc"}
								direction={orderByField === headCell.id ? "asc" : "desc"}
								// onClick={createSortHandler(headCell.id)}
								onClick={() => handleSorting(headCell.id)}
							>
								{headCell.label}
								{orderBy === headCell.id ? (
									<Box component="span" sx={visuallyHidden}>
										{order === "desc"
											? "sorted descending"
											: "sorted ascending"}
									</Box>
								) : null}
							</TableSortLabel>
						) : (
							<Typography
								sx={{
									fontWeight: 500,
									fontSize: "16px",
									color: "#000",
									fontFamily: "Lexend",
								}}
								variant="body1"
								color="textPrimary"
							>
								{headCell.label}
							</Typography>
						)}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

interface EnhancedTableToolbarProps {
	numSelected: number;
	actionAllCheckBox: any;
}

function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
	const { numSelected, actionAllCheckBox } = props;
	const classes = useStyles();
	// const [subscriptionStatus, setSubscriptionStatus] =
	// 	React.useState("");

	// const [addOns, setAddOns] = React.useState("");

	// const [userStatus, setUserStatus] = React.useState("");

	const [searchString, setSearchString] = useState("");
	const [searchMode, setSearchMode] = useState(false);
	const searchRef = useRef("");
	const token = localStorage.getItem("sessionId");

	const {
		rowData,
		setRowData,
		setPageNumber,
		subscriptionStatus1,
		addOns1,
		userStatus1,
		setAddOns1,
		setUserStatus1,
		setSubscriptionStatus1,
		handleFilterReset,
		searchUserName,
		setSearchUserName,
		inputRef,
	} = useUserManageFilter();

	useEffect(() => {
		setSubscriptionStatus1("");
		setAddOns1("");
		setUserStatus1("");
	}, []);

	const subscriptionStatusMenu = [
		{ id: "menu", title: "All Types", value: "" },
		{ id: "menu1", title: "Free", value: "Free" },
		{ id: "menu2", title: "Subscriber", value: "Subscriber" },
	];
	const addOnMenu = [
		{ id: "menu", title: "All add-ons", value: "" },
		{ id: "menu1", title: "Large Ensemble", value: "Buy Large Ensemble" },
		{ id: "menu2", title: "Extra Storage", value: "Buy Extra Storage" },
	];
	const userStatusMenu = [
		{ id: "menu", title: "All Users", value: "" },
		{ id: "menu1", title: "Active", value: "ACTIVE" },
		{ id: "menu2", title: "Suspended", value: "SUSPEND" },
		{ id: "menu3", title: "Banned", value: "BAN" },
		{ id: "menu4", title: "Terminated", value: "TERMINATE" },
		{ id: "menu5", title: "Inactive", value: "INACTIVE" },
	];

	const handleMusicSearch = () => {
		if (searchUserName !== "") {
			setSearchMode(true);

			const inputValue = inputRef.current.value;
			setSearchUserName(inputValue);


			handleFilterReset();
		}
	};

	const handleMusicReset = () => {
		setSearchMode(false);

		const inputValue = inputRef.current.value;
		setSearchUserName(inputValue);

		handleFilterReset();

		setSearchUserName("");
	};

	useEffect(() => {
		handleFilterReset();
	}, [subscriptionStatus1, addOns1, userStatus1]);

	const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
		if(e.key === "Enter") {
			handleMusicSearch();
		}
	}

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: "#fff",
				}),
			}}
		>
			{numSelected > 0 ? (
				<Grid container xs={12}>
					<Grid item xs={2}>
						<Typography
							sx={{ flex: "1 1 100%" }}
							color="inherit"
							variant="subtitle1"
							component="div"
						>
							{numSelected} selected
						</Typography>
					</Grid>
					<Grid item xs={10} sx={{ display: "flex" }}>
						<div
							className={classes.searchField}
							style={{ marginRight: "10px" }}
						>
							<FormControl fullWidth variant="outlined" size="small">
								<OutlinedInput
									id="outlined-adornment-password"
									type="text"
									value={searchUserName}
									onChange={(e) => setSearchUserName(e.target.value)}
									inputRef={searchRef}
									onKeyDown={handleKeyDown}
									endAdornment={
										<>
											{searchMode ? (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleMusicReset}
														edge="end"
													>
														<img src={CloseIcon} alt="Reset Search" />
													</IconButton>
												</InputAdornment>
											) : (
												<InputAdornment position="end">
													<IconButton
														aria-label="toggle password visibility"
														onClick={handleMusicSearch}
														edge="end"
													>
														<img src={Search} alt="Search" />
													</IconButton>
												</InputAdornment>
											)}
										</>
									}
									placeholder="Search user name"
									// onKeyDown={handleKeyPress}
									autoComplete="off"
									ref={inputRef}
									sx={{
										width: "273px",
										height: "30px",
									}}
								/>
							</FormControl>
						</div>

						{numSelected > 0 && (
							<>
								<Button
									onClick={() => actionAllCheckBox("SUSPEND")}
									variant="outlined"
									sx={{
										width: "120px",
										marginRight: 1,
										height: "30px",
										borderRadius: "15px",
										border: "1.5px solid #1D2D53",
									}}
								>
									<span className={classes.actionButtons}>Suspend</span>
								</Button>
								<Button
									onClick={() => actionAllCheckBox("BAN")}
									variant="outlined"
									sx={{
										width: "150px",
										marginRight: 1,
										height: "30px",
										borderRadius: "15px",
										border: "1.5px solid #1D2D53",
									}}
								>
									<span className={classes.actionButtons}>Ban</span>
								</Button>
								<Button
									onClick={() => actionAllCheckBox("TERMINATE")}
									variant="outlined"
									sx={{
										width: "150px",
										marginRight: 1,
										height: "30px",
										borderRadius: "15px",
										border: "1.5px solid #1D2D53",
									}}
								>
									<span className={classes.actionButtons}>Terminate</span>
								</Button>
								<Button
									onClick={() => actionAllCheckBox("REACTIVATE")}
									variant="outlined"
									sx={{
										width: "150px",
										marginRight: 1,
										height: "30px",
										borderRadius: "15px",
										border: "1.5px solid #1D2D53",
									}}
								>
									<span className={classes.actionButtons}>Reactivate</span>
								</Button>
							</>
						)}
					</Grid>
				</Grid>
			) : (
				<Grid container xs={12} spacing={1} style={{ width: "100%" }}>
					<Grid item xs={4} className={classes.searchField}>
						<FormControl fullWidth variant="outlined" size="small">
							<OutlinedInput
								id="outlined-adornment-password"
								type="text"
								value={searchUserName}
								onChange={(e) => setSearchUserName(e.target.value)}
								inputRef={searchRef}
								onKeyDown={handleKeyDown}
								endAdornment={
									<>
										{searchMode ? (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleMusicReset}
													edge="end"
												>
													<img src={CloseIcon} alt="Reset Search" />
												</IconButton>
											</InputAdornment>
										) : (
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleMusicSearch}
													edge="end"
												>
													<img src={Search} alt="Search" />
												</IconButton>
											</InputAdornment>
										)}
									</>
								}
								placeholder="Search user name"
								// onKeyDown={handleKeyPress}
								autoComplete="off"
								ref={inputRef}
								sx={{
									width: "220px",
									height: "30px",
								}}
							/>
						</FormControl>
					</Grid>
					<Grid item xs={2.5}>
						<FormControl sx={{ width: "100%" }}>
							<Select
								size="small"
								style={{ paddingLeft: "10px" }}
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}
								className={classes.folderDropdown}
								id="demo-simple-select"
								value={subscriptionStatus1}
								sx={{ width: "120px", height: "30px" }}
								onChange={(e) => setSubscriptionStatus1(e.target.value)}
							>
								{subscriptionStatusMenu.map((menu: any) => {
									return (
										<MenuItem value={menu.value}>
											<span
												style={{
													fontSize: "1rem",
													fontWeight: "400",
													fontFamily: "Lexend",
												}}
											>
												{menu.title}
											</span>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={3}>
						<FormControl sx={{ width: "100%" }}>
							<Select
								size="small"
								style={{ paddingLeft: "10px" }}
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}
								className={classes.folderDropdown}
								id="demo-simple-select"
								value={addOns1}
								sx={{ width: "150px", height: "30px" }}
								onChange={(e) => setAddOns1(e.target.value)}
							>
								{addOnMenu.map((menu: any) => {
									return (
										<MenuItem value={menu.value}>
											<span
												style={{
													fontSize: "1rem",
													fontWeight: "400",
													fontFamily: "Lexend",
												}}
											>
												{menu.title}
											</span>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
					<Grid item xs={2.5}>
						<FormControl sx={{ width: "80%" }}>
							<Select
								size="small"
								style={{ paddingLeft: "10px" }}
								displayEmpty
								inputProps={{ "aria-label": "Without label" }}
								className={classes.folderDropdown}
								id="demo-simple-select"
								value={userStatus1}
								sx={{ width: "120px", height: "30px" }}
								onChange={(e) => setUserStatus1(e.target.value)}
							>
								{userStatusMenu.map((menu: any) => {
									return (
										<MenuItem
											value={menu.value}
											// onClick={() => handleUserStatus(menu.id)}
										>
											<span
												style={{
													fontSize: "1rem",
													fontWeight: "400",
													fontFamily: "Lexend",
												}}
											>
												{menu.title}
											</span>
										</MenuItem>
									);
								})}
							</Select>
						</FormControl>
					</Grid>
				</Grid>
			)}
		</Toolbar>
	);
}

export default function UserManagementTb() {
	const [order, setOrder] = React.useState<Order>("asc");
	const [orderBy, setOrderBy] = React.useState<keyof Data>("userName");
	const [selected, setSelected] = React.useState<readonly string[]>([]);
	const [page, setPage] = React.useState(0);
	const [dense, setDense] = React.useState(false);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [type, setType] = useState("");
	const open = Boolean(anchorEl);
	const token = localStorage.getItem("sessionId");
	const navigate = useNavigate();
	const [openDialog, setOpenDialog] = useState(false);
	const { userId, deviceId } = useParams();
	const [transferDevice, setTransferDevice] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any>();
	const [selectedUserData, setSelectedUserData] = useState<any>();

	const [currentUserState, setCurrentUserState] = useState<any>("");

	const location = useLocation();

	const transferData = location.state;

	const classes = useStyles();


	const {
		userManagementFilter,
		setUserManagementFilter,
		isFilterActive,
		rowData,
		setRowData,
		//getLoadingStatus,
		getAllUsers1,
		hasMore,
		setChildData,
		setUserIdByAdmin,
		setUserProfileIdByAdmin,
		handleFilterReset,
		selectedUserIds,
		setSelectedUserIds,
	} = useUserManageFilter();

	useEffect(() => {
		setSelectedUserIds([]);
		setUserManagementFilter({
			isGlobalSearch: true,
			genres: [],
			cities: "",
			firstName: "",
			lastName: "",
			nickName: "",
			instrument: [],
			isOpenForCollaboration: false,
			acceptGigs: false,
		});
	}, []);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = (itemId: string) => {
		setAnchorElUser(null);
	};

	const options = ["Suspend", "Ban", "Terminate"];

	useEffect(() => {
		handleFilterReset();
	}, [userManagementFilter]);

	useEffect(() => {
		if (userId) {
			setTransferDevice(true);
			getAllUsers1({ userId: userId });
            
			setChildData(transferData?.deviceId);
		}
	}, []);

	const handleClick1 = (event: React.MouseEvent<HTMLElement>, data: any) => {
		setAnchorEl(event.currentTarget);
		setSelectedUserData(data);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleMenuClick = () => {
		handleClose();
	};

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof Data
	) => {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	const rowCount = rowData.length;

	const isHeaderCheckboxChecked =
		selectedUserIds.length === rowCount && selectedUserIds.length > 0;
	const isHeaderCheckboxIndeterminate =
		selectedUserIds.length > 0 && selectedUserIds.length < rowCount;

	const handleHeaderCheckboxClick = () => {
		if (isHeaderCheckboxChecked) {
			// If header checkbox is already checked, deselect all rows
			setSelectedUserIds([]);
			setSelected([]);
		} else {
			// If header checkbox is not checked, select all rows
			const allUserIds = rowData.map((row: any) => row.userProfileId);
			setSelectedUserIds(allUserIds);
			setSelected(allUserIds);
		}

	};

	// const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	if (event.target.checked) {
	// 		const newSelected = rowData.map((n: any) => n.userName);
	// 		setSelected(newSelected);
	// 		return;
	// 	}
	// 	setSelected([]);
	// };

	// const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
	// 	if (event.target.checked) {
	// 		// Check if all userIds are selected
	// 		const allUserIds = rowData.map((n: any) => n.userId);
	// 		const allSelected = allUserIds.every((userId: any) =>
	// 			selectedUserIds.includes(userId)
	// 		);

	// 		if (!allSelected) {
	// 			// If not all are selected, select them all
	// 			const newSelected = [...selectedUserIds, ...allUserIds];
	// 			setSelectedUserIds(newSelected);
	// 		}
	// 	} else {
	// 		// Deselect all
	// 		setSelectedUserIds([]);
	// 	}
	// };

	// const handleClick = (event: React.MouseEvent<unknown>, userId: any) => {
	// 	const selectedIndex = selected.indexOf(userId);
	// 	let newSelected: readonly any[] = [];

	// 	if (selectedIndex === -1) {
	// 		newSelected = newSelected.concat(selected, name);
	// 	} else if (selectedIndex === 0) {
	// 		newSelected = newSelected.concat(selected.slice(1));
	// 	} else if (selectedIndex === selected.length - 1) {
	// 		newSelected = newSelected.concat(selected.slice(0, -1));
	// 	} else if (selectedIndex > 0) {
	// 		newSelected = newSelected.concat(
	// 			selected.slice(0, selectedIndex),
	// 			selected.slice(selectedIndex + 1)
	// 		);
	// 	}

	// 	setSelected(newSelected);
	// };

	const handleClick = (event: any, userProfileId: any) => {
		const selectedIndex = selectedUserIds.indexOf(userProfileId);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = [...selectedUserIds, userProfileId];
		} else {
			newSelected = [
				...selectedUserIds.slice(0, selectedIndex),
				...selectedUserIds.slice(selectedIndex + 1),
			];
		}

		setSelectedUserIds(newSelected);
		setSelected(newSelected);

	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
		setDense(event.target.checked);
	};

	const isSelected = (userProfileId: string) =>
		selectedUserIds.indexOf(userProfileId) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

	const visibleRows = React.useMemo(
		() =>
			stableSort(rowData, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[order, orderBy, page, rowsPerPage]
	);

	const handleUserClick = (nickName: any) => {
		const encodedNickName = encodeURIComponent(nickName);
		const url = `${process.env.REACT_APP_BASE_URL_FRONTEND}/socialHome/`+encodedNickName;
		window.open(url, "_blank");
	};

	const handleTransferDeviceClick = (data: any) => {
		setOpenDialog(true);
		setType("transfer");
		setSelectedRow({ ...data, deviceId: deviceId });
	};

	const handleDialogClose = (success: boolean) => {
		setOpenDialog(false);
	};

	const updateUserStatus = (updatedUsers: any[]) => {
		if (rowData !== null) {
			const updatedRowData = rowData.map((user: any) => {
				const matchingUser = updatedUsers.find(
					(updatedUser) =>
						updatedUser.offenderUserProfile.id === user.userProfileId
				);

				if (matchingUser) {
					return {
						...user,
						userStatus:
							matchingUser.offenderUserProfile.userAccountStatus === "SUSPEND"
								? "Suspended"
								: matchingUser.offenderUserProfile.userAccountStatus === "BAN"
								? "Banned"
								: matchingUser.offenderUserProfile.userAccountStatus ===
								  "TERMINATE"
								? "Terminated"
								: "Active",
					};
				}

				return user;
			});

			setRowData(updatedRowData);
		}
	};

	// const adminAction = (action: string) => {
	// 	setAnchorEl(null);
	// 	try {
	// 		fetch(
	// 			`${process.env.REACT_APP_BASE_URL}/api/moderations/action-on-user-account?userAccountStatus=${action}&forType=User`,
	// 			{
	// 				method: "POST",
	// 				headers: {
	// 					"Content-Type": "application/json",
	// 					Authorization: `Bearer ${token}`,
	// 				},
	// 				body: JSON.stringify({
	// 					offenderUserProfileIds: selectedUserData.userProfileId
	// 						? [selectedUserData.userProfileId]
	// 						: null,
	// 				}),
	// 			}
	// 		)
	// 			.then((res) => {
	// 				if (res.status > 400) {
	// 					throw new Error("api_error");
	// 				} else {
	// 					return res.json();
	// 				}
	// 			})
	// 			.then((data) => {
	// 				const action = data[0].offenderUserProfile?.userAccountStatus;

	// 				setCurrentUserState(action);

	// 				updateUserStatus(data);
	// 			})
	// 			.catch((err) => {
	// 			});
	// 	} catch (error) {
	// 	}
	// };

	const adminAction1 = (action: string) => {
		setAnchorEl(null);
		try {
			const moderationDTOs = [
				{ offenderUserProfileId: selectedUserData.userProfileId },
			];

			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/moderations/action-on-user-account?userAccountStatus=${action}&forType=User`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(moderationDTOs),
					// body: JSON.stringify(selectedUserIds),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					const action = data[0].offenderUserProfile.userAccountStatus;
					setCurrentUserState(action);
					updateUserStatus(data);
				})
				.catch((err) => {
				});
		} catch (error) {
		}
	};

	const adminActionAllCheckBox = (action: string) => {
		setAnchorEl(null);
		try {
			// const userProfileIdsArray = selectedUserIds.map((userProfileId:any) => ({
			// 	offenderUserProfileId: userProfileId,
			// }));

			const moderationDTOs = selectedUserIds.map((id: any) => ({
				offenderUserProfileId: id,
			}));

			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/moderations/action-on-user-account?userAccountStatus=${action}&forType=User`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify(moderationDTOs),
					// body: JSON.stringify(selectedUserIds),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					const action = data[0].offenderUserProfile.userAccountStatus;
					setCurrentUserState(action);
					updateUserStatus(data);
				})
				.catch((err) => {
				});
		} catch (error) {
		}
	};

	const handleEditUserClick = (userId: any, userProfileId: any, email: any, user: any) => {
		setUserIdByAdmin(userId);
		setUserProfileIdByAdmin(userProfileId);

		navigate("/home/admin/settings/account/account/", {
			state: { userId, userProfileId, email, type: "edit", user },
		});
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Paper
				sx={{
					width: "100%",
					mb: 2,
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
				}}
			>
				<InfiniteScroll
					dataLength={rowData.length}
					next={getAllUsers1}
					hasMore={hasMore}
					loader={
						<Box className={classes.spinner}>
							<CircularProgress />
						</Box>
					}
					endMessage={
						<p style={{ textAlign: "center" }}>
							<b>Yay! You have seen it all</b>
						</p>
					}
				>
					<EnhancedTableToolbar
						actionAllCheckBox={adminActionAllCheckBox}
						numSelected={selected.length}
					/>
					<TableContainer>
						<Table
							sx={{ minWidth: 750 }}
							aria-labelledby="tableTitle"
							size={dense ? "small" : "medium"}
						>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								// onSelectAllClick={handleSelectAllClick}

								isHeadCkeckBoxChecked={isHeaderCheckboxChecked}
								isHeadCheckBoxIndeterminate={isHeaderCheckboxIndeterminate}
								onSelectAllClick={handleHeaderCheckboxClick}
								onRequestSort={handleRequestSort}
								rowCount={rowData.length}
							/>
							<TableBody>
								{
									rowData.length === 0 && hasMore && (
										<TableRow>
										<TableCell colSpan={7} align="center">
											<Box>
												<CircularProgress />
											</Box>
										</TableCell>
									</TableRow>
									)
								}
								{rowData.length === 0 && !hasMore ? ( // Check if there are no users
									<TableRow>
										<TableCell colSpan={7} align="center">
											<Typography sx={{fontSize: "16px !important"}}>No users present</Typography>
										</TableCell>
									</TableRow>
								) : (
									rowData.map((row: any, index: any) => {
										const isItemSelected = isSelected(row.userProfileId);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<TableRow
												hover
												role="checkbox"
												aria-checked={isItemSelected}
												tabIndex={-1}
												key={row.userName}
												selected={isItemSelected}
												sx={{ cursor: "pointer" }}
											>
												{!transferDevice && (
													<TableCell padding="checkbox">
														<Checkbox
															className={classes.checkBoxFill}
															color="primary"
															// checked={isItemSelected}
															checked={
																selectedUserIds.includes(row.userProfileId) ||
																isItemSelected
															}
															onClick={(event) =>
																handleClick(event, row.userProfileId)
															}
															inputProps={{
																"aria-labelledby": labelId,
															}}
														/>
													</TableCell>
												)}
												<TableCell
													component="th"
													id={labelId}
													scope="row"
													padding="none"
													onClick={() => {
														handleUserClick(row.nickName);
													}}
													sx={{
														maxWidth: "130px",
														whiteSpace: "nowrap",
														overflow: "hidden",
														textOverflow: "ellipsis",
														paddingLeft: 1,
														fontSize: "16px !important"
													}}
												>
													{row.userName}
												</TableCell>
												{/* <TableCell align="right">{row.city}</TableCell> */}
												<TableCell align="left" sx={{ p:0, fontSize: "16px !important" }}>{row.joinDate}</TableCell>
												<TableCell sx={{ p:0, fontSize: "16px !important" }} align="left"
													// align={row.expiryDate === "-" ? "center" : "left"}
												>
													{row.expiryDate}
												</TableCell>
												<TableCell sx={{ p:0, fontSize: "16px !important" }} align="left">{row.subscription}</TableCell>
												<TableCell sx={{ p:0, fontSize: "16px !important" }} align="left">{row.addOn}</TableCell>
												<TableCell sx={{ p:0, fontSize: "16px !important" }} align="left">{row.userStatus}</TableCell>
												<TableCell sx={{ p:0 }} align="left">
													<div
														style={{
															display: "flex",
															justifyContent: "flex-start",
															alignItems: "left",
															opacity: selectedUserIds.length > 0 ? 0.5 : 1,

															cursor:
																selectedUserIds.length > 0
																	? "not-allowed"
																	: "pointer",
														}}
													>
														{!transferDevice && (
															<img
																onClick={() => {
																	handleEditUserClick(
																		row.userId,
																		row.userProfileId,
																		row.email,
																		row.user,
																	);
																}}
																src={EditIcon}
																alt="edit-icon"
																style={{
																	pointerEvents:
																		selectedUserIds.length > 0
																			? "none"
																			: "auto",
																}}
															/>
														)}

														{!transferDevice && (
															<div
																style={{
																	height: "28px",
																	width: "1px",
																	backgroundColor: "#C2C2C3",
																	marginLeft: "6px",
																}}
															></div>
														)}

														{transferDevice ? (
															<img
																src={ArrowRight}
																onClick={() =>
																	handleTransferDeviceClick({
																		...row,
																		transferData: transferData,
																	})
																}
																alt="transfer-device-icon"
															/>
														) : (
															<IconButton
																aria-label="more"
																id="long-button"
																aria-controls={open ? "long-menu" : undefined}
																aria-expanded={open ? "true" : undefined}
																aria-haspopup="true"
																onClick={(e) => handleClick1(e, row)}
																style={{
																	pointerEvents:
																		selectedUserIds.length > 0
																			? "none"
																			: "auto",
																}}
															>
																<MoreVertIcon />
															</IconButton>
														)}
													</div>
												</TableCell>
											</TableRow>
										);
									})
								)}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: (dense ? 33 : 53) * emptyRows,
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</InfiniteScroll>
			</Paper>
			<Menu
				id="long-menu"
				MenuListProps={{
					"aria-labelledby": "long-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
			>
				{selectedUserData?.userStatus === "Suspended" ? (
					<MenuItem onClick={() => adminAction1("REACTIVATE")}>
						Activate
					</MenuItem>
				) : (
					<MenuItem onClick={() => adminAction1("SUSPEND")}>Suspend</MenuItem>
				)}

				{selectedUserData?.userStatus === "Banned" ? (
					<MenuItem onClick={() => adminAction1("REACTIVATE")}>
						Remove Ban
					</MenuItem>
				) : (
					<MenuItem onClick={() => adminAction1("BAN")}>Ban</MenuItem>
				)}
				{selectedUserData?.userStatus === "Terminated" ? (
					<MenuItem onClick={() => adminAction1("REACTIVATE")}>
						Reinstate
					</MenuItem>
				) : (
					<MenuItem onClick={() => adminAction1("TERMINATE")}>
						Terminate
					</MenuItem>
				)}
			</Menu>
			{type === "transfer" && (
				<DeviceActions
					type={type}
					open={openDialog}
					handleClose={handleDialogClose}
					data={selectedRow}
				/>
			)}
		</Box>
	);
}
