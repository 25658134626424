import { useState, useEffect, useRef, SyntheticEvent } from "react";
import {
	Typography,
	Grid,
	Button,
	Box,
	Divider,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	LinearProgress
} from "@mui/material";
import Snackbar from "../Snackbar/Snackbar";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";

import CenterCircularProgress from "../CenterCircularProgress";
import SessionRecordings from "./SessionRecordings";
import Recordings from "../../images/recordings.svg"
import RecordingCircle from "../../images/record-circle.svg"
import MusicPlaylist from "../../images/music-playlist.svg"
import MusicPlaylistSolid from "../../images/music-playlist-solid.svg"
import Next from "../../images/next.svg";
import { Security } from "@mui/icons-material";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import SettingsAlert from "../../pages/SettingsAlert";
import { useAlertStatus } from "../../context/AlertStatusContext";
import Share from "../../images/share.svg";
import ShareActive from "../../images/share-active.svg";
import CloudStorageBlue from "../../images/cloud-storage-blue.svg"
import SharedMusic from '../../images/share-music.svg';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		// root: {
		// 	"& .MuiLinearProgress-colorPrimary": {
		// 		backgroundColor: "#888 !important",
		// 		// backgroundColor: "#F0F0F0 !important"
		// 	}
		// },
		recordingTabs: {
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
			borderRadius: "18px !important",
		},
		recordingBtn: {
			width: "100%",
			margin: "0.5em 0 !important",
			background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
			borderRadius: "18px !important",
		},
		newFolderBtn: {
			display: 'none',
			width: "100%",
			margin: "0.5em 0 !important",
			border: "1.5px solid #0273E6",
			background: "#fff",
			borderRadius: "18px !important"
		},

		leftBarMenus: {
			"& img": {
				position: "absolute",
				left: "5%"
			}
		},
		recordingOptionsCard: {
			// position: "fixed"
		},
		progressBar: {
			height: "6px !important",
			borderRadius: "3px !important",
			backgroundColor: "#F0F0F0 !important",
			"& .MuiLinearProgress-bar": {
				backgroundColor: "#888 !important",
			}
		}
	})
);

type RouteParams = {
	menuId: string;
};

const MyMusic = () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
	const param = useParams<RouteParams>();
	const token = localStorage.getItem("sessionId");
	const { updateAlertStatus } = useAlertStatus();
	const user = useUser();
	const navigate = useNavigate();

	// Alert states
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [isSendingFile, setIsSendingFile] = useState(false);
	const [fileSendError, setFileSendError] = useState<string | null>(null);
	const fileInputRef = useRef(null);
	const [alertMsg, setAlertMsg] = useState("");
	const [value, setValue] = useState(0);
	const [activeMenu, setActiveMenu] = useState(param.menuId ?? "Recording");
	const [activeMenuTitle, setActiveMenuTitle] = useState(param.menuId ?? "Jam Recordings");
	const ALLOWED_FILE_TYPES = "audio/*,.mp3,.wav,.mpeg";
	const ALLOWED_FILE_EXT = ['mp3', 'wav', 'mpeg'];
	const [laodMusicDetails, setLoadMusicDetails] = useState(false);
	const [menus, setMenus] = useState([
		// Hiding for Social-Launch
		{
			id: "Recording",
			title: "Jam Recordings",
			icon: RecordingCircle,
			activeIcon: Recordings,
			disabled: false,
			showDivider: true
		},
		// {
		// 	title: "Shared with Me",
		// 	icon: Share,
		// 	activeIcon: ShareActive,
		// 	id: "sharedWithMe",
		// 	disabled: true,
		// 	showDivider: false
		// },
	]);
	const [storageConsumedPerc, setStorageConsumedPerc] = useState(0);
	const [storageConsumed, setStorageConsumed] = useState(0);
	const [storageAvailable, setStorageAvailable] = useState(0);

	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if (!userDetails.firstName || !userDetails.lastName || !userDetails.location) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]);

	const [subMenus, setSubMenus] = useState([
		{
			title: "Uploaded Music",
			icon: MusicPlaylist,
			activeIcon: MusicPlaylistSolid,
			id: "myMusic",
			disabled: false
		},
		{
			title: "Shared with me",
			icon: SharedMusic,
			activeIcon: SharedMusic,
			id: "sharedMusic",
			disabled: false
		},
	]);

	const changeMenu = (value: any) => {
		setActiveMenu(value.id);
		let currentActiveMenuTitle = menus.find(menu => {
			return menu.id === value.id;
		})
		setActiveMenuTitle(value!.title);

		// let recentIndex = menus.findIndex(menu => menu.id === "recent-recordings")
		// if (value === "recent-recordings") {
		// 	menus[recentIndex].icon = Recordings;
		// } else {
		// 	menus[recentIndex].icon = RecordingCircle;
		// }

		// let soloIndex = menus.findIndex(menu => menu.id === "solo-recordings")
		// if (value === "solo-recordings") {
		// 	menus[soloIndex].icon = MusicPlaylistSolid;
		// } else {
		// 	menus[soloIndex].icon = MusicPlaylist;
		// }
		// if (value === "deviceSetup") {
		//  getDevice();
		// } else {
		//  navigate(`/home/settings/${value}/${value}`);
		// }
	};

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleSendFile = (event: any) => {
		const file = event.target.files?.[0];
		if (file) {
			var formData = new FormData();
			formData.append("userfile", file);
			setIsSendingFile(true);
			setFileSendError(null);
			// conversation
			// 	.sendMessage(formData)
			// 	.catch((e: Error) => {
			// 		if (e.code === 413) {
			// 			setFileSendError(
			// 				"File size is too large. Maximum file size is 150MB."
			// 			);
			// 		} else {
			// 			setFileSendError(
			// 				"There was a problem uploading the file. Please try again."
			// 			);
			// 		}
			// 	})
			// 	.finally(() => {
			// 		setIsSendingFile(false);
			// 	});
		}
	};

	const musicUpload = (e: any) => {
		updateAlertStatus(true, true, 'Music uploading is in process');
		const targetFiles = e.target.files[0];
		let formData = new FormData();
		formData.append('files', targetFiles);

		let ext = targetFiles.type.split('/').pop();
		if (ALLOWED_FILE_EXT.includes(ext)) {
			fetch(`${process.env.REACT_APP_BASE_URL}/api/upload-music`, {
				method: "POST",
				headers: {
					// "Content-Type": "multipart/form-data",
					Authorization: `Bearer ${token}`,
				},
				body: formData
			}).then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
				} else {
					setLoadMusicDetails(true)
					getStorageConsumed();

					// getAllMusicListing();
				}
			}).catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
		} else {
			setShowAlert(true);
			setAlertStatus(false);
			setAlertMsg("Incorrect audio file format. Please try again.");
		}
		fileInputRef.current = null;
	}

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const getReadableFileSizeString = (fileSizeInBytes: number) => {
		if (fileSizeInBytes) {
			let i = -1;
			const byteUnits = [
				" kb",
				" Mb",
				" Gb",
				" Tb",
				"Pb",
				"Eb",
				"Zb",
				"Yb"
			];
			do {
				fileSizeInBytes = fileSizeInBytes / 1024;
				i++;
			} while (fileSizeInBytes > 1024);

			return Math.max(fileSizeInBytes, 0.1).toFixed(1) + byteUnits[i];
		} else {
			return 0;
		}
	};

	useEffect(() => {
		// setLoading(false);
		 getStorageConsumed();

		if (user) {
			if (user.storageCapacity != null) {
				let converterSize: any = getReadableFileSizeString(user.storageUsage);
				setStorageConsumed(converterSize);

				// Set Storage as 50 GB default untill avaialble from backend
				let totalSize: any = getReadableFileSizeString(user.storageCapacity as any)
				setStorageAvailable(totalSize);

				let consumptionPercent = (user.storageUsage / (user.storageCapacity as any)) * 100;
				setStorageConsumedPerc(consumptionPercent);
			}
		}
	}, []);

	const getStorageConsumed = () => {
		setLoading(true);
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/get-storage-usage`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					// body: JSON.stringify({
					// 	searchString: searchString,
					// 	pageNumber: pageNumber,
					// 	itemPerPage: itemPerPage,
					// }),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data > 0) {
						let converterSize: any = getReadableFileSizeString(data);
						setStorageConsumed(converterSize);

						// Set Storage as 50 GB default untill avaialble from backend
						let totalSize: any = getReadableFileSizeString(50 * 1024 * 1024 * 1024)
						setStorageAvailable(totalSize);

						let consumptionPercent = (data / (50 * 1024 * 1024 * 1024)) * 100;
						setStorageConsumedPerc(consumptionPercent);
					}
				})
				.catch((err) => {
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	}

	return (
		<>
			<SettingsAlert />
			<ErrorAlertDisplay alertMsg={alertMsg} alertStatus={alertStatus} showAlert={showAlert} handleAlertClose={handleAlertClose} />
			<Snackbar
				open={Boolean(fileSendError)}
				headline="Error"
				message={fileSendError || ""}
				variant="error"
				handleClose={() => setFileSendError(null)}
			/>
			<Grid className="mainPanel">
				<Grid
					container={true}
					className="column jam-session mainPanel"

				>
					<Grid item xs={12} py={2}>
						<Typography
							className="home-title-custom-size"
							style={{
								fontStyle: "normal",
								fontWeight: "100",
								lineHeight: "49px",
								color: "#000000",
								// marginBottom: "18px",
								zIndex: "1",
								// position: "sticky",
								// top: "80px",
							}}
						>
							Jam Session / My Music
						</Typography>
					</Grid>

					<Grid container >
						<Grid item xs={3} className={`${classes.recordingTabs} ${classes.recordingOptionsCard} leftPanelWidth`} sx={{padding: "1px"}}>
							{/* <Recordings /> */}
							<div style={{ }}>
								{/* <input
								ref={fileInputRef}
								type="file"
								// style={{ display: "none" }}
								onChange={handleSendFile}
								// value={""}
								accept={ALLOWED_FILE_TYPES}
							> */}
								{/* <Button className={classes.recordingBtn} variant="contained">Upload Music</Button> */}
								{/* <label>
									<Box
										className={classes.recordingBtn}
										id="photo-upload"
										sx={{
											textAlign: 'center',
											color: 'white',
											borderRadius: "20px", fontSize: "0.9rem",
											fontWeight: "var(--joy-fontWeight-lg)",
											py: 0.7,
										}}>
										Upload Music
									</Box>
									<input
										id="photo-upload"
										type="file"
										accept="audio/*,.mp3,.wav,.mpeg"
										style={{ display: "none" }}
										// multiple
										ref={fileInputRef}
										onChange={musicUpload}
									/>
								</label> */}
								{/* </input> */}
								{/* <Button className={classes.newFolderBtn} variant="outlined" disabled>New Folder</Button> */}
							</div>
							{/* <p>Container1</p> */}

							{
								user.isCollaborationActive === true ?
							<Box >
								<Typography variant="subtitle2" sx={{color: "#7A7985", paddingLeft: "16px", mb: "-5px", mt: "25px"}}>
									System Folders
								</Typography>
								<MenuList>
									{menus.map((menu, key) => {
										return <>
											<MenuItem
												key={key}
												onClick={() => changeMenu(menu)}
												sx={{
													padding: "10px 0 10px 16px",
													whiteSpace: 'normal',
													overflowX: 'auto',
													fontFamily: 'Avenir',
													fontStyle: "normal",
													fontWeight: "600 !important",
													fontSize: "1.125rem !important",
													lineHeight: "25px",
													backgroundColor: activeMenu === menu.id ? "#F8F8F8" : "none",
													borderBottom: "1px solid #D7D7D7",
												}}
												disabled={menu.disabled}
											>
												<ListItemIcon>
													{activeMenu === menu.id ? (
														<img src={menu.activeIcon} alt="Account&Login" />
													) : (
														<img src={menu.icon} alt="Account&Login" />
													)}
												</ListItemIcon>
												<ListItemText primaryTypographyProps={{
													style: { fontSize: "1.125rem" },
												}}>{menu.title}</ListItemText>
												<ListItemIcon>
													{activeMenu === menu.id && <img src={Next} alt="active" />}
												</ListItemIcon>
											</MenuItem>
											{/* {menu.showDivider && <Divider variant="fullWidth" component="li" />} */}
										</>
									})}
								</MenuList>
							</Box>
								: <></>
							}

							<Box >
								<Typography variant="subtitle2" sx={{color: "#7A7985",paddingLeft: "16px", mb: "-5px", mt: "20px"}}>
									User Folders
								</Typography>
								<MenuList>
									{subMenus.map((menu) => {
										return <>
											<MenuItem
												onClick={() => changeMenu(menu)}
												sx={{
													padding: "10px 0 10px 16px",
													whiteSpace: 'normal',
													overflowX: 'auto',
													fontFamily: 'Avenir',
													fontStyle: "normal",
													fontWeight: "600 !important",
													fontSize: "1.125rem",
													lineHeight: "25px",
													backgroundColor: activeMenu === menu.id ? "#F8F8F8" : "none",
													borderBottom: "1px solid #D7D7D7",
												}}
												disabled={menu.disabled}
											>
												<ListItemIcon>
													{activeMenu === menu.id ? (
														<img src={menu.activeIcon} alt="Account&Login" />
													) : (
														<img src={menu.icon} alt="Account&Login" />
													)}
												</ListItemIcon>
												<ListItemText primaryTypographyProps={{
													style: { fontSize: "1.125rem" },
												}}>{menu.title}</ListItemText>
												<ListItemIcon>
													{activeMenu === menu.id && <img src={Next} alt="active" />}
												</ListItemIcon>
											</MenuItem>
											{/* <Divider variant="fullWidth" component="li" /> */}
										</>
									})}
								</MenuList>
							</Box>

							<Box>
								<Grid container paddingTop={2} paddingLeft="16px" paddingRight="16px">
									<Grid item xs={1} sx={{mr: "12px"}} className="verticallyCenter" >
										<img src={CloudStorageBlue} alt="Storage" />
									</Grid>
									<Grid item xs={10}>
										<Typography variant="h6">
										  Storage
										</Typography>
									</Grid>
									<Grid xs={12} my={1}>
										<LinearProgress className={classes.progressBar} variant="determinate" value={storageConsumedPerc} sx={{ color: "#888888 !important" }} />
									</Grid>
									<Grid item xs={12}>
										<Typography variant="subtitle1" color="#888">
											{storageConsumed + ' of ' + storageAvailable + ' used'}
										</Typography>
									</Grid>
								</Grid>
							</Box>

							<Box>
								<Grid container xs={12} className="center-align" my={4}>
									{/* <Grid item xs={8} >
										<Button variant="outlined" size="large" fullWidth onClick={() => navigate("/plans")} disabled>Buy Storage</Button>
									</Grid> */}
								</Grid>
							</Box>

						</Grid>

						<Grid item xs={9} className="centerPanelwoRight">
						<SessionRecordings menus={menus} activeMenuTitle={activeMenuTitle} activeMenu={activeMenu} laodMusicDetails={laodMusicDetails} reloadStorage={getStorageConsumed} />
						</Grid>
					</Grid>

				</Grid>

			</Grid>
		</>
	);
};

export default MyMusic;
