import React, { useRef } from 'react';
import {
    Dialog,
    DialogContent,
    Grid,
    Button,
    Typography,
    DialogActions,
    Box,
} from "@mui/material";
import { useState } from "react";
import Cropper from "react-cropper";
import 'cropperjs/dist/cropper.css';
import { useAlertStatus } from "../context/AlertStatusContext";
import SettingsAlert from './SettingsAlert';


const ImageCropper = ({ open, setOpen, onClose, imagePreviewUrl, setImagePreviewUrl, setResult, post, photoUpload, cover, editImageStatus, homeSidebar, comment }: any) => {
    const [saveLoading, setSaveLoading] = useState(false);

    const { updateAlertStatus } = useAlertStatus();

    const handleClose = () => {
        if(comment){
        setImagePreviewUrl('');
        setResult('');
        }
        setSaveLoading(false);
        onClose();
    };

    const cropperRef = useRef<any>(null);
    
    const getCropData = () => {
        if (cropperRef.current && typeof cropperRef.current?.cropper.getCroppedCanvas === 'function') {
            const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
            if (croppedCanvas) {
                croppedCanvas.toBlob((blob: any) => {
                    const croppedImage = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
                    !homeSidebar && setImagePreviewUrl(croppedCanvas.toDataURL())
                    const maxSize = 4 * 1024 * 1024;
                    if (croppedImage.size > maxSize) {
                        updateAlertStatus(true, false, "Max image size should be 4mb");
                        setSaveLoading(false); 
                    } else {
                        setSaveLoading(true);
                        setResult(croppedImage)
                        photoUpload(croppedImage)
                        setTimeout(() => {
                            onClose();
                            setSaveLoading(false);
                        }, 1000);
                    }

                }, 'image/jpeg');
            }
        }
    };

    return (
        <>
            <Dialog open={open} sx={{
                border: "1px solid #888888",
                boxShadow: "customColor.dropShadow",
                borderRadius: "8px",
                zIndex: 9999
            }}
                fullWidth
                maxWidth="sm">
                <SettingsAlert />
                <DialogContent>
                    <Grid container
                        style={{ display: "flex", flexDirection: "column", alignContent: "center", height: '400px' }}>
                        <Grid item={true} sx={{ position: 'relative', backgroundColor: 'white', height: '78%' }}>

                            <Cropper
                                ref={cropperRef}
                                style={{ height: 400, width: "100%" }}
                                // zoomTo={0.5}
                                initialAspectRatio={undefined}
                                aspectRatio={undefined}
                                preview=".img-preview"
                                src={imagePreviewUrl}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                guides={true}
                                movable={true}               // Allow moving the crop box
                                cropBoxResizable={true} 
                            />

                        </Grid>
                        {/* <Grid item container sx={{ p: 3, height: '10%' }}>
                        <Grid item xs={3}>
                            <Typography> Zoom </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Slider
                                value={zoom}
                                min={1}
                                max={3}
                                step={0.1}
                                aria-labelledby="Zoom"
                                onChange={(e, zoom) => setZoom(zoom)}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container sx={{ px: 3, py: 1, height: '10%' }}>
                        <Grid item xs={3}>
                            <Typography> Rotation </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Slider
                                value={rotation}
                                min={0}
                                max={360}
                                step={1}
                                aria-labelledby="Rotation"
                                onChange={(e, rotation) => setRotation(rotation)}
                            />
                        </Grid>
                    </Grid> */}
                    </Grid>
                </DialogContent>
                {post ?
                    <DialogActions>
                        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: -1, flexDirection: 'row-reverse' }}>
                            <Typography sx={{ fontSize: "14px" }}> To crop, click and drag on the image. Adjust the zoom level for precise cropping.</Typography>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ borderRadius: '17px', background: '#FF702A', px: 2 }}
                                onClick={getCropData}
                            > Next
                            </Button>
                            <Button
                                sx={{ borderRadius: '17px' }}
                                size="small"
                                variant="outlined"
                                onClick={handleClose}
                            >Cancel
                            </Button>
                        </Box>
                    </DialogActions>
                    :
                    <DialogActions>
                        <Grid sx={{ display: "flex", flexDirection: "column" }}>
                            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: -1, flexDirection: 'row-reverse' }}>
                                <Typography sx={{ fontSize: "14px" }}> To crop, click and drag on the image. Adjust the zoom level for precise cropping.</Typography>
                                <Button
                                    sx={{ borderRadius: '17px' }}
                                    size="small"
                                    variant="outlined"
                                    onClick={handleClose}
                                >Cancel
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{ borderRadius: '17px', background: '#FF702A', px: 2 }}
                                    onClick={getCropData}
                                    disabled={editImageStatus || saveLoading}
                                > {saveLoading ? 'Saving' : 'Save'}
                                </Button>
                            </Box>
                        </Grid>
                    </DialogActions>
                }
            </Dialog>
        </>
    );
};

export default ImageCropper;
