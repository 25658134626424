import { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
	Typography,
	Grid,
	Link,
	CircularProgress
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, styled } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import SettingsAlert from "../../pages/SettingsAlert";
import i18n from "../../l10n/strings.json";
import UserCardShort from "./UserCardShort";
import CenterCircularProgress from "../CenterCircularProgress";
import { musicianCardInterface } from "../../types/types";
// import AdsComponent from "../commonComponents/AdsComponent";
import { MusiciansRecommended } from "../commonComponents/Recommendation/MusiciansRecommended";

type RouteParams = {
	menuId: string;
};

type AllMusicians = {
	recentJoiners: Array<musicianCardInterface>,
	mostActive: Array<musicianCardInterface>,
	recommended: Array<musicianCardInterface>
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			// padding: "1% 2%",
			borderRadius: "15px",
			//boxShadow: "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6"
		},
		musicMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#000 !important",
			// background: "#0273E6"
		},
		instrumentStack: {
			margin: "5% 0",
			"& div": {
				padding: "1%",
				margin: "1% 0",
				backgroudColor: "#FAFAFA",
				borderRadius: "15px"
			}
		},
		linkColor: {
			color: "#0273E6 !important"
		}
	})
);


const AllMusicians = () => {
	const param = useParams<RouteParams>();
	const classes = useStyles();
	const user = useUser();
	const navigate = useNavigate();
	const location = useLocation();
	const token = localStorage.getItem("sessionId");

	const [musicians, setMusicians] = useState<AllMusicians>({ recentJoiners: [], mostActive: [], recommended: [] });
	const [loading, setLoading] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [searchString, setSearchString] = useState("");


	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	  });

	  let initialItemPerPage = 0;

	  // Set the initialItemPerPage based on the condition
	 if(windowSize.width === 1024 && windowSize.height === 1366){
		initialItemPerPage = 3;
	  } else {
		initialItemPerPage = 4;
	  }

	const [itemPerPage, setItemPerPage] = useState(initialItemPerPage);

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
			  width: window.innerWidth,
			  height: window.innerHeight,
			});
		  };
		window.addEventListener('resize', handleResize);
		if(windowSize.width === 1024 && windowSize.height === 1366){
			initialItemPerPage = 3;
		  }
		 else {
			initialItemPerPage = 4;
		  }
		  setItemPerPage(initialItemPerPage)
		getAllMusicians();
		return () => {
		  window.removeEventListener('resize', handleResize);
		}


	}, [windowSize]);



	const getAllMusicians = () => {

		setLoading(true);

		// setMusicians({recentJoiners: userCardDetails, mostActive: userCardDetails, recommended: userCardDetails});
		try {
			setMusicians({ recentJoiners: [], mostActive: [], recommended: [] });
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/user-connections/all-musicians/${user.id}?searchString=${searchString}&page=${pageNumber}&size=${itemPerPage}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					// body: JSON.stringify({
					// 	searchString: searchString,
					// 	pageNumber: pageNumber,
					// 	itemPerPage: itemPerPage,
					// }),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					// setMusicians(data);
					setMusicians({ recentJoiners: data.recentJoiners, mostActive: data.mostActive, recommended: data.recentJoiners });
				})
				.catch((err) => {
				})
				.finally(() => {
					setLoading(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	return (
		<>
			{loading ? (
				<Grid container item justifyContent="center" sx={{ p: "16px" }}>
					<CircularProgress /> {/* The loading spinner */}
				</Grid>
			) : (
				<>
				<Grid container xs={12}  className="centerPanelwoRightGig">
					{musicians.recentJoiners && musicians.recentJoiners.length > 0 ? (

						<Grid xs={12} item>
							<Grid
								item
								xs={12}
								margin={1}
								className={classes.shadowedBox}
								padding="1% 2%"
								mb={2.5}
								mt={0}
							>
								<Grid container={true} xs={12} >
									<Grid item={true} xs={5}>
										<Typography variant="h5" sx={{ fontSize: "1.5rem", lineHeight: "1.875rem" }}>Recent Joiners</Typography>
									</Grid>
									<Grid item={true} xs={7} className="right-align">
										<Link
											className={classes.linkColor}
											href="javascript:void(0)"
											onClick={() => navigate("/musicians/recent")}
										>
											View All
										</Link>
									</Grid>
								</Grid>

								{/* User Cards */}
								<Grid xs={12} container >
									{musicians.recentJoiners.map((user) => {
										return (
											<UserCardShort
												user={user}
												refreshList={getAllMusicians}
											/>
										);
									})}
								</Grid>
							</Grid>
						</Grid>

					) : (
						""
					)}

					{/* Advertisement  */}
					{/* <Grid
						item
						xs={12}
						p={1}
						m={1}
						sx={{ background: "#87ceeb4a", borderRadius: "15px" }}
					>
						<AdsComponent dataAdSlot="4022680804" />
					</Grid> */}

					{/* Most Active Users */}
					{musicians.mostActive && musicians.mostActive.length > 0 ? (
						<Grid xs={12}>
							<Grid
								item
								xs={12}
								margin={1}
								className={classes.shadowedBox}
								padding="1% 2%"
								mb={2.5}
							>
								<Grid container={true} xs={12}>
									<Grid item={true} xs={3}>
										<Typography variant="h5" sx={{ fontSize: "1.5rem", lineHeight: "1.875rem" }}>Most Active</Typography>
									</Grid>
									<Grid item={true} xs={9} className="right-align">
										<Link
											className={classes.linkColor}
											href="javascript:void(0)"
											onClick={() => navigate("/musicians/active")}
										>
											View All
										</Link>
									</Grid>
								</Grid>

								{/* User Cards */}
								<Grid xs={12} container justifyContent="space-around">
									{musicians.mostActive.map((user) => {
										return (
											<UserCardShort
												user={user}
												refreshList={getAllMusicians}
											/>
										);
									})}
								</Grid>
							</Grid>
						</Grid>
					) : (
						""
					)}

					{/* Recomended to You Users */}
					{musicians.recommended  ? (
						<Grid xs={12}>
							<Grid
								item
								xs={12}
								margin={1}
								className={classes.shadowedBox}
								padding="1% 2%"
								mb={3}
							>
								<Grid container={true} xs={12}>
									<Grid item={true} xs={5}>
										<Typography variant="h5" sx={{ fontSize: "1.5rem", lineHeight: "1.875rem" }}>Recommended to You</Typography>
									</Grid>
									<Grid item={true} xs={7} className="right-align">
										<Link
											className={classes.linkColor}
											href="javascript:void(0)"
											onClick={() => navigate("/musicians/recomended")}
										>
											View All
										</Link>
									</Grid>
								</Grid>

								{/* User Cards */}
								<Grid xs={12} container>
									{/* {musicians.recommended.map((user) => {
										return (
											<UserCardShort
												user={user}
												refreshList={getAllMusicians}
											/>
										);
									})} */}
										<MusiciansRecommended />
								</Grid>
							</Grid>
						</Grid>
					) : (
						""
					)}
				</Grid>

				{/* <Grid container xs={12} lg={12}>
					<Grid item xs={12}>
						<AdsComponent dataAdSlot="6769027983" />
					</Grid>
				</Grid> */}
				</>
			)}
		</>
	);
};

export default AllMusicians;
