import { useState, useEffect } from "react";
import { Outlet, useParams, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
    Alert,
    Box,
    MenuList,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Typography,
    Divider,
    Grid,
    Button,
    FormControl,
    FormHelperText,
    TextField,
    Stack,
    Checkbox,
    FormControlLabel,
    Autocomplete,
    Slider,
    Card
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme, styled } from "@mui/material/styles";
// contexts
import { useUser } from "../../context/UserContext";
import SettingsAlert from "../../pages/SettingsAlert";
import AllMusicians from "./AllMusicians";
import MyConnections from "./MyConnections";
import Invitations from "./Invitations";
import i18n from "../../l10n/strings.json";
import InstrumentSkillLevel from "./InstrumentSkills";

import Guitar from "../../images/guitar.svg";
import Drums from "../../images/drums.svg";
import Violin from "../../images/Violin.svg";
import Trumpet from "../../images/trumpet.svg";
import MusicNote from "../../images/musicnote.svg";
import Account from "../../images/account.svg";
import ActiveAccount from "../../images/active-account.svg";
import Security from "../../images/security.svg";
import ActiveSecurity from "../../images/active-security.svg";
import Next from "../../images/next.svg";
import InvitationSent from "../../images/InvitationSent.svg"
import InvitationsSent from "./InvitationsSent";
import InvitationsRejected from "./InvitationsRejected";
import MusiciansFilter from "./MusiciansFilter";

import { useMusiciansFilter } from "./MusicProvider/useMusicianFilter";
import { useMusicianCounts } from "./MusicProvider/useMusicianCounts";
import MusicianSearchResult from "./MusicianSearchResult";
import Events from "../Social/events";
import SimilarArtists from "../Social/similarArtists";
import useAdminRedirect from "../../hooks/useAdminRedirect/useAdminRedirect";

type RouteParams = {
    menuId: string;
};

const musiciansMenu = [
    {
        id: "all",
        label: "All Musicians",
        navigateMenu: "",
        countKey: ""
    },
    {
        id: "connections",
        label: "Your Connections",
        navigateMenu: "connections",
        countKey: "totalConnections"
    },
    {
        id: "invitations",
        label: "Invitations",
        navigateMenu: "invitations",
        countKey: "totalReceivedConnectionRequests"
    }
];

const invitationsMenu = [
    {
        id: "invitations-received",
        label: "Received",
        activeIcon: ActiveAccount,
        inActiveIcon: Account,
        disabled: false,
        countKey: "totalReceivedConnectionRequests"
    },
    {
        id: "invitations-sent",
        label: "Sent",
        activeIcon: InvitationSent,
        inActiveIcon: InvitationSent,
        disabled: false,
        countKey: "totalSentConnectionRequests"
    },
    {
        id: "invitations-rejected",
        label: "Rejected",
        activeIcon: ActiveSecurity,
        inActiveIcon: Security,
        disabled: false,
        countKey: "totalRejectedConnectionRequests"
    }
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        shadowedBox: {
            padding: "1% 2%",
            borderRadius: "15px",
            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
        },
        activeMenu: {
            fontStyle: "normal",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "22px",
            color: "#ffffff",
            background: "#0273E6"
        },
        musicMenu: {
            fontStyle: "normal",
            fontWeight: "500",
            // fontSize: "36px",
            lineHeight: "22px",
            color: "#000 !important",
            // background: "#0273E6"
        },
    })
);


const Musicians = () => {
		useAdminRedirect();
    const param = useParams<RouteParams>();
    const classes = useStyles();
    const user = useUser();
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("sessionId");
    const musicFilter = useMusiciansFilter();
    const { musicianCount, resetMusicianCount } = useMusicianCounts();
    const { resetMusicianFilter } = useMusiciansFilter();

    const [genres, setGenres] = useState("");
    const [activeMenu, setActiveMenu] = useState("");
    const [activeSubMenu, setActiveSubMenu] = useState("invitations-received");
    // const [count, setCount] = useState({"totalSentConnectionRequests": 0, "totalConnections":0, "totalInvitations":0, "totalReceivedConnectionRequests":0, "totalRejectedConnectionRequests":0 })
   
    useEffect(() => {
        let user = localStorage.getItem('user');
     if (user) {
       try {
         const userDetails = JSON.parse(user);
         if (!userDetails.firstName || !userDetails.lastName || !userDetails.location) {
           navigate("/socialProfileNew", {
             state: {
               email: userDetails.email,
               password: "",
             },
           });
         }
       } catch (e) {
         console.error("Failed to parse user data:", e);
       }
     }
    }, [navigate]);

    useEffect(() => {
        let urlMenu = window.location.href.split("/").pop();
        if (urlMenu === "connections" || urlMenu === "invitations") {
            setActiveMenu(urlMenu);
        } else {
            setActiveMenu("all");
        }
    }, []);

    const changeMenu = (menu: any) => {
        setActiveMenu(menu.id);
        navigate(menu.navigateMenu);
        resetMusicianCount();
        resetMusicianFilter();
    };

    const changeSubMenu = (value: string) => {
        setActiveSubMenu(value);
        resetMusicianCount();
    };


    return (
        <>
            <Grid className="mainPanel"
            >
                <Grid
                    container={true}
                    className="column"
                    rowSpacing={{ xs: 1 }}

                >
                    <Grid item={true}
                        className="center-align"
                        style={{
                            padding: "10px",
                        }}
                    >
                        <SettingsAlert />
                    </Grid>

                    <Grid container={true} columns={{ xs: 12 }}
                            sx={{ display: "flex", marginTop:"24px !important"}}
                            rowSpacing={{ xs: 1 }} >
                            <Grid item={true}  xs={3}  className="leftPanelWidth">
                                <Typography
                                    className="home-title-custom-size center-align"
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: "500",
                                        fontSize: "36px !important",
                                        lineHeight: "normal"
                                    }}>Musicians
                                </Typography>

                            </Grid>
                            <Grid item={true} xs={8} gap={3} className= "musicianBtns ">
                                {
                                    musiciansMenu.map((menu: any) => {
                                        let keyForCount: any = Object.keys(musicianCount).find(key => { return key == menu.countKey });
                                        return menu.id === activeMenu ?
                                            <Button variant="contained" sx={{ mr: "27px", background: "#0273E6", fontSize: "1.125rem", '&:hover': { background: "#0273E6" }, }} className={classes.activeMenu} onClick={() => changeMenu(menu)}
                                            >{menu.label}
                                                {
                                                    //@ts-ignore
                                                    (menu.countKey && menu.countKey != "" && musicianCount[keyForCount] > 0) ? '(' + musicianCount[keyForCount] + ')' : ''
                                                }
                                            </Button>
                                            :
                                            <Button variant="text" sx={{ mr: "27px", '&:hover': { background: "#EEF6FF" }, fontSize: "1.125rem", lineHeight: "1.40625rem" }} className={classes.musicMenu} onClick={() => changeMenu(menu)}
                                            >{menu.label}
                                                {
                                                    //@ts-ignore
                                                    (menu.countKey && menu.countKey != "" && musicianCount[keyForCount] > 0) ? '(' + musicianCount[keyForCount] + ')' : ''
                                                }
                                            </Button>
                                    })
                                }
                            </Grid>
                    </Grid>

                    <Grid item={true} className="rowa mainPanel" sx={{ marginTop: "1em !important", zIndex: 1 }}>
                        <Grid container={true} columns={{ xs: 12 }}
                            style={{ display: "flex" }}
                            rowSpacing={{ xs: 1 }} className="mainPanel">
                            {
                                activeMenu === "invitations" ?
                                    <Grid item xs={3} md={3} xl={3} className="leftPanelWidth">
                                        <Grid className={classes.shadowedBox} container xs={12} sx={{ height: "fit-content", width: "288px" }}>
                                            <Grid item xs={12}>
                                                <Typography variant="h5" sx={{ textAlign: "center", fontWeight: "bolder" }}>Invitations</Typography>
                                            </Grid>
                                            <Grid item xs={12} width="100%">
                                                <Box>
                                                    <MenuList sx={{ width: "100%" }}>
                                                        {
                                                            invitationsMenu.map(subMenu => {
                                                                let keyForCount: any = Object.keys(musicianCount).find(key => { return (key == subMenu.countKey) });
                                                                return <>
                                                                    <MenuItem
                                                                        onClick={() => changeSubMenu(subMenu.id)}
                                                                        sx={{
                                                                            padding: "10px 0 10px 14px",
                                                                            whiteSpace: 'normal',
                                                                            overflowX: 'auto',
                                                                        }}
                                                                        disabled={subMenu.disabled}
                                                                    >
                                                                        <ListItemIcon>
                                                                            {activeSubMenu === subMenu.id ? (
                                                                                <img src={subMenu.activeIcon} alt={subMenu.label} />
                                                                            ) : (
                                                                                <img src={subMenu.inActiveIcon} alt={subMenu.label} />
                                                                            )}
                                                                        </ListItemIcon>
                                                                        <ListItemText>
                                                                            {subMenu.label}
                                                                            {
                                                                                //@ts-ignore
                                                                                (subMenu.countKey && subMenu.countKey != "" && musicianCount[keyForCount] > 0) ? '(' + musicianCount[keyForCount] + ')' : ''
                                                                            }
                                                                        </ListItemText>
                                                                        <ListItemIcon>
                                                                            {activeSubMenu === subMenu.id && <img src={Next} alt="active" />}
                                                                        </ListItemIcon>
                                                                    </MenuItem>
                                                                    <Divider variant="fullWidth" component="li" />
                                                                </>
                                                            })
                                                        }
                                                    </MenuList>
                                                </Box>
                                            </Grid>

                                        </Grid>

                                        <Grid item xs={12} sx={{ mt: 3.5,  }}>
                                            <Card>
                                                <SimilarArtists/>
                                            </Card>
                                        </Grid>

                                        <Grid item xs={12} sx={{ mt: 3.5,  }}>
                                            <Card>
                                                <Events />
                                            </Card>
                                        </Grid>

                                    </Grid>
                                    :
                                    <Grid xs={3} item className="leftPanelWidth">
                                        <MusiciansFilter />

                                        <Card sx={{ mt: 3.5 }}>
                                            <SimilarArtists/>
                                        </Card>

                                        <Card sx={{ mt: 3.5 }}>
                                            <Events />
                                        </Card>
                                    </Grid>
                            }
                            <Grid item xs={9} md={9} xl={9} className="centerPanelwoRightGig">
                                {/* <Outlet /> */}
                                {musicFilter.isFilterActive ? <MusicianSearchResult />
                                    :
                                    <>
                                        {activeMenu === "all" && <AllMusicians />}
                                        {activeMenu === "connections" && <MyConnections />}
                                        {activeMenu === "invitations" && activeSubMenu === "invitations-received" && <Invitations />}
                                        {activeMenu === "invitations" && activeSubMenu === "invitations-sent" && <InvitationsSent />}
                                        {activeMenu === "invitations" && activeSubMenu === "invitations-rejected" && <InvitationsRejected />}
                                    </>
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Musicians;
