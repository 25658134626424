import { useState, useEffect, useRef, SyntheticEvent } from "react";
import {
	Typography,
	Grid,
	Paper,
	Stack,
	Button,
	LinearProgress,
	Link,
} from "@mui/material";
import Snackbar from "../Snackbar/Snackbar";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useParams, useNavigate } from "react-router-dom";

import CenterCircularProgress from "../CenterCircularProgress";
// import SessionRecordings from "./SessionRecordings";
import Recordings from "../../images/recordings.svg";
import RecordingCircle from "../../images/record-circle.svg";
import MusicPlaylist from "../../images/music-playlist.svg";
import MusicPlaylistSolid from "../../images/music-playlist-solid.svg";
import Next from "../../images/next.svg";
import { Security } from "@mui/icons-material";
import ErrorAlert from "../Error/ErrorAlert";
import { useUser } from "../../context/UserContext";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import SettingsAlert from "../../pages/SettingsAlert";
import { useAlertStatus } from "../../context/AlertStatusContext";
import Share from "../../images/share.svg";
import ShareActive from "../../images/share-active.svg";
import Device1 from "../../images/image 46.png";
import Device2 from "../../images/image 44.png";
import Device3 from "../../images/image 45.png";
import RightArrow from "../../images/right arrow.svg";
import GoldBadge from "../../images/Gold-badge.svg";
import CloudStorage from "../../images/cloud storage 2.svg";
import ShopRightSideBar from "./ShopRightSideBar";
import { useActiveSubscription } from "./SubscriptionProvider/useUserSubscription";
import { format, lightFormat } from "date-fns";
import PurchaseCompatibilityTest from "./PurchaseCompatibilityTest";
import DOMPurify from "dompurify";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		linearGradBtn: {
			width: "100%",
			// margin: "0.5em 0 !important",
			background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
			borderRadius: "18px !important",
		},
		newFolderBtn: {
			display: "none",
			width: "100%",
			margin: "0.5em 0 !important",
			border: "1.5px solid #0273E6",
			background: "#fff",
			borderRadius: "18px !important",
		},

		leftBarMenus: {
			"& img": {
				position: "absolute",
				left: "5%",
			},
		},
	})
);

type RouteParams = {
	menuId: string;
};

const Shop = () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
	const param = useParams<RouteParams>();
	const token = localStorage.getItem("sessionId");
	const { updateAlertStatus } = useAlertStatus();
	const user = useUser();
	const navigate = useNavigate();
	const { isDeviceAvailable, deviceDetails } = useActiveSubscription();

	// Alert states
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [isSendingFile, setIsSendingFile] = useState(false);
	const [fileSendError, setFileSendError] = useState<string | null>(null);
	const fileInputRef = useRef(null);
	const [alertMsg, setAlertMsg] = useState("");
	const [value, setValue] = useState(0);
	const [activeMenu, setActiveMenu] = useState(param.menuId ?? "Recording");
	const [activeMenuTitle, setActiveMenuTitle] = useState(
		param.menuId ?? "Recent Recordings"
	);
	const ALLOWED_FILE_TYPES = "audio/*";
	const ALLOWED_FILE_EXT = ["mp3", "wav", "mpeg"];
	const [laodMusicDetails, setLoadMusicDetails] = useState(false);
	const [menus, setMenus] = useState([
		// Hiding for Social Launch
		// {
		// 	id: "Recording",
		// 	title: "Jam Recordings",
		// 	icon: RecordingCircle,
		// 	activeIcon: Recordings,
		// 	disabled: false,
		// 	showDivider: true
		// },
		{
			title: "Shared with Me",
			icon: Share,
			activeIcon: ShareActive,
			id: "sharedWithMe",
			disabled: true,
			showDivider: false,
		},
	]);
	const [productDetails, setProductDetails] = useState<any | never | null>();
	const [showCompatibilityPopup, setShowCompatibilityPopup] = useState(false);

	const [subMenus, setSubMenus] = useState([
		{
			title: "Solo Recordings",
			icon: MusicPlaylist,
			activeIcon: MusicPlaylistSolid,
			id: "myMusic",
			disabled: false,
		},
	]);

	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if (!userDetails.firstName || !userDetails.lastName || !userDetails.location) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]);

	const getProductDetails = async () => {
		setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/master-subscription-plans`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data.length > 0) {
					let prodDetails = data.find(
						(plan: any) => plan.type === "Electronics" && plan.status
					);
					setProductDetails(prodDetails);
				}
			})
			.catch((err) => {
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setLoading(false);
		getProductDetails();
	}, []);

	if (loading) {
		return <CenterCircularProgress />;
	}

	const changeMenu = (value: any) => {
		setActiveMenu(value.id);
		let currentActiveMenuTitle = menus.find((menu) => {
			return menu.id === value.id;
		});
		setActiveMenuTitle(currentActiveMenuTitle!.title);

		// let recentIndex = menus.findIndex(menu => menu.id === "recent-recordings")
		// if (value === "recent-recordings") {
		// 	menus[recentIndex].icon = Recordings;
		// } else {
		// 	menus[recentIndex].icon = RecordingCircle;
		// }

		// let soloIndex = menus.findIndex(menu => menu.id === "solo-recordings")
		// if (value === "solo-recordings") {
		// 	menus[soloIndex].icon = MusicPlaylistSolid;
		// } else {
		// 	menus[soloIndex].icon = MusicPlaylist;
		// }
		// if (value === "deviceSetup") {
		//  getDevice();
		// } else {
		//  navigate(`/home/settings/${value}/${value}`);
		// }
	};

	const handleChange = (event: SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const handleCompatibilityTest = () => {
		setShowCompatibilityPopup(true);
	};

	return (
		<>
			<SettingsAlert />
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>
			<Snackbar
				open={Boolean(fileSendError)}
				headline="Error"
				message={fileSendError || ""}
				variant="error"
				handleClose={() => setFileSendError(null)}
			/>
			<Grid className="center-align" spacing={{ xs: 1 }}>
				<Grid
					container={true}
					className="column jam-session"
					style={{
						width: "80%",
						padding: "1em",
						margin: "1em 0",
					}}
				>
					<Grid item xs={12}>
						<Typography
							className="home-title-custom-size"
							style={{
								fontStyle: "normal",
								fontWeight: "100",
								lineHeight: "49px",
								color: "#000000",
								// marginBottom: "18px",
								zIndex: "1",
								// position: "sticky",
								// top: "80px",
							}}
						>
							Shop
						</Typography>
					</Grid>

					<Grid container xs={12}>
						<Grid item xs={9}>
							<Paper
								sx={{
									padding: 1,
									borderRadius: "12px",
									boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								}}
							>
								{/* <SessionRecordings menus={menus} activeMenuTitle={activeMenuTitle} activeMenu={activeMenu} laodMusicDetails={laodMusicDetails} /> */}
								<Grid container m={2}>
									<Grid xs={4}>
										<Typography variant="h3">Audio Box V1.2</Typography>
									</Grid>
									<Grid xs={8}>
										{isDeviceAvailable && (
											<Typography variant="subtitle1">
												{JSON.stringify(deviceDetails)}
												You already purchased this device on{" "}
												{format(
													new Date(deviceDetails.startDate),
													"MM/dd/yyyy"
												)}
												.{" "}
												<Link
													href="javascript:void(0)"
													onClick={() =>
														navigate(
															"/home/settings/subscriptionPayment/subscriptionPayment"
														)
													}
												>
													Go to Payments
												</Link>
											</Typography>
										)}
									</Grid>
								</Grid>

								<Grid container>
									<Grid item xs={4}>
										<Stack>
											{/* <img src={Device1} alt="Device"/>
                                            <img src={Device2} alt="Device2"/>
                                            <img src={Device3} alt="Device3"/> */}
											{productDetails && productDetails.images ? (
												<img src={productDetails?.images} alt="Device3" />
											) : (
												<></>
											)}
										</Stack>
									</Grid>

									<Grid item xs={8} padding={2}>
										{/* <Typography variant="h5" >
                                            Specifications
                                        </Typography>

                                        <div>
                                            <span>2 analog inputs (XLR/TRS combo)</span>
                                            <ul>
                                                <li>Line (balanced and unbalanced +22dBu max input level)</li>
                                                <li>Microphone preamp (-12dBu max input level)</li>
                                                <li>Instrument (unbalanced on TRS/TS connector only +13dBu max input level)</li>
                                                <li>Selectable 48V phantom power per port (XLR only)</li>
                                            </ul>

                                            <span>4 analog outputs</span>
                                            <ul>
                                                <li>2 unbalanced 1/4" line outputs, +2dBu maximum output level</li>
                                                <li>Stereo headphne output (selectable 1/4" or 3,5mm), +dBu maximum output level</li>
                                                <li>Digital IN/OUTPUT:ADAT, S/PDIF</li>
                                                <li>USB class-compliant (UAC-2) Audio/MIDI device</li>
                                                <li>A/D and D/A conversion 24-bit up to 192kHz</li>
                                                <li>Network - Gigabit (1000BASE-T)</li>
                                                <li>Power - 5V, 3A(15W) USB type C</li>
                                                <li>Dimensions - 140x140x45mm</li>
                                                <li>Weight - 483gr</li>
                                                <li>Audio OS: Elk Audio OS - Ultra Low-Latency Audio Oprating System</li>
                                            </ul>
                                        </div> */}
										{productDetails?.subscriptionDescContentType && (
											<div
												style={{ fontFamily: "Lexend", fontSize: "18px" }}
												dangerouslySetInnerHTML={{
													__html: DOMPurify.sanitize(
														productDetails.subscriptionDescContentType
													),
												}}
											></div>
										)}
									</Grid>
								</Grid>

                                <Grid container xs={12} justifyContent="center">
                                    <Grid item xs={3}>
                                        <Typography variant="h3" marginY={2} sx={{fontFamily: "Lexend", fontSize: "36px", fontWeight: 500}}> $ {productDetails?.cost}</Typography>
                                        <Button
											className={classes.linearGradBtn}
                                            variant="contained"
                                            onClick={handleCompatibilityTest}
                                            endIcon={<img src={RightArrow} alt="Buy Again" />}
                                            size="large"
										><Typography>{ isDeviceAvailable ? "Buy Again" : "Buy Now"}</Typography></Button>
                                    </Grid>
                                </Grid>
                            </Paper>
						</Grid>

						<Grid item xs={3} paddingX={1}>
							<ShopRightSideBar />
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{showCompatibilityPopup && (
				<PurchaseCompatibilityTest
					open={showCompatibilityPopup}
					onClose={() => setShowCompatibilityPopup(false)}
				/>

			)}


		</>
	);
};

export default Shop;
