import { useState, useContext, createContext } from "react";
// types
import PropTypes from "prop-types";
import type { ReactNode } from "react";
type BroadcastingStatusContextType = {
	isBroadcastingStarted: boolean;
	setIsBroadcastingStarted: (isBroadcastingStarted: boolean) => void;
};
const BroadcastingStatusContext = createContext<BroadcastingStatusContextType>(
	null!
);

export const useBroadcastingStatusContext = () =>
	useContext(BroadcastingStatusContext);

type Props = {
	children: ReactNode;
};

export const BroadcastingStatusProvider = (props: Props) => {
	const [isBroadcastingStarted, setIsBroadcastingStarted] =
		useState<boolean>(false);

	return (
		<BroadcastingStatusContext.Provider
			value={{
				isBroadcastingStarted,
				setIsBroadcastingStarted,
			}}
		>
			{props.children}
		</BroadcastingStatusContext.Provider>
	);
};

BroadcastingStatusProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
