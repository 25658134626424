import { useState, useEffect } from "react";
import { Outlet, useParams, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import {
	Alert,
	Box,
	MenuList,
	MenuItem,
	ListItemText,
	ListItemIcon,
	Typography,
	Divider,
	Grid,
	Button,
} from "@mui/material";
// contexts
import { useUser } from "../context/UserContext";
import Account from "../images/account.svg";
import Security from "../images/security.svg";
//import Preferences from "../images/pref.svg";
import DeviceSetup from "../images/keyboard.svg";
import ActiveAccount from "../images/active-account.svg";
import ActiveSecurity from "../images/active-security.svg";
//import ActivePreferences from "../images/active-preff.svg";
import ActiveDeviceSetup from "../images/device-setup.svg";
import Next from "../images/next.svg";
import SettingsAlert from "./SettingsAlert";
import RemoveDeviceSetting from "./RemoveDeviceSetting";
import Privacy from '../images/setting-4.svg';
import Card from '../images/card-pos.svg';
import Profile from '../images/profile-remove.svg';
import Notification from '../images/notification-bing1.svg';
import Dollar from '../images/dollar-circle.svg';
import Cards from '../images/cards1.svg';
import ProfileCircle from '../images/profile-circle11.svg'
import EditSocialSetting from "../images/user-octagon edit.svg";
import { useUserManageFilter } from "../components/AdminDashboard/ManageUserDashBoard/UserManagementProvider/useUserManageFilter";
import CompatibilityPopup from "./CompatibilityTestPage";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";


type RouteParams = {
	menuId: any;
};

const AdminSettingsMenu = () => {
	const param = useParams<RouteParams>();
	const user = useUser();
	const navigate = useNavigate();
	// let [searchParams] = useSearchParams();
	// const term = searchParams.get();
	const location = useLocation();

	const token = localStorage.getItem("sessionId");
	const [showCompatibilityPopup, setShowCompatibilityPopup] = useState(false);
	const { isDisabledOptions } = useUserManageFilter();

	const [activeMenu, setActiveMenu] = useState(param.menuId ?? "deviceSetup");
	const {adminSetUserId} = useAdminContext();

    const {userId, userProfileId, email, user: userDetails} = location?.state || {}
	const getDevice = () => {
		const authorities = user.authorities.map(
			(authority: { name: string }) => authority.name
		);
		const fetchDeviceOf = adminSetUserId? adminSetUserId.id : user.id;
		const fetchApi = adminSetUserId && authorities.includes("ROLE_ADMIN") ? `user-devices/${adminSetUserId.id}` :
		location.state?.userId  ? `user-devices/${location.state?.userId}` : "active-user-device"

		fetch(`${process.env.REACT_APP_BASE_URL}/api/${fetchApi}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
                    navigate("/home/admin/settings/deviceSetup/device", {
                        state: { userId, userProfileId, email, type: "edit", userDetails },
                    });
				}
				else {
					navigate(`/home/admin/settings/deviceSetup/add`, {
                        state: { userId, userProfileId, email, type: "edit", userDetails },
                    });
				}
			})
			.catch((err) => {
				navigate(`/home/admin/settings/deviceSetup/add`, {
                    state: { userId, userProfileId, email, type: "edit", userDetails },
                });
			});
	};

	const handleCompatibilityTest = () => {
		//setShowCompatibilityPopup(true);
		const url = `${process.env.REACT_APP_BASE_URL_FRONTEND}`;
		const baseUrl = new URL(url).origin;
		window.open(baseUrl + "/speedtest", '_blank');
		

	};

	useEffect(() => {
		if (param.menuId === "deviceSetup") {
			getDevice();
		}
		setActiveMenu(param.menuId);
	}, [param.menuId]);

	const changeMenu = (value: string) => {
		setActiveMenu(value);
		if (value === "deviceSetup") {
			getDevice();
		}
		else {
			navigate(`/home/admin/settings/${value}/${value}`, {
                state: { userId, userProfileId, email, type: "edit", userDetails },
            });
		}
	};
	return (
		<>
			<Grid className="mainPanel" spacing={{ xs: 1 }}>
				<Grid
					container={true}
					className="column mainPanel"
					sx={{
						width: "80%",
					}}
					rowSpacing={{ xs: 1 }}
				>
					<Grid
						item={true}
						className="center-align"
						style={{
							padding: "10px",
						}}
					>
						<SettingsAlert />
					</Grid>
					<Grid item={true}>
						<Grid
							container={true}
							columns={{ xs: 12 }}
							sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", mt: 2 }}
							className="mainPanel"
						>
							<Grid item={true} xs={9}>
								<Typography

									sx={{
										fontStyle: "normal",
										fontWeight: "500",
										fontSize: "2.25rem",
										lineHeight: "1.875rem",
										color: "#000000",
									}}
								>
									Settings
								</Typography>
							</Grid>

                  {(location.pathname ==='/home/admin/settings/deviceSetup/device' || location.pathname === "/home/admin/settings/device/device") && (
	                        <Button
									//className="cancel-button button-field-custom-font-size"
									size="large"
									variant="outlined"
									onClick={handleCompatibilityTest}
									sx={{ fontSize: '0.9rem',
									lineHeight: '20px',
									border: '2px solid #0273E6',
									color: '#0273E6',
									transition: 'border 0.1s',

									'&:hover': {
									  border: '2px solid #0273E6',
									  backgroundColor: '#E9F1FF'
									},}}
								>
									Compatibility Test
							</Button>
                   )}

						</Grid>
					</Grid>
					<Grid item={true} className="row mainPanel">
						<Grid
							container={true}
							columns={{ xs: 12 }}
							className=" mainPanel"	sx={{display: "flex",marginTop:"10px"}}				>
							<Grid item={true} xs={3} className="leftPanelWidth">
								<Box>
									<MenuList
										sx={{
											background: "#FFFFFF",
											boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											borderRadius: "12px",
										}}
									>
										<MenuItem
											onClick={() => changeMenu("account")}
											sx={{
												padding: "10px 0 10px 14px",
												whiteSpace: "normal",
												overflowX: "auto",
												backgroundColor: `${activeMenu === "account" && "#EAEAEA"
													}`,
											}}
										>
											<ListItemIcon>
												{activeMenu === "account" ? (
													<img src={ProfileCircle} alt="Account&Login" />
												) : (
													<img src={ProfileCircle} alt="Account&Login" />
												)}
											</ListItemIcon>
											<ListItemText>Account & Login</ListItemText>
											<ListItemIcon>
												{activeMenu === "account" && (
													<img src={Next} alt="active" />
												)}
											</ListItemIcon>
										</MenuItem>
										{/* <Divider variant="fullWidth" component="li" />
										<MenuItem
											onClick={() => changeMenu("security")}
											sx={{
												padding: "10px 0 10px 14px",
												whiteSpace: "normal",
												overflowX: "auto",
												backgroundColor: `${activeMenu === "security" && "#EAEAEA"
													}`,
											}}
											disabled={isDisabledOptions}
										>
											<ListItemIcon>
												{activeMenu === "security" ? (
													<img src={ActiveSecurity} alt="ActiveSecurity" />
												) : (
													<img src={Security} alt="Security" />
												)}
											</ListItemIcon>
											<ListItemText>Security</ListItemText>
											<ListItemIcon>
												{activeMenu === "security" && (
													<img src={Next} alt="active" />
												)}
											</ListItemIcon>
										</MenuItem> */}
										{/* {(user.authorities[0]?.name === "ROLE_USER" ||
											(user.authorities[0]?.name === "ROLE_ADMIN" 
                                            // && adminSetUserId
                                            )) && ( */}
												<>
													<Divider variant="fullWidth" component="li" />
													<MenuItem
														onClick={() => changeMenu("deviceSetup")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "deviceSetup" && "#EAEAEA"
																}`,
														}}
														// disabled={isDisabledOptions}
													>
														<ListItemIcon>
															{activeMenu === "deviceSetup" ? (
																<img src={ActiveDeviceSetup} alt="Device Setup" />
															) : (
																<img src={DeviceSetup} alt="Device Setup" />
															)}
														</ListItemIcon>
														<ListItemText>Device Setup</ListItemText>
														<ListItemIcon>
															{activeMenu === "deviceSetup" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
												</>
											 {/* )} */}
									</MenuList>
								</Box>

								{/* {(user.authorities[0]?.name === "ROLE_USER" ||
									(user.authorities[0]?.name === "ROLE_ADMIN" &&
										isDisabledOptions)) && (
										<>
											<Grid item={true} xs={9} sx={{ my: 1 }}>
												<Typography
													sx={{
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "1.5rem",
														lineHeight: "49px",
														color: "#000000",
													}}
												>
													Social
												</Typography>
											</Grid>
											<Box>
												<MenuList
													sx={{
														background: "#FFFFFF",
														boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
														borderRadius: "12px",
														mt: 1,
													}}
												>
													<MenuItem
														onClick={() => changeMenu("socialProfileSetting")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "socialProfileSetting" && "#EAEAEA"
																}`,
														}}
														disabled={isDisabledOptions}
													>
														<ListItemIcon>
															{activeMenu === "socialProfileSetting" ? (
																<img src={EditSocialSetting} alt="SocialProfile" />
															) : (
																<img src={EditSocialSetting} alt="SocialProfile" />
															)}
														</ListItemIcon>
														<ListItemText>Social Profile</ListItemText>
														<ListItemIcon>
															{activeMenu === "socialProfileSetting" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
													<Divider variant="fullWidth" component="li" />
													<MenuItem
														onClick={() => changeMenu("privacy")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "privacy" && "#EAEAEA"
																}`,
														}}
														disabled={isDisabledOptions}
													>
														<ListItemIcon>
															{activeMenu === "privacy" ? (
																<img src={Privacy} alt="Privacy" />
															) : (
																<img src={Privacy} alt="Privacy" />
															)}
														</ListItemIcon>
														<ListItemText>Privacy</ListItemText>
														<ListItemIcon>
															{activeMenu === "privacy" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
													<Divider variant="fullWidth" component="li" />
													<MenuItem
														onClick={() => changeMenu("gigs")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "gigs" && "#EAEAEA"
																}`,
														}}
														disabled={isDisabledOptions}
													>
														<ListItemIcon>
															{activeMenu === "gigs" ? (
																<img src={Cards} alt="Gigs" />
															) : (
																<img src={Cards} alt="Gigs" />
															)}
														</ListItemIcon>
														<ListItemText>Gigs & Collaboration</ListItemText>
														<ListItemIcon>
															{activeMenu === "gigs" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
													<Divider variant="fullWidth" component="li" />
													<MenuItem
														onClick={() => changeMenu("notificationSettings")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "notificationSettings" && "#EAEAEA"
																}`,
														}}
														disabled={isDisabledOptions}
													>
														<ListItemIcon>
															{activeMenu === "notificationSettings" ? (
																<img src={Notification} alt="Notifications" />
															) : (
																<img src={Notification} alt="Notifications" />
															)}
														</ListItemIcon>
														<ListItemText>Notifications</ListItemText>
														<ListItemIcon>
															{activeMenu === "notificationSettings" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
													<Divider variant="fullWidth" component="li" />
													<MenuItem
														onClick={() => changeMenu("blockedUsers")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "blockedUser" && "#EAEAEA"
																}`,
														}}
														disabled={isDisabledOptions}
													>
														<ListItemIcon>
															{activeMenu === "blockedUsers" ? (
																<img src={Profile} alt="BlockedUser" />
															) : (
																<img src={Profile} alt="BlockedUser" />
															)}
														</ListItemIcon>
														<ListItemText>Blocked user list</ListItemText>
														<ListItemIcon>
															{activeMenu === "blockedUsers" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
												</MenuList>
											</Box>

											<Grid item={true} xs={9} sx={{ my: 1 }}>
												<Typography
													sx={{
														fontStyle: "normal",
														fontWeight: "500",
														fontSize: "1.5rem",
														lineHeight: "49px",
														color: "#000000",
													}}
												>
													Payments
												</Typography>
											</Grid>
											<Box>
												<MenuList
													sx={{
														background: "#FFFFFF",
														boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
														borderRadius: "12px",
														mt: 1,
													}}
												>
													<MenuItem
														onClick={() => changeMenu("payment")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "payment" && "#EAEAEA"
																}`,
														}}
														disabled
													>
														<ListItemIcon>
															{activeMenu === "payment" ? (
																<img src={Card} alt="Payment" />
															) : (
																<img src={Card} alt="Payment" />
															)}
														</ListItemIcon>
														<ListItemText>Payment</ListItemText>
														<ListItemIcon>
															{activeMenu === "payment" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
													<Divider variant="fullWidth" component="li" />
													<MenuItem
														onClick={() => changeMenu("subscriptionPayment")}
														sx={{
															padding: "10px 0 10px 14px",
															whiteSpace: "normal",
															overflowX: "auto",
															backgroundColor: `${activeMenu === "subscriptionPayment" && "#EAEAEA"
																}`,
														}}
														disabled
													>
														<ListItemIcon>
															{activeMenu === "subscriptionPayment" ? (
																<img src={Dollar} alt="Subscription" />
															) : (
																<img src={Dollar} alt="Subscription" />
															)}
														</ListItemIcon>
														<ListItemText>Subscription & Invoices</ListItemText>
														<ListItemIcon>
															{activeMenu === "subscriptionPayment" && (
																<img src={Next} alt="active" />
															)}
														</ListItemIcon>
													</MenuItem>
												</MenuList>
											</Box>
										</>
									)} */}
							</Grid>
							<Grid
								item
								xs={9}
								className={`${
									activeMenu === "socialProfileSetting"
										? "centerPanelSocial"
										: "centerPanelWidth"
								}`}
							>
								<Outlet />
							</Grid>
							{user.authorities[0].name === 'ROLE_USER' &&
								<Grid
									item
									className={`hideInTabPro rightPanelWidth`}
									hidden={activeMenu === "socialProfileSetting"}
								>
									<MyActivitiesSidePanel />
								</Grid>
							}
						</Grid>
					</Grid>
				</Grid>
			</Grid>

			{showCompatibilityPopup && (
				<CompatibilityPopup
					open={showCompatibilityPopup}
					onClose={() => setShowCompatibilityPopup(false)}
				/>
			)}
		</>
	);
};

export default AdminSettingsMenu;
