import { useState } from 'react';
import { Card, Typography, IconButton, Grid, Dialog, DialogTitle, Box, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Like from '../../images/like.svg'
import CancelIcon from '../../images/Group 4282.svg';
import { useUser } from '../../context/UserContext';
import ErrorAlert from '../Error/ErrorAlert';
import CloseIcon from "../../images/close.svg";
import ViewImage from '../commonComponents/ViewImage';

const PictureCmp = ({ image, viewer, getPicturesDetails }: any) => {

    const user = useUser();
    const [likeStatus, setLikeStatus] = useState(image.myLikeStatus);
    const token = localStorage.getItem("sessionId");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showAlert, setShowAlert] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [alertMsg, setAlertMsg] = useState("");
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [alertStatus, setAlertStatus] = useState(false);
    const [openDeleteDialog, setOPenDeleteDialog] = useState(false)
    const [isModalOpen, setModalOpen] = useState(false);

    const handleClick = () => {
        setModalOpen(true);
    };

    const handleCloseDeleteDialog = () => {
        setOPenDeleteDialog(false);
    }

    const handleDeletePicture = () => {

        fetch(`${process.env.REACT_APP_BASE_URL}/api/mypictures`, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                id: image.id
			}),
        }).then((res) => {
            if (res.status === 200 || res.status === 204) {
                getPicturesDetails()
                handleCloseDeleteDialog()
            } else {
                ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
            }
        }).catch((err) => {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Network Error! Please try again..");
        });
    }

    const handleLike = (status: any) => {

        fetch(`${process.env.REACT_APP_BASE_URL}/api/like-photo`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                userId: user.id,
                photoId: image.id,
                action: status,
            }),
        }).then((res) => {
            if (res.status !== 200) {
                ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
            } else {
                setLikeStatus(status)
                getPicturesDetails()
            }
        }).catch((err) => {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Network Error! Please try again..");
        });

    }

    return (
        <>
            <Grid item xs={4} key={image}>
                <Grid container direction={'column'} spacing={2} >
                    <Grid item >
                        <Card sx={{
                            height: 280, position: 'relative',
                            // backgroundColor:"#D7D7D7"
                        }}>
                            {viewer === 'SELF' && user.userAccountStatus !== "SUSPEND" ?
                                <label
                                    style={{
                                        position: 'absolute',
                                        top: '5px',
                                        right: '5px',
                                        cursor: 'pointer',
                                        zIndex: 2,
                                    }}
                                >
                                    <IconButton id="photo-Cancel"
                                        aria-label="Edit" size='small' onClick={() => setOPenDeleteDialog(true)} >
                                        <img src={CancelIcon} alt="Edit" style={{ height: '24px', width: '24px' }} />
                                    </IconButton>
                                </label>
                                : ''}
                            <img
                                src={`${process.env.REACT_APP_PICTURE_URL}${image.pictureUrl}`}
                                id="upload-button"
                                alt="Upload"
                                style={{
                                    objectFit: "fill",
                                    // objectFit: "contain", 
                                    borderRadius: 'unset', width: '100%',
                                    height: '100%', cursor: 'pointer',
                                }}
                                onClick={handleClick}
                            />
                        </Card>
                    </Grid>

                    <Grid sx={{ alignSelf: 'self-end' }} >
                        {likeStatus ?
                            <IconButton color="primary" size="small" onClick={() => handleLike(false)} disabled={user.userAccountStatus === "SUSPEND"} >
                                <ThumbUpIcon sx={{ color: '#3F77D7', height: '24px', width: '24px' }} />
                            </IconButton> :
                            <IconButton color="primary" size="small" onClick={() => handleLike(true)} disabled={user.userAccountStatus === "SUSPEND"} >
                                <img src={Like} alt="Like" style={{ height: '24px', width: '24px' }} />
                            </IconButton>
                        }
                        <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black', fontSize: '14px', verticalAlign: 'middle' }}>{image.likesCount}</Typography>

                    </Grid>
                </Grid>
            </Grid>

            {isModalOpen &&
                <ViewImage open={isModalOpen} handleClose={() => setModalOpen(false)} ImageUrl={`${process.env.REACT_APP_PICTURE_URL}${image.pictureUrl}`} />
            }

            {openDeleteDialog &&
                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={openDeleteDialog}
                    onClose={() => handleCloseDeleteDialog()}
                >
                    <DialogTitle
                        className="edit-heading-name"
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-end",
                            }}
                        >
                            <IconButton
                                aria-label="close dialogbox"
                                onClick={() => handleCloseDeleteDialog()}
                                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                                    event.preventDefault()
                                }
                                edge="end"
                                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
                            >
                                <img src={CloseIcon} alt="close" />
                            </IconButton>
                        </Box>
                        <Typography>Delete Picture</Typography>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                            }}
                        >
                            Are you sure?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            onClick={() => handleCloseDeleteDialog()}
                        >
                            Cancel
                        </Button>
                        <Button variant="contained"
                            onClick={() => handleDeletePicture()}
                        >
                            Confirm
                        </Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}

export default PictureCmp