import { Card, CardContent, Grid, IconButton, Menu, MenuItem, Typography } from "@mui/material";
import Inactive from "../../../images/profile-tickpaid-users.svg";
import { useEffect, useState } from "react";
import ErrorAlert from "../../Error/ErrorAlert";
import ArrowDown from "../../../images/arrow-down-grey.svg";

const InactiveUsers = () => {
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const token = localStorage.getItem("sessionId");

	const itemsMap = new Map<string, string>([
		["90 day inactivity", "DAYS_90"],
		["6 months inactivity", "MONTHS_6"],
		["1 year inactivity", "YEAR_1"],
	]);

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

    const handleClose = (key: string, value: string) => {
        setAnchorEl(null);
        setSelectedItem(key);
        setSelectedValue(value);
      };

	const [totalInactiveUsers, setTotalInactiveUsers] = useState<number>(0);
	const [selectedItem, setSelectedItem] = useState<string>(
		Array.from(itemsMap.keys())[0]
	);
    const [selectedValue, setSelectedValue] = useState<string>(
        Array.from(itemsMap.values())[0]
    )
	const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedItem(event.target.value);
	};

	useEffect(() => {
		getTotalInactiveUsers(selectedValue);
	}, [selectedValue]);
	const getTotalInactiveUsers = (selectedItem: string) => {
		let duration = selectedItem;
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/management/admin-dashboard/total-inactive-user/?duration=${duration}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status !== 200) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((result) => {
				const displayValue = result >= 1000 ? `${(result / 1000).toFixed(result % 1000 === 0 ? 0 : 1)}k` : result.toString();
				setTotalInactiveUsers(displayValue);
			})
			.catch((error) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	return (
		<Card
			style={{
				width: "18rem",
				height: "178px",
				borderRadius: "12px",
				display: "flex",
				flexDirection: "column",
				justifyContent: "center",
				alignItems: "center",
				boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
			}}
			className="statCard"
		>
			<CardContent>
				<Grid container={true} className="center-align inActUserContainer">
					<Grid item={true}>
						<Typography
							variant="h6"
							gutterBottom
							style={{
								fontSize: "1.5rem",
								fontWeight: "500",
								lineHeight: "24px",
								letterSpacing: "0em",
								textAlign: "center",
							}}
						>
							Total Inactive Users
						</Typography>
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<img src={Inactive} alt="Icon" style={{ marginRight: "8px" }} />
							<Typography
								variant="h4"
								component="div"
								className="dispVal"
							>
								{totalInactiveUsers}
							</Typography>
						</div>
					</Grid>
					<Grid item={true} >
						<div
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
							className="inActIconBtn"
						>
							<Typography>{selectedItem}</Typography>
							<IconButton onClick={handleClick}>
								<img src={ArrowDown} alt="Arrow Down" />
							</IconButton>
                            <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => handleClose(selectedItem, itemsMap.get(selectedItem) || "")}>
                                {Array.from(itemsMap.keys()).map((key, value)=>(
                                    <MenuItem key={key} onClick={() => handleClose(key, itemsMap.get(key) || "")}>
                                        {key}
                                    </MenuItem>
                                ))}
                            </Menu>
						</div>
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

export default InactiveUsers;
