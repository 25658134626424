
import { Box, Button, Card, Grid, Tab, Tabs, Typography, CircularProgress } from '@mui/material';
import MyActivitiesSidePanel from './myActivitiesSidePanel';
import { useUser } from '../../context/UserContext';
import { useEffect, useState } from 'react';
import ErrorAlert from '../Error/ErrorAlert';
import ErrorAlertDisplay from '../Error/ErrorAlertDisplay';
import Post from '../Posts/Post';
import NewPost from '../../images/Posts/new-post.svg';
import InfiniteScroll from "react-infinite-scroll-component";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import CreatePostNew from '../Posts/CreatePostNew';

const TabsMenu = [
    {
        label: "All",
        id: "All",
        disabled: false,
    },
    {
        label: "Posts",
        id: "Posts",
        disabled: false,
    },
    {
        label: "Comments",
        id: "Comments",
        disabled: false,
    },
    {
        label: "Reactions",
        id: "Reactions",
        disabled: false,
    }
];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        spinner: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "15px 0",
        }
    })
);

const MyActivities = (props: any) => {

    const classes = useStyles();
    const user = useUser();
    const token = localStorage.getItem("sessionId");
    const userDetails: any = props.userDetails.privacySettings ? props.userDetails.privacySettings?.userProfile : {};
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertStatus, setAlertStatus] = useState(false);
    const [postDetails, setPostDetails] = useState<any>([]);
    const [selectedTab, setSelectedTab] = useState('All');
    const [openCreatePost, setOPenCreatePost] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [itemPerPage, setItemPerPage] = useState(10);
    let getPostUrl: any;

    const handleCloseCreatePost = () => {
        setOPenCreatePost(false)
    }

    const handleChange = (tabId: any) => {
        setLoading(true)
        setPostDetails([]);
        setPageNumber(0);
        setHasMore(true);
        setSelectedTab(tabId);
        if (tabId === 'Posts') {
            getPostUrl = `${process.env.REACT_APP_BASE_URL}/api/getSelfPosts/?page=0&size=${itemPerPage}`
        } else if (tabId === 'Comments') {
            getPostUrl = `${process.env.REACT_APP_BASE_URL}/api/getSelfCommentOnPosts/?page=0&size=${itemPerPage}`
        } else if (tabId === 'Reactions') {
            getPostUrl = `${process.env.REACT_APP_BASE_URL}/api/getSelfReactedOnPosts/?page=0&size=${itemPerPage}`
        } else {
            getPostUrl = `${process.env.REACT_APP_BASE_URL}/api/combinedPosts/?page=0&size=${itemPerPage}`
        }
        postDetails.length === 0 && setLoading(true);
        try {
            fetch(
                getPostUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    userProfileId:userDetails.id
                }),
            }
            )
                .then((res) => {
                    if (res.status > 400) {
                        throw new Error("api_error");
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data.length > 0) {
                        setPostDetails(data);
                        var nextPageNumber = pageNumber + 1;
                        setPageNumber(1);
                        setLoading(false)
                    }
                    if (data.length === 0) {
                        setHasMore(false);
                    }
                })
                .catch((err) => {
                    setHasMore(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    const getPostDetails = () => {
        if (selectedTab === 'Posts') {
            getPostUrl = `${process.env.REACT_APP_BASE_URL}/api/getSelfPosts/?page=${pageNumber}&size=${itemPerPage}`
        } else if (selectedTab === 'Comments') {
            getPostUrl = `${process.env.REACT_APP_BASE_URL}/api/getSelfCommentOnPosts/?page=${pageNumber}&size=${itemPerPage}`
        } else if (selectedTab === 'Reactions') {
            getPostUrl = `${process.env.REACT_APP_BASE_URL}/api/getSelfReactedOnPosts/?page=${pageNumber}&size=${itemPerPage}`
        } else {
            getPostUrl = `${process.env.REACT_APP_BASE_URL}/api/combinedPosts/?page=${pageNumber}&size=${itemPerPage}`
        }
        postDetails.length === 0 && setLoading(true);
        try {
            fetch(
                getPostUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                }, 
                body: JSON.stringify({
                    userProfileId:userDetails.id
                }),
            }
            )
                .then((res) => {
                    if (res.status > 400) {
                        throw new Error("api_error");
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data.length > 0) {
                        setPostDetails([...postDetails, ...data]);
                        var nextPageNumber = pageNumber + 1;
                        setPageNumber(nextPageNumber);
                        setLoading(false)
                    }
                    if (data.length === 0) {
                        setHasMore(false);
                    }
                })
                .catch((err) => {
                    setHasMore(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };
    const resetPostDetails = () => {
        setPostDetails([]);
        setPageNumber(0);
        setHasMore(true);
        let getPostUrlReset: any = ''
        setLoading(true);
        if (selectedTab === 'Posts') {
            getPostUrlReset = `${process.env.REACT_APP_BASE_URL}/api/getSelfPosts/?page=0&size=${itemPerPage}`
        } else if (selectedTab === 'Comments') {
            getPostUrlReset = `${process.env.REACT_APP_BASE_URL}/api/getSelfCommentOnPosts/?page=0&size=${itemPerPage}`
        } else if (selectedTab === 'Reactions') {
            getPostUrlReset = `${process.env.REACT_APP_BASE_URL}/api/getSelfReactedOnPosts/?page=0&size=${itemPerPage}`
        } else {
            getPostUrlReset = `${process.env.REACT_APP_BASE_URL}/api/combinedPosts/?page=0&size=${itemPerPage}`
        }
        try {
            setPostDetails([]);
            setHasMore(true);
            setPageNumber(0);
            fetch(
                getPostUrlReset,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        userProfileId:userDetails.id
                    }),
                }
            )
                .then((res) => {
                    if (res.status > 400) {
                        throw new Error("api_error");
                    } else {
                        return res.json();
                    }
                })
                .then((data) => {
                    if (data.length > 0) {
                        setPostDetails(data);
                        var nextPageNumber = pageNumber + 1;
                        setPageNumber(1);
                        setLoading(false)
                    }
                    if (data.length < itemPerPage) {
                        // setHasMore(false);
                    }
                })
                .catch((err) => {
                    setHasMore(false);
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        getPostDetails()
    }, [])

    const handleAlertClose = () => {
        setShowAlert(false);
    };
    const reversedMappedArray = postDetails && postDetails.map((element: any, index: any, array: any) => {
        return array[array.length - 1 - index];
    });
    const updateConnectionStatus = (id: any, newValue: any, requestFrom: any, requestTo: any) => {
        const newData = postDetails && postDetails.map((obj: any) => {
            if (obj.userProfileId === id) {
                return { ...obj, conneStatus: newValue, conneRequestFrom: requestFrom, conneRequestTo: requestTo, canSendConnection: (newValue === 'REJECTED' || newValue === 'WITHDRAWN' || newValue === 'REMOVED') && true }
            }
            return obj;
        })
        setPostDetails(newData);
    }

    return (
        <>
            <ErrorAlertDisplay alertMsg={alertMsg} alertStatus={alertStatus} showAlert={showAlert} handleAlertClose={handleAlertClose} />

            <Grid container columns={{ xs: 4, sm: 8, md: 12 }} className="centerPanelwoRight">
                <Grid item xs={12} container columns={{ xs: 4, sm: 8, md: 12 }} >
                    <Grid item  sx={{marginRight:"5px"}}>
                        {props.viewer === 'SELF' ?
                            <Button
                                size="small"
                                variant="contained"
                                disabled={user.userAccountStatus === "SUSPEND" ? true : false}
                                sx={{ borderRadius: '17px', background: '#FF702A', maxHeight: '30px', minHeight: '30px', padding: '0px 10px 0px 10px', fontSize: '16px', fontFamily: "Lexend", fontWeight: 400 }}
                                onClick={() => setOPenCreatePost(true)}
                            >
                                <img src={NewPost} alt='New Post' />&nbsp;
                                New Post
                            </Button>
                            : ''}
                    </Grid>
                    <Grid item>
                        <Tabs
                            value={selectedTab}
                            onChange={(event, value) => handleChange(value)}
                            aria-label="Plain tabs"
                            sx={{
                                '& .MuiTabs-indicator': {
                                    display: 'none'
                                },
                                borderRadius: 'lg',
                                '& .Mui-selected': {
                                    color: 'white !important',
                                    minHeight: '30px',
                                    minWidth: 'min-content',
                                    '&:active': { background: "#DAECFF !important", color: '#0273E6 !important' },
                                },
                                width: 'fit-content',
                                '& .MuiTab-root': {
                                    minHeight: '30px',
                                    minWidth: 'min-content',
                                }

                            }}
                        >
                            {TabsMenu.map((tabMenu) => (
                                <Tab
                                    key={tabMenu.id}
                                    sx={{
                                        backgroundColor: selectedTab === tabMenu.id ? '#0273E6' : 'white',
                                        border: '2px solid #0273E6',
                                        color: selectedTab === tabMenu.id ? 'white' : '#0273E6',
                                        borderRadius: '20px',
                                        mr: 1,
                                        minHeight: '30px',
                                        maxHeight: '30px',
                                        fontSize: '16px',
                                        padding: '0px 10px 0px 10px',
                                        fontWeight: '400 !important',
                                        textTransform: 'none',
                                        fontFamily: 'Lexend',
                                        '&:active': { background: "#EEF6FF" },

                                    }}
                                    value={tabMenu.id}
                                    label={tabMenu.label}
                                />
                            ))}
                        </Tabs>
                    </Grid>
                </Grid>
                <Grid item container spacing={{xl:3}}>
                    <Grid container item md={8} lg={8} sx={{ pl: 0.5 }} className="centerPanelSocialHome">

                        <Grid item sx={{ width: {lg:'600px', md:"695px" }}}>

                            {loading ? (
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100px",
                                    }}
                                >
                                    <CircularProgress />
                                </div>
                            ) :
                                ((postDetails && postDetails.length > 0) ?
                                    <InfiniteScroll
                                        style={{ overflow: 'inherit' }}
                                        dataLength={postDetails && postDetails.length}
                                        next={getPostDetails}
                                        hasMore={hasMore}
                                        loader={
                                            <Box
                                                className={classes.spinner}
                                            >
                                                <CircularProgress />
                                            </Box>
                                        }
                                        endMessage={
                                            <p style={{ textAlign: 'center' }}>
                                                <b>Yay! You have seen it all</b>
                                            </p>
                                        } >
                                        {
                                            (postDetails && postDetails.length > 0) ?
                                                postDetails.map((post: any) =>
                                                    <Post post={post} resetPostDetails={resetPostDetails} selectedTab={selectedTab} viewer={props.viewer} userName={userDetails?.user?.firstName + userDetails?.user?.lastName} profileUrl={userDetails?.profilePhotoUrl} postDetails={postDetails} setPostDetails={setPostDetails} updateConnectionStatus={updateConnectionStatus} />
                                                )
                                                :
                                                <Typography sx={{ textAlignLast: 'center' }}>Posts not available</Typography>
                                        }
                                    </InfiniteScroll>
                                    :
                                    <Typography sx={{ textAlignLast: 'center' }}>Posts not available</Typography>
                                )
                            }
                        </Grid>
                    </Grid>

                    <Grid item md={4} lg
                    ={3.881} sx={{ alignItems: 'end', pl: 1 }} className="hideInTabPro">
                        <MyActivitiesSidePanel />
                    </Grid>
                </Grid>
            </Grid>

            <CreatePostNew
                open={openCreatePost}
                handleClose={handleCloseCreatePost}
                resetPostDetails={resetPostDetails}
                editPost={false}
            />
        </>
    )
}

export default MyActivities;