import SideBar from "../commonComponents/sideBar";
import { useState, useEffect, useRef } from "react";
import { CardContent, Grid, Typography, Box, Card } from "@mui/material";
import CoverPhoto from "./coverPhoto";
import BriefBio from "./briefBio";
import MyPictures from "./myPictures";
import MyMusicCalendar from "./myMusicCalendar";
import MyActivities from "./myActivities";
import SessionRecordings from "../MyMusic/SessionRecordings";
import { useUser } from "../../context/UserContext";
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import CenterCircularProgress from "../CenterCircularProgress";
import { SocialUSerDetails } from "../../types/types";
import { useNavigate, useParams } from "react-router-dom";
// import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import { MusicListing } from "../MyMusic/MusicListing";
import MyActivitiesSidePanel from "./myActivitiesSidePanel";
import BlockProfile from "./BlockProfile";
import MyProfileMusic from "./myProfileMusic";
import { margin } from "@mui/system";
import { SuspendAlert } from "../commonComponents/SuspendAlert";
import Loader from "../commonComponents/Loader";

const SocialHome = () => {
	const user = useUser();
	const params = useParams();
	const [userDetails, setUserDetails] = useState<SocialUSerDetails>({});
	const token = localStorage.getItem("sessionId");
	const [selectedTab, setSelectedTab] = useState("myActivities");
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [ArrayOfInstruments, setArrayOfInstruments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const navigate = useNavigate();
	const viewer = userDetails?.loggedInUser?.ViewerStatus;
	const permissions = userDetails?.privacySettings;
	let getProfileUrl: any;

	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if (!userDetails.firstName || !userDetails.lastName || !userDetails.location) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	 window.scrollTo(0, 0);
	}, [navigate]);

	//   for getting user details
	const getUserDetails = () => {
		setIsLoading(true);
		if (params?.nickName) {
			const decodedNickName = decodeURIComponent(params.nickName);
    getProfileUrl = `${process.env.REACT_APP_BASE_URL}/api/view-user-profile/${encodeURIComponent(decodedNickName)}`;			;
		} else {
			getProfileUrl =
				process.env.REACT_APP_BASE_URL + "/api/view-user-profile/" + user.email;
		}

		fetch(getProfileUrl, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setUserDetails(data);
				setArrayOfInstruments(data.instrumentsList && data.instrumentsList);
				setIsLoading(false);
				// getAllMusicListing();
			})
			.catch((err) => {
				ErrorAlert(err.status, setAlertStatus, setShowAlert, setAlertMsg);
				setIsLoading(false);
			});
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	useEffect(() => {
		getUserDetails();
	}, [params]);

	// useEffect(() => {
	// 	getAllMusicListing();
	// }, []);

	const handleChange = (tabId: any) => {
		setSelectedTab(tabId);
	};
	if (isLoading) {
		return <Loader />;
	}

	return (
		<>
			<Grid className="mainPanel" sx={{ mt: "40px !important" }}>
				<Grid
					container
					columns={{ xs: 12, sm: 12, md: 12 }}
					sx={{ margin: "0 auto" }}
					className="mainPanel"
				>
					<ErrorAlertDisplay
						alertMsg={alertMsg}
						alertStatus={alertStatus}
						showAlert={showAlert}
						handleAlertClose={handleAlertClose}
					/>
					{viewer !== "SELF" && viewer === "BLOCKED" ? (
						<Grid item xs={12} sx={{ mt: "100px" }}>
							<BlockProfile />
						</Grid>
					) : (
						<>
							<Grid item xs={2.9} className="leftPanelWidth">
								<SideBar
									userDetails={userDetails}
									ArrayOfInstruments={ArrayOfInstruments}
									setIsLoading={setIsLoading}
									getUserDetails={getUserDetails}
								/>
							</Grid>
							<Grid
								container
								direction="column"
								item
								xs={9}
								className="centerPanelwoRight"
							>
								<Grid item>
									<CoverPhoto userDetails={userDetails} />
								</Grid>
								<Grid item>
									<BriefBio
										handleChange={handleChange}
										selectedTab={selectedTab}
										userDetails={userDetails}
										setIsLoading={setIsLoading}
										getUserDetails={getUserDetails}
									/>
								</Grid>

								{(viewer !== "SELF" &&
									permissions?.viewProfilePermission ===
										"No one can view profile. Only me") ||
								(viewer === "NOT_IN_CONNECTION" &&
									permissions?.viewProfilePermission ===
										"Only my connections can view full profile") ? (
									""
								) : (
									<Grid item sx={{ mt: 3, width: "100%" }}>
										{selectedTab === "myActivities" ? (
											<MyActivities viewer={viewer} userDetails={userDetails} />
										) : selectedTab === "pictures" ? (
											<MyPictures userDetails={userDetails} />
										) : selectedTab === "calendar" ? (
											<MyMusicCalendar userDetails={userDetails} />
										) : selectedTab === "myMusic" ? (
											<MyProfileMusic userDetails={userDetails} getUserDetails={getUserDetails} id={userDetails?.privacySettings?.userProfile?.id} />
										) : (
											`Hello ${selectedTab}`
										)}
									</Grid>
								)}
							</Grid>

							{/* <Grid item xs={3}>
							<SideBar
								userDetails={userDetails}
								ArrayOfInstruments={ArrayOfInstruments}
								setIsLoading={setIsLoading}
								getUserDetails={getUserDetails}
							/>
						</Grid>

						<Grid item xs={9} sx={{ minWidth:'912px', mt:4 }}>
							<Grid container direction="column" sx={{ pl: 4 }}>
								<Grid item>
									<CoverPhoto userDetails={userDetails} />
								</Grid>
								<Grid item>
									<BriefBio
										handleChange={handleChange}
										selectedTab={selectedTab}
										userDetails={userDetails}
										setIsLoading={setIsLoading}
										getUserDetails={getUserDetails}
									/>
								</Grid>

								<Grid item>
									{selectedTab === "myActivities" ? (
										<MyActivities viewer={viewer} userDetails={userDetails} />
									) : selectedTab === "pictures" ? (
										<MyPictures userDetails={userDetails} />
									) : selectedTab === "calendar" ? (
										<MyMusicCalendar userDetails={userDetails} />
									) : selectedTab === "myMusic" ? (
										<MyProfileMusic userDetails={userDetails} id={params.id} />
									) : (
										`Hello ${selectedTab}`
									)}
								</Grid>
							</Grid>
						</Grid> */}
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default SocialHome;
