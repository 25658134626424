import { useEffect, useState } from "react";
import {
	Card,
	CardContent,
	CardHeader,
	Typography,
	Button,
	Grid,
	Box,
	IconButton,
	Menu,
	MenuItem,
	ListItemIcon,
	Chip,
	Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Profile from "../../images/profile-circle.svg";
import { SessionInterface, UserInterfaceVideoSession } from "../../types/types";
import { useUser } from "../../context/UserContext";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import InstrumentSkillLevel from "../Musicians/InstrumentSkills";
import { Bars } from "react-loader-spinner";
import {
	MoreVert as MoreVertIcon,
	Edit as EditIcon,
	Delete as DeleteIcon,
} from "@mui/icons-material";
import {
	addMinutes,
	format,
	isBefore,
	differenceInDays,
	differenceInMinutes,
} from "date-fns";

import { useNavigate } from "react-router-dom";
import CancelSessionDialog from "../commonComponents/CancelSessionDialog";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		acceptBtn: {
			color: "#FFF",
			borderRadius: "20px !important",
		},
		rejectBtn: {
			border: "1px solid #FF702A !important",
			color: "#FF702A !important",
			borderRadius: "20px !important",
		},
	})
);

const SessionCmp = (props: any) => {
	const classes = useStyles();
	const user = useUser();
	const navigate = useNavigate();
	const currentPath = window.location.hash;
	const [joinDisabled, setJoinDisabled] = useState(true);
	const [openCancelDialog, setOpenCancelDialog] = useState(false);
	const [isNotificationRead, setIsNotificationRead] = useState(
		props.notification?.readStatus || false
	);

	const handleCardClick = () => {
		setIsNotificationRead(true);
	};

	useEffect(() => {
		if (props.notification?.readStatus) {
			setIsNotificationRead(true);
		}
	}, [props.notification?.readStatus]);

	useEffect(() => {
		if (differenceInDays(new Date(props.session.dateTime), new Date()) < 2) {
			let timeDiff =
				new Date(props.session.dateTime).getTime() - new Date().getTime();
			timeDiff = timeDiff - 300000;

			setTimeout(() => {
				setJoinDisabled(false);
			}, timeDiff);
		}
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			setCountdownMessage(calculateCountdownMessage());
		}, 60000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	const checkDisabled = (session: SessionInterface | null) => {
		if (session != null) {
			const endTime = addMinutes(new Date(session.dateTime), session.duration);
			if (isBefore(endTime, new Date())) {
				return true;
			} else {
				return false;
			}
		}
	};

	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleCloseSessionDialog = () => {
		setOpenCancelDialog(false);
		handleClose();
	};

	// const handleJoinClick = () => {
	// 		props.startVideoSession(props.session);
	// 		handleClose();
	// };

	const handleEditClick = () => {
		if (checkDisabled(props.session)) {
			return;
		}
		props.openEditDialog(props.session);
		handleClose();
	};
	const handleCancelSession = () => {
		setOpenCancelDialog(true);
	};

	const calculateCountdownMessage = () => {
		const notificationTime = new Date(props.notification?.dateTime);
		const sessionStartTime = addMinutes(notificationTime, 15);
		const sessionEndTime = addMinutes(sessionStartTime, props.session.duration);
		const currentTime = new Date();

		if (isBefore(currentTime, sessionStartTime)) {
			const minutesRemaining = differenceInMinutes(
				sessionStartTime,
				currentTime
			);
			if (minutesRemaining === 0) {
				return "Session Started"; // Display "Session Started" immediately
			} else if (minutesRemaining === 15) {
				return "Starting in 15 minutes";
			} else {
				return `Starting in ${minutesRemaining} minutes`;
			}
		} else if (isBefore(currentTime, sessionEndTime)) {
			return "Session Started";
		} else {
			return "Session Ended";
		}
	};

	const [countdownMessage, setCountdownMessage] = useState(
		calculateCountdownMessage()
	);

	const viewSocialProfile = (nickName: any) => {
		const encodedNickName = encodeURIComponent(nickName);
		navigate("/socialHome/" + encodedNickName);
	};

	return (
		<Grid
			container={true}
			columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}
			spacing={2}
		>
			<Grid item={true} xs={12}>
				<Card
					elevation={0}
					key={props.session.id}
					sx={{
						padding: "16px 16px 0 16px",
						// background: !props.notification?.readStatus ? "#fff" : "#F9F9FA",
						// border: !props.notification?.readStatus
						// 	? "none"
						// 	: "1px solid #E2E2E2",
						// boxShadow: !props.notification?.readStatus
						// 	? "0px 1px 16px 1px rgba(217, 231, 255, 0.77)"
						// 	: "none",
						background:
							!isNotificationRead || currentPath !== "#/notifications"
								? "#fff"
								: "#F9F9FA",
						border:
							!isNotificationRead || currentPath !== "#/notifications"
								? "none"
								: "1px solid #E2E2E2",
						boxShadow:
							!isNotificationRead || currentPath !== "#/notifications"
								? "0px 1px 14px 0px rgba(217, 231, 255, 0.77)"
								: "none",
						m: 1,
					}}
					// onClick={() => {
					// 	if (props.unread) {
					// 		setIsNotificationRead(true);
					// 	}
					// }}
					onClick={handleCardClick}
				>
					<CardHeader
						action={
							<Grid
								container={true}
								columns={{ xs: 12 }}
								spacing={{ xs: 1, sm: 1, md: 2 }}
							>
								{/* {props.session.createdBy === user.id && (
									<Grid item={true} xs={6}>
										<Button
											className="button-field-custom-font-size"
											fullWidth
											size="small"
											variant="contained"
											disabled={checkDisabled(props.session)}
											onClick={() => {
												props.openEditDialog(props.session);
											}}
										>
											Edit
										</Button>
									</Grid>
								)} */}
								{!props.isInvitation && !props.isUpdatedInvitation && (
									<Grid
										item={true}
										xs={props.session.createdBy === user.id ? 6 : 12}
									>
										<Box sx={{ display: "flex", alignItems: "center" }}>
										<Tooltip title="Live Streaming">
											<LoadingButton
												fullWidth
												size="small"
												sx={{marginRight:'5px'}}
												variant="contained"
												onClick={() => {
													if (props.session.livestreamStatus) {
														window.open(
															`${process.env.REACT_APP_BASE_URL_FRONTEND}/live?id=${props.session.twilioSessionId}`,
															"_blank"
														);
													}
												}}
												loading={props.inProgress}
												style={{
													display: props.session.livestreamStatus
														? "block"
														: "none",
												}}
											>
												<Bars
													height="25px"
													width="45px"
													color="#ffffff"
													wrapperStyle={{}}
													wrapperClass="wrapper-class"
													visible={true}
												/>{" "}
											</LoadingButton>
										</Tooltip>

											<LoadingButton
												className={
													props.isMeeting
														? classes.acceptBtn
														: "button-field-custom-font-size"
												}
												fullWidth
												size="small"
												variant="contained"
												// disabled={checkDisabled(props.session)}
												onClick={() => {
													props.startVideoSession(props.session);
												}}
												loading={props.inProgress}
											>
												<Typography variant="subtitle1">Join</Typography>
											</LoadingButton>

											{props.displayEdit === false
												? ""
												: !props.notification &&
												  props.session.createdBy === user.id && (
														<IconButton
															size="small"
															aria-controls="menu"
															aria-haspopup="true"
															aria-expanded={Boolean(anchorEl)}
															onClick={handleClick}
														>
															<MoreVertIcon />
														</IconButton>
												  )}

											{props.session.createdBy === user.id && (
												<Menu
													id="menu"
													anchorEl={anchorEl}
													open={Boolean(anchorEl)}
													onClose={handleClose}
													sx={{
														borderRadius: "10px",
														border: "5px",
														borderColor: "customColor.gray1",
														opacity: 0.95,
													}}
												>
													{!checkDisabled(props.session) && (
														<MenuItem onClick={handleEditClick}>
															<ListItemIcon>
																<EditIcon fontSize="small" />
															</ListItemIcon>
															<Typography variant="subtitle1">Edit</Typography>
														</MenuItem>
													)}

													<MenuItem onClick={handleCancelSession}>
														<ListItemIcon>
															<DeleteIcon fontSize="small" />
														</ListItemIcon>
														<Typography variant="subtitle1">Cancel</Typography>
													</MenuItem>
												</Menu>
											)}
										</Box>
									</Grid>
								)}
							</Grid>
						}
						title={
							<>
								<Grid container xs={12}>
									<Grid item xs={props.isUpdatedInvitation ? 12 : 8.8}>
										<div className="invitation-card-title-wrapper">
											{props.isInvitation && (
												<Typography
													sx={{
														backgroundColor: "#DFEBFF",
														borderRadius: "8px",
														textAlign: "center",
														display: "inline-block",
														padding: "0 10px",
														fontWeight: "bold",
													}}
													variant="subtitle2"
												>
													Invitation
												</Typography>
											)}
											{props.isUpdatedInvitation && (
												<Typography
													sx={{
														backgroundColor: "#DFEBFF",
														borderRadius: "8px",
														textAlign: "center",
														display: "inline-block",
														padding: "0 10px",
														fontWeight: "bold",
													}}
													variant="subtitle2"
												>
													Updated Invitation
												</Typography>
											)}
											{props.isMeeting && (
												<Typography
													sx={{
														backgroundColor: "#DFEBFF",
														borderRadius: "8px",
														textAlign: "center",
														display: "inline-block",
														padding: "0 10px",
														fontWeight: "bold",
													}}
													variant="subtitle2"
												>
													Meeting
												</Typography>
											)}
											{!props.isMeeting && (
												<Typography
													className="menu-label-typography-custom-font-size"
													sx={
														checkDisabled(props.session)
															? {
																	fontWeight: "900 !important",
																	color: "#C5C5C5",
															  }
															: { fontWeight: "900 !important" }
													}
												>
													{`${format(
														new Date(props.session.dateTime),
														"eeee, LLLL dd yyyy   hh:mm aaa"
													)} - ${format(
														addMinutes(
															new Date(props.session.dateTime),
															props.session.duration
														),
														"hh:mm aaa"
													)}`}
												</Typography>
											)}
											{props.isMeeting && (
												<Typography
													className="menu-label-typography-custom-font-size"
													sx={
														checkDisabled(props.session)
															? {
																	fontWeight: "900 !important",
																	color: "#C5C5C5",
															  }
															: { fontWeight: "900 !important" }
													}
												>
													{countdownMessage}
												</Typography>
											)}
										</div>
									</Grid>
									{props.session?.averageRating && props.source !== "home" && (
										<Grid item xs={3}>
											<InstrumentSkillLevel
												width="200px"
												skillLevel={
													props.session?.averageRating
														? props.session?.averageRating
														: 0
												}
												editable={false}
												instrumentLabel="Rating"
												source="Rating"
											/>
										</Grid>
									)}
								</Grid>
								{/* <Typography
								variant="body6"
								sx={{
									color: joinDisabled || checkDisabled(props.session) ? "grey" : "customColor.black"
								}}
								>
								{`${format(
									new Date(props.session.dateTime),
									"eeee, LLLL dd yyyy   hh:mm aaa"
									)} - ${format(
										addMinutes(
											new Date(props.session.dateTime),
											props.session.duration
											),
											"hh:mm aaa"
											)}`}
							</Typography> */}
							</>
						}
						subheader={
							<Typography
								variant="h6"
								sx={{
									color: "customColor.gray5",
								}}
							>
								{props.session.sessionName}
							</Typography>
						}
						sx={{ p: 0 }}
					/>
					<CardContent sx={{ p: 0, mt: 1 }}>
						<Grid container={true} columns={{ xs: 12 }}>
							<Grid item={true} xs={12}>
								<Typography
									sx={{ marginBottom: "10px", color: "customColor.gray4" }}
									variant="subtitle1"
								>
									{props.session.sessionDescription}
								</Typography>
							</Grid>
							<Grid item={true} xs={12}>
								<Grid container={true} columns={{ xs: 12 }}>
									{props.session.attendees?.map(
										(attendee: UserInterfaceVideoSession, index: any) => (
											<Chip
												avatar={
													<img
														src={Profile}
														alt="user-icon"
														className="music-logo-custom-height"
													/>
												}
												sx={{ mr: 1, mb: 1 }}
												// label={attendee.firstName + " " + attendee.lastName}
												// Name to change acc privacy...
												label={
													attendee?.userProfileId === user?.userProfileId
														? attendee?.firstName + " " + attendee?.lastName
														: attendee?.userPrivacySettingsDTO
																?.identityPermission ===
														  "Name is not visible. Instead just show nickname"
														? `@` + attendee?.nickname
														: attendee?.userPrivacySettingsDTO
																?.identityPermission ===
														  "Name is visible to all"
														? attendee?.firstName + " " + attendee?.lastName
														: attendee?.userPrivacySettingsDTO
																?.identityPermission ===
														  "Name is visible only to my connections. Other see nickname"
														? attendee?.isConnected
															? attendee?.firstName + " " + attendee?.lastName
															: `@` + attendee?.nickname
														: attendee?.firstName + " " + attendee?.lastName
												}
												onClick={() =>
													viewSocialProfile(attendee.nickname)
												}
											/>
										)
									)}
								</Grid>
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>

			<CancelSessionDialog
				open={openCancelDialog}
				refreshList={props.reloadSessions}
				handleCloseSessionDialog={handleCloseSessionDialog}
				sessionId={props.session.id}
			/>
		</Grid>
	);
};

export default SessionCmp;
