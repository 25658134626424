import { useState, useEffect, useRef, SetStateAction } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	TextField,
	FormHelperText,
	DialogActions,
	FormControl,
	Select,
	MenuItem,
	Autocomplete,
	Typography,
	Chip,
	Box,
	IconButton,
	Grid,
	FormControlLabel,
	Switch,
	DialogContentText,
} from "@mui/material";
import {
	LocalizationProvider,
	DatePicker,
	TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
	addMinutes,
	lightFormat,
	differenceInMinutes,
	min,
	set,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import i18n from "../../l10n/strings.json";
import Close from "../../images/close.svg";
import DisabledProfile from "../../images/profile-circle.svg";
import Profile from "../../images/profile-circle-auto.svg";
import { Constants } from "../../services/constants";
import { UserInterface } from "../../types/types";
import { useUser } from "../../context/UserContext";
import TimePickerCmp from "./TimePickerCmp";
// utils
import { checkIsValidDate, convertDateTz } from "../../utils";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { Link, useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme: any) => ({
	root: {
		color: "white",
		"& .MuiAutocomplete-tag": {
			backgroundColor: "#0273E6",
			color: "white",
			"&:hover": {
				backgroundColor: "#0273E6",
				color: "white",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "white",
			"&:hover": {
				color: "white",
			},
		},
	},
}));
const EditSessionDialog = (props: any) => {
	const classes = useStyles();
	const theme = useTheme();
	const user = useUser();
	const token = localStorage.getItem("sessionId");
	const timeoutIdRef = useRef<any>(undefined);

	const _chips = props.session.attendees.filter((u: UserInterface) => {
		return u.id !== user.id;
	});

	const attendeeIds = props.session.attendees.map((u: UserInterface) => u.id);

	const [formDisabled, setFormDisabled] = useState(props.formDisabled);
	const [activeUsers, setActiveUsers] = useState<Array<UserInterface> | []>([]);
	const [filteredActiveUsers, setFilteredActiveUsers] = useState<
		Array<UserInterface> | []
	>([]);
	const [chips, setChips] = useState<Array<UserInterface>>([..._chips]);
	const [sessionName, setSessionName] = useState(props.session?.sessionName);
	const [desc, setDesc] = useState(props.session?.sessionDescription);
	const [startDate, setStartDate] = useState<Date | null>(
		new Date(
			convertDateTz(new Date(props.session?.dateTime), props.session?.timezone)
		)
	);
	const [endDate, setEndDate] = useState<Date | null>(
		addMinutes(new Date(props.session?.dateTime), props.session?.duration)
	);
	const [maxTime, setMaxTime] = useState<Date | null>(
		addMinutes(new Date(), 240)
	);
	const [minTime, setMinTime] = useState<Date | null>(
		addMinutes(new Date(), 30)
	);
	const [timezone, setTimezone] = useState<any>(props.session?.timezone);
	const [duration, setDuration] = useState<number>(props.session?.duration);
	const [endsAt, setEndsAt] = useState<Date | null | string>(
		addMinutes(new Date(), 30)
	);

	const [showAdvanceView, setShowAdvanceView] = useState(false);
	const [errorDate, setErrorDate] = useState(false);
	const [formModified, setFormModified] = useState(false);
	const [pastSessionOpen, setPastSessionOpen] = useState(false);
	const [userLoading, setUserLoading] = useState(false);
	const navigate = useNavigate();
	const [searchString, setSearchString] = useState<string>("");
	const [isAutocompleteFocused, setIsAutocompleteFocused] = useState(false);

	useEffect(() => {
		if (startDate !== null) {
			const _endDate = addMinutes(new Date(startDate), duration);
			if (checkIsValidDate(_endDate)) {
				if (showAdvanceView) {
					setEndDate(_endDate);
				}
				setEndsAt(
					_endDate.toLocaleTimeString("en-US", {
						day: "numeric",
						year: "numeric",
						month: "numeric",
						hour: "numeric",
						minute: "numeric",
					})
				);
			}
		}
	}, [startDate, duration, showAdvanceView]);

	useEffect(() => {
		setTimezone(
			Constants.timezoneList.find(
				(tz) => tz.value === props.session?.timezone
			) ?? props.session?.timezone
		);
		setUserLoading(true);
		if (searchString !== "" && searchString !== "0" && isAutocompleteFocused) {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/jamsection/active-users?searchString=${searchString}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((res) => {
					if (res.status > 400) {
						setUserLoading(false);
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data !== null) {
						const filteredUsers = data.filter((u: UserInterface) => {
							return !attendeeIds.includes(u.id);
						});
						let filteredList: any = [];
						filteredUsers.map((auser: any) => {
							let name = ""; // Variable to hold the name value
							name = auser?.firstName + " " + auser?.lastName;
							filteredList.push({ ...auser, name });
						});
						setFilteredActiveUsers(filteredList);
						setUserLoading(false);
					} else {
						setUserLoading(false);
						setFilteredActiveUsers([]);
					}
				})
				.catch((err) => {
					setUserLoading(false);
					setFilteredActiveUsers([]);
				});
		} else {
			setUserLoading(false);
			setFilteredActiveUsers([]);
		}
	}, [searchString]);

	useEffect(() => {
		let _maxTime = null;
		let _minTime = null;
		if (startDate !== null) {
			if (startDate?.getDate() === endDate?.getDate()) {
				const maxDate = set(startDate, { hours: 23, minutes: 59, seconds: 59 });
				_maxTime = min([maxDate, addMinutes(startDate, 240)]);
			} else {
				_maxTime = addMinutes(startDate, 240);
				if (endDate !== null) {
					_minTime = set(endDate, { hours: 0, minutes: 0, seconds: 0 });
				}
			}
		}
		setMaxTime(_maxTime);
		setMinTime(_minTime);
	}, [endDate, startDate]);

	const handleAutocompleteChange = (
		event: any,
		item: readonly UserInterface[]
	) => {
		setChips([...item]);
		setFormModified(true);
		setSearchString("");
		setFilteredActiveUsers([]);
	};

	useEffect(() => {
		if (searchString === "" || searchString === "0") setFilteredActiveUsers([]);
	}, [filteredActiveUsers, searchString]);

	const updateScheduledSession = (allowPastSession: boolean = false) => {
		let min = duration;
		if (showAdvanceView) {
			if (startDate !== null && endDate !== null) {
				min = differenceInMinutes(endDate, startDate);
			}
		}
		if (
			startDate !== null &&
			startDate.getTime() < new Date().getTime() &&
			!allowPastSession
		) {
			let _Date = addMinutes(new Date(startDate), duration);
			const new_time = new Date(_Date);
			const new_time_stamp = new_time.getTime();
			if (new_time_stamp <= new Date().getTime()) {
				// setValidSession(false);
				setPastSessionOpen(true);
			}
			setPastSessionOpen(true);
			return false;
		} else if (showAdvanceView) {
			if (startDate !== null && endDate !== null) {
				min = differenceInMinutes(endDate, startDate);
			}
		}

		fetch(`${process.env.REACT_APP_BASE_URL}/api/update-sessions`, {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				id: props.session.id,
				sessionName: sessionName,
				createdBy: user.id,
				dateTime: zonedTimeToUtc(startDate as Date, timezone.value as string),
				duration: min,
				sessionDescription: desc,
				timezone: timezone.value,
				attendees: [...chips, user],
			}),
		}).then((res) => {
			if (res.status === 502) {
				navigate("/defaultPage");
			} else if (res.status >= 400) {
				let showAlert = true;
				let alertStatus = false;
				let alertMsg = "Error! Please try again..";
				props.handleClose(showAlert, alertStatus, alertMsg);
			} else {
				let showAlert = true;
				let alertStatus = true;
				let alertMsg = `${i18n.jamSession.editSessionSuccess}`;
				props.handleClose(showAlert, alertStatus, alertMsg);
			}
		});
	};

	const isInvalid = () => {
		if (
			sessionName === props.session.sessionName &&
			// desc === props.session.sessionDescription &&
			chips === props.session.attendees &&
			timezone === props.session.timezone &&
			// startDate === props.session.dateTime &&
			// endDate === addMinutes(new Date(props.session.dateTime), props.session.duration) &&
			duration === props.session.duration
		) {
			return true;
		} else if (sessionName === "" || timezone === "") {
			return true;
		} else if (chips.length < 1) {
			return true;
		} else if (showAdvanceView && errorDate) {
			return true;
		} else {
			return false;
		}
	};

	const handleClose = () => {
		props.handleClose();
	};

	const getMaxTime = () => {
		if (startDate !== null) {
			return addMinutes(startDate, 240);
		}
	};
	const getMinTime = () => {
		if (startDate !== null) {
			return addMinutes(startDate, 30);
		}
	};

	const handleFilterChange = (event: any, value: string) => {
		globalThis.clearTimeout(timeoutIdRef.current);
		timeoutIdRef.current = globalThis.setTimeout(() => {
			const currentActiveUsers = activeUsers.filter(
				(item: UserInterface) =>
					!chips.some((att: UserInterface) => att.id === item.id)
			);
			const _filteredActiveUsers =
				value === ""
					? []
					: currentActiveUsers.filter(
							(attendee) =>
								attendee.firstName
									.toLowerCase()
									.includes(value.toLowerCase()) ||
								attendee.lastName.toLowerCase().includes(value.toLowerCase()) ||
								`${attendee.firstName.toLowerCase()} ${attendee.lastName.toLowerCase()}`.includes(
									value.toLowerCase()
								)
					  );
			setFilteredActiveUsers([..._filteredActiveUsers]);
		}, 200);
		setFilteredActiveUsers([
			{ firstName: "loading", lastName: "" },
		] as Array<UserInterface>);
	};

	// Debounce function
	const debounce = (func: Function, delay: number) => {
		let timeoutId: NodeJS.Timeout;
		return (...args: any[]) => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => func(...args), delay);
		};
	};

	const debouncedSetSearchString = debounce((value: string) => {
		setSearchString(value);
	}, 200);

	const handleSearchStringChange = (event: any, value: string) => {
		debouncedSetSearchString(event.target.value);
	};

	const handleFocus = () => {
		setIsAutocompleteFocused(true);
	};

	const handleBlur = () => {
		setIsAutocompleteFocused(false);
	};

	useEffect(() => {
		if (!isAutocompleteFocused) {
			setSearchString("");
		}
	}, [isAutocompleteFocused]);

	const getMinStartTime = () => {
		// if (startDate !== null) {
		// 	if (startDate.getDate() === new Date().getDate()) {
		// 		if (timezone !== null) {
		// 			return new Date(
		// 				new Date().toLocaleString("en-US", {
		// 					timeZone: timezone.value,
		// 				})
		// 			);
		// 		} else {
		// 			return new Date();
		// 		}
		// 	}
		// }
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		return today;
	};
	// Public Visibility feature
	const [loading, setLoading] = useState(true);
	const [visible, setVisible] = useState("");

	function handleClick() {
		setLoading(true);
	}

	useEffect(() => {
		setVisible(loading ? "Public Visible" : "Public Invisible");
	}, [loading]);

	const handlePopupCloseClick = () => {
		setPastSessionOpen(false);
	};

	const handlePopupSubmitClick = () => {
		updateScheduledSession(true);
	};

	const filterOptions = (options: any, { inputValue }: any) => {
		const trim = inputValue.trim();
		return options.filter((option: any) =>
			option.name.toLowerCase().includes(trim.toLowerCase())
		);
	};

	return (
		<>
			<Dialog
				sx={{
					border: "1px solid #888888",
					color: "customColor.gray4",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="lg"
				onClose={(event, reason) => {
					if (reason && reason === "backdropClick") return;
					props.handleClose();
				}}
				open={props.editDialogOpen}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "column",
						textAlign: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<IconButton
							aria-label="close dialogbox"
							onClick={handleClose}
							onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
								event.preventDefault()
							}
							edge="end"
							sx={{ marginLeft: "90%", position: "absolute", top: "10px" }}
						>
							<img src={Close} alt="close" />
						</IconButton>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							variant="h2"
							sx={{
								color: "customColor.black",
							}}
						>
							{formDisabled
								? i18n.jamSession.Heading
								: i18n.jamSession.dialog.update}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent
					sx={{
						padding: "5px 20px",
					}}
				>
					<Grid
						container={true}
						columns={{ xs: 12, sm: 12, md: 12 }}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<FormControl sx={{ width: "100%" }}>
							<Grid item={true} xs={12}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<FormHelperText sx={{ ml: 0 }}>
										<Typography variant="body4" color="customColor.label">
											{i18n.jamSession.dialog.title}
											<span style={{ color: "red" }}>*</span>
										</Typography>
									</FormHelperText>
									{/* Added the toogle button */}
									{/* <FormControlLabel
    								control={
      										<Switch
        									checked={loading}
        									onChange={() => {setLoading(!loading); }}
        									name="loading"
        									sx={{
												'& .MuiSwitch-thumb': {
													backgroundColor: loading ? '#0273E6' : '#0273E6',
												},
												'& .MuiSwitch-track': {
												  backgroundColor: '#ffffff',
												},
											}}
      										/>
    									}
    								label={visible}
  								/> */}
								</Box>
								<TextField
									sx={{
										input: {
											color: "customColor.black",
											fontSize: theme.typography.subtitle1.fontSize,
											fontWeight: theme.typography.subtitle1.fontWeight,
											backgroundColor: formDisabled
												? "secondary"
												: "customColor.gray1",
										},
									}}
									disabled={formDisabled}
									fullWidth
									variant={formDisabled ? "standard" : "outlined"}
									value={sessionName}
									onChange={(event) => {
										setSessionName(event.target.value);
										setFormModified(true);
									}}
									error={sessionName?.length > 64}
								></TextField>
								<FormHelperText
									sx={{
										textAlign: "right",
									}}
								>
									<Typography variant="body2" color="error.main">
										{sessionName?.length > 64
											? "Enter maximum 64 characters"
											: ""}
									</Typography>
								</FormHelperText>
							</Grid>
							<Grid item={true} xs={12}>
								<FormHelperText sx={{ ml: 0 }}>
									<Typography variant="body4" color="customColor.label">
										{i18n.jamSession.dialog.description}
									</Typography>
								</FormHelperText>
								<TextField
									disabled={formDisabled}
									sx={{
										input: {
											color: "customColor.black",
											fontSize: theme.typography.subtitle1.fontSize,
											fontWeight: theme.typography.subtitle1.fontWeight,
											backgroundColor: formDisabled
												? "secondary"
												: "customColor.gray1",
										},
										backgroundColor: "customColor.gray1",
									}}
									multiline
									rows={2}
									fullWidth
									variant={formDisabled ? "standard" : "outlined"}
									value={desc}
									onChange={(event) => {
										setDesc(event.target.value);
										setFormModified(true);
									}}
									error={desc?.length > 512}
								></TextField>
								<FormHelperText
									sx={{
										textAlign: "right",
									}}
								>
									<Typography variant="body2" color="error.main">
										{desc?.length > 512 ? "Enter maximum 512 characters" : ""}
									</Typography>
								</FormHelperText>
							</Grid>
							<Grid item={true} xs={12}>
								<FormHelperText sx={{ ml: 0 }}>
									<Typography variant="body4" color="customColor.label">
										{i18n.jamSession.dialog.attendees}
										<span style={{ color: "red" }}>*</span>
									</Typography>
								</FormHelperText>
								<Autocomplete
									multiple
									className="session-form"
									classes={classes}
									disabled={formDisabled}
									options={filteredActiveUsers.filter(
										(user) => !chips.some((chip) => chip.id === user.id)
									)}
									getOptionLabel={(option) =>
										`${option.firstName} ${option.lastName}`
									}
									// filterSelectedOptions
									filterOptions={filterOptions}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									)}
									defaultValue={_chips}
									loading={userLoading}
									noOptionsText={
										searchString !== "" && filteredActiveUsers.length <= 0
											? "No musician found."
											: null
									}
									onFocus={handleFocus}
									onBlur={handleBlur}
									onInputChange={handleSearchStringChange}
									onChange={handleAutocompleteChange}
									renderTags={(values, getTagProps) =>
										chips.map((value, index) => {
											const { key, className, ...tagProps } = getTagProps({
												index: index,
											});
											return (
												<Chip
													key={key}
													avatar={
														formDisabled ? (
															<img src={DisabledProfile} alt="profile" />
														) : (
															<img src={Profile} alt="profile" />
														)
													}
													className={`${className} label-chip`}
													label={`${value.firstName} ${value.lastName}`}
													sx={{
														backgroundColor: formDisabled
															? "customColor.gray"
															: "customColor.blue",
														fontStyle: theme.typography.subtitle2.fontStyle,
														fontSize: theme.typography.subtitle2.fontSize,
														lineHeight: theme.typography.subtitle2.lineHeight,
														fontWeight: theme.typography.subtitle2.fontWeight,
														color: formDisabled
															? "customColor.label"
															: "secondary.main",
													}}
													{...tagProps}
												/>
											);
										})
									}
									renderInput={(params) => (
										<TextField
											{...params}
											variant={formDisabled ? "standard" : "outlined"}
										/>
									)}
									sx={{
										backgroundColor: formDisabled
											? "secondary"
											: "customColor.gray1",
										color: "customColor.black",
									}}
									open={filteredActiveUsers.length > 0}
								/>
							</Grid>
							{!showAdvanceView ? (
								<Grid container columnSpacing={1}>
									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.startDate}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												openTo="day"
												inputFormat="MM/dd/yyyy"
												className="session-form"
												views={["year", "month", "day"]}
												value={startDate}
												//disablePast
												onChange={(newValue: Date | null) => {
													if (checkIsValidDate(newValue))
														setStartDate(newValue);
													setFormModified(true);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														// variant={formDisabled ? "standard" : "outlined"}
														sx={{
															input: {
																color: "customColor.black",
																fontSize: theme.typography.subtitle1.fontSize,
																fontWeight:
																	theme.typography.subtitle1.fontWeight,
															},
															"&.MuiTextField-root": {
																backgroundColor: formDisabled
																	? "secondary"
																	: "customColor.gray1",
																width: "100%",
															},
														}}
														variant={formDisabled ? "standard" : "outlined"}
													/>
												)}
												disabled={formDisabled}
											/>
										</LocalizationProvider>
									</Grid>

									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.startTime}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<TimePicker
												disabled={formDisabled}
												value={startDate}
												onChange={(newValue: Date | null) => {
													if (checkIsValidDate(newValue)) {
														setStartDate(newValue);
														setFormModified(true);
													}
												}}
												minTime={getMinStartTime()}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															input: {
																color: "customColor.black",
																fontSize: theme.typography.subtitle1.fontSize,
																fontWeight:
																	theme.typography.subtitle1.fontWeight,
															},
															"&.MuiTextField-root": {
																backgroundColor: formDisabled
																	? "secondary"
																	: "customColor.gray1",
																width: "100%",
															},
														}}
														variant={formDisabled ? "standard" : "outlined"}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>

									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.duration}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<Select
											variant={formDisabled ? "standard" : "outlined"}
											sx={{
												backgroundColor: formDisabled
													? "secondary"
													: "customColor.gray1",
												width: "100%",
												color: "customColor.black",
											}}
											disabled={formDisabled}
											labelId="demo-simple-select-label"
											value={duration}
											onChange={(event: any) => {
												setDuration(event.target.value);
												setFormModified(true);
											}}
										>
											{Constants.duration.map((duration) => (
												<MenuItem value={duration.value}>
													{duration.label}
												</MenuItem>
											))}
										</Select>
									</Grid>

									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.timeZone}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<Autocomplete
											disabled={formDisabled}
											options={Constants.timezoneList}
											value={timezone}
											defaultValue={timezone}
											onChange={(event: any, newValue: any) => {
												setTimezone(newValue);
												setFormModified(true);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													sx={{
														background: formDisabled
															? "transparent !important"
															: "#F8F8F8",
													}}
													variant={formDisabled ? "standard" : "outlined"}
												/>
											)}
										/>
									</Grid>
								</Grid>
							) : (
								<>
									<Grid container columnSpacing={1}>
										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.startDate}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													openTo="day"
													inputFormat="MM/dd/yyyy"
													disabled={formDisabled}
													className="session-form"
													views={["year", "month", "day"]}
													value={startDate}
													disablePast
													onChange={(newValue: Date | null) => {
														if (checkIsValidDate(newValue))
															setStartDate(newValue);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																background: formDisabled
																	? "transparent !important"
																	: "#F8F8F8",
																width: "100%",
															}}
															variant={formDisabled ? "standard" : "outlined"}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>

										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.startTime}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<TimePicker
													disabled={formDisabled}
													value={startDate}
													onChange={(newValue: Date | null) => {
														if (checkIsValidDate(newValue))
															setStartDate(newValue);
													}}
													minTime={getMinStartTime()}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																input: {
																	color: "customColor.black",
																	fontSize: theme.typography.subtitle1.fontSize,
																	fontWeight:
																		theme.typography.subtitle1.fontWeight,
																},
																"&.MuiTextField-root": {
																	backgroundColor: formDisabled
																		? "secondary"
																		: "customColor.gray1",
																	width: "100%",
																},
															}}
															variant={formDisabled ? "standard" : "outlined"}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>

										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.timeZone}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<Autocomplete
												disabled={formDisabled}
												options={Constants.timezoneList}
												value={timezone}
												// defaultValue={Constants.timezoneList[0]}
												onChange={(event: any, newValue: any) => {
													setTimezone(newValue);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															input: {
																color: "customColor.black",
																fontSize: theme.typography.subtitle1.fontSize,
																fontWeight:
																	theme.typography.subtitle1.fontWeight,
															},
															"&.MuiTextField-root": {
																backgroundColor: formDisabled
																	? "secondary"
																	: "customColor.gray1",
																width: "100%",
															},
														}}
														variant={formDisabled ? "standard" : "outlined"}
													/>
												)}
											/>
										</Grid>

										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.endDate}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													openTo="day"
													views={["year", "month", "day"]}
													disabled={formDisabled}
													inputFormat="MM/dd/yyyy"
													maxDate={getMaxTime()}
													minDate={getMinTime()}
													value={endDate}
													disablePast
													onChange={(newValue: Date | null) => {
														if (checkIsValidDate(newValue)) {
															setEndDate(newValue);
														}
													}}
													onError={(reason, value) => {
														if (reason) {
															setErrorDate(true);
														} else {
															setErrorDate(false);
														}
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																input: {
																	color: "customColor.black",
																	fontSize: theme.typography.subtitle1.fontSize,
																	fontWeight:
																		theme.typography.subtitle1.fontWeight,
																},
																"&.MuiTextField-root": {
																	backgroundColor: formDisabled
																		? "secondary"
																		: "customColor.gray1",
																	width: "100%",
																},
															}}
															variant={formDisabled ? "standard" : "outlined"}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>

										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.endTime}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<TimePickerCmp
													maxTime={maxTime}
													minTime={minTime}
													endDate={endDate}
													setEndDate={(newValue: Date | null) => {
														if (checkIsValidDate(newValue)) {
															setEndDate(newValue);
														}
													}}
													setErrorDate={setErrorDate}
													errorDate={errorDate}
												/>
											</LocalizationProvider>
										</Grid>

										{showAdvanceView ? (
											""
										) : (
											<Grid item={true} xs={4}>
												<FormHelperText sx={{ ml: 0 }}>
													<Typography variant="body4" color="customColor.label">
														{i18n.jamSession.dialog.timeZone}
														<span style={{ color: "red" }}>*</span>
													</Typography>
												</FormHelperText>
												<Autocomplete
													disabled={formDisabled}
													options={Constants.timezoneList}
													value={timezone}
													onChange={(event: any, newValue: any) => {
														setTimezone(newValue);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																input: {
																	color: "customColor.black",
																	fontSize: theme.typography.subtitle1.fontSize,
																	fontWeight:
																		theme.typography.subtitle1.fontWeight,
																},
																"&.MuiTextField-root": {
																	backgroundColor: formDisabled
																		? "secondary"
																		: "customColor.gray1",
																	width: "100%",
																},
															}}
															variant={formDisabled ? "standard" : "outlined"}
														/>
													)}
												/>
											</Grid>
										)}
									</Grid>
								</>
							)}
						</FormControl>
						<Grid>
							<Typography
								sx={{
									marginTop: "20px",
									color: "customColor.gray5",
								}}
								variant="subtitle1"
							>
								{!showAdvanceView
									? `Ends at ${endsAt}`
									: endDate !== null
									? `Ends at ${lightFormat(endDate, "MM/dd/yyyy - hh:mm aaa")}`
									: ""}
							</Typography>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => setShowAdvanceView(!showAdvanceView)}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginTop: "5px" }}
								disabled={formDisabled}
							>
								<Typography
									sx={{ color: "customColor.link" }}
									variant="subtitle1"
								>
									{showAdvanceView ? "Set Duration" : "Set End Date"}
								</Typography>
							</IconButton>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						xs={12}
						sm={8}
						md={3}
						columnSpacing={1}
						sx={{
							display: "flex",
							justifyContent: "flex-end",
						}}
					>
						<Grid item={true}>
							<Button
								sx={{
									color: "customColor.orange",
									borderRadius: "24px",
									borderColor: "customColor.orange",
									"&:hover": {
										borderColor: "customColor.orange",
									},
								}}
								size="large"
								variant="outlined"
								onClick={handleClose}
							>
								<Typography variant="h6">{i18n.dialog.cancel}</Typography>
							</Button>
						</Grid>
						<Grid item={true}>
							{/* {formDisabled ? (
							<Button
								sx={{
									borderRadius: "24px",
									backgroundColor: "customColor.orange",
									color: "secondary",
									"&:hover": {
										backgroundColor: "customColor.orange",
										color: "secondary",
									},
								}}
								size="large"
								variant="contained"
								type="submit"
								onClick={() => setFormDisabled(!formDisabled)}
							>
								<Typography variant="h6">{i18n.jamSession.dialog.editBtn}</Typography>
							</Button>
						) : ( */}
							<Button
								sx={{
									borderRadius: "24px",
									backgroundColor: "customColor.orange",
									color: "secondary",
									"&:hover": {
										backgroundColor: "customColor.orange",
										color: "secondary",
									},
								}}
								size="large"
								variant="contained"
								type="submit"
								disabled={!formModified || isInvalid()}
								onClick={() => updateScheduledSession()}
							>
								<Typography variant="h6">
									{i18n.jamSession.dialog.saveBtn}
								</Typography>
							</Button>
							{/* )} */}
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			<div>
				<Dialog
					open={pastSessionOpen}
					onClose={() => {
						return false;
					}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					fullWidth
					maxWidth="sm"
				>
					<DialogTitle id="alert-dialog-title"></DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{i18n.jamSession.dialog.confirmPastSession}
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							className="cancel-button"
							variant="outlined"
							onClick={handlePopupCloseClick}
						>
							{i18n.jamSession.dialog.confirmPastSessionBtnDecline}
						</Button>
						<Button
							className="submit-button"
							variant="contained"
							onClick={handlePopupSubmitClick}
							autoFocus
						>
							{i18n.jamSession.dialog.confirmPastSessionBtnAllow}
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		</>
	);
};

export default EditSessionDialog;
