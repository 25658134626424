import { createContext, useContext, useState } from 'react';

const AdminContext = createContext<any>(null);

export const UserDetailsProvider = ({ children } : any) => {
  const [adminSetUserId, setAdminSetUserId] = useState({});

  return (
    <AdminContext.Provider value={{ adminSetUserId, setAdminSetUserId }}>
      {children}
    </AdminContext.Provider>
  );
};

export const useAdminContext = () => {
  return useContext(AdminContext);
};