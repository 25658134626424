import { useNavigate } from "react-router-dom";
import { useUser } from "../../context/UserContext";
import { useEffect } from "react";

const useAdminRedirect = () => {
	const user = useUser();
	const navigate = useNavigate();

	useEffect(() => {
		if (user && user.authorities) {
			const authorities = user.authorities.map(
				(authority: { name: any }) => authority.name
			);
			if (authorities.includes("ROLE_ADMIN")) {
				navigate("/admin-dashboard");
			}
		}
	}, [navigate, user]);
};

export default useAdminRedirect;
