import { useState, useEffect, useRef } from "react";
import {
	Button,
	Typography,
	Chip,
	Tab,
	Alert,
	IconButton,
	AlertTitle,
	Snackbar,
	Grid,
} from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import {
	format,
	addMinutes,
	addDays,
	subDays,
	isToday,
	min,
	set,
} from "date-fns";

import CenterCircularProgress from "../CenterCircularProgress";
import Sessions from "./Sessions";
import HelpAndTips from "../../pages/HelpAndTips";
import AddDevice from "./AddDevice";
import CalenderCmp from "./CalenderCmp";

import i18n from "../../l10n/strings.json";
import MusicDashboard from "../../images/music-dashboard.svg";
import Prev from "../../images/prev.svg";
import Next from "../../images/next.svg";
import ErrorIcon from "../../images/danger.svg";
import SuccessIcon from "../../images/tick-square.svg";
import { DeviceInterface } from "../../types/types";
import { Constants } from "../../services/constants";
import { useUser } from "../../context/UserContext";
import { checkIsValidDate } from "../../utils";
import { useTheme } from "@mui/material/styles";
import ScheduleSession from "./scheduleSession";
import zIndex from "@mui/material/styles/zIndex";
import CommigSoon from "./CommingSoon";

import RightAarrow from "../../images/right arrow.svg";
import Loader from "../commonComponents/Loader";
import { useReload } from "../../context/ReloadContext";
import useAdminRedirect from "../../hooks/useAdminRedirect/useAdminRedirect";
const JamSession = () => {
	useAdminRedirect();
	const user = useUser();
	const theme = useTheme();

	const navigate = useNavigate();
	let [searchParams] = useSearchParams();

	const sid = searchParams.get("sid");
	const token = localStorage.getItem("sessionId");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const timeoutIdRef = useRef<any>(undefined);

	const [open, setOpen] = useState(false);
	const [loading, setLoading] = useState(true);
	const [activeDevice, setActiveDevice] = useState<DeviceInterface | null>(
		null
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const durationList = Constants.duration;

	const [reqStartdate, setReqStartdate] = useState<Date>(new Date());

	// Alert states
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");

	const [startDate, setStartDate] = useState<Date | null>(null);
	const [endDate, setEndDate] = useState<Date | null>(
		addMinutes(new Date(), 30)
	);// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [maxTime, setMaxTime] = useState<Date | null>(
		addMinutes(new Date(), 360)
	);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [minTime, setMinTime] = useState<Date | null>(
		addMinutes(new Date(), 30)
	);
	const [timezone, setTimezone] = useState<any>(null);
	const [showAdvanceView, setShowAdvanceView] = useState(false);
	const [duration, setDuration] = useState<number>(30);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [endsAt, setEndsAt] = useState<Date | null | string>(
		addMinutes(new Date(), 30)
	);
	const [tabValue, setTabValue] = useState("agenda");
	const { reloadJamSession } = useReload();
	const [key, setKey] = useState(0);

	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if (!userDetails.firstName || !userDetails.lastName || !userDetails.location) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]); 

	useEffect(() => {
	  if (reloadJamSession > 0) {
		setKey(prevKey => prevKey + 1);
	  }
	}, [reloadJamSession]);

	useEffect(() => {
		if (startDate !== null) {
			const _endDate = addMinutes(new Date(startDate), duration);
			if (checkIsValidDate(_endDate)) {
				if (showAdvanceView) {
					setEndDate(_endDate);
				}
				setEndsAt(
					_endDate.toLocaleTimeString("en-US", {
						day: "numeric",
						year: "numeric",
						month: "numeric",
						hour: "numeric",
						minute: "numeric",
					})
				);
			}
		}
	}, [startDate, duration, showAdvanceView]);

	useEffect(() => {
		let _maxTime = null;
		let _minTime = null;
		if (startDate !== null) {
			if (startDate?.getDate() === endDate?.getDate()) {
				const maxDate = set(startDate, { hours: 23, minutes: 59, seconds: 59 });
				_maxTime = min([maxDate, addMinutes(startDate, 360)]);
				_minTime = addMinutes(startDate, 30);
			} else {
				_maxTime = addMinutes(startDate, 360);
				if (endDate !== null) {
					_minTime = set(endDate, { hours: 0, minutes: 0, seconds: 0 });
				}
			}
		}
		setMaxTime(_maxTime);
		setMinTime(_minTime);
	}, [endDate, startDate]);

	useEffect(() => {
		if (timezone) {
			let _startDate = new Date().toLocaleString("en-US", {
				timeZone: timezone.value,
			});
			const reminder = 30 - (new Date(_startDate).getMinutes() % 30);
			setStartDate(addMinutes(new Date(_startDate), reminder));
		}
	}, [timezone]);

	const getActiveDevice = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/active-user-device`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					setActiveDevice(data);
					// getActiveUsers();
				} else {
					setActiveDevice(null);
				}
				setLoading(false);
			})
			.catch((err) => {
				setActiveDevice(null);
				setLoading(false);
			});
	};

	useEffect(() => {
		getActiveDevice();
		setLoading(true);
		 // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const reminder = 30 - (new Date().getMinutes() % 30);
		setStartDate(addMinutes(new Date(), reminder));
		setEndDate(addMinutes(new Date(), 30));
		setDuration(30);
		const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		setTimezone(
			Constants.timezoneList.find((tz) => tz.value === currTimezone) ??
				Constants.timezoneList[0]
		);
	}, [open]);

	const handleSuccess = () => {
		setAlertMsg(i18n.settings.deviceSetup.success);
		setShowAlert(true);
		setAlertStatus(true);
		getActiveDevice();
	};

	const updateAlertMsg = (showErr: boolean, msg: string) => {
		setAlertMsg(msg);
		setShowAlert(showErr);
		setAlertStatus(false);
	};

	const handleTabChange = (event: any, newValue: string) => {
		if (newValue === "agenda") {
			setReqStartdate(new Date());
		} else if (newValue === "week") {
			const curr = new Date();
			let sunday = curr.getDate() - curr.getDay();
			let firstDayOfWeek = new Date(curr.setDate(sunday)).toUTCString();
			setReqStartdate(new Date(firstDayOfWeek));
		} else if (newValue === "month") {
			const date = new Date();
			var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
			setReqStartdate(firstDay);
		}

		setTabValue(newValue);
	};

	const handleNextDay = () => {
		setReqStartdate(addDays(reqStartdate, 1));
	};

	const handlePrevDay = () => {
		setReqStartdate(subDays(reqStartdate, 1));
	};

	const handleNextWeek = () => {
		setReqStartdate(addDays(reqStartdate, 7));
	};

	const handlePrevWeek = () => {
		setReqStartdate(subDays(reqStartdate, 7));
	};

	const handleNextMonth = () => {
		setReqStartdate(
			new Date(reqStartdate.getFullYear(), reqStartdate.getMonth() + 1, 1)
		);
	};

	const handlePrevMonth = () => {
		setReqStartdate(
			new Date(reqStartdate.getFullYear(), reqStartdate.getMonth() - 1, 1)
		);
	};

	const handleDateChangeFromCalender = (updatdDate: any[]) => {
		if (updatdDate !== null && updatdDate.length > 0) {
			const selectedDate = updatdDate[updatdDate.length - 1];
			if (tabValue === "agenda") {
				setReqStartdate(new Date(selectedDate));
			} else if (tabValue === "week") {
				const curr = new Date(selectedDate);
				let sunday = curr.getDate() - curr.getDay();
				let firstDayOfWeek = new Date(curr.setDate(sunday)).toUTCString();
				setReqStartdate(new Date(firstDayOfWeek));
			} else if (tabValue === "month") {
				const date = new Date(selectedDate);
				var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
				setReqStartdate(firstDay);
			}
		}
	};
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const handleSessionDialogClose = () => {
		setShowAdvanceView(false);
		setStartDate(new Date());
		setEndDate(addMinutes(new Date(), 30));
		setTimezone(Constants.timezoneList[0]);
		setEndsAt(addMinutes(new Date(), 30));
		setOpen(false);
	};

	const setAlertOnEditSession = (
		_showAlert: boolean,
		_alertStatus: boolean,
		_alertMsg: string
	) => {
		setShowAlert(_showAlert);
		setAlertStatus(_alertStatus);
		setAlertMsg(_alertMsg);
	};

	const setAlertOnSessionAutoJoin = (
		_showAlert: boolean,
		_alertStatus: boolean,
		_alertMsg: string
	) => {
		setShowAlert(_showAlert);
		setAlertStatus(_alertStatus);
		setAlertMsg(_alertMsg);
	};
	const handleAlertClose = () => {
		setShowAlert(false);
		if (sid) {
			navigate("/jamsession");
		}
	};

	if (loading) {
		return <Loader />;
	}

	const handleEditSessionClose = (
		_showAlert: boolean,
		_alertStatus: boolean,
		_alertMsg: string
	) => {
		setOpen(false);
		if (_showAlert && _alertStatus && _alertMsg) {
			setShowAlert(_showAlert);
			setAlertStatus(_alertStatus);
			setAlertMsg(_alertMsg);
		}
	};


	return (
		<div key={key}>
		<>
			<Grid className="mainPanel">
				<Grid
					container={true}
					className="column jam-session mainPanel"
					rowSpacing={{ xs: 1 }}
				>
					<Grid item={true} sx={{ position: "sticky" }}>
						{showAlert && (
							<Snackbar
								sx={{
									"&.MuiSnackbar-anchorOriginTopCenter": {
										top: "70px",
									},
								}}
								anchorOrigin={{ vertical: "top", horizontal: "center" }}
								open={showAlert}
								autoHideDuration={6000}
								onClose={handleAlertClose}
							>
								<Alert
									onClose={handleAlertClose}
									icon={
										alertStatus ? (
											<img src={SuccessIcon} alt="error-icon" />
										) : (
											<img
												src={ErrorIcon}
												alt="error-icon"
												style={{ marginRight: "10px" }}
											/>
										)
									}
									sx={{
										backgroundColor: "#FFFFFF",
										boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
										borderRadius: "6px",
										fontSize: theme.typography.h6.fontSize,
										fontWeight: theme.typography.h6.fontWeight,
										color: "customColor.black",
									}}
								>
									<AlertTitle
										sx={{
											fontSize: theme.typography.h6.fontSize,
											fontWeight: theme.typography.h6.fontWeight,
											color: "customColor.black",
											display: "flex",
											margin: "auto",
											padding: "5px",
											justifyContent: "center !important",
										}}
									>
										{alertMsg}
									</AlertTitle>
								</Alert>
							</Snackbar>
						)}
					</Grid>
					<Grid item={true} sx={{ zIndex: "1", top: "60px" }}>
						<Grid
							container={true}
							xs={12}
							sx={{
								display: "flex",
								flexDirection: "row",
								alignItems: "baseline",
								backgroundColor: "secondary.main",
							}}
							className="mainPanel"
						>
							<Grid
								item={true}
								xs={3}
								position="relative"
								className="leftPanelWidth"
							>
								<Typography
									variant="h2"
									sx={{
										color: "customColor.black",
										mt: 5,
										fontSize: "36px !important",
										lineHeight: "normal",
										paddingLeft: "15px",
									}}
								>
									Jam Session
								</Typography>
							</Grid>
							<Grid
								item={true}
								xs={9}
								position="relative"
								className="centerPanelwoRight"
							>
								{/* {activeDevice !== null && ( */}
								<div
									style={{
										display: "flex",

										flexDirection: "column",
									}}
								>
									<div
										style={{
											top: "60px",
											zIndex: "1",
											paddingTop: "10px",
											display: "inherit",
											// position: "sticky",
										}}
									>
										<div style={{ flex: 4 }}>
											<TabContext value={tabValue}>
												<TabList
													onChange={handleTabChange}
													aria-label="wrapped label tabs example"
													sx={{
														"& .Mui-selected": {
															backgroundColor: user.isCollaborationActive
																? "customColor.blue"
																: "#C2C2C3",
															borderRadius: "24px",
															textDecoration: "none",
															height: "36px",
															width: "100px",
															"&: hover": {
																backgroundColor: "customColor.blue",
															},
														},
														// Changed the css class
														"& .MuiTabs-indicator": {
															display: "none",
														},
													}}
												>
													<Tab
														key="agenda"
														value="agenda"
														sx={{
															"&:hover": {
																backgroundColor: !user.isCollaborationActive
																	? "grey"
																	: tabValue === "day"
																	? "customColor.blue"
																	: "#EEF6FF",
																borderRadius: "24px",
															},
															marginRight: "27px",
														}}
														label={
															<Typography
																variant="h6"
																sx={{
																	textTransform: "capitalize",
																	margin: "0 5px",
																}}
																color={
																	!user.isCollaborationActive
																		? "white"
																		: tabValue === "agenda"
																		? "secondary"
																		: "#1d2d53"
																	// "gray"
																}
															>
																Day
															</Typography>
														}
														wrapped
														disabled={!user.isCollaborationActive}
													/>
													<Tab
														key="week"
														value="week"
														sx={{
															"&:hover": {
																backgroundColor:
																	tabValue === "week"
																		? "customColor.blue"
																		: "#EEF6FF",
																borderRadius: "24px",
															},
															marginRight: "27px",
														}}
														label={
															<Typography
																variant="h6"
																sx={{
																	textTransform: "capitalize",
																	margin: "0 5px",
																}}
																color={
																	tabValue === "week" ? "secondary" : "#1d2d53"
																	// "gray"
																}
															>
																Week
															</Typography>
														}
														disabled={!user.isCollaborationActive}
													/>
													<Tab
														key="month"
														value="month"
														sx={{
															"&:hover": {
																backgroundColor:
																	tabValue === "month"
																		? "customColor.blue"
																		: "#EEF6FF",
																borderRadius: "24px",
															},
														}}
														label={
															<Typography
																variant="h6"
																sx={{
																	textTransform: "capitalize",
																	margin: "0 5px",
																}}
																color={
																	tabValue === "month" ? "secondary" : "#1d2d53"
																	// "gray"
																}
															>
																Month
															</Typography>
														}
														disabled={!user.isCollaborationActive}
													/>
												</TabList>
												<TabPanel sx={{ padding: "25px 0 0 0" }} value="agenda">
													<div
														style={{
															display: "flex",
															alignItems: "center",
														}}
													>
														{isToday(reqStartdate) ? (
															<Chip
																label={
																	<Typography
																		variant="subtitle1"
																		color="#000000"
																		bgcolor="customColor.gray2"
																	>
																		Today
																	</Typography>
																}
																sx={{
																	borderRadius: "15px",
																	padding: "0 20px",
																	mr: "4px",
																	height: "30px",
																}}
															/>
														) : (
															""
														)}
														<IconButton
															onClick={handlePrevDay}
															disabled={!user.isCollaborationActive}
														>
															<img src={Prev} alt="prev" />
														</IconButton>
														<IconButton
															onClick={handleNextDay}
															disabled={!user.isCollaborationActive}
														>
															<img src={Next} alt="next" />
														</IconButton>
														<Typography
															variant="subtitle1"
															sx={{
																paddingLeft: "20px",
															}}
															color="customColor.gray5"
														>
															{format(reqStartdate, "LLLL dd, yyyy")}
														</Typography>
													</div>
												</TabPanel>
												<TabPanel sx={{ padding: "25px 0 0 0" }} value="week">
													<div
														style={{
															display: "flex",
															alignItems: "center",
														}}
													>
														<IconButton onClick={handlePrevWeek}>
															<img src={Prev} alt="prev" />
														</IconButton>
														<IconButton onClick={handleNextWeek}>
															<img src={Next} alt="next" />
														</IconButton>
														<Typography
															variant="subtitle1"
															sx={{
																paddingLeft: "20px",
															}}
															color="customColor.gray5"
														>
															{`${format(
																reqStartdate,
																"LLLL dd, yyyy"
															)} - ${format(
																addDays(reqStartdate, 6),
																"LLLL dd, yyyy"
															)}`}
														</Typography>
													</div>
												</TabPanel>
												<TabPanel sx={{ padding: "25px 0 0 0" }} value="month">
													<div
														style={{
															display: "flex",
															alignItems: "center",
														}}
													>
														<IconButton onClick={handlePrevMonth}>
															<img src={Prev} alt="prev" />
														</IconButton>
														<IconButton onClick={handleNextMonth}>
															<img src={Next} alt="next" />
														</IconButton>
														<Typography
															variant="subtitle1"
															sx={{
																paddingLeft: "20px",
															}}
															color="customColor.gray5"
														>
															{`${format(
																reqStartdate,
																"LLLL dd, yyyy"
															)} - ${format(
																new Date(
																	reqStartdate.getFullYear(),
																	reqStartdate.getMonth() + 1,
																	0
																),
																"LLLL dd, yyyy"
															)}`}
														</Typography>
													</div>
												</TabPanel>
											</TabContext>
										</div>
										<div>
											<Button
												// sx={{
												// 	borderRadius: "24px",
												// 	// backgroundColor:"#BDBDBD",
												// 	backgroundColor: user.userAccountStatus === "SUSPEND"? "rgba(0, 0, 0, 0.12)" : "#BDBDBD",
												// 	color: "secondary",
												// 	"&:hover": {
												// 		backgroundColor: "customColor.orange",
												// 		color: "secondary",
												// 	},
												// }}

												size="large"
												disabled={
													!user.isCollaborationActive ||
													user.userAccountStatus === "SUSPEND" || activeDevice === null
												}
												style={{ minWidth: "247px" }}
												fullWidth
												variant="contained"
												onClick={() => setOpen(true)}
												endIcon={<img src={RightAarrow} alt="Right Arrow" />}
												className="submit-button"
											>
												<Typography>{i18n.jamSession.schedule}</Typography>
											</Button>
										</div>
									</div>
								</div>
								{/* )} */}
							</Grid>
						</Grid>
						{/* <div
							style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}
						>
							<Button
								sx={{
									justifySelf: "flex-end",
									height: "36px",
									whiteSpace: "nowrap",
								}}
								variant="contained"
								onClick={() => setOpen(true)}
							>
								{i18n.jamSession.schedule}
							</Button>
						</div> */}
					</Grid>

					<Grid item={true}>
						<Grid container columns={{ xs: 12 }} className="mainPanel">
							<Grid
								container
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									zIndex: 1,
								}}
								xs={3}
								className="leftPanelWidth"
							>
								<Grid item={true} sx={{ alignContent: "center" }}>
									<CalenderCmp
										filter={tabValue}
										startDate={reqStartdate}
										onDateChange={handleDateChangeFromCalender}
									/>
								</Grid>
								<Grid item={true} sx={{ width: "100%" }}>
									<Button
										sx={{
											width: "100%",
											height: "48px",
											//margin: "0.5em 0 !important",
											background:
												"linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
											borderRadius: "24px !important",
											marginTop: "25px",
										}}
										variant="contained"
										onClick={() => navigate(`/mymusic`)}
										className="submit-button"
										startIcon={
											<img src={MusicDashboard} alt="Music Dashoard" />
										}
									>
										<Typography>My Music Files</Typography>
									</Button>
									<Typography
										sx={{
											color: "gray",
											fontSize: "14px",
											lineHeight: "19.6px",
											fontWeight: 500,
											maxWidth: "245px",
											height: "40px",
											mt: "13px",
											ml: "16px",
										}}
									>
										View your recorded music files and upload local music files
										here
									</Typography>
								</Grid>
							</Grid>
							<Grid item={true} xs={9} mt={-0.7} className="centerPanelwoRight">
								<Grid
									container={true}
									className="row"
									columns={{ xs: 12 }}
									rowSpacing={{ xs: 3 }}
								>
									{
										user.isCollaborationActive === true ? (
											<>
												{activeDevice === null && (
													<>
														<Grid
															item={true}
															xs={9}
															className="centerPanelWidth"
														>
															<AddDevice
																updateAlertMsg={updateAlertMsg}
																onSuccess={handleSuccess}
															/>
														</Grid>
														<Grid
															item={true}
															xs={3}
															className="rightPanelWidth"
														>
															<HelpAndTips
																title={
																	i18n.jamSession.instructions.howToConnect
																		.title
																}
																text={
																	i18n.jamSession.instructions.howToConnect.text
																}
															/>
														</Grid>
													</>
												)}
												{activeDevice !== null && (
													// <div
													// 	style={{
													// 		marginTop: "10px",
													// 		flex: "6",
													// 		overflowY: "auto",
													// 	}}
													<Grid
														item
														sx={{
															flex: "1",
															scrollbarWidth: "thin",
															overflowY: "auto",
															paddingRight: "16px",
															paddingBottom: "16px",
															maxHeight: `calc(100vh - 37vh)`,
														}}
													>
														<Sessions
															refresh={open}
															reqStartdate={reqStartdate}
															filter={tabValue}
															setAlertOnEditSession={setAlertOnEditSession}
															autoJoinSID={sid}
															setAlertOnSessionAutoJoin={
																setAlertOnSessionAutoJoin
															}
														/>
													</Grid>
												)}
											</>
										) : (
											<Grid item={true} xs={12}>
												<CommigSoon
													updateAlertMsg={updateAlertMsg}
													onSuccess={handleSuccess}
												/>
											</Grid>
										)

										// <>
										// 	{activeDevice === null && (
										// 		<>
										// 			<Grid item={true} xs={9} className="centerPanelWidth">
										// 				<AddDevice
										// 					updateAlertMsg={updateAlertMsg}
										// 					onSuccess={handleSuccess}
										// 				/>
										// 			</Grid>
										// 			<Grid item={true} xs={3} className="rightPanelWidth">
										// 				<HelpAndTips
										// 					title={
										// 						i18n.jamSession.instructions.howToConnect.title
										// 					}
										// 					text={i18n.jamSession.instructions.howToConnect.text}
										// 				/>
										// 			</Grid>
										// 		</>
										// 	)}
										// 	{activeDevice !== null && (
										// 		// <div
										// 		// 	style={{
										// 		// 		marginTop: "10px",
										// 		// 		flex: "6",
										// 		// 		overflowY: "auto",
										// 		// 	}}
										// 		<Grid
										// 			item
										// 			sx={{
										// 				flex: "1",
										// 				scrollbarWidth: "thin",
										// 				overflowY: "auto",
										// 				paddingRight: "16px",
										// 				paddingBottom: "16px",
										// 				maxHeight: `calc(100vh - 37vh)`,
										// 			}}
										// 		>
										// 			<Sessions
										// 				refresh={open}
										// 				reqStartdate={reqStartdate}
										// 				filter={tabValue}
										// 				setAlertOnEditSession={setAlertOnEditSession}
										// 				autoJoinSID={sid}
										// 				setAlertOnSessionAutoJoin={setAlertOnSessionAutoJoin}
										// 			/>
										// 		</Grid>
										// 	)}
										// </>
									}
								</Grid>
							</Grid>
						</Grid>

						{open && (
							<ScheduleSession
								open={open}
								handleClose={handleEditSessionClose}
							></ScheduleSession>
						)}
					</Grid>
				</Grid>
			</Grid>
		</>
		</div>
	);
};

export default JamSession;
