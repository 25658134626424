import { useEffect, useState } from "react";
import {
	Outlet,
	Navigate,
	useNavigate,
	useLocation,
	useSearchParams,
} from "react-router-dom";
// cmps
import AppBarCmp from "./components/AppBarCmp";
import LogoutDialog from "./dialogBox/LogoutDialog";
import { VideoProvider } from "./components/VideoProvider";
import { ParticipantProvider } from "./components/ParticipantProvider";
import { ChatProvider } from "./components/ChatProvider";
import { PeopleProvider } from "./components/PeopleProvider";
import { useAppState } from "./state";
import { AuthInterceptor } from "./services/AuthInterceptor";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import User from "./services/user";
import { FullscreenModeProvider } from "./context/FullscreenModeContext";
import { BroadcastingStatusProvider } from "./context/BroadcastingStatusContext";
import { MusicianFilterProvider } from "./components/Musicians/MusicProvider/useMusicianFilter";
import { UserManagementFilterProvider } from "./components/AdminDashboard/ManageUserDashBoard/UserManagementProvider/useUserManageFilter";
import { DeviceManagementFilterProvider } from "./components/AdminDashboard/ManageUserDashBoard/DeviceManagementProvider/useDeviceManageFilter";
import UserModerationFilter from "./components/AdminDashboard/Moderation/UserModerationFilter";
import { UserModerationFilterProvider } from "./components/AdminDashboard/Moderation/ModerationManagementProvider/useUserModerationFilter";
import { MusicianCountProvider } from "./components/Musicians/MusicProvider/useMusicianCounts";
import { ContentModerationFilterProvider } from "./components/AdminDashboard/Moderation/ContentModerationManagementProvider/useContentModerationFilter";
import { UserActiveSubscriptionProvider } from "./components/Subscription/SubscriptionProvider/useUserSubscription";
import AppContainer from "./components/Messages/AppContainer";
import { Theme } from "@twilio-paste/theme";
import { SuspendAlert } from "./components/commonComponents/SuspendAlert";
import { useUser } from "./context/UserContext";
import { EventsFilterProvider } from "./components/Events/useEventsFilter";
import { useAlertStatus } from "./context/AlertStatusContext";
import SettingsAlert from "./pages/SettingsAlert";
import { UserDetailsProvider } from "./components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";
import { clearAllLocalstorage } from "./utils";
import useAdminRedirect from "./hooks/useAdminRedirect/useAdminRedirect";

const Container = () => {
	const sessionId = localStorage.getItem("sessionId") ?? null;
	const { error, setError } = useAppState();
	const connectionOptions = useConnectionOptions();
	const [open, setOpen] = useState(false);
	const user = useUser();
	const { updateAlertStatus } = useAlertStatus();
	const location = useLocation();
	const [queryParameters] = useSearchParams();
	const token = localStorage.getItem("sessionId");
	const navigate = useNavigate();
	useEffect(() => {
		User.on("unauthorized", handleLogOutDialog);
		AuthInterceptor();
		return () => {
			User.off("unauthorized", () => setOpen(false));
		};
	}, []);

	function handleLogOutDialog() {
		if (location.pathname === "/jamsession") {
			clearAllLocalstorage();
			setOpen(false);
			const sid = queryParameters.get("sid");
			navigate(`/login?referrer=jamsession&sid=${sid}`);
		} else {
			navigate("/login");
			setOpen(true);
		}
	}
	const expectedBaseURL = process.env.REACT_APP_BASE_URL_FRONTEND;
	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if (!userDetails.firstName || !userDetails.lastName || !userDetails.location) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]);
	const [isLoading, setIsLoading] = useState(false);
	const [usersData, setUsersData] = useState<any>({});

	const fetchUserInfo = async () => {
		setIsLoading(true);
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/view-user-profile/${encodeURIComponent(user?.nickname)}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((response) => {
				if(response.status === 502){
					navigate('/defaultPage')
				} else if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
        if(result?.privacySettings?.userProfile?.multifactorAuthEnabled == true && localStorage.getItem("isVerified") == "false") {
          navigate("/codeVerify", {
            state: {
              email: user.email,
              userProfileId: user.userProfileId,
              sessionId: token,
            }
          });
        }
				setIsLoading(false);
				if (result !== null) {
					setUsersData(result);
				}
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		const currentBaseURL = `${window.location.href.split("#")[0]}#`;
		if (currentBaseURL !== expectedBaseURL) {
			updateAlertStatus(true, false, "Invalid Url!");
			setTimeout(() => {
				window.location.replace(`${expectedBaseURL}/login`);
				navigate("/login");
			}, 3000);
		}
	}, [expectedBaseURL]);
	useEffect(() => {
		if(user?.nickname){
			fetchUserInfo();
		}
		setIsLoading(true);
	},[]);

	return (
		<>
			<SettingsAlert />
			<VideoProvider options={connectionOptions} onError={setError}>
				<ParticipantProvider>
					<ChatProvider>
						<PeopleProvider>
							<ContentModerationFilterProvider>
								<UserModerationFilterProvider>
									<DeviceManagementFilterProvider>
										<EventsFilterProvider>
											<UserActiveSubscriptionProvider>
												<UserManagementFilterProvider>
													<UserDetailsProvider>
														<MusicianCountProvider>
															<MusicianFilterProvider>
																<FullscreenModeProvider>
																	<BroadcastingStatusProvider>
																		<div
																			className="containers"
																			style={{
																				display: "flex",
																				flexDirection: "column",
																			}}
																		>
																			<AppBarCmp />
																			{/* <Theme.Provider theme="twilio">
																	<AppContainer />
																	</Theme.Provider > */}
																			{user.userAccountStatus === "SUSPEND" && (
																				<SuspendAlert />
																			)}

																			{sessionId ? (
																				<Outlet />
																			) : (
																				<Navigate to="/login" />
																			)}
																			<LogoutDialog open={open} />
																		</div>
																	</BroadcastingStatusProvider>
																</FullscreenModeProvider>
															</MusicianFilterProvider>
														</MusicianCountProvider>
													</UserDetailsProvider>
												</UserManagementFilterProvider>
											</UserActiveSubscriptionProvider>
										</EventsFilterProvider>
									</DeviceManagementFilterProvider>
								</UserModerationFilterProvider>
							</ContentModerationFilterProvider>
						</PeopleProvider>
					</ChatProvider>
				</ParticipantProvider>
			</VideoProvider>
		</>
	);
};

export default Container;
