import { useState, useEffect, useRef, SyntheticEvent } from "react";
import {
	Typography,
	Grid,
    Paper,
	Stack,
    Button,
    LinearProgress,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableRow,
} from "@mui/material";
import Snackbar from "../Snackbar/Snackbar";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import { format, lightFormat } from "date-fns";
import CenterCircularProgress from "../CenterCircularProgress";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import SettingsAlert from "../../pages/SettingsAlert";
import { useAlertStatus } from "../../context/AlertStatusContext";
import HelpAndTips from "../../pages/HelpAndTips";
import { useActiveSubscription } from "./SubscriptionProvider/useUserSubscription";
import DOMPurify from "dompurify";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		linearGradBtn: {
			width: "100%",
            // margin: "0.5em 0 !important",
            background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
            borderRadius: "18px !important",
		},
		newFolderBtn: {
			display: 'none',
			width: "100%",
			margin: "0.5em 0 !important",
			border: "1.5px solid #0273E6",
			background: "#fff",
			borderRadius: "18px !important"
		},

		leftBarMenus: {
			"& img": {
				position: "absolute",
				left: "5%"
			}
		},
	})
);

type RouteParams = {
	menuId: string;
};

const SubscriptionPayments = () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
	const param = useParams<RouteParams>();
	const token = localStorage.getItem("sessionId");
	const { updateAlertStatus } = useAlertStatus();
    const { purchasedSubscriptions, updateUserActiveSubscription } = useActiveSubscription();
    const [subscriptionHistory, setSubscriptionHistory] = useState([]);

	// Alert states
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [fileSendError, setFileSendError] = useState<string | null>(null);
	const [alertMsg, setAlertMsg] = useState("");

	const handleAlertClose = () => {
		setShowAlert(false);
	};

    const invoiceData = [
        {
            id: 1,
            invoiceTitle: "Invoice 1244/3455",
            invoiceDate: "12/08/2023",
            invoiceFor: "Jam Box V2.1",
            endDate: "Warranty exp: 12/08/2027",
            invoiceAmt: "$250",
            invoiceUrl: ""
        },
        {
            id: 2,
            invoiceTitle: "Invoice 1244/3455",
            invoiceDate: "12/08/2023",
            invoiceFor: "10Gb Storage subscription",
            endDate: "Monthly billing",
            invoiceAmt: "$250",
            invoiceUrl: ""
        },
        {
            id: 1,
            invoiceTitle: "Invoice 1244/3455",
            invoiceDate: "12/08/2023",
            invoiceFor: "Standard Plan subscription",
            endDate: "Monthly Billing",
            invoiceAmt: "$250",
            invoiceUrl: ""
        },
    ]

    const cancelSubscription = (selectedPlan:any) => {
        setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/cancel-subscription`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
            body: JSON.stringify(selectedPlan)
		})
        .then((res) => {
            if (res.status > 400) {
                throw new Error("api_error");
            } else {
                return res.json();
            }
        })
        .then((data) => {
            if (data && data !== null) {
                // setActiveSubscription(data);
                updateUserActiveSubscription();
            }
        })
        .catch((err) => {
            // setActiveDevice(null);
        })
        .finally(() => {
            setLoading(false);
        });
    }

    const getUserSubscriptionHistory = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/get-purchase-history`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
        .then((res) => {
            if (res.status > 400) {
                throw new Error("api_error");
            } else {
                return res.json();
            }
        })
        .then((data) => {
            if (data && data !== null && data.length > 0) {
                setSubscriptionHistory(data);
            }
        })
        .catch((err) => {
            // setActiveDevice(null);
        })
	};

    useEffect(() => {
		setLoading(false);
        getUserSubscriptionHistory();
	}, []);

    if (loading) {
		return <CenterCircularProgress />;
	}

	return (
		<>
		    <SettingsAlert />
			<ErrorAlertDisplay alertMsg={alertMsg} alertStatus={alertStatus} showAlert={showAlert} handleAlertClose={handleAlertClose} />
			<Snackbar
				open={Boolean(fileSendError)}
				headline="Error"
				message={fileSendError || ""}
				variant="error"
				handleClose={() => setFileSendError(null)}
			/>
			<Grid container={true} spacing={{ xs: 3 }}>
				<Grid item xs={9} paddingX={1}>
                    <Paper sx={{padding: 1}}>
                        {(purchasedSubscriptions && purchasedSubscriptions.length > 0 ) ? <>
                            {/* Jam Subscription Section */}
                            {
                                purchasedSubscriptions.map((plan:any, key:number) => {
                                    return <>
                                        <Grid>
                                            <Typography variant="h3">
                                                {plan.masterSubscriptionPlan.name}
                                            </Typography>

                                            <Grid container p={1}>
                                                <Grid item container xs={4} p={1} paddingY={4} sx={{background: "#F5F9FD"}}>
                                                    <Grid item xs={5}>
                                                        {plan.masterSubscriptionPlan.images && <img src={plan.masterSubscriptionPlan.images} alt="Badge" />}
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        {
                                                            plan.masterSubscriptionPlan.type == "Software" ?
                                                            <>
                                                                <Typography variant="h5">Standard Plan</Typography>
                                                                <Typography variant="subtitle2">${plan.masterSubscriptionPlan.cost}/month</Typography>
                                                            </>
                                                            :

                                                            <div dangerouslySetInnerHTML={{ __html:  DOMPurify.sanitize( plan.masterSubscriptionPlan.subscriptionDescContentType ) }}></div>
                                                        }

                                                    </Grid>
                                                </Grid>

                                                <Grid item xs={8} p={1}>
                                                    <Grid>
                                                        <Typography variant="body1" fontWeight="600">Subscription Start date:</Typography>
                                                        <Typography variant="subtitle2">{format(new Date(plan.startDate), "MM/dd/yyyy")}</Typography>
                                                    </Grid>

                                                    <Grid marginY={2}>
                                                        <Typography variant="body1" fontWeight="600">Next Billing:</Typography>
                                                        <Typography variant="subtitle2">{format(new Date(plan.endDate), "MM/dd/yyyy")}</Typography>
                                                    </Grid>

                                                    <Grid item xs={6} marginY={1}>
                                                        {
                                                            plan.status === "ACTIVE" ?
                                                                <Button
                                                                    // className={classes.linearGradBtn}
                                                                    sx={{width: "100%"}}
                                                                    variant="outlined"
                                                                    onClick={() => cancelSubscription(plan)}
                                                                    // endIcon={<img src={RightArrow} alt="Buy Again" />}
                                                                    size="small"
                                                                >
                                                                    Cancel
                                                                </Button>
                                                            : plan.status === "STOPPED" ?
                                                                <Typography variant="h6">
                                                                    Cancelled
                                                                </Typography>
                                                            :
                                                            <></>
                                                        }

                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        {key < purchasedSubscriptions.length && <Divider sx={{marginY: 2}} />}
                                    </>
                                })
                            }
                        </>
                        :
                        <>
                            <Grid className="center-align">
                                <Typography variant="h6">
                                    No active subscriptions available
                                </Typography>
                            </Grid>
                        </>
                        }
                    </Paper>

                    {subscriptionHistory && <Paper sx={{padding: 1, marginTop: 3}} >
                        {/* Download Invoice Section */}
                        {
                            subscriptionHistory && subscriptionHistory.length > 0 && <Grid >
                                <Typography variant="h3">
                                    Subscription History
                                </Typography>
                                <Grid container p={1}>
                                    <Table aria-label="simple table">
                                        <TableBody>
                                            {subscriptionHistory.map((history:any) => {
                                                return <TableRow
                                                        key={history.id}
                                                        // sx={{border: 0 }}
                                                    >
                                                    <TableCell component="th" scope="row">
                                                        <Typography variant="body1" >
                                                            {history.shopifySubscriptionId}
                                                        </Typography>
                                                        <Typography variant="body2" >
                                                            {format(new Date(history.startDate), "dd/MM/yyyy") + ' - ' + format(new Date(history.endDate), "dd/MM/yyyy") }
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="body1" >
                                                            {history.masterSubscriptionPlan.name}
                                                        </Typography>
                                                        <Typography variant="body2" >
                                                            {history.masterSubscriptionPlan.type}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" >
                                                            {'$'+history.masterSubscriptionPlan.cost}
                                                        </Typography>
                                                    </TableCell>
                                                    <TableCell>
                                                        <Typography variant="h6" >
                                                            {history.status}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            })}
                                        </TableBody>
                                    </Table>
                                </Grid>
                            </Grid>
                        }

                    </Paper>}
                </Grid>


                <Grid item xs={3} >
                    <HelpAndTips
                        title="Help & tips"
                        text=""
                    />
                </Grid>
			</Grid>
		</>
	);
};

export default SubscriptionPayments;
