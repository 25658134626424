import { useCallback, useEffect, useState } from "react";
import {
	Button,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	TextField,
	FormHelperText,
	DialogActions,
	FormControl,
	Select,
	MenuItem,
	Autocomplete,
	Typography,
	Chip,
	Box,
	IconButton,
	Grid,
	FormControlLabel,
	CircularProgress,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
	addMinutes,
	lightFormat,
	differenceInMinutes,
	min,
	set,
} from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import TimePickerCmp from "./TimePickerCmp";
import StartTimePicker from "./StartTimePicker";
import i18n from "../../l10n/strings.json";
import Close from "../../images/close.svg";
import Profile from "../../images/profile-circle-auto.svg";
// contexts
import { useUser } from "../../context/UserContext";
// services
import { Constants } from "../../services/constants";
import { UserInterface } from "../../types/types";

// utils
import { checkIsValidDate } from "../../utils";
import { useTheme } from "@mui/material/styles";
import RightAarrow from "../../images/right arrow.svg";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useActiveSubscription } from "../Subscription/SubscriptionProvider/useUserSubscription";
import User from "../../services/user";
import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";
import CenterCircularProgress from "../CenterCircularProgress";

const useStyles = makeStyles((theme: any) => ({
	root: {
		color: "white",
		"& .MuiAutocomplete-tag": {
			backgroundColor: "#0273E6",
			color: "white",
			"&:hover": {
				backgroundColor: "#0273E6",
				color: "white",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "white",
			"&:hover": {
				color: "white",
			},
		},
	},
}));

const IOSSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0273E6",
				opacity: 1,
				border: 0,
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5,
			},
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff",
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
		},
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22,
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500,
		}),
	},
}));
const ScheduleSession = (props: any) => {
	const classes = useStyles();
	const theme = useTheme();
	const user = useUser();
	const navigate = useNavigate();
	const durationList = Constants.duration;
	const { isSubscribed } = useActiveSubscription();

	const token = localStorage.getItem("sessionId");

	const [activeUsers, setActiveUsers] = useState<Array<UserInterface> | []>([]);

	const [sessionName, setSessionName] = useState("");
	const [desc, setDesc] = useState("");
	const [startDate, setStartDate] = useState<Date | null>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [minDate, setMinDate] = useState<Date | undefined>();
	const [endDate, setEndDate] = useState<Date | null>(
		addMinutes(new Date(), 30)
	);
	const [maxTime, setMaxTime] = useState<Date | null>(
		addMinutes(new Date(), 240)
	);
	const [minTime, setMinTime] = useState<Date | null>(
		addMinutes(new Date(), 30)
	);
	const [timezone, setTimezone] = useState<any>(null);
	const [showAdvanceView, setShowAdvanceView] = useState(false);
	const [errorDate, setErrorDate] = useState(false);
	const [duration, setDuration] = useState<number>(30);
	const [endsAt, setEndsAt] = useState<Date | null | string>(
		addMinutes(new Date(), 30)
	);
	const [pastSessionOpen, setPastSessionOpen] = useState(false);

	const [chips, setChips] = useState<Array<UserInterface>>([]);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [validSession, setValidSession] = useState(true);
	const [loading, setLoading] = useState(false);
	const [visible, setVisible] = useState("");
	const [subscriptionActive, setSubscriptionActive] = useState<Boolean>();
	const ALLOWED_SESSION_FOR_SUBSCRIPTION: number = 5;

	const [isScheduling, setIsScheduling] = useState(false);
	const [userLoading, setUserLoading] = useState(false);
	const [searchString, setSearchString] = useState<string>("");
	const [isAutocompleteFocused, setIsAutocompleteFocused] = useState(false);

	const getActiveUsers = () => {
		setUserLoading(true);
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/jamsection/active-users?searchString=${searchString}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status > 400) {
					setUserLoading(false);
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					const arr = data.filter((u: UserInterface) => {
						return u.id !== user.id;
					});
					let filteredList: any = [];
					arr.map((auser: any) => {
						let name = "";
						name = auser?.firstName + " " + auser?.lastName;
						filteredList.push({ ...auser, name });
					});
					setActiveUsers(
						filteredList
					);
					setUserLoading(false);
				}
			})
			.catch((err) => {
				setUserLoading(false);
				setActiveUsers([]);
			});
	};
	useEffect(() => {
		if (searchString !== "" && searchString !== "0" && isAutocompleteFocused) {
			getActiveUsers();
		} else {
			setUserLoading(false);
			setActiveUsers([]);
		}
		const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		setTimezone(
			Constants.timezoneList.find((tz) => tz.value === currTimezone) ??
				Constants.timezoneList[0]
		);

		// CHECK ACTIVE SUBSCRIPTION & SET WHETHER USER CAN SCHEDULE A SESSIONS OR NOT
		if (
			user.sessionCount &&
			ALLOWED_SESSION_FOR_SUBSCRIPTION > user.sessionCount &&
			isSubscribed
		) {
			setSubscriptionActive(true);
		} else {
			// REMOVED THE CONDITION FOR THE DEPLOYMENT PURPOSE AS WEBHOOK ARE NOT WORKING
			setSubscriptionActive(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchString]);

	useEffect(() => {
		if (startDate !== null) {
			const _endDate = addMinutes(new Date(startDate), duration);
			if (checkIsValidDate(_endDate)) {
				if (showAdvanceView) {
					setEndDate(_endDate);
				}

				setEndsAt(
					_endDate.toLocaleTimeString("en-US", {
						day: "numeric",
						year: "numeric",
						month: "numeric",
						hour: "numeric",
						minute: "numeric",
					})
				);
			}
		}
	}, [startDate, duration, showAdvanceView]);

	useEffect(() => {
		let _maxTime = null;
		let _minTime = null;
		if (startDate !== null) {
			if (startDate?.getDate() === endDate?.getDate()) {
				const maxDate = set(startDate, {
					hours: 23,
					minutes: 59,
					seconds: 59,
				});
				_maxTime = min([maxDate, addMinutes(startDate, 240)]);
				_minTime = addMinutes(startDate, 30);
			} else {
				_maxTime = addMinutes(startDate, 240);
				if (endDate !== null) {
					_minTime = set(endDate, { hours: 0, minutes: 0, seconds: 0 });
				}
			}
		}
		setMaxTime(_maxTime);
		setMinTime(_minTime);
	}, [endDate, startDate]);

	useEffect(() => {
		if (timezone) {
			let _startDate = new Date().toLocaleString("en-US", {
				timeZone: timezone.value,
			});
			const reminder = 30 - (new Date(_startDate).getMinutes() % 30);
			setStartDate(addMinutes(new Date(_startDate), reminder));
			setMinDate(new Date(_startDate));
		}
	}, [timezone]);

	useEffect(() => {
		//This useEffected needed because it synchronosly changes validsession variable
		//which useState does not because it is async.
	}, [validSession]);

	const isInvalid = () => {
		if (
			sessionName === "" ||
			timezone === "" ||
			startDate === null ||
			minTime === null
		) {
			return true;
		} else if (chips.length < 1) {
			return true;
		} else if (showAdvanceView && errorDate) {
			return true;
		} else if (sessionName.length > 64 || desc.length > 250) {
			return true;
		} else if (isScheduling) {
			return true;
		} else {
			return false;
		}
	};
	const handleAutocompleteChange = (
		event: any,
		item: readonly UserInterface[]
	) => {
		setChips([...item]);
		setActiveUsers([]);
		setSearchString("");
	};

	useEffect(() => {
		if (searchString === "" || searchString === "0") setActiveUsers([]);
	}, [activeUsers, searchString]);

	const handleSubmit = (allowPastSession: boolean = false) => {
		setIsScheduling(true);

		let min = duration;
		// To check whether its a past session or not. If yes then get confirmation.
		if (
			startDate !== null &&
			startDate.getTime() < new Date().getTime() &&
			!allowPastSession
		) {
			let _Date = addMinutes(new Date(startDate), duration);
			const new_time = new Date(_Date);
			const new_time_stamp = new_time.getTime();
			if (new_time_stamp <= new Date().getTime()) {
				// setValidSession(false);
				setPastSessionOpen(true);
			}
			setPastSessionOpen(true);
			return false;
		} else if (showAdvanceView) {
			if (startDate !== null && endDate !== null) {
				min = differenceInMinutes(endDate, startDate);
			}
		}
		chips.push(user);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/create-sessions`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				sessionName: sessionName.trim(),
				createdBy: user.id,
				dateTime: zonedTimeToUtc(startDate as Date, timezone.value as string),
				duration: min,
				sessionDescription: desc.trim(),
				timezone: timezone.value,
				attendees: chips,
				livestreamStatus: loading,
			}),
		}).then((res) => {
			if (res.status === 502) {
				navigate("/defaultPage");
			} else if (res.status >= 400) {
				let showAlert = true;
				let alertStatus = false;
				let alertMsg = "Error! Please try again..";
				props.handleClose(showAlert, alertStatus, alertMsg);
			} else {
				// UPDATE THE SESSION COUNT STORED IN USER CONTEXT WHENEVER ANY NEW SESSION IS SCHEDULED
				User.fetchUserInfo(() => {});
				setIsScheduling(false);
				let showAlert = true;
				let alertStatus = true;
				let alertMsg = `${i18n.jamSession.success}`;
				props.handleClose(showAlert, alertStatus, alertMsg);
			}
		});
	};

	const getMaxDate = () => {
		if (startDate !== null) {
			return addMinutes(startDate, 240);
		}
	};

	const getMinDate = () => {
		if (startDate !== null) {
			return addMinutes(startDate, 30);
		}
	};

	const getMinStartTime = () => {
		return null;
	};

	const handleSessionDialogClose = () => {
		setShowAdvanceView(false);
		setPastSessionOpen(false);
		setSessionName("");
		setDesc("");
		setStartDate(new Date());
		setEndDate(addMinutes(new Date(), 30));
		setEndsAt(addMinutes(new Date(), 30));
		props.handleClose();
		setLoading(false);
	};

	useEffect(() => {
		setVisible(
			loading
				? i18n.jamSession.dialog.liveStreamingStatusTrue
				: i18n.jamSession.dialog.liveStreamingStatusFalse
		);
	}, [loading]);

	const handlePopupCloseClick = () => {
		setPastSessionOpen(false);
	};

	function handlePopupSubmitClick(this: any) {
		handleSubmit(true);
	}

	const filterOptions = (options: any, { inputValue }: any) => {
		const input = inputValue.trim();
		return options.filter((option: any) =>
			option.name.toLowerCase().includes(input.toLowerCase())
		);
	};

	// Debounce function
	const debounce = (func: Function, delay: number) => {
		let timeoutId: NodeJS.Timeout;
		return (...args: any[]) => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => func(...args), delay);
		};
	};

	const debouncedSetSearchString = debounce((value: string) => {
		setSearchString(value);
	}, 300);

	const handleSearchStringChange = (event: any, value: string) => {
		debouncedSetSearchString(event.target.value);
	};

	const handleFocus = () => {
		setIsAutocompleteFocused(true);
	};

	const handleBlur = () => {
		setIsAutocompleteFocused(false);
	};

	useEffect(() => {
		if (!isAutocompleteFocused) {
			setSearchString("");
		}
	}, [isAutocompleteFocused]);

	return (
		<>
			<Dialog
				sx={{
					border: "1px solid #888888",
					color: "customColor.gray4",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					borderRadius: "8px",
					//background: `linear-gradient(0deg, #888888, #888888),linear-gradient(0deg, #FFFFFF, #FFFFFF)`,
				}}
				fullWidth
				maxWidth="lg"
				onClose={(event, reason) => {
					if (reason && reason === "backdropClick") return;
					props.handleClose();
				}}
				open={props.open && subscriptionActive}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "column",
						textAlign: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<IconButton
							aria-label="close dialogbox"
							onClick={props.handleClose}
							onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
								event.preventDefault()
							}
							edge="end"
							sx={{ marginLeft: "90%", position: "absolute", top: "10px" }}
						>
							<img src={Close} alt="close" />
						</IconButton>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							variant="h2"
							sx={{
								color: "customColor.black",
							}}
						>
							{i18n.jamSession.dialog.dialogTitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent>
					<Grid
						container={true}
						columns={{ xs: 12, sm: 12, md: 12 }}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<FormControl sx={{ width: "100%" }}>
							<Grid item={true} xs={12}>
								<Box
									display="flex"
									justifyContent="space-between"
									alignItems="center"
								>
									<FormHelperText sx={{ ml: 0 }}>
										<Typography variant="body4" color="customColor.label">
											{i18n.jamSession.dialog.title}
											<span style={{ color: "red" }}>*</span>
										</Typography>
									</FormHelperText>
								</Box>
								<TextField
									sx={{
										input: {
											color: "customColor.black",
											fontSize: theme.typography.subtitle1.fontSize,
											fontWeight: theme.typography.subtitle1.fontWeight,
											backgroundColor: "customColor.gray1",
										},
									}}
									fullWidth
									variant="outlined"
									value={sessionName}
									onChange={(event) =>
										setSessionName(event.target.value.trimStart())
									}
									error={sessionName.length > 64}
								></TextField>
								<FormHelperText
									sx={{
										textAlign: "right",
									}}
								>
									<Typography variant="body2" color="error.main">
										{sessionName?.length > 64
											? "Enter maximum 64 characters"
											: ""}
									</Typography>
								</FormHelperText>
							</Grid>
							<Grid item={true} xs={12}>
								<FormHelperText sx={{ ml: 0 }}>
									<Typography variant="body4" color="customColor.label">
										{i18n.jamSession.dialog.description}
									</Typography>
								</FormHelperText>
								<TextField
									sx={{
										input: {
											color: "customColor.black",
											fontSize: theme.typography.subtitle1.fontSize,
											fontWeight: theme.typography.subtitle1.fontWeight,
										},
										backgroundColor: "customColor.gray1",
									}}
									multiline
									rows={1}
									fullWidth
									variant="outlined"
									value={desc}
									onChange={(event) => setDesc(event.target.value.trimStart())}
									error={desc.length > 250}
								></TextField>
								<FormHelperText
									sx={{
										textAlign: "right",
									}}
								>
									{desc?.length > 250 ? "Enter maximum 250 characters" : ""}
								</FormHelperText>
							</Grid>
							<Grid item={true} xs={12}>
								<FormHelperText sx={{ ml: 0 }}>
									<Typography variant="body4" color="customColor.label">
										{i18n.jamSession.dialog.attendees}
										<span style={{ color: "red" }}>*</span>
									</Typography>
								</FormHelperText>
								<Autocomplete
									multiple
									className="session-form"
									classes={classes}
									options={activeUsers.filter(
										(user) => !chips.some((chip) => chip.id === user.id)
									)}
									// getOptionLabel={(option) =>
									// 	`${option.firstName} ${option.lastName}`
									// }
									//to change acc to nickname privacy
									getOptionLabel={(option) => `${option?.name}`}
									sx={{
										backgroundColor: "customColor.gray1",
									}}
									filterOptions={filterOptions}
									renderOption={(props, option) => (
										<li {...props} key={option.id}>
											{option.name}
										</li>
									)}
									loading={userLoading}
									noOptionsText={
										searchString !== "" && activeUsers.length <= 0
											? "No musician found."
											: null
									}
									// filterSelectedOptions
									onFocus={handleFocus}
									onBlur={handleBlur}
									onChange={handleAutocompleteChange}
									onInputChange={handleSearchStringChange}
									renderTags={(values, getTagProps) =>
										chips.map((chip, index) => {
											const { key, className, ...tagProps } = getTagProps({
												index: index,
											});
											return (
												<Chip
													key={key}
													avatar={<img src={Profile} alt="profile" />}
													className={`${className} label-chip`}
													// label={`${chip.firstName} ${chip.lastName}`}
													//change for nickname privacy
													label={`${chip?.name}`}
													sx={{
														backgroundColor: "customColor.blue",
														fontStyle: theme.typography.subtitle2.fontStyle,
														fontSize: theme.typography.subtitle2.fontSize,
														lineHeight: theme.typography.subtitle2.lineHeight,
														fontWeight: theme.typography.subtitle2.fontWeight,
														color: "secondary.main",
													}}
													{...tagProps}
												/>
											);
										})
									}
									renderInput={(params) => <TextField {...params} />}
									// renderInput={(params) => (
									// 	<TextField
									// 	  {...params}
									// 	  InputProps={{
									// 		...params.InputProps,
									// 		endAdornment: (
									// 		  <>
									// 			{userLoading && <CircularProgress/>}
									// 		  </>
									// 		),
									// 	  }}
									// 	/>
									//   )}
									open={activeUsers.length > 0}
								/>
							</Grid>
							{!showAdvanceView ? (
								// <div style={{ display: "flex", flexDirection: "row" }}>
								<Grid container columnSpacing={1}>
									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.startDate}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												openTo="day"
												views={["year", "month", "day"]}
												inputFormat="MM/dd/yyyy"
												// minDate={minDate}
												value={startDate}
												onChange={(newValue: Date | null) => {
													if (newValue === null) {
														setStartDate(null);
													} else if (checkIsValidDate(newValue)) {
														setStartDate(newValue);
													} else {
														setStartDate(null);
													}
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															"&.MuiTextField-root": {
																backgroundColor: "customColor.gray1",
																width: "100%",
															},
															input: {
																color: "customColor.black",
																fontSize: theme.typography.subtitle1.fontSize,
																fontWeight:
																	theme.typography.subtitle1.fontWeight,
															},
														}}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>

									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.startTime}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<LocalizationProvider
											sx={{ width: "100%" }}
											dateAdapter={AdapterDateFns}
										>
											<StartTimePicker
												startDate={startDate}
												setStartDate={(newValue: Date | null) => {
													// if (checkIsValidDate(newValue)) {
													// 	setStartDate(newValue);
													// }

													if (newValue === null) {
														setStartDate(null);
													} else if (checkIsValidDate(newValue)) {
														setStartDate(newValue);
													} else {
														setStartDate(null);
													}
												}}
												getMinStartTime={getMinStartTime}
											/>
										</LocalizationProvider>
									</Grid>

									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.duration}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<Select
											labelId="demo-simple-select-label"
											value={duration}
											onChange={(event: any) => {
												setDuration(event.target.value);
											}}
											sx={{
												backgroundColor: "customColor.gray1",
												color: "customColor.black",
												width: "100%",
											}}
										>
											{durationList.map((duration) => (
												<MenuItem key={duration.value} value={duration.value}>
													{duration.label}
												</MenuItem>
											))}
										</Select>
									</Grid>

									<Grid item={true} xs={3}>
										<FormHelperText sx={{ ml: 0 }}>
											<Typography variant="body4" color="customColor.label">
												{i18n.jamSession.dialog.timeZone}
												<span style={{ color: "red" }}>*</span>
											</Typography>
										</FormHelperText>
										<Autocomplete
											options={Constants.timezoneList}
											value={timezone}
											defaultValue={Constants.timezoneList[0]}
											onChange={(event: any, newValue: any) => {
												setTimezone(newValue);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													sx={{
														input: {
															color: "customColor.black",
															fontSize: theme.typography.subtitle1.fontSize,
															fontWeight: theme.typography.subtitle1.fontWeight,
														},
														"&.MuiTextField-root": {
															backgroundColor: "customColor.gray1",
														},
													}}
												/>
											)}
										/>
									</Grid>
								</Grid>
							) : (
								<>
									<Grid container columnSpacing={1}>
										<Grid item={true} xs={4}>
											<Grid>
												<FormHelperText sx={{ ml: 0 }}>
													<Typography variant="body4" color="customColor.label">
														{i18n.jamSession.dialog.startDate}
														<span style={{ color: "red" }}>*</span>
													</Typography>
												</FormHelperText>
											</Grid>
											<Grid>
												<LocalizationProvider dateAdapter={AdapterDateFns}>
													<DatePicker
														openTo="day"
														views={["year", "month", "day"]}
														inputFormat="MM/dd/yyyy"
														// minDate={minDate as Date}
														value={startDate}
														onChange={(newValue: Date | null) => {
															if (checkIsValidDate(newValue)) {
																setStartDate(newValue);
															}
														}}
														renderInput={(params) => (
															<TextField
																{...params}
																sx={{
																	input: {
																		color: "customColor.black",
																		fontSize:
																			theme.typography.subtitle1.fontSize,
																		fontWeight:
																			theme.typography.subtitle1.fontWeight,
																	},
																	"&.MuiTextField-root": {
																		backgroundColor: "customColor.gray1",
																		width: "100%",
																	},
																}}
															/>
														)}
													/>
												</LocalizationProvider>
											</Grid>
										</Grid>

										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.startTime}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<StartTimePicker
													startDate={startDate}
													setStartDate={(newValue: Date | null) => {
														if (checkIsValidDate(newValue)) {
															setStartDate(newValue);
														}
													}}
													getMinStartTime={getMinStartTime}
												/>
											</LocalizationProvider>
										</Grid>

										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.timeZone}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<Autocomplete
												options={Constants.timezoneList}
												value={timezone}
												defaultValue={Constants.timezoneList[0]}
												onChange={(event: any, newValue: any) => {
													setTimezone(newValue);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															input: {
																color: "customColor.black",
																fontSize: theme.typography.subtitle1.fontSize,
																fontWeight:
																	theme.typography.subtitle1.fontWeight,
															},
															"&.MuiTextField-root": {
																backgroundColor: "customColor.gray1",
															},
														}}
													/>
												)}
											/>
										</Grid>
									</Grid>

									<Grid container columnSpacing={1}>
										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.endDate}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													openTo="day"
													views={["year", "month", "day"]}
													inputFormat="MM/dd/yyyy"
													maxDate={getMaxDate()}
													minDate={getMinDate()}
													value={endDate}
													onChange={(newValue: Date | null) => {
														if (checkIsValidDate(newValue)) {
															setEndDate(newValue);
														}
													}}
													onError={(reason, value) => {
														if (reason) {
															setErrorDate(true);
														} else {
															setErrorDate(false);
														}
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																input: {
																	color: "customColor.black",
																	fontSize: theme.typography.subtitle1.fontSize,
																	fontWeight:
																		theme.typography.subtitle1.fontWeight,
																},
																"&.MuiTextField-root": {
																	backgroundColor: "customColor.gray1",
																	width: "100%",
																},
															}}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>

										<Grid item={true} xs={4}>
											<FormHelperText sx={{ ml: 0 }}>
												<Typography variant="body4" color="customColor.label">
													{i18n.jamSession.dialog.endTime}
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<TimePickerCmp
													maxTime={maxTime}
													minTime={minTime}
													endDate={endDate}
													setEndDate={(newValue: Date | null) => {
														if (checkIsValidDate(newValue)) {
															setEndDate(newValue);
														}
													}}
													setErrorDate={setErrorDate}
													errorDate={errorDate}
												/>
											</LocalizationProvider>
										</Grid>

										{showAdvanceView ? (
											""
										) : (
											<Grid item={true} xs={4}>
												<FormHelperText sx={{ ml: 0 }}>
													<Typography variant="body4" color="customColor.label">
														{i18n.jamSession.dialog.timeZone}
														<span style={{ color: "red" }}>*</span>
													</Typography>
												</FormHelperText>
												<Autocomplete
													options={Constants.timezoneList}
													value={timezone}
													defaultValue={Constants.timezoneList[0]}
													onChange={(event: any, newValue: any) => {
														setTimezone(newValue);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																input: {
																	color: "customColor.black",
																	fontSize: theme.typography.subtitle1.fontSize,
																	fontWeight:
																		theme.typography.subtitle1.fontWeight,
																},
																"&.MuiTextField-root": {
																	backgroundColor: "customColor.gray1",
																},
															}}
														/>
													)}
												/>
											</Grid>
										)}
									</Grid>
								</>
							)}
						</FormControl>
						<Grid xs={12} sx={{ marginTop: "10px", marginLeft: "11px" }}>
							<FormControlLabel
								control={
									<IOSSwitch
										checked={loading}
										onChange={() => setLoading(!loading)}
									/>
								}
								label={
									<span style={{ fontSize: "1rem", color: "#5C5C5C" }}>
										{visible}
									</span>
								}
							/>
						</Grid>
						<Grid>
							<Typography
								sx={{
									marginTop: "15px",
									color: "customColor.gray5",
								}}
								variant="subtitle1"
							>
								{!showAdvanceView
									? `Ends at ${endsAt}`
									: endDate !== null
									? `Ends at ${lightFormat(endDate, "MM/dd/yyyy - hh:mm aaa")}`
									: ""}

								{/* {startDate?.setMinutes(startDate?.getMinutes() + duration)} */}
							</Typography>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => setShowAdvanceView(!showAdvanceView)}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginTop: "5px" }}
							>
								<Typography
									sx={{ color: "customColor.link" }}
									variant="subtitle1"
								>
									{showAdvanceView ? "Set Duration" : "Set End Date"}
								</Typography>
							</IconButton>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<Grid
						container
						xs={12}
						// sm={8}
						// md={3}
						columnSpacing={1}
						direction={"row"}
						sx={{
							display: "flex",
							justifyContent: "flex-end",
							padding: "0 16px 16px 0",
						}}
					>
						<Grid item={true}>
							<Button
								sx={{
									color: "customColor.orange",
									borderRadius: "24px",
									borderColor: "customColor.orange",
									"&:hover": {
										borderColor: "customColor.orange",
									},
								}}
								size="large"
								variant="outlined"
								onClick={handleSessionDialogClose}
							>
								<Typography variant="h6">{i18n.dialog.cancel}</Typography>
							</Button>
						</Grid>
						<Grid item={true}>
							<Button
								sx={{
									borderRadius: "24px",
									backgroundColor: "customColor.orange",
									color: "secondary",
									"&:hover": {
										backgroundColor: "customColor.orange",
										color: "secondary",
									},
									"& .MuiButton-endIcon": {
										mr: 0,
									},
								}}
								size="large"
								variant="contained"
								type="submit"
								disabled={isInvalid()}
								onClick={() => handleSubmit()}
								endIcon={<img src={RightAarrow} alt="Right Arrow" />}
								className="submit-button"
							>
								<Typography variant="h6">
									{isScheduling
										? i18n.jamSession.dialog.schedulingBtn
										: i18n.jamSession.dialog.scheduleBtn}
								</Typography>
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>

			<Dialog
				sx={{
					border: "1px solid #888888",
					color: "customColor.gray4",
					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					borderRadius: "8px",
					// background: `linear-gradient(0deg, #888888, #888888),linear-gradient(0deg, #FFFFFF, #FFFFFF)`
				}}
				// fullWidth
				maxWidth="md"
				onClose={(event, reason) => {
					if (reason && reason === "backdropClick") return;
					props.handleClose();
				}}
				open={subscriptionActive === false}
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "column",
						textAlign: "center",
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<IconButton
							aria-label="close dialogbox"
							onClick={props.handleClose}
							onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
								event.preventDefault()
							}
							edge="end"
							sx={{ marginLeft: "90%", position: "absolute", top: "10px" }}
						>
							<img src={Close} alt="close" />
						</IconButton>
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
						<Typography
							variant="h2"
							sx={{
								color: "customColor.black",
							}}
						>
							{i18n.jamSession.dialog.subscriptionExpiredTitle}
						</Typography>
					</Box>
				</DialogTitle>
				<DialogContent className="center-align">
					<Typography variant="h5">
						{i18n.jamSession.dialog.subscriptionExpiredDesc}
					</Typography>
				</DialogContent>
				<DialogActions className="center-align">
					<Button
						sx={{
							color: "customColor.orange",
							borderRadius: "24px",
							borderColor: "customColor.orange",
							"&:hover": {
								borderColor: "customColor.orange",
							},
						}}
						size="large"
						variant="outlined"
						onClick={() => {
							navigate("/plans");
						}}
					>
						<Typography variant="h6">{i18n.dialog.viewPlans}</Typography>
					</Button>
				</DialogActions>
			</Dialog>

			{validSession ? (
				<div>
					<Dialog
						open={pastSessionOpen}
						onClose={() => {
							return false;
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						fullWidth
						maxWidth="sm"
					>
						<DialogTitle id="alert-dialog-title">
							{/* <Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={handlePopupCloseClick}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%" }}
							>
								<img src={Close} alt="close" />
							</IconButton>
						</Box> */}
							{/* {"Use Google's location service?"} */}
						</DialogTitle>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								{i18n.jamSession.dialog.confirmPastSession}
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								className="cancel-button"
								variant="outlined"
								onClick={handlePopupCloseClick}
							>
								{i18n.jamSession.dialog.confirmPastSessionBtnDecline}
							</Button>
							<Button
								className="submit-button"
								variant="contained"
								onClick={handlePopupSubmitClick}
								autoFocus
							>
								{i18n.jamSession.dialog.confirmPastSessionBtnAllow}
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			) : (
				<div>
					<Dialog
						open={pastSessionOpen}
						onClose={() => {
							return false;
						}}
						aria-labelledby="alert-dialog-title"
						aria-describedby="alert-dialog-description"
						fullWidth
						maxWidth="sm"
					>
						<DialogContent>
							<DialogContentText id="alert-dialog-description">
								Cannot Schedule Session
							</DialogContentText>
						</DialogContent>
						<DialogActions>
							<Button
								className="cancel-button"
								variant="outlined"
								onClick={handlePopupCloseClick}
							>
								Cancel
							</Button>
						</DialogActions>
					</Dialog>
				</div>
			)}
		</>
	);
};
export default ScheduleSession;
