import { useState, useEffect, useRef } from 'react';

import { Card, CardContent, Typography, Grid, CircularProgress } from '@mui/material';
import MultipleImageUpload from './multipleImageUpload';
import ErrorAlert from "../Error/ErrorAlert";
import ErrorAlertDisplay from '../Error/ErrorAlertDisplay';
import PictureCmp from './PictureCmp';
import { SocialUSerDetails } from '../../types/types';
import { useAlertStatus } from '../../context/AlertStatusContext';
import SettingsAlert from '../../pages/SettingsAlert';
import ImageCropper from '../../pages/ImageCropper';

interface Props {
    userDetails: SocialUSerDetails;
}

const MyPictures = (props: Props) => {

    const token = localStorage.getItem("sessionId");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertStatus, setAlertStatus] = useState(false);
    const [myPictures, setMyPictures] = useState<any>([])
    const viewer = props.userDetails.loggedInUser?.ViewerStatus
    const UserId = props.userDetails.privacySettings?.userProfile?.id
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [imagePreviewUrl, setImagePreviewUrl] = useState('')
    const [result, setResult] = useState('');
    const [open, setOpen] = useState(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [file, setFile] = useState<any>('');
    const { updateAlertStatus } = useAlertStatus();
    const inputFileRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState(false);

    const getPicturesDetails = () => {
        // setIsLoading(true)
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mypictures`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                userProfileId: UserId
			}),
        })
            .then((res) => {
                if (res.status !== 200) {
                    ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                setMyPictures(data)
                setIsLoading(false)
            }).catch((err) => {
                setIsLoading(false)
                setShowAlert(true);
                setAlertStatus(false);
                setAlertMsg("Network Error! Please try again..");
            });
    }

    const photoUpload = (destFile: any) => {
        // const targetFiles = e.target.files;
        // const finalFiles: any = [];
        // Object.values(targetFiles).forEach(value => {
        //     finalFiles.push(value);
        // });
        setIsLoading(true)
        const maxSize = 4 * 1024 * 1024;
        if (destFile.size > maxSize) {
            updateAlertStatus(true, false, "Max image size should be 4mb");
        } else {
            updateAlertStatus(true, true, 'Picture uploading is in process');
            let formData = new FormData();
            formData.append('files', destFile);

            fetch(`${process.env.REACT_APP_BASE_URL}/api/upload-pictures`, {
                method: "POST",
                headers: {
                    // "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${token}`,
                },
                body: formData
            }).then((res) => {
                if (res.status !== 200) {
                    ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
                } else {
                    getPicturesDetails()
                    setIsLoading(false)
                    setOpen(false)
                    updateAlertStatus(true, true, 'Picture uploaded successfully!');
                }
            }).catch((err) => {
                setIsLoading(false)
                setShowAlert(true);
                setAlertStatus(false);
                setAlertMsg("Network Error! Please try again..");
            });
        }
    }

    useEffect(() => {
        getPicturesDetails()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    const handleOpenEditor = (e: any) => {
        e.preventDefault();
        const file = e.target.files[0];
        setResult(URL.createObjectURL(file))
        setFile(file);
        if (file.type.startsWith('image/')) {
            setOpen(true)
        } else {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Please select valid picture");
        }

        if (inputFileRef.current) {
            inputFileRef.current.value = null; // Clear the input value
            const newInput = document.createElement('input');
            newInput.style.display = 'none';
            newInput.type = 'file';
            newInput.accept = 'image/*';
            newInput.addEventListener('change', handleOpenEditor);
            inputFileRef.current.parentNode.replaceChild(newInput, inputFileRef.current);
            inputFileRef.current = newInput;
        }
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <SettingsAlert />
            <ErrorAlertDisplay alertMsg={alertMsg} alertStatus={alertStatus} showAlert={showAlert} handleAlertClose={handleAlertClose} />

            {isLoading ?
                <Grid sx={{ textAlign: 'center' }}>
                    <CircularProgress />
                </Grid>
                :
                <Card sx={{ minWidth: 300, flexGrow: 1, minHeight: '30%', boxShadow: '0px 1px 14px 0px rgba(217, 231, 255, 0.77)', borderRadius: '12px' }}>

                    <CardContent sx={{ alignItems: 'center', gap: 1 }}>
                        <Grid container columns={{ xs: 6, sm: 12, md: 12 }}>
                            <Grid item xs={7}>
                                {viewer === 'SELF' ?
                                    <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '24px' }}>
                                        My Pictures
                                    </Typography>
                                    :
                                    <Typography variant="h6" sx={{ fontWeight: 500, fontSize: '24px' }}>
                                        Pictures
                                    </Typography>
                                }
                            </Grid>
                            {viewer === 'SELF' ?
                                <Grid item xs={5}>
                                    <Grid container >
                                        <Typography variant="body2" sx={{ mt: 1.5, color: '#5D5D65', mr: 3, ml: 'auto', fontSize: '14px' }}>
                                            Max 10 allowed
                                        </Typography>
                                        <MultipleImageUpload imagesLength={myPictures.length} photoUpload={handleOpenEditor} inputFileRef={inputFileRef} />
                                    </Grid>
                                </Grid>
                                : ""}
                        </Grid>
                    </CardContent>

                    <Grid
                        container
                        spacing={{ xs: 2, md: 3 }}
                        columns={{ xs: 4, sm: 8, md: 12 }}
                        sx={{ flexGrow: 1 }}
                    >
                        <Grid container spacing={2} sx={{ mx: 3, my: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                            {myPictures.length > 0 ? myPictures.map((image: any, index: any) => (
                                <PictureCmp image={image} viewer={viewer} getPicturesDetails={getPicturesDetails} />
                            )) :
                                <Grid xs={12} sx={{ textAlign: 'center', py: 3 }}>
                                    <Typography variant="subtitle1" fontSize="lg" sx={{ fontSize: '16px', fontWeight: 500 }}>
                                        No photos available
                                    </Typography>
                                </Grid>}
                        </Grid>
                    </Grid>
                </Card>
            }
            <ImageCropper
                open={open}
                onClose={handleClose}
                imagePreviewUrl={result}
                setImagePreviewUrl={setImagePreviewUrl}
                setResult={setFile}
                post={false}
                photoUpload={photoUpload}
                cover={false}
            />
        </>
    )
}

export default MyPictures