import {
	Alert,
	AlertTitle,
	Card,
	CardContent,
	Checkbox,
	FormControlLabel,
	Grid,
	Snackbar,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUser } from "../context/UserContext";
import ErrorIcon from "../images/danger.svg";
import SuccessIcon from "../images/tick-square.svg";
import { useAlertStatus } from "../context/AlertStatusContext";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";

const GigsAndColab = () => {
	const token = localStorage.getItem("sessionId");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showAlert, setShowAlert] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertMsg, setAlertMsg] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertStatus, setAlertStatus] = useState(false);
	const user = useUser();
	const [isCheckedGig, setCheckedGig] = useState<boolean>(false);
	const [isCheckedJam, setCheckedJam] = useState<boolean>(false);
	const { updateAlertStatus } = useAlertStatus();

	const getUserDetails = () => {
		let userEmail = user?.email !== null || user?.email !== "" ? user.email: null;
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/get-user-details?email=${userEmail}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data !== null) {
						setCheckedJam(data.openForCollab);
						setCheckedGig(data.openForGigs);
					} else {
						setCheckedGig(false);
						setCheckedJam(false);
					}
				})
				.catch((err) => {});
		} catch (error) {}
	};

	const handleSettings = (openForGigs: boolean, openForCollab: boolean) => {
		try {
			updateAlertStatus(true, true, "Settings change is in progress.");
			fetch(`${process.env.REACT_APP_BASE_URL}/api/gigs-collab-settings`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					openForGigs: openForGigs,
					openForCollab: openForCollab,
				}),
			})
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					updateAlertStatus(true, true, "Settings updated");
					if (data !== null) {
						setCheckedJam(data.openForCollab);
						setCheckedGig(data.openForGigs);
					} else {
						setCheckedGig(isCheckedGig);
						setCheckedJam(isCheckedJam);
					}
				})
				.catch((err) => {
					setCheckedJam(isCheckedJam);
					setCheckedGig(isCheckedGig);
					updateAlertStatus(true, false, "Something went wrong.");
				});
		} catch (error) {
			setCheckedJam(isCheckedJam);
			setCheckedGig(isCheckedGig);
			updateAlertStatus(true, false, "Something went wrong.");
		}
	};

	const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCheckedGig(event.target.checked);
		handleSettings(event.target.checked, isCheckedJam);
	};
	const handleCheckboxChangeJam = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setCheckedJam(event.target.checked);
		handleSettings(isCheckedGig, event.target.checked);
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	useEffect(() => {
		getUserDetails();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isCheckedGig, isCheckedJam]);

	// useEffect(()=>{
	//    handleSettings(isCheckedGig, isCheckedJam);
	// },[isCheckedGig, isCheckedJam])
	return (
		<>
			<Grid container>
				{showAlert && (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopCenter": {
								top: "70px",
							},
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showAlert}
						autoHideDuration={6000}
						onClose={handleAlertClose}
					>
						<Alert
							onClose={handleAlertClose}
							icon={
								alertStatus ? (
									<img src={SuccessIcon} alt="error-icon" />
								) : (
									<img
										src={ErrorIcon}
										alt="error-icon"
										style={{ marginRight: "10px" }}
									/>
								)
							}
							sx={{
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "black",
							}}
						>
							<AlertTitle
								sx={{
									fontSize: "1.125rem",
									fontWeight: "500",
									color: "#000000",
									display: "flex",
									margin: "auto",
									padding: "5px",
									justifyContent: "center !important",
								}}
							>
								{alertMsg}
							</AlertTitle>
						</Alert>
					</Snackbar>
				)}
				<Grid item xs={9} className="centerPanelWidth">
					<Card
						sx={{
							background: "#FFFFFF",
							boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
							borderRadius: "12px",
							mb: 3,
						}}
					>
						<CardContent sx={{ padding: "24px" }}>
							<Typography
								className="card-title-custom-size"
								sx={{
									display: "flex",
									alignItems: "center",

									fontWeight: "500",
								}}
								color="#000000"
							>
								{" "}
								Gigs
							</Typography>
						</CardContent>
						<FormControlLabel
							control={
								<Checkbox
									checked={isCheckedGig}
									onChange={handleCheckboxChange}
									sx={{
										width: "20px",
										height: "20px",
										marginRight: "0.5rem",
										"&.Mui-checked": { color: "#0273e6 !important" },
									}}
								/>
							}
							label={
								<Typography
									sx={{
										fontSize: "16px",
										fontWeight: 500,
										lineHeight: "20px",
										letterSpacing: "0em",
										textAlign: "left",
										fontFamily: "Lexend",
										color: "#7A7985",
									}}
								>
									Open for Gigs
								</Typography>
							}
							sx={{ marginTop: 1, marginLeft: "1.5rem" }}
						/>
						<Typography
							sx={{
								fontSize: "16px",
								fontWeight: 500,
								lineHeight: "20px",
								letterSpacing: "0em",
								textAlign: "left",
								fontFamily: "Lexend",
								marginLeft: "3.3rem",
								color: "#999999",
							}}
						>
							(people not in my connection can also message me)
						</Typography>
						<CardContent sx={{ padding: "24px" }}>
							<Typography
								className="card-title-custom-size"
								sx={{
									display: "flex",
									alignItems: "center",

									fontWeight: "500",
								}}
								color="#000000"
							>
								Collaboration (Jam)
							</Typography>
						</CardContent>
						<FormControlLabel
							control={
								<Checkbox
									checked={isCheckedJam}
									onChange={handleCheckboxChangeJam}
									sx={{
										width: "20px",
										height: "20px",
										marginRight: "0.5rem",
										"&.Mui-checked": { color: "#0273e6 !important" },
									}}
								/>
							}
							label={
								<Typography
									sx={{
										fontSize: "16px",
										fontWeight: 500,
										lineHeight: "20px",
										letterSpacing: "0em",
										textAlign: "left",
										fontFamily: "Lexend",
										color: "#7A7985",
									}}
								>
									Open for Jam collaboration with others
								</Typography>
							}
							sx={{ marginTop: 1, marginLeft: "1.5rem", marginBottom: "1rem" }}
						/>
					</Card>
				</Grid>
				{/* <Grid item={true} xs={3} className="rightPanelWidth">
					<MyActivitiesSidePanel />
				</Grid> */}
			</Grid>
		</>
	);
};

export default GigsAndColab;
