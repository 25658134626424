import { useState } from "react";
import {
	Grid,
	Typography,
	FormHelperText,
	TextField,
	Button,
	AlertTitle,
	Alert,
	Snackbar,
	Box,
	MenuList,
	MenuItem,
	ListItemIcon,
	ListItemText,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import Next from "../../../images/next.svg";
import AdminDetails from "./AdminDetails";
import AssignUserRole from "../../../images/profile-2user-r.svg";
import AssignRole from "../../../images/profile-2user-rf.svg";
import AIAvatarPrompt from "../../../images/profile-circle.svg";
import AvatarPrompt from "../../../images/profile-circle11.svg";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
			borderRadius: "18px !important",
		},
		promptBtn: {
			width: "100%",
			// background: (user.userAccountStatus == "ACTIVE") ? "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)" : "linear-gradient(126.25deg, rgba(0,0,0,0.2) 3.77%, rgba(0,0,0,0.2) 88.31%)",
			borderRadius: "18px !important",
			textAlign: 'center',
			color: 'white',
			fontSize: "0.9rem",
			fontWeight: "var(--joy-fontWeight-lg)",
			padding: "2% 0 !important",
			cursor: "pointer"
		},
	})
);

type RouteParams = {
	menuId: string;
};

const AdminSettings = () => {
	const classes = useStyles();
	const token = localStorage.getItem("sessionId");
	const param = useParams<RouteParams>();

	const [activeMenu, setActiveMenu] = useState(param.menuId ?? "assignRole");
	const [activeMenuTitle, setActiveMenuTitle] = useState(param.menuId ?? "Assign Role");
	const [avatarPromptOpen, setAvatarPromptOpen] = useState(false);

	const [menus, setMenus] = useState([
		{
			title: "Assign Role",
			icon: AssignUserRole,
			activeIcon: AssignRole,
			id: "assignRole",
			disabled: false
		},
		{
			title: "Avatar Prompt",
			icon: AvatarPrompt,
			activeIcon: AIAvatarPrompt,
			id: "avatarprompt",
			disabled: false
		},
	]);

	const changeMenu = (value: any) => {
		setActiveMenu(value.id);
		let currentActiveMenuTitle = menus.find(menu => {
			return menu.id === value.id;
		})
		setActiveMenuTitle(value!.title);
	};

	const handleDialogClose = () => {
		setAvatarPromptOpen(false);
	};


	return (
		<>

			<Grid className="mainPanel">
				<Grid
					container={true}
					className="column jam-session mainPanel"

				>
					<Grid container xs={12} sx={{ backgroundColor: 'white', zIndex: '1', paddingTop: '25px', position: 'sticky' }}>
						<Grid item xs={3} py={2}>
							<Typography
								className="home-title-custom-size"
								style={{
									fontWeight: "500",
									fontSize: "36px !important",
									lineHeight: "49px",
									color: "#000000",
								}}
							>
								App Settings
							</Typography>
						</Grid>
						{activeMenu === "avatarprompt" ?
							<>
								<Grid item xs={6.9}>
									<Typography
										sx={{ flex: "1 1 100%", marginTop: '35px', marginLeft: '5px' }}
										variant="h5"
										id="tableTitle"
										component="div"
									>
										Avatar Image Generation Prompt
									</Typography>
								</Grid>
								<Grid item xs={2} alignSelf="right">
									<label>
										<Box
											className={classes.promptBtn}
											id="photo-upload"
											sx={{ height: "28px", 
											fontSize: "18px !important", 
											fontWeight: "500", 
											background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
											marginTop: '25px',
											width: "100%",
										}}
											onClick={() => setAvatarPromptOpen(true)}
										>
											+  New Prompt
										</Box>
									</label>
								</Grid>
							</>
							: ""
						}
					</Grid>

					<Grid container sx={{ paddingTop: '10px' }}>
						<Grid item xs={3} className={`${classes.shadowedBox} leftPanelWidth`}
							sx={{ padding: "1px", height: "125px" }}>
							<Box >
								<MenuList>
									{menus.map((menu, key) => {
										return <>
											<MenuItem
												key={key}
												onClick={() => changeMenu(menu)}// onClick={() => changeMenu(menu)}
												sx={{
													padding: "10px 0 10px 16px",
													whiteSpace: 'normal',
													overflowX: 'auto',
													fontFamily: 'Avenir',
													fontStyle: "normal",
													fontWeight: "600 !important",
													fontSize: "1.125rem",
													lineHeight: "25px",
													backgroundColor: activeMenu === menu.id ? "#F8F8F8" : "none",
													borderBottom: "1px solid #D7D7D7",
												}}
												disabled={menu.disabled}
											>
												<ListItemIcon>
													{activeMenu === menu.id ? (
														<img src={menu.activeIcon} alt="Account&Login" />
													) : (
														<img src={menu.icon} alt="Account&Login" />
													)}
												</ListItemIcon>
												<ListItemText primaryTypographyProps={{
													style: { fontSize: "1.125rem" },
												}}>{menu.title}</ListItemText>
												<ListItemIcon>
													{activeMenu === menu.id && <img src={Next} alt="active" />}
												</ListItemIcon>
											</MenuItem>
											{/* <Divider variant="fullWidth" component="li" /> */}
										</>
									})}
								</MenuList>
							</Box>



							<Box>
								<Grid container xs={12} className="center-align" my={4}>
									{/* <Grid item xs={8} >
										<Button variant="outlined" size="large" fullWidth onClick={() => navigate("/plans")} disabled>Buy Storage</Button>
									</Grid> */}
								</Grid>
							</Box>

						</Grid>

						<Grid item xs={9} className="centerPanelwoRight">
							<AdminDetails menus={menus} activeMenuTitle={activeMenuTitle} activeMenu={activeMenu} avatarPromptOpen={avatarPromptOpen} handleDialogClose={handleDialogClose} />
						</Grid>
					</Grid>
				</Grid>
			</Grid >
		</>
	);
};

export default AdminSettings;
