import { useState, useEffect } from "react";
import {
	Outlet,
	useParams,
	useNavigate,
	useLocation,
} from "react-router-dom";
import {
	Typography,
	Grid,
	Button,
} from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// contexts
import { useUser } from "../../../context/UserContext";


import { useMusiciansFilter } from "../../Musicians/MusicProvider/useMusicianFilter";
import { display, fontWeight } from "@mui/system";
import LeftArrow from "../../../images/left-arrow.svg";
import { useUserManageFilter } from "./UserManagementProvider/useUserManageFilter";

import { useTheme, useMediaQuery } from '@mui/material';
import SettingsAlert from "../../../pages/SettingsAlert";

type RouteParams = {
	menuId: string;
};

const musiciansMenu = [
	// {
	// 	id: "referralcodes",
	// 	label: "Referral Codes",
	// 	navigateMenu: "",
	// },
	// {
	// 	id: "waitlistedusers",
	// 	label: "Waitlisted User",
	// 	navigateMenu: "waitlistedusers",
	// },
	{
		id: "users",
		label: "Registered User",
		// navigateMenu: "registereduser",
		navigateMenu: "",
	},
	// {
	// 	id: "users",
	// 	label: "Registered User",
	// 	navigateMenu: "registereduser",
	// },
	{
		id: "device",
		label: "Device",
		navigateMenu: "device",
	},
];



const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		shadowedBox: {
			padding: "1% 2%",
			borderRadius: "15px",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#ffffff",
			background: "#0273E6",
		},
		musicMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			// fontSize: "36px",
			lineHeight: "22px",
			color: "#000 !important",
			// background: "#0273E6"
		},
	})
);

const UsersManagement = () => {
	const param = useParams<RouteParams>();
	const { userId } = useParams();

	const classes = useStyles();
	const user = useUser();
	const navigate = useNavigate();
	const location = useLocation();
	const token = localStorage.getItem("sessionId");
	const musicFilter = useMusiciansFilter();

	const [genres, setGenres] = useState("");
	const [activeMenu, setActiveMenu] = useState("");
	const [activeSubMenu, setActiveSubMenu] = useState("invitations-received");
	const [transferDevice, setTransferDevice] = useState(false);

	const transferData = location.state;
	const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

	const {
		deviceId
	} = useUserManageFilter();


	useEffect(() => {
		if (location.pathname === "/users-management/device") {
			setActiveMenu("device");
		}
		// else if (location.pathname === "/users-management/registereduser") {
		// 	setActiveMenu("users");
		// }
		else if (location.pathname === "/users-management") {
			// setActiveMenu("referralcodes");
			setActiveMenu("users");
		}
		// else if (location.pathname === "/users-management/waitlistedusers") {
		// 	setActiveMenu("waitlistedusers");
		// }

	}, []);


	useEffect(() => {
		if (userId) {
			setTransferDevice(true);
		} else {
			setTransferDevice(false); // Make sure to reset to false if one of them is missing
		}
	}, [userId]);

	const changeMenu = (menu: any) => {
		setActiveMenu(menu.id);
		navigate(menu.navigateMenu);
	};

	const changeSubMenu = (value: string) => {
		setActiveSubMenu(value);
	};

	const handleBackNavigation = () => {
		navigate(-1);
	}

	return (
		<>
		<SettingsAlert/>
			<Grid className="mainPanel">
				<Grid
					container={true}
					className={`userContainer mainPanel ${!transferDevice ? ' column' : ''}`}
					rowSpacing={{ xs: 1 }}
					sx={{paddingTop: "20px"}}
				>
					{!transferDevice ? (
					<Grid item container sx={{ top: '72px', backgroundColor: 'white', zIndex: '999', height: '100px', paddingTop: '40px !important', position:'sticky', ml:{lg:'-1rem',xs:'0rem'}, width:{lg:"103% !important", xs:'100% !important'}}}>
						<Grid item xs={3}>
								<Typography sx={{
									fontWeight: 500,
									fontSize: {lg:"2.25rem", xs:"1.7rem"},
									lineHeight: "2.81rem",
									color: "#000000",
									width:"120% !important",
								}}>
										{activeMenu !== "device"
											? "Users Management"
											: "Device Management"}
									</Typography>
								</Grid>
								<Grid item xs={8.8} sx={{display:'flex',justifyContent:'center', maxWidth:"908px !important", margin:'auto'}}>
								{!musicFilter.isFilterActive &&
									musiciansMenu.map((menu: any) =>
										<Button
											variant="contained"
											sx={{
												background: menu.id === activeMenu ? "#0273E6" : '#F5F5F5',
												"&:hover": { background: menu.id === activeMenu ? "#0273E6" : '#EEF6FF' },
												height: "36px",
												width: {lg:'10.63rem',xs:'8.5rem'},
												fontSize: {lg:'1.125rem', xs:'0.8rem'},
												lineHeight: '1.34rem',
												fontWeight: 500,
												color: menu.id === activeMenu ? "white" : '#0273E6',
												fontFamily:'Lexend',
												padding:"10px",
												mr:'1rem'
											}}
											onClick={() => changeMenu(menu)}
											key={menu.id}
										>
											{menu.label}
										</Button>
									)}
							</Grid>
						</Grid>
					) : (
						<Grid item xs={12} sx={{ position: "sticky", top: "65px" }}>
							{transferDevice && (
								<Grid item>
									<Button
										sx={{ padding: 0, marginTop: 1 }}
										onClick={handleBackNavigation}
									>
										<Typography
											variant="body1"
											sx={{
												color: "#0273E6",
												fontSize: "1.125rem",
												fontWeight: "500",
											}}
										>
											<img
												src={LeftArrow}
												alt="back"
												style={{ marginRight: 8 }}
											/>
											Back
										</Typography>
									</Button>
								</Grid>
							)}
							<Grid container alignItems="center">
								<Grid item xs={3}>
									<Typography
										variant="body1"
										sx={{ fontSize: "36px", fontWeight: "500" }}
									>
										Transfer Device
									</Typography>
								</Grid>
								<Grid item xs={9}>
									<Grid container>
										<Grid item xs={5}>
											<Typography
												sx={{
													fontSize: "20px",
													fontWeight: "500",
													color: "#7A7985",
												}}
											>
												Select User to transfer to:
											</Typography>
										</Grid>
										<Grid
											item
											xs={7}
											sx={{ display: "flex", justifyContent: "flex-end" }}
										>
											<Typography
												sx={{
													fontSize: "20px",
													fontWeight: "500",
													color: "#7A7985",
													justifyContent: "flex-end",
												}}
											>
												Device ID to transfer : {deviceId}
											</Typography>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					)}
					<Grid
						item={true}
						className="rowa"
						sx={{
							zIndex: 777,
							width: "100%",
						}}
					>
						<Grid container sx={{marginTop: "1rem"}} xs={12}>
							<Grid item xs={12} md={12} xl={12}>
								<Outlet />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default UsersManagement;
