import {
	Alert,
	AlertTitle,
	Autocomplete,
	Box,
	Button,
	Card,
	CardMedia,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	FormControl,
	FormHelperText,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	Snackbar,
	TextField,
	Typography,
} from "@mui/material";
import BannerImage from "../commonComponents/UploadBannerImage";
import { useEffect, useState } from "react";
import ImageCropper from "../../pages/ImageCropper";
import Close from "../../images/deleteCover.svg";
import CloseIcon from "../../images/close.svg";
import {
	DatePicker,
	LocalizationProvider,
	TimePicker,
} from "@mui/x-date-pickers";
import i18n from "../../l10n/strings.json";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { checkIsValidDate } from "../../utils";
import StartTimePicker from "../jam-session/StartTimePicker";
import { Constants } from "../../services/constants";
import { addMinutes } from "date-fns";
import { useTheme } from "@mui/material/styles";
import TimePickerCmp from "../jam-session/TimePickerCmp";
import RightAarrow from "../../images/right arrow.svg";
import ErrorAlert from "../Error/ErrorAlert";
import { useNavigate } from "react-router-dom";
import CreatePostNew from "../Posts/CreatePostNew";
import { makeStyles } from "@mui/styles";
import useLocationAutocomplete from "../../hooks/useLocationAutocomplete/useLocationAutocomplete";
import { zonedTimeToUtc } from "date-fns-tz";
import { useRef } from "react";
import { useAlertStatus } from "../../context/AlertStatusContext";
import SettingsAlert from "../../pages/SettingsAlert";
import { useUser } from "../../context/UserContext";
import ErrorIcon from "../../images/danger.svg";
import SuccessIcon from "../../images/tick-square.svg";
import useGenresAndInstruments from "../../hooks/useGenresAndInstruments/useGenresAndInstruments";
import GoogleLocationService from "../GoogleLocationService";
// eslint-disable-next-line @typescript-eslint/no-unused-vars

const useStyles = makeStyles((theme: any) => ({
	root: {
		color: "white !important",
		fontFamily: "Lexend !important",
		"& .MuiAutocomplete-tag": {
			backgroundColor: "#0273E6",
			color: "white",
			"&:hover": {
				backgroundColor: "#0273E6",
				color: "white",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "white !important",
		},
		"& .MuiAutocomplete-clearIndicator": {
			"&:hover": {
				backgroundColor: "#7A7985 !important",
			},
			backgroundColor: "#7A7985 !important",
			height: "18px",
			width: "18px",
		},

		"& .MuiDialog-paper": {
			maxWidth: "808px !important",
		},
		"& .MuiDialogContent-root": {
			padding: "20px 51px 20px 38px",
		},
		"& .MuiInputBase-inputAdornedEnd": {
			padding: "8.5px 0 8.5px 12px !important",
		},
		"& p": {
			fontSize: "1rem !important",
			fontWeight: "400 !important",
			// additional styles for all <p> tags
		},
		"& .MuiTypography-root": {
			fontSize: "1rem  !important",
			fontWeight: "400 !important",
			// additional styles for the Typography component
		},
	},
}));

const NewGig = (props: any) => {
	const user = useUser();
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const durationList = Constants.duration;
	const token = localStorage.getItem("sessionId");
	const navigate = useNavigate();
	const classes = useStyles();
	const [searchString, setSearchString] = useState("");
	const cities = useLocationAutocomplete(searchString);
	const [gigTitle, setGigTitle] = useState<any>("");
	const [gigAmount, setGigAmount] = useState<any>();
	const [gigRequirement, setGigRequirement] = useState("");
	const [titleValidationMessage, setTitleValidationMessage] =
		useState<string>("");
	const [requirementValidationMessage, setRequirementValidationMessage] =
		useState<string>("");
	const [validationMessageVenue, setValidationMessageVenue] =
		useState<string>("");
	const [validationMessageAddress, setValidationMessageAddress] =
		useState<string>("");
	const [cityCountry, setCityCountry] = useState(user.location);
	const [venuStreetLocality, setVenuStreetLocality] = useState("");
	const [address, setAddress] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [endsAt, setEndsAt] = useState<Date | null | string>(
		addMinutes(new Date(), 30)
	);
	const [endDate, setEndDate] = useState<Date | null>(
		addMinutes(new Date(), 30)
	);
	const [startDate, setStartDate] = useState<Date | null>(null);
	const [duration, setDuration] = useState<number>(30);
	const [timezone, setTimezone] = useState<any>(null);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showAdvanceView, setShowAdvanceView] = useState(false);
	const [maxTime, setMaxTime] = useState<Date | null>(
		addMinutes(new Date(), 360)
	);
	const [minTime, setMinTime] = useState<Date | null>(
		addMinutes(new Date(), 30)
	);
	const [errorDate, setErrorDate] = useState(false);
	const [masterInstruments, setInstruments] = useState<any[]>([]);

	const [isPlaceholderVisible, setPlaceholderVisibility] = useState(true);
	const [selectedValue, setSelectedValue] = useState<any>([]);

	const [thumbnailImagePreviewUrl, setThumbnailImagePreviewUrl] =
		useState<any>("");
	const [thumbnailImgFile, setThumbnailImgFile] = useState<any>("");
	const [thumbnailResult, setThumbnailResult] = useState("");
	const [thumbnailOpen, setThumbnailOpen] = useState(false);
	const [openThumbnailImgDeleteDialog, setOpenThumbnailImgDeleteDialog] =
		useState(false);

	const [bannerImgFile, setBannerImgFile] = useState<any>("");
	const [bannerImagePreviewUrl, setBannerImagePreviewUrl] = useState<any>("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [showAlert, setShowAlert] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertMsg, setAlertMsg] = useState("");
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [alertStatus, setAlertStatus] = useState(false);
	const [postOpen, setPostOpen] = useState(false);
	const [gigData, setGigData] = useState<any>();
	const [isLoading, setIsLoading] = useState(false);
	const [validationTitle, setValidationTitle] = useState(false);
	const [validationDetails, setValidationDetails] = useState(false);
	const [validationVenue, setValidationVenue] = useState(false);
	const [validationAddress, setValidationAddress] = useState(false);
	const [gigAMountType, setGigAMountType] = useState<any>("free");
	const [thumbnailUrl, setThumbnailUrl] = useState("");
	const [bannerUrl, setBannerUrl] = useState("");
	const { updateAlertStatus } = useAlertStatus();
	const [genres, instruments, alertMessage, statusOfAlert] =
		useGenresAndInstruments("Genres");
	const [genreArray, setGenreArray] = useState<any>([]);
	const [lat, setLat] = useState<Number>(0);
	const [longi, setLongi] = useState<Number>(0);

	const resetGigForm = () => {
		setGigTitle("");
		setGigAmount("");
		setGigRequirement("");
		setTitleValidationMessage("");
		setRequirementValidationMessage("");
		setValidationMessageAddress("");
		setValidationMessageVenue("");
		setCityCountry("");
		setVenuStreetLocality("");
		setAddress("");
		setEndsAt(addMinutes(new Date(), 30));
		setEndDate(addMinutes(new Date(), 30));
		setStartDate(null);
		setDuration(30);
		setTimezone(null);
		// setShowAdvanceView(false);
		setMaxTime(addMinutes(new Date(), 360));
		setMinTime(addMinutes(new Date(), 30));
		setErrorDate(false);
		setInstruments([]);
		setPlaceholderVisibility(true);
		setSelectedValue([]);
		setGenreArray([]);
		setThumbnailImagePreviewUrl("");
		setThumbnailImgFile("");
		setThumbnailResult("");
		setThumbnailOpen(false);
		setOpenThumbnailImgDeleteDialog(false);
		setBannerImgFile("");
		setBannerImagePreviewUrl("");
		setShowAlert(false);
		setAlertMsg("");
		setAlertStatus(false);
		setPostOpen(false);
		setGigData(null);
		setIsLoading(false);
		setValidationTitle(false);
		setValidationDetails(false);
		setValidationAddress(false);
		setValidationVenue(false);
	};

	useEffect(() => {
		getInstrumentsList();
	}, []);

	useEffect(() => {
		if (gigAMountType === "free") {
			setGigAmount(0);
		}
	}, [gigAMountType]);

	const getInstrumentsList = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/master-instruments?page=0&size=1000`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setInstruments(data);
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	function getLocationLatLng(
		location: string
	): Promise<{ lat: number; lng: number }> {
		return fetch(
			`https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
				location
			)}&key=${process.env.REACT_APP_GOOOGLE_MAPS_API_KEY}`
		)
			.then((response) => {
				if (response.ok) {
					return response.json();
				} else {
					throw new Error(
						`Geocoding API request failed with status: ${response.status}`
					);
				}
			})
			.then((data) => {
				if (data.status === "OK") {
					const { lat, lng } = data.results[0].geometry.location;
					return { lat, lng };
				} else {
					throw new Error("Geocoding API request failed");
				}
			})
			.catch((error) => {
				console.error("Error during geocoding request:", error);
				throw error;
			});
	}

	const handleClose = (event: any, reason: string) => {
		setValidationDetails(false);
		setValidationTitle(false);
		setValidationAddress(false);
		setValidationVenue(false);
		//resetGigForm();
		if (reason && reason === "backdropClick") {
			return;
		}
		props.onPostEventClose();
	};
	const handleThumbnailOpenEditor = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];

		if (!file || !file.type.startsWith("image/")) {
			updateAlertStatus(
				true,
				false,
				"Invalid file format. Please upload an image."
			);
			return;
		}

		setThumbnailResult(URL.createObjectURL(file));
		setThumbnailImgFile(file);
		setThumbnailOpen(true);

		const newInput = document.createElement("input");
		newInput.type = "file";
		newInput.accept = "image/*";
		newInput.style.display = "none";
		newInput.addEventListener("change", handleThumbnailOpenEditor);

		const currentInput = fileInputRef.current;
		if (currentInput) {
			const parent = currentInput.parentNode;
			if (parent) {
				parent.replaceChild(newInput, currentInput);
				fileInputRef.current = newInput;
			}
		}
	};
	const deleteThumbnailUpload = () => {
		setThumbnailImagePreviewUrl("");
		setThumbnailUrl("");
		setOpenThumbnailImgDeleteDialog(false);
	};
	const thumbnailUpload = (destFile: any) => {
		// e.preventDefault();
		const reader = new FileReader();
		const file = destFile;
		reader.onloadend = () => {
			setThumbnailImgFile(file);
			setThumbnailImagePreviewUrl(reader.result as string);
		};
		reader.readAsDataURL(file);
	};
	const handlethumbnailUploadClose = () => {
		setThumbnailOpen(false);
	};
	const handleCloseThumbnailDeleteDialog = () => {
		setOpenThumbnailImgDeleteDialog(false);
	};
	const setBannerFile = (file: any) => {
		setBannerImgFile(file);
	};

	const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value;
		if (inputText.length <= 50) {
			setGigTitle(inputText);
			setTitleValidationMessage("");
			setValidationTitle(false);
		} else {
			setValidationTitle(true);
			setTitleValidationMessage("Maximum 50 characters are allowed.");
		}
	};

	const handleChange = (
		event: React.ChangeEvent<{ value: unknown }> | SelectChangeEvent<string>
	) => {
		setGigAMountType(event.target.value as string);
		setGigAmount(event.target.value === "free" ? 0 : 0);
	};

	const handleRequirementChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const inputText = event.target.value;
		if (inputText.length <= 3000) {
			setGigRequirement(inputText);
			setRequirementValidationMessage("");
			setValidationDetails(false);
		} else {
			setValidationDetails(true);
			setRequirementValidationMessage("Maximum 3000 characters are allowed.");
		}
	};

	// Date time
	const getMinStartTime = () => {
		const today = new Date();
		today.setHours(0, 0, 0, 0);
		return today;
	};
	//set initial timezone
	useEffect(() => {
		const currTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		setTimezone(
			Constants.timezoneList.find((tz) => tz.value === currTimezone) ??
				Constants.timezoneList[0]
		);
	}, []);

	//set Start Date
	useEffect(() => {
		if (timezone) {
			if (props.gigType === "modifyGig" && props.gigDetails) {
				// Set start time from data coming from backend for modifyEvent
				setStartDate(new Date(props.gigDetails.startDateTime));
			} else {
				// Set default start time for non-modifyEvent
				let _startDate = new Date().toLocaleString("en-US", {
					timeZone: timezone.value,
				});
				const reminder = 30 - (new Date(_startDate).getMinutes() % 30);
				setStartDate(addMinutes(new Date(_startDate), reminder));
			}
		}
	}, [timezone, props.gigType, props.gigDetails]);

	//send ends at
	useEffect(() => {
		if (startDate !== null) {
			const _endDate = addMinutes(new Date(startDate), duration);
			if (checkIsValidDate(_endDate)) {
				setEndDate(_endDate);
				setEndsAt(
					_endDate.toLocaleTimeString("en-US", {
						year: "numeric",
						month: "numeric",
						day: "numeric",
						hour: "numeric",
						minute: "numeric",
					})
				);
			}
		}
	}, [startDate, duration, showAdvanceView]);

	const getMaxDate = () => {
		if (startDate !== null) {
			return addMinutes(startDate, 360);
		}
	};

	const getMinDate = () => {
		if (startDate !== null) {
			return addMinutes(startDate, 30);
		}
	};

	const ArrayOfInstruments: string = selectedValue
		? Array.from(selectedValue)
				.map((item: any) => item.id)
				.join(",")
		: "";

	const handleSearchStringChange = (event: any) => {
		setSearchString(event.target.value);
	};

	//post gig
	const postGig = () => {
		setIsLoading(true);
		getLocationLatLng(cityCountry).then((result) => {
			setLat(result.lat);
			setLongi(result.lng);
		});
		const gigStartDate = zonedTimeToUtc(
			startDate as Date,
			timezone.value as string
		).toISOString();
		let gigEndDate = zonedTimeToUtc(
			endDate as Date,
			timezone.value as string
		).toISOString();
		const tz =
			timezone !== undefined && timezone !== null
				? timezone
				: setTimezone(
						Constants.timezoneList.find(
							(tz) =>
								tz.value === Intl.DateTimeFormat().resolvedOptions().timeZone
						) ?? Constants.timezoneList[0]
				  );

		let cost = gigAMountType === "free" ? 0 : gigAmount;

		let add = venuStreetLocality + " # " + address;

		let formData = new FormData();

		formData.append("type", "GIG");
		formData.append("title", gigTitle);
		formData.append("description", "");
		formData.append("cost", cost);
		formData.append("cityCountry", cityCountry);
		formData.append("venue", add);
		formData.append("startDateTime", gigStartDate);
		formData.append("endDateTime", gigEndDate);
		formData.append("timezone", tz.value);
		formData.append("duration", duration.toString());
		formData.append("eventTicketLink", "");
		formData.append("requirements", gigRequirement);
		formData.append("instrumentId", ArrayOfInstruments);
		formData.append(
			"genres",
			genreArray ? genreArray.map((genre: any) => genre.title).join(",") : ""
		);
		formData.append("thumbnail", thumbnailImgFile);
		formData.append("banner", bannerImgFile);
		formData.append("bannerUrl", bannerUrl);
		formData.append("thumbnailUrl", thumbnailUrl);

		if (props.gigType === "modifyGig") {
			formData.append("id", props.gigDetails.id);
		}
		formData.append("latitude", lat.toLocaleString());
		formData.append("longitude", longi.toLocaleString());

		fetch(`${process.env.REACT_APP_BASE_URL}/api/events/create-event`, {
			method: "POST",
			headers: {
				Authorization: `Bearer ${token}`,
			},
			body: formData,
		})
			.then((response) => {
				if(response.status === 502){
					navigate('/defaultPage')
				
				} else if (response.status < 400) return response.json();
				else {
					if (response.status >= 400 && response.status < 500) {
						updateAlertStatus(true, false, "Something went wrong ..");
					} else if (response.status >= 500 && response.status < 600) {
						updateAlertStatus(true, false, "Internal server error ..");
					}
					throw response;
				}
			})
			.then((result) => {
				if (props.gigType === "newGig" || props.gigType === "modifyGig") {
					props.onSuccess();
				}
				setGigData(result);
				setPostOpen(true);
				setIsLoading(false);

				if (props.gigType === "newGig-post") {
					setShowAlert(true);
					setAlertMsg("Gig Created Succesfully!");
					setAlertStatus(true);
				} else if (props.gigType === "modifyGig") {
					props.setShowAlertMain(true);
					props.setAlertMsgMain("Gig Modified Succesfully!");
					props.setAlertStatusMain(true);
				} else if (props.gigType === "newGig") {
					props.setShowAlertMain(true);
					props.setAlertMsgMain("Gig Created Succesfully!");
					props.setAlertStatusMain(true);
				} else {
				}
			})
			.catch((e) => {
				setIsLoading(false);
				if (e.status >= 400 && e.status < 500) {
					setShowAlert(true);
					setAlertMsg("Something Went Wrong!");
					setAlertStatus(false);
				} else if (e.status >= 500 && e.status < 600) {
					setShowAlert(true);
					setAlertMsg("Internal Server Error!");
					setAlertStatus(false);
				} else {
					setShowAlert(true);
					setAlertMsg("Please Try Again!");
					setAlertStatus(false);
				}
			});
	};
	// modify gig
	useEffect(() => {
		if (props.gigType === "modifyGig") {
			const gigDetails = props.gigDetails;

			setGigTitle(gigDetails.title);
			setGigAmount(gigDetails.cost);
			setGigAMountType(gigDetails.cost === 0 ? "free" : "paid");
			if (
				gigDetails.requirements !== null &&
				gigDetails.requirements !== "undefined"
			) {
				setGigRequirement(gigDetails.requirements);
			} else {
				setGigRequirement("Not mentioned");
			}
			setCityCountry(gigDetails.cityCountry);
			const a =
				gigDetails.venue === "" || gigDetails.venue === null
					? ""
					: gigDetails.venue;
			const [venuStreetLocality, address] = a.split(" # ");
			setVenuStreetLocality(venuStreetLocality);
			setAddress(address);
			setStartDate(gigDetails.startDateTime);
			setEndDate(gigDetails.endDateTime);
			setDuration(gigDetails.duration);
			setTimezone(
				Constants.timezoneList.find(
					(tz) => tz.value === gigDetails?.timezone
				) ?? gigDetails?.timezone
			);
			if (gigDetails.thumbnailUrl === null || gigDetails.thumbnailUrl === "") {
				setThumbnailImagePreviewUrl(
					process.env.REACT_APP_PICTURE_URL + "/defaultimages/gig-thumbnail.svg"
				);
			} else {
				setThumbnailImagePreviewUrl(
					process.env.REACT_APP_PICTURE_URL + gigDetails.thumbnailUrl
				);
			}

			if (gigDetails.bannerUrl === null || gigDetails.bannerUrl === "") {
				setBannerImagePreviewUrl("");
			} else {
				setBannerImagePreviewUrl(
					process.env.REACT_APP_PICTURE_URL + gigDetails.bannerUrl
				);
			}

			setSelectedValue(
				gigDetails.masterInstruments !== null
					? gigDetails.masterInstruments
					: ""
			);

			const prevGenres = gigDetails.genres;
			setGenreArray(
				gigDetails.genres
					? prevGenres.split(",").map((item: any) => ({ title: item.trim() }))
					: []
			);

			setBannerUrl(gigDetails.bannerUrl);
			setThumbnailUrl(gigDetails.thumbnailUrl);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleDeleteImage = (call: boolean) => {
		if (call) {
			setBannerUrl("");
		}
	};

	const handlePostClose = (call: boolean) => {
		if (call) {
			setPostOpen(false);
			handleClose(call, "");
		} else {
			setPostOpen(false);
		}
	};

	const isDisabled = () => {
		if (
			gigTitle === "" ||
			isNaN(gigAmount) ||
			gigAmount === "" ||
			cityCountry === "" ||
			selectedValue.length === 0 ||
			genreArray.length === 0 ||
			validationDetails ||
			validationTitle ||
			validationAddress ||
			validationVenue ||
			isLoading ||
			startDate === null ||
			timezone === null
		) {
			return true;
		} else {
			return false;
		}
	};

	const handleVenueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value;
		if (inputText.length <= 255 || inputText.length === 0) {
			setValidationVenue(false);
			setVenuStreetLocality(inputText);
			setValidationMessageVenue("");
		} else {
			setValidationVenue(true);
			setVenuStreetLocality(inputText);
			setValidationMessageVenue("Maximum 255 characters are allowed.");
		}
	};

	const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputText = event.target.value;
		if (inputText.length <= 255 || inputText.length === 0) {
			setValidationAddress(false);
			setAddress(inputText);
			setValidationMessageAddress("");
		} else {
			setValidationAddress(true);
			setAddress(inputText);
			setValidationMessageAddress("Maximum 255 characters are allowed.");
		}
	};

	return (
		<>
			<Grid
				className="center-align homeTab"
				style={{ padding: "10px", position: "relative" }}
				id="home-alert-title-bar"
			>
				{showAlert && (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopCenter": {
								top: "80px",
							},
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showAlert}
						autoHideDuration={6000}
						onClose={() => setShowAlert(false)}
					>
						<Alert
							onClose={() => setShowAlert(false)}
							icon={
								alertStatus ? (
									<img src={SuccessIcon} alt="error-icon" />
								) : (
									<img
										src={ErrorIcon}
										alt="error-icon"
										style={{ marginRight: "10px" }}
									/>
								)
							}
							sx={{
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "black",
							}}
						>
							<AlertTitle
								sx={{
									fontSize: "1.125rem",

									fontWeight: "500",
									color: "#000000",
									display: "flex",

									margin: "auto",
									padding: "4px",
									justifyContent: "center !important",
								}}
							>
								{alertMsg}
							</AlertTitle>
						</Alert>
					</Snackbar>
				)}
			</Grid>
			<Dialog
				sx={{
					border: 1,
					borderColor: "customColor.gray4",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="md"
				onClose={handleClose}
				open={props.open}
				className="dialogWidthEvent"
			>
				<DialogTitle
					sx={{
						display: "flex",
						flexDirection: "column",
						textAlign: "center",
					}}
				>
					<Typography
						component="div"
						color="customColor.black"
						sx={{ fontSize: "24px", lineHeight: "30px" }}
						variant="h3"
					>
						{props.gigType === "newGig" || props.gigType === "newGig-post"
							? "Post New Gig"
							: "Modify Gig"}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid
						container={true}
						columns={{ xs: 12, sm: 12, md: 12 }}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<FormControl sx={{ width: "100%" }}>
							<Grid xs={12}>
								<Typography color="customColor.label" variant="body4">
									Gig Page banner
								</Typography>
							</Grid>
							<Grid item sx={{ mb: 2 }}>
								{/* Upload Banner image coode */}
								<BannerImage
									imgFile={setBannerFile}
									sx={{ width: "719px", height: "181px", borderRadius: "12px" }}
									// bannerPreviewUrl={props.gigType === "modifyGig" || props.gigType === "modifyGig-post" ? process.env.REACT_APP_PICTURE_URL + props.gigDetails.bannerUrl : bannerImagePreviewUrl}
									bannerPreviewUrl={bannerImagePreviewUrl}
									type={"gig"}
									imageDeleted={(call: boolean) => handleDeleteImage(call)}
								/>
							</Grid>

							<Grid
								container
								columns={{ xs: 4, sm: 8, md: 12 }}
								sx={{ justifyContent: "space-between" }}
							>
								<Grid xs={3}>
									{/* Upload image Code */}
									<SettingsAlert />
									<Typography color="customColor.label" variant="body4">
										Gig thumbnail in search
									</Typography>
									<Card
										sx={{
											width: 150,
											height: 150,
											flexGrow: 1,
											position: "relative",
											boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
											borderRadius: "10px",
											mt: "9px",
											mb: "20px",
										}}
									>
										<label
											id="photo-upload"
											title="Upload Photo"
											style={{
												position: "absolute",
												top: "95px",
												left: "25px",
												cursor: "pointer",
												zIndex: 2,

												padding: " 5px 20px",
												backgroundColor: "#fff",
												border: "1.5px solid #0273E6",
												color: "#0273E6",
												borderRadius: "15px",
											}}
										>
											{" "}
											Upload
											{/* <img

												style={{
													height: "32px",
													width: "32px",
													backgroundColor: "black",
												}}
												src={EditIcon}
												alt="Upload"
											/> */}
											<input
												ref={fileInputRef}
												id="photo-upload"
												type="file"
												accept="image/*"
												style={{ display: "none" }}
												onChange={(e) => handleThumbnailOpenEditor(e)}
											/>
										</label>
										{thumbnailImagePreviewUrl && (
												<IconButton
													onClick={() => setOpenThumbnailImgDeleteDialog(true)}
													sx={{
														position: "absolute",
														top: "5px",
														right: "5px",
														cursor: "pointer",
														zIndex: 2,
													}}
												>
													<img
														src={Close}
														alt="Close"
														style={{
															height: "32px",
															width: "32px",
															backgroundColor: "black",
														}}
													/>
												</IconButton>
											)}
										<CardMedia
											sx={{ height: "181px", backgroundColor: "#727272" }}
											image={
												thumbnailImagePreviewUrl &&
												thumbnailImagePreviewUrl !== ""
													? thumbnailImagePreviewUrl
													: process.env.REACT_APP_PICTURE_URL +
													  "/defaultimages/gig-thumbnail.svg"
											}
										/>
									</Card>
									{openThumbnailImgDeleteDialog && (
										<Dialog
											fullWidth
											maxWidth="sm"
											open={openThumbnailImgDeleteDialog}
											onClose={() => handleCloseThumbnailDeleteDialog()}
										>
											<DialogTitle
												className="edit-heading-name"
												sx={{
													display: "flex",
													flexDirection: "column",
												}}
											>
												<Box
													sx={{
														display: "flex",
														flexDirection: "row",
														justifyContent: "flex-end",
													}}
												>
													<IconButton
														aria-label="close dialogbox"
														onClick={() => handleCloseThumbnailDeleteDialog()}
														onMouseDown={(
															event: React.MouseEvent<HTMLButtonElement>
														) => event.preventDefault()}
														edge="end"
														sx={{
															marginLeft: "90%",
															position: "absolute",
															top: "8px",
														}}
													>
														<img src={CloseIcon} alt="close" />
													</IconButton>
												</Box>
												<Typography variant="body4">Delete Image</Typography>
											</DialogTitle>
											<DialogContent>
												<DialogContentText
													sx={{
														display: "flex",
														flexDirection: "row",
														justifyContent: "center",
													}}
												>
													Are you sure?
												</DialogContentText>
											</DialogContent>
											<DialogActions>
												<Button
													variant="outlined"
													onClick={() => handleCloseThumbnailDeleteDialog()}
												>
													Cancel
												</Button>
												<Button
													variant="contained"
													onClick={() => deleteThumbnailUpload()}
												>
													Confirm
												</Button>
											</DialogActions>
										</Dialog>
									)}

									<ImageCropper
										open={thumbnailOpen}
										onClose={handlethumbnailUploadClose}
										imagePreviewUrl={thumbnailResult}
										setImagePreviewUrl={setThumbnailImagePreviewUrl}
										setResult={setThumbnailImgFile}
										post={true}
										photoUpload={thumbnailUpload}
										cover={false}
									/>
								</Grid>
								<Grid container direction={"column"} xs={8}>
									<Typography
										variant="body4"
										color="customColor.label"
										sx={{ marginLeft: "0px !important" }}
									>
										Gig Title
										<span style={{ color: "red" }}>*</span>
									</Typography>
									<Grid sx={{ mt: "9px", mb: "20px" }}>
										<TextField
											inputProps={{
												style: {
													background: "#F8F8F8",
													color: "#000000",
													fontSize: "1rem",
													fontWeight: "500",
													lineHeight: "1rem",
												},
												autoFocus: true,
											}}
											fullWidth
											variant="outlined"
											value={gigTitle}
											onChange={handleTitleChange}
											size="small"
											required
										></TextField>
										{titleValidationMessage && (
											<Typography
												variant="caption"
												color="error"
												sx={{ float: "right" }}
											>
												{titleValidationMessage}
											</Typography>
										)}
									</Grid>

									<Grid item container={true} columns={{ xs: 12 }}>
										<Grid item xs={6}>
											<FormHelperText id="outlined-weight-helper-text">
												<Typography
													variant="body4"
													color="customColor.label"
													sx={{ ml: 0 }}
												>
													{i18n.events.postNewEvent.ticketPrice}
													<span style={{ color: "red", marginRight: "7px" }}>
														*
													</span>
													<Select
														value={gigAMountType}
														onChange={handleChange}
														displayEmpty
														inputProps={{ "aria-label": "Without label" }}
														sx={{
															backgroundColor: "#EEEEEE",
															borderRadius: "24px",
															height: "40px",
															width: "80px",
															"& .MuiOutlinedInput-notchedOutline": {
																borderColor: "white !important",
															},
															"& .MuiOutlinedInput-notchedOutline:focus-visible":
																{
																	borderColor: "white",
																},
															"& .MuiOutlinedInput-notchedOutline:hover": {
																borderColor: "white",
															},
															"& .MuiOutlinedInput-root:hover": {
																borderColor: "white",
															},
															"& .MuiSelect-outlined:hover": {
																borderCollapse: "white !important",
															},
															"& .MuiSelect-root:hover": {
																borderCollapse: "white !important",
															},
															"& .MuiSelect-select:hover": {
																borderCollapse: "white !important",
															},
														}}
													>
														<MenuItem value="free">Free</MenuItem>
														<MenuItem value="paid">Paid</MenuItem>
													</Select>
												</Typography>
											</FormHelperText>
											<FormControl sx={{ mr: 1, minWidth: 120, mt: 1 }}>
												<OutlinedInput
													type="string"
													size="small"
													startAdornment={
														<InputAdornment position="start">$</InputAdornment>
													}
													fullWidth
													value={gigAMountType === "free" ? "Free" : gigAmount}
													onChange={(event) => {
														const inputValue = parseFloat(event.target.value);
														if (!isNaN(inputValue) && inputValue > 0) {
															setGigAmount(inputValue);
														} else {
															setGigAmount("");
														}
													}}
													required
													sx={{
														background: "#F8F8F8",
														color: "#000000",
													}}
													disabled={gigAMountType === "free"}
												/>
											</FormControl>
										</Grid>
										{/* <Grid item xs={1} /> */}
										<Grid item xs={6} sx={{ mt: "8px" }}>
											<FormHelperText
												id="outlined-weight-helper-text"
												sx={{ marginLeft: "0px" }}
											>
												<Typography
													variant="body4"
													color="customColor.label"
													sx={{ ml: 0 }}
												>
													Instruments
													<span style={{ color: "red" }}>*</span>
												</Typography>
											</FormHelperText>
											<Autocomplete
												multiple
												id="instruments"
												classes={classes}
												className="social-textField"
												value={selectedValue}
												onChange={(event, newValue) => {
													const sortedValues = newValue
														.slice()
														.sort((a, b) =>
															a.instrumentName.localeCompare(b.instrumentName)
														);
													setSelectedValue(sortedValues);
													setPlaceholderVisibility(newValue.length === 0);
												}}
												options={masterInstruments.sort((a: any, b: any) =>
													a.instrumentName.localeCompare(b.instrumentName)
												)}
												getOptionLabel={(option) => option.instrumentName || ""}
												isOptionEqualToValue={(option, value) =>
													option.id === value.id
												}
												// filterSelectedOptions
												renderInput={(params) => (
													<TextField
														{...params}
														placeholder={
															isPlaceholderVisible ? "Instruments" : ""
														}
														required
														sx={{
															input: {
																color: "customColor.black",
																fontSize: "1rem",
																fontWeight: "500",
																lineHeight: "20px",
															},
															backgroundColor: "customColor.gray1",
														}}
													/>
												)}
												aria-required
												sx={{ mt: "13px" }}
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>

							{/* Gig Genres */}
							<Grid item xs={12}>
								<FormHelperText
									id="outlined-weight-helper-text"
									sx={{ marginLeft: "0px" }}
								>
									<Typography
										variant="body4"
										color="customColor.label"
										sx={{ marginLeft: "0px !important" }}
									>
										Genres
										<span style={{ color: "red" }}>*</span>
									</Typography>
								</FormHelperText>
								<Autocomplete
									multiple
									id="Genres"
									className="social-textField"
									classes={classes}
									value={genreArray}
									onChange={(event, newValue) => {
										const sortedValues = newValue
											.slice()
											.sort((a, b) => a.title.localeCompare(b.title));
										setGenreArray(sortedValues as any);
									}}
									options={genres}
									getOptionLabel={(option) => option.title}
									isOptionEqualToValue={(option, value) =>
										option.title === value.title
									}
									filterSelectedOptions
									renderInput={(params) => (
										<TextField
											{...params}
											placeholder={genreArray.length === 0 ? "Genres" : ""}
											required
										/>
									)}
									aria-required
									sx={{ mt: "2px", mb: "20px" }}
								/>
							</Grid>

							<Typography
								variant="body4"
								color="customColor.label"
								sx={{ marginLeft: "0px !important" }}
							>
								Requirement
							</Typography>
							<TextField
								sx={{
									textarea: {
										color: "customColor.black",
										fontSize: "1rem",
										fontWeight: "500",
										lineHeight: "1rem",
									},
									div: {
										padding: "14px",
									},
									backgroundColor: "customColor.gray1",
									mt: "9px",
									mb: "20px",
								}}
								multiline
								rows={3}
								fullWidth
								variant="outlined"
								value={gigRequirement}
								onChange={handleRequirementChange}
							></TextField>
							{requirementValidationMessage && (
								<Typography
									variant="caption"
									color="error"
									sx={{ textAlign: "right" }}
								>
									{requirementValidationMessage}
								</Typography>
							)}

							<Typography
								variant="body4"
								color="customColor.label"
								sx={{ marginLeft: "0px !important", mb: "10px" }}
							>
								Gig Address
							</Typography>

							<Typography
								variant="body4"
								color="customColor.label"
								sx={{ marginLeft: "0px !important" }}
							>
								Venue name
							</Typography>
							<Grid>
								<TextField
									sx={{
										input: {
											color: "customColor.black",
											fontSize: "1rem",
											fontWeight: "500",
											lineHeight: "1rem",
										},
										backgroundColor: "customColor.gray1",
										mt: "9px",
										mb: "20px",
									}}
									fullWidth
									variant="outlined"
									value={venuStreetLocality}
									onChange={handleVenueChange}
								></TextField>
								{validationMessageVenue && (
									<Typography
										variant="caption"
										color="error"
										sx={{ float: "right" }}
									>
										{validationMessageVenue}
									</Typography>
								)}
							</Grid>

							<Typography
								variant="body4"
								color="customColor.label"
								sx={{ marginLeft: "0px !important" }}
							>
								Address
							</Typography>
							<Grid>
								<TextField
									sx={{
										input: {
											color: "customColor.black",
											fontSize: "1rem",
											fontWeight: "500",
											lineHeight: "1rem",
										},
										backgroundColor: "customColor.gray1",
										mt: "9px",
										mb: "20px",
									}}
									fullWidth
									variant="outlined"
									value={address}
									onChange={handleAddressChange}
								></TextField>
								{validationMessageAddress && (
									<Typography
										variant="caption"
										color="error"
										sx={{ float: "right" }}
									>
										{validationMessageAddress}
									</Typography>
								)}
							</Grid>
							<Typography
								variant="body4"
								color="customColor.label"
								sx={{ marginLeft: "0px !important" }}
							>
								City, Country
								<span style={{ color: "red" }}>*</span>
							</Typography>
							<Grid
								item={true}
								xs={12}
								sx={{
									input: {
										color: "customColor.black",
										fontSize: "1rem",
										fontWeight: "500",
										lineHeight: "16px",
									},
									backgroundColor: "customColor.gray1",
									mt: "9px",
									mb: "10px",
								}}
							>
								<GoogleLocationService
									defaultValue={cityCountry}
									onLocationChange={(locationDesc: string) =>
										setCityCountry(locationDesc)
									}
								/>
							</Grid>

							{!showAdvanceView ? (
								<Grid container columnSpacing={1}>
									<Grid item={true} xs={3}>
										<Typography
											variant="body4"
											color="customColor.label"
											sx={{ marginLeft: "0px !important" }}
										>
											Start Date 
											<span style={{ color: "red" }}>*</span>
										</Typography>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											<DatePicker
												openTo="day"
												views={["year", "month", "day"]}
												inputFormat="MM/dd/yyyy"
												// minDate={minDate}
												value={startDate}
												onChange={(newValue: Date | null) => {
													if(newValue === null){
														setStartDate(null)
													}else 
													if (checkIsValidDate(newValue)) {
														setStartDate(newValue);
													}else{
														setStartDate(null)
													}
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															"&.MuiTextField-root": {
																backgroundColor: "customColor.gray1",
																width: "100%",
																mt: "9px",
																mb: "20px",
															},

															input: {
																color: "customColor.black",
																fontSize: "1rem",
																fontWeight: "500",
																lineHeight: "1rem",
															},
														}}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>

									<Grid item={true} xs={2.5}>
										<Typography
											variant="body4"
											color="customColor.label"
											sx={{ marginLeft: "0px !important" }}
										>
											Start Time
											<span style={{ color: "red" }}>*</span>
										</Typography>
										<LocalizationProvider dateAdapter={AdapterDateFns}>
											{/* <StartTimePicker
												startDate={startDate}
												setStartDate={(newValue: Date | null) => {
													if (checkIsValidDate(newValue)) {
														setStartDate(newValue);
													}
												}}
												getMinStartTime={getMinStartTime}
											/> */}
											<TimePicker
												value={startDate}
												onChange={(newValue: Date | null) => {
													if(newValue === null){
														setStartDate(null)
													}else 
													if (checkIsValidDate(newValue)) {
														setStartDate(newValue);
													}else{
														setStartDate(null)
													}
												}}
												minTime={getMinStartTime()}
												renderInput={(params: any) => (
													<TextField
														{...params}
														sx={{
															input: {
																color: "customColor.black",
																fontSize: "1rem",
																fontWeight: "500",
																lineHeight: "1rem",
															},
															"&.MuiTextField-root": {
																width: "100%",
																mt: "9px",
																mb: "20px",
															},
														}}
														variant={"outlined"}
													/>
												)}
											/>
										</LocalizationProvider>
									</Grid>

									<Grid
										item={true}
										xs={2.5}
										sx={{
											div: {
												fontSize: "1rem",
												fontWeight: "500",
												lineHeight: "1rem",
											},
										}}
									>
										<Typography
											variant="body4"
											color="customColor.label"
											sx={{ marginLeft: "0px !important" }}
										>
											Duration
											<span style={{ color: "red" }}>*</span>
										</Typography>
										<Select
											labelId="demo-simple-select-label"
											value={duration}
											onChange={(event: any) => setDuration(event.target.value)}
											sx={{
												backgroundColor: "customColor.gray1",
												color: "customColor.black",
												width: "100%",
												fontSize: "1rem",
												fontWeight: "500",
												lineHeight: "1rem",
												mt: "9px",
												mb: "20px",
											}}
										>
											{durationList.map((duration) => (
												<MenuItem key={duration.value} value={duration.value}>
													{duration.label}
												</MenuItem>
											))}
										</Select>
									</Grid>

									<Grid item={true} xs={4}>
										<Typography
											variant="body4"
											color="customColor.label"
											sx={{ marginLeft: "0px !important" }}
										>
											Time Zone
											<span style={{ color: "red" }}>*</span>
										</Typography>
										<Autocomplete
											sx={{ mt: "9px", mb: "20px" }}
											options={Constants.timezoneList}
											value={timezone}
											defaultValue={Constants.timezoneList[0]}
											onChange={(event: any, newValue: any) => {
												setTimezone(newValue);
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													sx={{
														input: {
															color: "customColor.black",
															fontSize: "1rem",
															fontWeight: "500",
															lineHeight: "1rem",
														},
														"&.MuiTextField-root": {
															backgroundColor: "customColor.gray1",
														},
													}}
												/>
											)}
										/>
									</Grid>
								</Grid>
							) : (
								<>
									<Grid container columnSpacing={1}>
										<Grid item={true} xs={4}>
											<Typography
												variant="body4"
												color="customColor.label"
												sx={{ marginLeft: "0px !important" }}
											>
												Start Date
											</Typography>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													openTo="day"
													views={["year", "month", "day"]}
													inputFormat="MM/dd/yyyy"
													// minDate={minDate as Date}
													value={startDate}
													onChange={(newValue: Date | null) => {
														if (checkIsValidDate(newValue)) {
															setStartDate(newValue);
														}
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																input: {
																	color: "customColor.black",
																	fontSize: "1rem",
																	fontWeight: "500",
																	lineHeight: "1rem",
																},
																"&.MuiTextField-root": {
																	backgroundColor: "customColor.gray1",
																	width: "100%",
																},
																mt: "9px",
																mb: "20px",
															}}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>

										<Grid item={true} xs={4}>
											<Typography
												variant="body4"
												color="customColor.label"
												sx={{ marginLeft: "0px !important", mb: "9px" }}
											>
												Start Time
											</Typography>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<StartTimePicker
													startDate={startDate}
													setStartDate={(newValue: Date | null) => {
														if (checkIsValidDate(newValue)) {
															setStartDate(newValue);
														}
													}}
													sx={{
														"&.MuiTextField-root": {
															mt: "9px",
															mb: "20px",
														},
														mt: "9px",
														mb: "20px",
													}}
													getMinStartTime={getMinStartTime}
												/>
											</LocalizationProvider>
										</Grid>

										<Grid item={true} xs={4}>
											<Typography
												variant="body4"
												color="customColor.label"
												sx={{ marginLeft: "0px !important" }}
											>
												Time Zone
											</Typography>
											<Autocomplete
												sx={{ mt: "9px", mb: "20px" }}
												options={Constants.timezoneList}
												value={timezone}
												defaultValue={Constants.timezoneList[0]}
												onChange={(event: any, newValue: any) => {
													setTimezone(newValue);
												}}
												renderInput={(params) => (
													<TextField
														{...params}
														sx={{
															input: {
																color: "customColor.black",
																fontSize: "1rem",
																fontWeight: "500",
																lineHeight: "1rem",
															},
															"&.MuiTextField-root": {
																backgroundColor: "customColor.gray1",
															},
														}}
													/>
												)}
											/>
										</Grid>
									</Grid>
									<Grid container columnSpacing={1}>
										<Grid item={true} xs={4}>
											<Typography
												variant="body4"
												color="customColor.label"
												sx={{ marginLeft: "0px !important" }}
											>
												End Date
											</Typography>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<DatePicker
													openTo="day"
													views={["year", "month", "day"]}
													inputFormat="MM/dd/yyyy"
													maxDate={getMaxDate()}
													minDate={getMinDate()}
													value={endDate}
													onChange={(newValue: Date | null) => {
														if (checkIsValidDate(newValue)) {
															setEndDate(newValue);
														}
													}}
													onError={(reason, value) => {
														if (reason) {
															setErrorDate(true);
														} else {
															setErrorDate(false);
														}
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																input: {
																	color: "customColor.black",
																	fontSize: "1rem",
																	fontWeight: "500",
																	lineHeight: "1rem",
																},
																"&.MuiTextField-root": {
																	backgroundColor: "customColor.gray1",
																	width: "100%",
																},
																mt: "9px",
																mb: "20px",
															}}
														/>
													)}
												/>
											</LocalizationProvider>
										</Grid>

										<Grid item={true} xs={4}>
											<Typography
												variant="body4"
												color="customColor.label"
												sx={{ marginLeft: "0px !important", mb: "9px" }}
											>
												End Time
											</Typography>
											<LocalizationProvider dateAdapter={AdapterDateFns}>
												<TimePickerCmp
													sx={{ mt: "9px", mb: "20px" }}
													maxTime={maxTime}
													minTime={minTime}
													endDate={endDate}
													setEndDate={(newValue: Date | null) => {
														if (checkIsValidDate(newValue)) {
															setEndDate(newValue);
														}
													}}
													setErrorDate={setErrorDate}
													errorDate={errorDate}
												/>
											</LocalizationProvider>
										</Grid>

										{showAdvanceView ? (
											""
										) : (
											<Grid item={true} xs={4}>
												<Typography
													variant="body4"
													color="customColor.label"
													sx={{ marginLeft: "0px !important" }}
												>
													Time Zone
												</Typography>
												<Autocomplete
													sx={{}}
													options={Constants.timezoneList}
													value={timezone}
													defaultValue={Constants.timezoneList[0]}
													onChange={(event: any, newValue: any) => {
														setTimezone(newValue);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															sx={{
																input: {
																	color: "customColor.black",
																	fontSize: "1rem",
																	fontWeight: "500",
																	lineHeight: "1rem",
																},
																"&.MuiTextField-root": {
																	backgroundColor: "customColor.gray1",
																},
															}}
														/>
													)}
												/>
											</Grid>
										)}
									</Grid>
								</>
							)}
						</FormControl>
					</Grid>
				</DialogContent>

				<DialogActions sx={{ mr: 2 }}>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-start",
						}}
					>
						<Grid item={true} xs={12}>
							<Typography
								sx={{
									marginTop: "15px",
									color: "customColor.gray5",
								}}
								variant="subtitle1"
							>
								{endDate !== null
									? `Ends at ${endDate.toLocaleString("en-US", {
											year: "numeric",
											month: "numeric",
											day: "numeric",
											hour: "numeric",
											minute: "numeric",
											hour12: true,
									  })}`
									: ""}

								{/* {startDate?.setMinutes(startDate?.getMinutes() + duration)} */}
							</Typography>

							<IconButton
								aria-label="close dialogbox"
								// onClick={() => setShowAdvanceView(!showAdvanceView)}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginTop: "5px" }}
							>
								<Typography
									sx={{ color: "customColor.link" }}
									variant="subtitle1"
								>
									{/* {showAdvanceView ? "Set Duration" : "Advance"} */}
								</Typography>
							</IconButton>
						</Grid>
					</Grid>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "flex-end",
						}}
					>
						<Grid item={true}>
							<Button
								sx={{
									color: "#ff702a  !important",
									borderRadius: "24px  !important",
									"&:hover": {
										borderColor: "#FF702A",
									},
									width: "140px",
									height: "40px",
									border: "2px solid #FF702A !important",
								}}
								size="large"
								variant="outlined"
								onClick={(e) => {
									handleClose(e, "");
								}}
							>
								Cancel
							</Button>
						</Grid>
						<Grid item={true}>
							<Button
								sx={{
									borderRadius: "24px  !important",
									backgroundColor: isDisabled()
										? "rgb(136, 136, 136)"
										: "#FF702A ",
									color: "#fff",
									"&:hover": {
										backgroundColor: "#FF702A",
										color: "secondary",
									},

									height: "40px",
								}}
								className="submit-button"
								size="large"
								endIcon={<img src={RightAarrow} alt="Right Arrow" />}
								variant="contained"
								onClick={() => postGig()}
								disabled={isDisabled()}
							>
								<Typography>
									{isLoading
										? "Saving..."
										: props.gigType === "newGig" ||
										  props.gigType === "newGig-post"
										? "Post Gig"
										: "Modify Gig"}
								</Typography>

								{/* {!isLoading ? props.gigType === "newGig" ? "Post Gig" : isLoading? props.gigType === "newGig":"Posting Gig"}
								{!isLoading && props.gigType === "newGig" ? "Post Gig" :  "Modify Gig" }
								{isLoading && props.gigType === "newGig" ? "Posing Gig" :  "Modifying Gig" } */}

								{/* {props.gigType === "newGig" || props.gigType === "newGig-post" ? "Post Gig" : "Modify Gig"} */}
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</Dialog>
			{props.gigType === "newGig-post" && postOpen && (
				<CreatePostNew
					open={postOpen}
					handleClose={(call: boolean) => handlePostClose(call)}
					resetPostDetails={props.resetPostDetails}
					gigDto={gigData}
					postType={"GIG"}
					editPost={false}
				/>
			)}
		</>
	);
};

export default NewGig;
