import { useEffect, useState } from "react";
import {
	Card,
	CardActions,
	CardContent,
	Button,
	Typography,
	Grid,
	Link,
	CircularProgress,
	useMediaQuery,
} from "@mui/material";
import RightAarrow from "../../images/right arrow.svg";
import i18n from "../../l10n/strings.json";
import { Theme, useTheme } from "@mui/material/styles";
import AllMusicians from "../Musicians/AllMusicians";
import { createStyles,  makeStyles } from "@mui/styles";
import { useUser } from "../../context/UserContext";
import { useLocation, useNavigate } from "react-router-dom";
import CenterCircularProgress from "../CenterCircularProgress";
import UserCardShort from "../Musicians/UserCardShort";

import commingsoon from "../../images/commingSoonSpeaker.svg"
import GoogleEventCardCom from "../Events/GoogleEventCardComp";


const searchQuery = 'musical events';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
        shadowedBox:{
            borderRadius: "15px",
            boxShadow: "0px 1px 16px 1px #D9E7FFC4",
        },
				linkColor : {
					color : "#0273E6 !important"
				},
				commingsoonbody:{
					fontSize: '18px',
					fontWeight: 400,
					lineHeight: '23px',
					letterSpacing: '0em',
					textAlign: 'left',
					color: '#5B5B65',
				}

			})
);

const CommigSoon = (props: any) => {
	const classes = useStyles();
	const user = useUser();
	const navigate = useNavigate();
	const token = localStorage.getItem("sessionId");
	const [Recommendedmusicians, setRecommendedMusicians] = useState([]);
	const [eventList, setEventList] = useState([]);
	const [musicianLoading, setmusicianLoading] = useState(true);
	const [eventLoading, setEventLoading] = useState(true);
	const theme = useTheme();
	const isLargeScreen = useMediaQuery('(min-width:1280px)');



	useEffect(() => {
		getAllMusicians();
		getEvents();
	}, []);

	const preorderStream = () => {
		window.open("https://lutefish.com/products/lutefish", "_blank");
	};

    const getAllMusicians = () => {
        setmusicianLoading(true);
			let size = isLargeScreen?4:3;
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/recommendation/similar-artist?page=0&size=20`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
	)
				.then((res) => {
						if (res.status > 400) {
								throw new Error("api_error");
						} else {
								return res.json();
						}
				})
				.then((data) => {
						setRecommendedMusicians(data.slice(0, size));
				})
				.catch((err) => {
				})
				.finally(() => {
						setmusicianLoading(false);
				});
		} catch (error) {
			setmusicianLoading(false);
		}
    };

		const getEvents = async () => {
			setEventLoading(true);
			let size = isLargeScreen?4:3;
			try {
			fetch(`${process.env.REACT_APP_BASE_URL}/api/events/get-musician-events?query=${searchQuery}&city=${user.location}`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				}
		})
				.then((res) => {
					if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data:any) => {
						if(data.events_results){
							const eventData = data.events_results

							const sortedEventList = eventData.slice().sort((a:any, b:any) => {
								const dateA:any = new Date(a.date.start_date);
								const dateB:any = new Date(b.date.start_date);

								return dateA - dateB;
							})
							.slice(0, size);
							setEventList(sortedEventList)
							setEventLoading(false);
						}
				})
				.catch((err) => {
				})
				.finally(() => {
					setEventLoading(false);
				});
		} catch (error) {
			setEventLoading(false);
		}
		};
	
	return (
			<Grid container={true} xs={12} >
					<Grid
						item
						xs={12}
						margin={1}
						className={classes.shadowedBox}
						padding="1.5% 2%"
						sx={{
							height:'244px',
							maxWidth:'908px'
						}}
					>
						<Grid container spacing={2}>
							<Grid item xs={3}>
								<img src={commingsoon} alt="comming soon" style={{width:'80%', height:'100%', marginTop:"10%", marginLeft:"10%"}}/>
							</Grid>
							<Grid item xs={9}>
								<Typography sx={{color: "customColor.orange", ml:"100px", fontFamily: 'Lexend', fontSize: '36px', fontWeight: 400, lineHeight: '45px', textAlign: 'left', mb:'19px'}}>
									{i18n.jamSession.commingsoon.title}
								</Typography>
								<Grid item xs={12} direction={'row'} display={'flex'}>
										<span style={{ color: '#5B5B65', width: '20px', flexShrink: 0 }}>•</span>
										<Typography className={classes.commingsoonbody} >{i18n.jamSession.commingsoon.l1}</Typography>
								</Grid>
								<Grid item xs={12} direction={'row'} display={'flex'}>
										<span style={{ color: '#5B5B65', width: '20px', flexShrink: 0 }}>•</span>
										<Typography className={classes.commingsoonbody}>{i18n.jamSession.commingsoon.l2}</Typography>
								</Grid>
								<Grid item xs={12} direction={'row'} display={'flex'}>
										<span style={{ color: '#5B5B65', width: '20px', flexShrink: 0 }}>•</span>
										<Typography className={classes.commingsoonbody}>{i18n.jamSession.commingsoon.l3}</Typography>
								</Grid>
								<Grid item xs={12} direction={'row'} display={'flex'}>
										<span style={{ color: '#5B5B65', width: '20px', flexShrink: 0 }}>•</span>
										<Typography className={classes.commingsoonbody}>
											{i18n.jamSession.commingsoon.l4}{" "}
											<Link onClick={() => navigate('/mymusic')} sx={{color:'#0273E6', cursor:'pointer'}}>My Music Files</Link>
										</Typography>
								</Grid>
								<Button
										className="submit-button"
										size="large"
										sx={{
											width: "300px",
											justifyContent: "center",
											marginTop: "18px",
										}}
										variant="contained"
										onClick={preorderStream}
										endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									>
										<Typography style={{fontSize: "17px !important", color: "#FFFFFF",}}
										>Pre-Order the Stream Today</Typography>
									</Button>
							</Grid>
						</Grid>
				</Grid>



				<Grid container xs={12}>
							<Grid
								item
								xs={12}
								margin={1}
								className={classes.shadowedBox}
								padding="1% 2%"
							>
								<Grid container={true} xs={12} sx={{mb:1}}>
									<Grid item={true} xs={5}>
										<Typography variant="h5">Recommended Musicians</Typography>
									</Grid>
									<Grid item={true} xs={7} className="right-align">
										{Recommendedmusicians.length>0&&
										<Link
											className={classes.linkColor}
											href="javascript:void(0)"
											onClick={() => navigate("/musicians/recomended")}
										>
											View All
										</Link>
										}
									</Grid>
								</Grid>

								{/* User Cards */}
								{musicianLoading ? (
									<Grid container xs={12} justifyContent="center" sx={{ p: "16px" }}>
										<CircularProgress /> {/* The musicianLoading spinner */}
										<Grid container item justifyContent="center">Searching musicians...</Grid>
									</Grid>
								) : (
								<Grid xs={12} container justifyContent="left">
									{Recommendedmusicians.length>0?(
										Recommendedmusicians.map((user) => {
											return (
												<UserCardShort
													user={user}
													refreshList={getAllMusicians}
												/>
											);
										})
									):(
										<Grid xs={12} sx={{display:'flex', textAlign: "center", justifyContent: "center" }}>
										<Typography>No Recommended Musician found</Typography>
										</Grid>
									)}

								</Grid>
								)}
							</Grid>


							<Grid
								item
								xs={12}
								margin={1}
								className={classes.shadowedBox}
								padding="1% 2%"
							>
								<Grid container={true} xs={12} sx={{mb:1}}>
									<Grid item={true} xs={5}>
										<Typography variant="h5">Events near you</Typography>
									</Grid>
									<Grid item={true} xs={7} className="right-align">
										{eventList.length>0&&
										<Link
											className={classes.linkColor}
											href="javascript:void(0)"
											onClick={() => navigate("/events")}
										>
											View All
										</Link>
										}
									</Grid>
								</Grid>

								{eventLoading ? (
									<Grid container xs={12} justifyContent="center" sx={{ p: "16px" }}>
										<CircularProgress /> {/* The musicianLoading spinner */}
										<Grid container item justifyContent="center">Searching events...</Grid>
									</Grid>
								) : (
								<Grid xs={12} container justifyContent="space-around">
									{eventList.length>0 ? (
										eventList.map((event:any) => {
											return (
												<Grid sx={{marginBottom:"12px"}}>
													<GoogleEventCardCom data={event}/>
												</Grid>
											)
										})
										):(
											<Grid sx={{ textAlign: "center", justifyContent: "center" , mb:'10px'}}>No Google events found near you</Grid>

										)}
								</Grid>
							)}
							</Grid>

				</Grid>
			</Grid>
	);
};

export default CommigSoon;
