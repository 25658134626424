import { useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useUser } from "../../context/UserContext";

const MultipleImageUpload = (props: any) => {

    const user = useUser();
    return (
        <>
            {props.imagesLength < 10 && user.userAccountStatus !== "SUSPEND" ?
                <label title="Upload Photo"
                    style={{ height: '30px' }}
                >
                    <Box className='upload-Multiple' id="photo-upload" sx={{
                        borderRadius: '20px',
                        border: '1px solid #0273E6',
                        color: '#0273E6',
                        fontSize: '16px !important',
                        px: 1,
                        width: 'fit-content',
                        marginLeft: 'auto',
                        height: '30px !important',
                        maxHeight: '30px !important',
                        display: 'unset',
                        pt: '4px',
                        pb: '5px'
                    }}
                    >

                        Upload Photos</Box>
                    <input
                        ref={props.inputFileRef}
                        id="photo-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        // multiple
                        onChange={(e) => props.photoUpload(e)} />
                </label>
                :
                <label title="Upload Photo"
                    style={{ height: '30px' }}
                >
                    <Box className='upload-Multiple-disable' id="photo-upload" sx={{
                        borderRadius: '20px',
                        border: '1px solid #F5F5F5',
                        color: 'gray',
                        fontSize: '16px !important',
                        px: 1,
                        width: 'fit-content',
                        marginLeft: 'auto',
                        height: '30px !important',
                        maxHeight: '30px !important',
                        display: 'unset',
                        pt: '4px',
                        pb: '5px'
                    }}
                    >Upload Photos</Box>
                    {/* }
                {props.imagesLength < 10 && user.userAccountStatus !== "SUSPEND" ?
                    <input
                        ref={props.inputFileRef}
                        id="photo-upload"
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        // multiple
                        onChange={(e) => props.photoUpload(e)} /> : ""} */}
                </label>
            }
        </>
    )
}
export default MultipleImageUpload