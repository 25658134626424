import { useEffect, useState } from "react";
import {
	TextField,
	Button,
	Box,
	Grid,
	FormControl,
	FormHelperText,
	Switch,
	Autocomplete,
	AlertTitle,
	Snackbar,
	Alert,
	IconButton,
	InputAdornment,
	OutlinedInput,
	Typography,
} from "@mui/material";
import i18n from "../../l10n/strings.json";
import RightAarrow from "../../images/right arrow.svg";
import { useNavigate } from "react-router-dom";
import "../Social/social.css";
import { makeStyles } from "@mui/styles";
import { useUser } from "../../context/UserContext";
import User from "../../services/user";
import InstrumentSkillLevel from "../Musicians/InstrumentSkills";
import ErrorIcon from "../../images/danger.svg";
import SuccessIcon from "../../images/tick-square.svg";
import ErrorAlert from "../Error/ErrorAlert";
import CloseIcon from "../../images/close.svg";
import CheckIcon from "../../images/check-fill.svg";
import { useSocialUser } from "../../context/SocialSignupUser";
import DeleteProfile from "../../images/deleteProfile.svg";
import EditProfile from "../../images/editProfile.svg";
import DefaultAvatar from "../../images/defaultAvatar.png";
import CameraIcon from "../../images/camera.svg";
import ChooseProfilePictureDialog from "./ChooseProfilePictureDialog";
import { useSignUp } from "../../context/SignUpContext";
import SettingsAlert from "../../pages/SettingsAlert";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
// FOR TWILIO CONVERSATIONS
import { getToken as getConversationToken } from "../Messages/api";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from '../../store';
const useStyles = makeStyles((theme: any) => ({
	root: {
		color: "white !important",
		fontFamily: "Lexend !important",
		"& .MuiAutocomplete-tag": {
			backgroundColor: "#0273E6",
			color: "white",
			"&:hover": {
				backgroundColor: "#0273E6",
				color: "white",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "white !important",
		},
		"& .MuiAutocomplete-clearIndicator": {
			"&:hover": {
				backgroundColor: "#7A7985 !important",
			},
			backgroundColor: "#7A7985 !important",
			height: "18px",
			width: "18px",
		},
	},
}));

const ProfileDetails = ({
	firstName,
	lastName,
	email,
	location,
	fbUserId,
	password,
	type,
}: any) => {
	const classes = useStyles();

	const genres = [
		{ title: "Americana" },
		{ title: "Avant-garde" },
		{ title: "Baroque" },
		{ title: "Big Band" },
		{ title: "Bluegrass" },
		{ title: "Blues" },
		{ title: "Bollywood" },
		{ title: "Broadway/Showtunes" },
		{ title: "Chamber-Music" },
		{ title: "Classic Rock" },
		{ title: "Classical" },
		{ title: "Contemporary Classical" },
		{ title: "Country" },
		{ title: "Disco" },
		{ title: "Drumline" },
		{ title: "EDM" },
		{ title: "Electronic/Dance" },
		{ title: "Folk" },
		{ title: "Funk" },
		{ title: "Gospel" },
		{ title: "Hip-hop/Rap" },
		{ title: "House" },
		{ title: "Indie/Alternative" },
		{ title: "Jazz" },
		{ title: "K-Pop" },
		{ title: "Latin" },
		{ title: "Metal" },
		{ title: "Minimalism" },
		{ title: "New Age" },
		{ title: "Opera" },
		{ title: "Orchestral" },
		{ title: "Pop" },
		{ title: "Punk" },
		{ title: "R&B" },
		{ title: "Ragtime" },
		{ title: "Reggae" },
		{ title: "Reggaeton" },
		{ title: "Religious" },
		{ title: "Rock" },
		{ title: "Romantic" },
		{ title: "Salsa" },
		{ title: "Ska" },
		{ title: "Soul" },
		{ title: "Soundtrack" },
		{ title: "Swing" },
		{ title: "Symphonic" },
		{ title: "Techno" },
		{ title: "Wind Ensemble" },
	];

	const navigate = useNavigate();
	const user = useUser();
	const socialUser = useSocialUser();
	const { userData, setUserData } = useSignUp();
	const [fullName, setFullName] = useState(`${firstName} ${lastName}`);
	const [nickname, setNickname] = useState("");
	const [containsSpecialChars, setContainsSpecialChars] = useState(false);
	const [selectedLocation, setSelectedLocation] = useState(location);
	const [title, setTitle] = useState("");
	const [bio, setBio] = useState("");
	const [instruments, setInstruments] = useState<any>([]);
	const [interestedIn, setInterestedIn] = useState("");
	const [genresValue, setGenresValue] = useState([]);
	const [instrumentsValue, setInstrumentsValue] = useState<any>([]);
	const [rating, setRating] = useState<number | null>(2);
	const [openForColl, setOpenForColl] = useState(true);
	const [acceptGigsForWork, setAcceptGigsForWork] = useState(true);
	const [dirtyFullName, setDirtyFullName] = useState(false);
	const [dirtyNickName, setDirtyNickName] = useState(false);
	const [nicknameMessage, setNicknameMessage] = useState("");
	const [dirtyTitle, setDirtyTitle] = useState(false);
	const [dirtyLocation, setDirtyLocation] = useState(false);
	const [dirtyBriefBio, setDirtyBriefBio] = useState(false);
	const [dirtyInterestedIn, setDirtyInterestedIn] = useState(false);
	const [dirtyInstrument, setDirtyInstrument] = useState(false);
	const [isError, setIsError] = useState(false);
	const token = localStorage.getItem("sessionId");
	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [skillLevelValues, setSKillLevelValues] = useState<any>([]);
	// eslint-disable-next-line
	const [file, setFile] = useState<any>("");
	const [imageUrl, setImageUrl] = useState<any>("");
	// const [result, setResult] = useState("");
	// const [open, setOpen] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const dispatch = useDispatch();
	const { login } = bindActionCreators(actionCreators, dispatch);

	const fetchData = async () => {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_BASE_URL}/api/view-user-profile/${encodeURIComponent(user.nickname)}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if(response.status === 502){
				navigate('/defaultPage')
			} else if (response.status >= 400) {
				ErrorAlert(response.status, setAlertStatus, setShowAlert, setAlertMsg);
			} else {
				const data = await response.json();
				if (data.instrumentsList.length !== 0) {
					setShowAlert(true);
					setAlertStatus(false);
					setAlertMsg("Account already created");

					setTimeout(() => {
						navigate('/home');
					}, 3000);
				}
			}
		} catch (error) {
		}
	};

	const handleBrowserBack = () => {
		fetchData();
	};

	window.onpopstate = handleBrowserBack;

	const handleOpenForColl = (event: React.ChangeEvent<HTMLInputElement>) => {
		setOpenForColl(event.target.checked);
	};
	const handleAcceptGigsForWork = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setAcceptGigsForWork(event.target.checked);
	};

	useEffect(() => {
		getInstrumentsList();
		handleAlertClose();
	}, []);

	useEffect(() => {
		setImageUrl(
			user?.profilePhotoUrl
				? `${process.env.REACT_APP_PICTURE_URL}${user?.profilePhotoUrl}`
				: ""
		);
	}, [user]);

	const getInstrumentsList = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/master-instruments?page=0&size=1000`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status >= 400) {
					ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setInstruments(data);
			})
			.catch((err) => {
				setShowAlert(true);
				setAlertStatus(false);
				setAlertMsg("Network Error! Please try again..");
			});
	};

	const ArrayOfGenres = Array.prototype.map
		.call(genresValue, function (item) {
			return item.title;
		})
		.join(",");

	const ArrayOfInstruments: any = [];

	instrumentsValue.map((inst: any) => {
		ArrayOfInstruments.push({
			skillLevel: 0,
			instrument: {
				id: inst.id,
				instrumentName: inst.instrumentName,
			},
		});

		skillLevelValues.map((skill: any) => {
			ArrayOfInstruments.map((value: any) => {
				if (skill.id === value.instrument.id) {
					value.skillLevel = skill.skillLevel ? skill.skillLevel : 0;
				}
			});
		});
	});

	const handleSignUp = () => {
		const data = {
			Code: "",
			Password: "",
			Repassword: "",
			FirstName: "",
			LastName: "",
			Location: "",
		};
		setUserData(data);
		setIsLoading(true);
		if (
			fullName !== "" &&
			selectedLocation !== "" &&
			instrumentsValue.length !== 0
		) {
			let formData = new FormData();
			formData.append("nickname", nickname);
			formData.append("location", selectedLocation);
			formData.append("profileTitle", title);
			formData.append("profileBio", bio);
			formData.append("genres", ArrayOfGenres);
			// formData.append("uploadProfilePhoto", file);
			formData.append("openForCollab", `${openForColl}`);
			formData.append("openForGigs", `${acceptGigsForWork}`);
			formData.append("firstName", firstName);
			formData.append("lastName", lastName);
			formData.append("email", email);
			formData.append("facebookUserId", fbUserId ?? "");
			formData.append("password", password);
			formData.append("type", type);
			fetch(`${process.env.REACT_APP_BASE_URL}/api/social-signup-update`, {
				method: "PUT",
				body: formData,
				headers: {
					// "Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			})
				.then((res) => {
					if (res.status !== 200 && res.status !== 201) {
						ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
					} else {
						return res.json();
					}
				})
				.then((data) => {
					fetch(
						`${process.env.REACT_APP_BASE_URL}/api/public/user-instruments-list/${email}`,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`,
							},
							body: JSON.stringify(ArrayOfInstruments),
						}
					).then((res) => {
						if (res.status !== 200 && res.status !== 201) {
							ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg);
						} else {
							let data = {
								username: email,
								password: "password",
								loginType: "social",
							};

							fetch(`${process.env.REACT_APP_BASE_URL}/api/new-login`, {
								method: "POST",
								headers: { "Content-Type": "application/json" },
								body: JSON.stringify(data),
							})
								.then((response) => {
									if (response.status >= 400) {
										navigate("/login");
									} else {
										return response.json();
									}
								})
								.then(async (result) => {
									if (result.errorKey === "accountDeactivated") {
										navigate("/login");
									}
									if (result.id_token != null && result.loginIdentifier) {
										if (!result.isAlreadyLoggedIn) {
											if (email.length > 0) {
												const lowerCaseUsername = email.toLowerCase();
												await getConversationToken(lowerCaseUsername, password)
													.then((token: string) => {
														login(token);
														localStorage.setItem("conversationToken", token);
													})
													.catch(() => {
														localStorage.setItem("username", "");
														localStorage.setItem("password", "");
													});
											}

											WebsocketClientUSerStatus.createConnectionUSerStatus(email);
											localStorage.setItem(
												"wsConnection",
												JSON.stringify({
													url: `/public/userStatus/${email}`,
													email: email,
													connected: true,
												})
											);
											const token = result.id_token;
											localStorage.setItem("sessionId", token);
											localStorage.setItem(
												"loginIdentifier",
												result.loginIdentifier
											);
											User.fetchUserInfo(() => {
												navigate("/home");
											});
										}
									}
								});
						}
					});
				})
				.catch((err) => {
					setShowAlert(true);
					setAlertStatus(false);
					setAlertMsg("Network Error! Please try again..");
				})
				.finally(() => {
					setTimeout(() => {
						setIsLoading(false);
					}, 1000);
				});
		} else {
			setDirtyFullName(true);
			setDirtyInstrument(true);
			setDirtyLocation(true);
		}
	};

	const updateInstrumentFilter = (instrument: string, skillLevel: number) => {
		setSKillLevelValues([
			...skillLevelValues,
			...[{ id: instrument, skillLevel: skillLevel }],
		]);
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};

	const checkNickName = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/user-profiles/check-nickName`,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					nickname: nickname,
				}),
			}
		)
			.then((response) => {
				if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				if (result.status === "OK") {
					setNicknameMessage(result.message);
				}
				if (result.status === "BAD_REQUEST") {
					setNicknameMessage(result.message);
				}
			});
	};

	const isEnable = () => {
		if (
			firstName.length > 0 &&
			firstName.length <= 50 &&
			lastName.length > 0 &&
			lastName.length <= 50 &&
			selectedLocation.length > 0 &&
			ArrayOfInstruments.length > 0 &&
			nickname?.length <= 50 &&
			!containsSpecialChars &&
			title?.length <= 100 &&
			bio?.length <= 3000 &&
			ArrayOfInstruments.every(
				(obj: any) =>
					obj.skillLevel !== undefined &&
					obj.skillLevel !== null &&
					obj.skillLevel !== 0
			)
		) {
			return false;
		} else {
			return true;
		}
	};

	const handleImageUrl = (callbackImageUrl: string) => {
		// setImageUrl(callbackImageUrl);
	};

	const deleteUpload = () => {
        let formData = new FormData();
        formData.append('email', user?.email);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/user-profiles/delete-profile-photo`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formData,
        }).then((res) => {
            if (res.status >= 400) {
                ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
            } else {
				setImageUrl("");
                User.fetchUserInfo(() => { });
            }
        }).catch((err) => {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Network Error! Please try again..");
        });
	};

	const handleDialogClose = (success: boolean) => {
		setOpenDialog(false);
	};

    const handleNicknameChange = (event:any) => {
        const value = event.target.value;
		setNickname(value);
        const specialCharsRegex = /[!@#$%^&*(),.?":{}|<>]/;
        if (!specialCharsRegex.test(value)) {

            setContainsSpecialChars(false);
        } else {
            setContainsSpecialChars(true);
        }
    };

	return (
		<>
			<FormControl sx={{ width: "100%" }}>
				<SettingsAlert />
				<Box sx={{ textAlign: "left", m: 1 }}>
					{showAlert && (
						<Snackbar
							sx={{
								"&.MuiSnackbar-anchorOriginTopCenter1": {
									top: "70px",
								},
							}}
							anchorOrigin={{ vertical: "top", horizontal: "center" }}
							open={showAlert}
							autoHideDuration={6000}
							onClose={handleAlertClose}
						>
							<Alert
								onClose={handleAlertClose}
								icon={
									alertStatus ? (
										<img src={SuccessIcon} alt="error-icon" />
									) : (
										<img
											src={ErrorIcon}
											alt="error-icon"
											style={{ marginRight: "10px" }}
										/>
									)
								}
								sx={{
									backgroundColor: "#FFFFFF",
									boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
									borderRadius: "6px",
									fontSize: "1.125rem",
									fontWeight: "500",
									color: "black",
								}}
							>
								<AlertTitle
									sx={{
										fontSize: "1.125rem",
										fontWeight: "500",
										color: "#000000",
										display: "flex",
										margin: "auto",
										padding: "5px",
										justifyContent: "center !important",
									}}
								>
									{alertMsg}
								</AlertTitle>
							</Alert>
						</Snackbar>
					)}

					<Grid
						container
						direction={"column"}
						// columns={{ xs: 4, sm: 6, md: 6, xl: 12 }}
						sx={{ justifyContent: "space-between" }}
					>
						<Grid item>
							<FormHelperText sx={{ textAlignLast: "center" }}>
								Profile Picture
							</FormHelperText>
						</Grid>
						<Grid
							item
							sx={{ m: 0.5, textAlignLast: "center", alignSelf: "center" }}
						>
							{imageUrl ? (
								<div className="social-img-wrap">
									<img
										style={{
											width: "150px",
											height: "150px",
											borderRadius: "12px",
										}}
										src={imageUrl}
										alt="Upload"
									/>
									<Grid
										container
										sx={{
											width: "150px",
											position: "absolute",
											marginTop: { md: "-49%", xl: "-38%" },
										}}
									>
										<Grid xs={6} sx={{ textAlignLast: "start", mt: "3px" }}>
											<label title="Upload Photo">
												<img
													id="photo-edit"
													src={EditProfile}
													alt="Upload"
													style={{ cursor: "pointer" }}
													onClick={() => setOpenDialog(true)}
												/>
											</label>
										</Grid>
										<Grid xs={6} sx={{ textAlignLast: "end" }}>
											<IconButton size="small" onClick={() => deleteUpload()}>
												<img src={DeleteProfile} />
											</IconButton>
										</Grid>
									</Grid>
								</div>
							) : (
								<Grid
									item
									xs={12}
									sx={{ display: "flex", justifyContent: "center" }}
								>
									<div
										style={{
											width: "160px",
											height: "160px",
											backgroundColor: "#F0F0F0",
											borderRadius: "12px",
											position: "relative",
										}}
									>
										<img
											onClick={() => setOpenDialog(true)}
											style={{
												position: "absolute",
												left: "56px",
												top: "34px",
												cursor: "pointer",
											}}
											src={CameraIcon}
											alt="camera-icon"
										/>
										<Box sx={{ padding: "12px 32px 12px" }}>
											<img src={DefaultAvatar} alt="default-avatar" />
										</Box>

										<Box className="avtarUpload">
											<Typography
												sx={{
													color: "#000",
													fontSize: "12px",
													fontWeight: "800",
												}}
											>
												Upload Your Photo or Generate Avatar
											</Typography>
										</Box>
									</div>
								</Grid>
							)}
						</Grid>
					</Grid>

					<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid xs={8}>
							{/* <FormHelperText>{`${i18n.socialProfile.nickName} ${i18n.socialProfile.nickNameHelpText}`}</FormHelperText> */}
							<FormHelperText>{`Social Handle`}</FormHelperText>
						</Grid>
						<Grid xs={4} sx={{ textAlign: "right" }}>
							<span className="social-support-text">Shown on Profile</span>
							{/* <Button
								className="social-check-availability"
								size="small"
								disabled={!nickname} //|| nickname === props.nickName}
								variant="outlined"
								onClick={checkNickName}
								sx={{ color: "blue", border: "1px solid blue" }}
								fullWidth
							>
								{i18n.dialog.checkAvailability}
							</Button> */}
						</Grid>
						<Grid xs={12} sx={{ m: 0.5 }}>
							<OutlinedInput
								id="nickname"
								type="text"
								value={nickname}
								onChange={handleNicknameChange}
								onFocus={() => setDirtyNickName(true)}
								error={dirtyNickName && (nickname?.length > 50 || containsSpecialChars)}
								// variant="outlined"
								sx={{ width: "100%" }}
								className="social-textField"
								endAdornment={
									<InputAdornment position="end">
										<Button
											className="social-check-availability"
											size="small"
											disabled={!nickname} //|| nickname === props.nickName}
											variant="outlined"
											onClick={checkNickName}
											sx={{ color: "blue", border: "1px solid blue" }}
											fullWidth
										>
											Check
											{/* {i18n.dialog.checkAvailability} */}
										</Button>
									</InputAdornment>
								}
								required
							/>
							{dirtyNickName && (
								<FormHelperText
									sx={{ color: "#FD4B24", textAlign: "right", mr: 0 }}
								>
									{nickname?.length > 50 ? "Maximum 50 characters allowed" : ""}
									{containsSpecialChars ? " Special characters are not allowed" : ""}
								</FormHelperText>
							)}
							{nicknameMessage === "Nickname is not available" && (
								<FormHelperText
								sx={{ color: "#FD4B24", textAlign: "right", mr: 0 }}
							>
								{nicknameMessage}
							</FormHelperText>
								// <FormHelperText
								// 	sx={{
								// 		display: "flex",
								// 		alignItems: "center",
								// 		color: "#FD4B24",
								// 		width: "100%",
								// 		// marginRight: "20px",
								// 		textAlign: "right", mr: 0
								// 	}}
								// >
								// 	{/* <img src={CloseIcon} alt="error-icon" /> */}
								// 	{nicknameMessage}
								// </FormHelperText>
							)}
							{nicknameMessage === "Nickname is available" && (
								<FormHelperText
								sx={{ color: "#3EB92A", textAlign: "right", mr: 0 }}
							>
								{nicknameMessage}
							</FormHelperText>
								// <FormHelperText
								// 	sx={{
								// 		display: "flex",
								// 		alignItems: "center",
								// 		width: "100%",
								// 		// marginRight: "20px",
								// 		color:'#3EB92A',
								// 		textAlign: "right", mr: 0
								// 	}}
								// >
								// 	{/* <img src={CheckIcon} alt="error-icon" /> */}
								// 	{nicknameMessage}
								// </FormHelperText>
							)}
						</Grid>
					</Grid>

					{/* <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid xs={4}>
							<FormHelperText>{i18n.socialUp.location}*</FormHelperText>
						</Grid>
						<Grid xs={12} sx={{ m: 0.5 }}>
							<GoogleLocationService
								defaultValue={selectedLocation}
								onLocationChange={(locationDesc: string) =>
									setSelectedLocation(locationDesc)
								}
								isError={dirtyLocation && selectedLocation === ""}
							/>
							{dirtyLocation && (
								<FormHelperText sx={{ color: "#FD4B24", textAlign: 'right' }}>
									{selectedLocation === "" ? "Please enter your location" : ""}
								</FormHelperText>
							)}
						</Grid>
					</Grid> */}

					<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid item xs={12}>
							<ChooseProfilePictureDialog
								open={openDialog}
								handleClose={handleDialogClose}
								handleImageUrlCallback={handleImageUrl}
								requestFrom={"signup"}
							/>
						</Grid>
						<Grid xs={3}>
							<FormHelperText>{i18n.socialProfile.titleText}</FormHelperText>
						</Grid>
						<Grid xs={9.3} sx={{ textAlign: "right" }}>
							<span className="social-support-text">
								{i18n.socialProfile.titleHelpText}
							</span>
						</Grid>
						<Grid xs={12} sx={{ m: 0.5 }}>
							<TextField
								id="title"
								className="social-textField"
								value={title}
								onChange={(e: any) => setTitle(e.target.value)}
								onFocus={() => setDirtyTitle(true)}
								error={dirtyTitle && title?.length > 100}
								variant="outlined"
								required
							/>
							{dirtyTitle && (
								<FormHelperText
									sx={{ color: "#FD4B24", textAlign: "right", mr: 0 }}
								>
									{title?.length > 100 ? "Maximum 100 characters allowed" : ""}
								</FormHelperText>
							)}
						</Grid>
					</Grid>

					<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid xs={6}>
							<FormHelperText>{i18n.socialProfile.bio}</FormHelperText>
						</Grid>
						<Grid xs={12} sx={{ m: 0.5 }}>
							<TextField
								id="bio"
								className="social-textField"
								value={bio}
								onChange={(e: any) => setBio(e.target.value)}
								multiline
								rows={4}
								variant="outlined"
								onFocus={() => setDirtyBriefBio(true)}
								error={dirtyBriefBio && bio?.length > 3000}
								required
							/>
							{dirtyBriefBio && (
								<FormHelperText
									sx={{ color: "#FD4B24", textAlign: "right", mr: 0 }}
								>
									{bio?.length > 3000 ? "Maximum 3000 characters allowed" : ""}
								</FormHelperText>
							)}
						</Grid>
					</Grid>

					{/* <Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
                        <Grid xs={4}>
                            <FormHelperText>{i18n.socialProfile.interest}</FormHelperText>
                        </Grid>
                        <Grid xs={12} sx={{ m: 0.5 }}>
                            <TextField
                                id="interestedIn"
                                className='social-textField'
                                value={interestedIn}
                                onChange={(e: any) => setInterestedIn(e.target.value)}
                                onFocus={() => setDirtyInterestedIn(true)}
                                error={dirtyInterestedIn && (interestedIn?.length > 100)}
                                variant="outlined"
                                required />
                            {
                                dirtyInterestedIn &&
                                <FormHelperText sx={{ color: "#FD4B24" }}>
                                    {
                                        interestedIn?.length > 100 ? "Maximum 100 characters allowed"
                                            : ""
                                    }
                                </FormHelperText>
                            }
                        </Grid>
                    </Grid> */}

					<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid xs={4}>
							<FormHelperText>{i18n.socialProfile.genres}</FormHelperText>
						</Grid>
						<Grid xs={12} sx={{ m: 0.5 }}>
							<Autocomplete
								multiple
								id="Genres"
								classes={classes}
								className="social-textField"
								value={genresValue}
								onChange={(event, newValue) => {
									setGenresValue(newValue as any);
								}}
								options={genres}
								getOptionLabel={(option) => option.title}
								isOptionEqualToValue={(option, value) =>
									option.title === value.title
								}
								filterSelectedOptions
								renderInput={(params) => (
									<TextField
										{...params}
										// placeholder="Genres"
									/>
								)}
							/>
						</Grid>
					</Grid>

					<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
						<Grid xs={12}>
							<FormHelperText>
								Select Instruments you Play
								<span style={{ color: "red" }}> *</span>
							</FormHelperText>
						</Grid>
						<Grid xs={12} sx={{ m: 0.5 }}>
							<Autocomplete
								multiple
								id="instruments"
								classes={classes}
								className="social-textField"
								value={instrumentsValue}
								onChange={(event, newValue) => {
									setInstrumentsValue(newValue as any);
								}}
								options={instruments.sort((a: any, b: any) =>
									a.instrumentName.localeCompare(b.instrumentName)
								)}
								getOptionLabel={(option) => option.instrumentName || ""}
								isOptionEqualToValue={(option, value) => option.id === value.id}
								filterSelectedOptions
								renderInput={(params) => (
									<TextField
										{...params}
										placeholder="Instruments"
										// onFocus={() => setDirtyInstrument(true)}
										// error={dirtyInstrument && instrumentsValue.length < 1}
									/>
								)}
							/>
							{/* {dirtyInstrument && (
								<FormHelperText
									sx={{ color: "#FD4B24", textAlign: "right", mr: 0 }}
								>
									{instrumentsValue.length < 1
										? "Select at least one Instrument"
										: ""}
								</FormHelperText>
							)} */}
						</Grid>
					</Grid>

					{ArrayOfInstruments.length > 0 && (
						<Grid container columns={{ xs: 4, sm: 8, md: 12 }}>
							<Grid xs={9}>
								<FormHelperText>
									Rate your Instrument Skill levels
									<span style={{ color: "red" }}> *</span>
								</FormHelperText>
							</Grid>
							<Grid xs={12} sx={{ m: 0.5 }}>
								<Box
									sx={{
										backgroundColor: "#F8F8F8",
										border: "1px solid #D7D7D7",
										borderRadius: "6px",
										px: 1,
										pb: 1,
										mt: 1,
									}}
								>
									{ArrayOfInstruments.map((instrument: any) => {
										return (
											<InstrumentSkillLevel
												instrument={instrument.instrument.id}
												skillLevel={
													instrument.skillLevel ? instrument.skillLevel : 0
												}
												editable={true}
												updateInstrumentFilter={updateInstrumentFilter}
												instrumentLabel={instrument.instrument.instrumentName}
												background="white"
											/>
										);
									})}
								</Box>
								{/* {ArrayOfInstruments.every((obj: any) => obj.skillLevel === 0) ? ( */}
								<FormHelperText
									sx={{ color: "#FD4B24", textAlign: "right", mr: 0 }}
								>
									{ArrayOfInstruments.every(
										(obj: any) =>
											obj.skillLevel !== undefined &&
											obj.skillLevel !== null &&
											obj.skillLevel !== 0
									)
										? ""
										: "Rate your skills"}
								</FormHelperText>
								{/* ): ''} */}
							</Grid>
						</Grid>
					)}

					{/* <InstrumentsRating setRating={setRating} rating={rating} /> */}


					{/* Hiding Collaboration Content for social-site launch */}
					{
						user.isCollaborationActive === true ? <>
							<Grid container columns={{ md: 12 }}>
								<Grid xs={7}>
									<FormHelperText>
										{i18n.socialProfile.collaborationText}
									</FormHelperText>
								</Grid>
								<Grid xs={1} sx={{ m: 1 }}>
									<Switch
										checked={openForColl}
										onChange={handleOpenForColl}
										color="info"
									/>
								</Grid>
								<Grid xs={3} sx={{}}>
									{openForColl ? (
										<FormHelperText>{i18n.socialProfile.open}</FormHelperText>
									) : (
										<FormHelperText>Close</FormHelperText>
									)}
								</Grid>
							</Grid>

							<Grid container columns={{ md: 12 }}>
								<Grid xs={7}>
									<FormHelperText>{i18n.socialProfile.gigsText}</FormHelperText>
								</Grid>
								<Grid xs={1} sx={{ m: 1 }}>
									<Switch
										color="info"
										checked={acceptGigsForWork}
										onChange={handleAcceptGigsForWork}
									/>
								</Grid>
								<Grid xs={3} sx={{}}>
									{acceptGigsForWork ? (
										<>
											<FormHelperText>Yes</FormHelperText>
										</>
									) : (
										<FormHelperText>{i18n.socialProfile.no}</FormHelperText>
									)}
								</Grid>
							</Grid>
						</>
						: <></>
					}

					<Grid
						container
						columns={{ xs: 4, sm: 8, md: 12 }}
						sx={{ marginTop: "10px" }}
					>
						<Grid xs={12} sx={{ m: 0.5 }}>
							{isLoading ? (
								<Button
									//className="next-button button-field-custom-font-size"
									className={`submit-button`}
									sx={{ background: "#FF702A" }}
									size="large"
									//onClick={handleSignUp}
									disabled={true}
									endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									fullWidth
									variant="contained"
								>
									<Typography>Signing In</Typography>
								</Button>
							) : (
								<Button
									className="button-field-custom-font-size submit-button"
									size="large"
									onClick={handleSignUp}
									endIcon={<img src={RightAarrow} alt="Right Arrow" />}
									fullWidth
									variant="contained"
									sx={{
										color: "white !important",
										fontSize: "18px !important",
										fontWeight: 500,
									}}
									disabled={isEnable()}
								>
									<Typography>{i18n.socialProfile.button}</Typography>
								</Button>
							)}
						</Grid>
					</Grid>
				</Box>
			</FormControl>

			{/* <ImageCropper
				open={open}
				onClose={handleClose}
				imageUrl={result}
				SetimageUrl={SetimageUrl}
				setResult={setFile}
				post={false}
				photoUpload={photoUpload}
				cover={false}
			/> */}
		</>
	);
};

export default ProfileDetails;
