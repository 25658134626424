import { useState, useEffect } from 'react'
import { Card, CardContent, Typography, IconButton, Switch, Avatar, Grid, Button, Box } from '@mui/material';
import Play from '../../images/Play.svg';
import Chart from '../../images/chart.svg';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ErrorAlert from "../Error/ErrorAlert";
import { SessionInterface } from "../../types/types";
import { addMinutes, format, isBefore } from "date-fns";
import Like from '../../images/like.svg'
import { useNavigate } from 'react-router';
import { useUser } from '../../context/UserContext';

const CalendarCmp = ({ session, viewer }: any) => {

    const navigate = useNavigate();
    const user = useUser();
    const token = localStorage.getItem("sessionId");
    const [visibility, setVisibility] = useState(false);
    const [LikeId, setLikeId] = useState('')
    const [likeStatus, setLikeStatus] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertStatus, setAlertStatus] = useState(false);
    const link = `https://dev.44ti.me/#/live?id=` + session.twilioSessionId;

    const handleLike = (id: any, status: any) => {
        setLikeStatus(status)
        setLikeId(id)
    }
    const UpdateSessionScope = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/updateSessionScope`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                "id": session.id,
                "sessionScope": visibility ? 'Private' : "Public"
            }),
        })
            .then((res) => {
                if (res.status !== 200) {
                    ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
                } else {
                    return res.json();
                }
            })
            .catch((err) => {
                // setShowAlert(true);
                // setAlertStatus(false);
                // setAlertMsg("Network Error! Please try again..");
            });
    };

    const handleVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVisibility(event.target.checked);
        UpdateSessionScope();
    }
    const checkDisabled = (session: SessionInterface | null) => {
        if (session != null) {
            const endTime = addMinutes(new Date(session.dateTime), session.duration);
            if (isBefore(endTime, new Date())) {
                return false;
            } else {
                return true;
            }
        }
    };
    useEffect(() => {
        setVisibility(session.sessionScope === 'Public' ? true : false)
    }, [])
    return (
        <>
            <Card sx={{ minWidth: 300, flexGrow: 1, minHeight: '30%', m: 2, pb: 2, boxShadow: '0px 1px 14px 0px rgba(217, 231, 255, 0.77)', borderRadius: '12px' }}>
                <CardContent sx={{ alignItems: 'center', gap: 1, }} >
                    <Grid container columns={{ xs: 6, sm: 6, md: 12 }} >
                        <Grid item xs={7}>
                            {session.livestreamStatus ?
                                <>
                                    <Typography variant="body3" sx={{ color: 'black', backgroundColor: '#E1F5DE', px: 2, borderRadius: '10px', fontSize: '16px' }}>
                                        Live Streaming Now
                                    </Typography>&nbsp;
                                </>
                                :
                                checkDisabled(session) &&
                                <>
                                    <Typography variant="body3" sx={{ color: 'black', backgroundColor: '#DFEBFF', px: 2, borderRadius: '10px', fontSize: '16px' }}>
                                        Scheduled
                                    </Typography>&nbsp;
                                </>
                            }
                            <Typography variant="body3" sx={{ color: 'black', fontSize: '16px' }}>
                                {`${format(
                                    new Date(session.dateTime),
                                    "eeee, LLLL dd yyyy   hh:mm aaa"
                                )} - ${format(
                                    addMinutes(
                                        new Date(session.dateTime),
                                        session.duration
                                    ),
                                    "hh:mm aaa"
                                )}`}
                            </Typography>
                        </Grid>

                        <Grid item xs={5}>

                            {viewer === 'SELF' ?
                                <Grid container flexDirection='row-reverse'>
                                    <Grid item xs={3}>
                                        <Typography variant="body3">{visibility ? 'Public' : 'Private'}</Typography>
                                    </Grid>
                                    <Grid item xs={2} sx={{ textAlign: '-webkit-right' }}>
                                        <Switch color='info' checked={visibility} onChange={handleVisibility} disabled={user.userAccountStatus === "SUSPEND"} />
                                    </Grid>
                                </Grid>
                                :
                                <Grid container flexDirection='row-reverse'>
                                    {session.livestreamStatus ?
                                        <>
                                            <Button className='colorStylingButtonOnHover' size="small" variant="outlined"
                                                sx={{ borderRadius: '20px', color: '#FF702A', borderColor: '#FF702A' }}
                                                onClick={() => window.open(link, '_blank')} disabled={user.userAccountStatus === "SUSPEND"} >
                                                Open in new window
                                            </Button>&nbsp;
                                            <Button className='colorStylingButton' size="small" variant="contained" disabled>
                                                ($) Tip the band
                                            </Button>

                                        </>
                                        : ''}
                                    {/* // <Grid item xs={5}>
                                        //     <Button className='colorStylingButton' size="small" variant="contained" >
                                        //         Add to my calendar
                                        //     </Button>
                                        // </Grid> */}
                                </Grid>
                            }
                        </Grid>

                    </Grid>
                </CardContent>


                <Typography variant="h6" sx={{ px: 2, color: '#5C5C5C', }}>
                    {session.sessionName}
                </Typography>

                <Typography variant="body1" sx={{ px: 2, color: '#888888' }} >
                    {session.sessionDescription}
                </Typography>

                <Grid container columnGap={1} sx={{ px: 2 }}>
                    {session.attendees && session.attendees.map((attendees: any, index: any) => (
                        <>
                            <Avatar
                                alt={`${attendees.firstName}`}
                                src="/static/images/avatar/1.jpg"
                                sx={{ width: 20, height: 20 }} />
                            <Typography variant="body3" sx={{ mt: 0.5, color: '#3F77D7', }}>
                                {/* to change acc to nickname */}
                                {/* {attendees.firstName} {attendees.lastName} */}
                                {attendees?.userProfileId === user?.userProfileId
											? attendees?.firstName + " " + attendees?.lastName
											: attendees?.userPrivacySettingsDTO?.identityPermission ===
											'Name is not visible. Instead just show nickname'
											? `@`+attendees?.nickname
											: attendees?.userPrivacySettingsDTO?.identityPermission ===
											'Name is visible to all'
											? attendees?.firstName + " " + attendees?.lastName
											: attendees?.userPrivacySettingsDTO?.identityPermission ===
											'Name is visible only to my connections. Other see nickname'
											? attendees?.isConnected
											? attendees?.firstName + " " + attendees?.lastName
											: `@`+attendees?.nickname
											: attendees?.firstName + " " + attendees?.lastName}
                            </Typography>
                        </>
                    ))}
                </Grid>
                {/* {session.livestreamStatus !== null ?
                    <Card sx={{ minWidth: 300, flexGrow: 1, minHeight: '30%', backgroundColor: '#F7F8FB' }}>

                        <CardContent sx={{ alignItems: 'center', gap: 1 }}>
                            <Grid container columns={{ xs: 6, sm: 12, md: 12 }}>

                                <Grid item xs={1}>
                                    <IconButton aria-label="Play" size='small' >
                                        <img src={Play} alt="Play" />
                                    </IconButton>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography variant="h6" sx={{ fontWeight: 'bold', pt: 2 }}>
                                        Welcome to my musics
                                    </Typography>
                                </Grid>

                                <Grid item xs={4} sx={{ textAlign: 'right', pt: 1, pr: 1 }}>
                                    <img src={Chart} alt="Play" style={{ marginLeft: 'auto', height: '17px', width: '17px' }} />
                                    <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black' }}>2.5k</Typography>
                                </Grid>
                                <Grid item xs={1}>
                                    {likeStatus ?
                                        <IconButton color="primary" size="small" onClick={() => handleLike(session.id, false)} >
                                            <ThumbUpIcon sx={{ color: '#3F77D7' }} />
                                        </IconButton> :
                                        <IconButton color="primary" size="small" onClick={() => handleLike(session.id, true)}  >
                                            <img src={Like} alt="Like" />
                                        </IconButton>
                                    }
                                    <Typography variant="body3" sx={{ fontWeight: 'bold', color: 'black' }}>101</Typography>
                                </Grid>

                            </Grid>

                        </CardContent>
                    </Card>
                    : ''} */}
            </Card>
        </>
    )
}

export default CalendarCmp;