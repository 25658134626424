import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormHelperText, InputAdornment, Menu, MenuItem, Stack } from '@mui/material';
import React, { useEffect, useRef, useState,useCallback, ChangeEvent } from 'react';
import { Avatar, Box, Grid, IconButton, Typography } from '@mui/material';
import { useUser } from '../../context/UserContext';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import Like from '../../images/like.svg';
import ReplyIcon from '../../images/Posts/reply-icon.svg';
import ErrorAlert from "../Error/ErrorAlert";
import OutlinedInput from '@mui/material/OutlinedInput';
import Picture from '../../images/Posts/picture-upload-blue.svg';
import Music from '../../images/Posts/music-upload-blue.svg';
import CommentPostIcon from '../../images/Posts/comment-post.svg';
import CustomAudioPlayer from '../../pages/CustomAudioPlayer';
import LikeDisabled from '../../images/Admin/like.svg';
import MoreIcon from '../../images/Posts/menu dots verticle.svg';
import { useAlertStatus } from '../../context/AlertStatusContext';
import DeletePostIcon from '../../images/Posts/delete-post.svg';
import EditPostIcon from '../../images/Posts/edit-post.svg';
import CloseIcon from "../../images/close.svg";
import CancelIcon from '../../images/Group 4282.svg';
import CreateCommentDisabled from '../../images/Posts/comment-post-disabled.svg';
import SelectMusic from './selectMusic';
import ImageCropper from '../../pages/ImageCropper';
import Close from '../../images/Posts/close.svg'
import { Link, useNavigate } from "react-router-dom";
import Linkify from "react-linkify";
import ViewLikeUsers from "../commonComponents/ViewLikeUsers";

interface User {
	id: number;
	profilePhotoUrl: string | null;
	profileTitle: string | null;
	// Add other properties as needed
	nickname: string | null;
	user: {

		login: string;
		firstName: string;
		lastName: string;
		// Add other properties as needed
	};
}
interface TagRecord {
	taggedUserProfile :{ id: number; }
	// Add other properties if there are any
  }
const Comment = ({ setSelectedId, parentId, profilePicture, commentType, content, childComments, postId, userReaction, id, children, userProfileName, createdAt,
  parentCommentReactionCount, parentCommentReplyCount, commentOnPost, userProfile, totalCommentCount, setTotalCommentCount, setMoreComments, moreComments,
  postPrivacy, setPostPrivacy, isFirst, setParentCommentUserName, setParentCommentId, setDisplayAddReply, AuthorName, userProfileId, postCreateBy, handleRefreshComments,tagRecordsDto,profileUrl }: any) => {

  const [expandHere, setExpandHere] = useState(false)
  const user = useUser();
  const token = localStorage.getItem("sessionId");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [showAlert, setShowAlert] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [alertMsg, setAlertMsg] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [alertStatus, setAlertStatus] = useState(false);
  const [likeStatus, setLikeStatus] = useState(userReaction ? userReaction : false);
  const [musicOpen, setMusicOpen] = useState(false)
  const [commentText, setCommentText] = useState('')
  const [totalLikeCount, setTotalLikeCount] = useState(parentCommentReactionCount);
  const [profileImageUrl, setProfileImageUrl] = useState('')
  const [openMenu, setOpenMenu] = useState(false);
  const buttonRef = useRef(null);
  const { updateAlertStatus } = useAlertStatus();
  const [openDeleteDialog, setOPenDeleteDialog] = useState(false);
  const [openEdit, setOpenEdit] = useState(false)
  const [dirtyComment, setDirtyComment] = useState(false);
  const [file, setFile] = useState<any>('');
  const [imagePreviewUrl, setImagePreviewUrl] = useState('')
  const [result, setResult] = useState('');
  const [open, setOpen] = useState(false);
  const [music, setMusic] = useState<any | null>('');
  const inputFileRef = useRef<any>(null);
  const [deviceMusic, setDeviceMusic] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [musicFromDevice, setMusicFromDevice] = useState<any>('');
  const [adding, setAdding] = useState(false)
  const navigate = useNavigate();
	const [inputValue, setInputValue] = useState<string>("");
	const [autocompleteOptions, setAutocompleteOptions] = React.useState<User[]>();
	const [showAutocomplete, setShowAutocomplete] = useState<boolean>(false);
  let searchTerm = "";
  const [taggedUserList, setTaggedUserList] = useState<number[]>([]);
	const [isAtTyped, setIsAtTyped] = useState(false);
  const [prevtaggedUserList, setPrevTaggedUserList] = useState<number[]>([]);
	const [fullNameArray, setFullNameArray] = useState<{ id: any; name: string; }[]>([]);

	const [likedDto, setLikedDto] = useState<any>([]);
  const [isViewUserModalOpen, setViewUserModalOpen] = useState(false);
	const [isLoadingLiked, setIsLoadingLiked] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [itemPerPage, setItemPerPage] = useState(10);
	const [hasMore, setHasMore] = useState(true);
	const authorities = user.authorities.map(
		(authority: { name: string }) => authority.name
  );
  // useEffect(() => {
  //   setCommentText(commentOnPost)
  // }, [commentOnPost])

  // useEffect(() => {
  //   commentType === 'PHOTO' && setImagePreviewUrl(content.mediaUrl.startsWith('data:image/png;base64') ? content.mediaUrl  : content.mediaUrl.startsWith(`${process.env.REACT_APP_PICTURE_URL}`) ? content.mediaUrl : `${process.env.REACT_APP_PICTURE_URL}${content.mediaUrl}`)
  //   commentType === 'MUSIC' && setMusic({ musicUrl: content.mediaUrl, id: content.musicId })
  // }, [commentType])

  useEffect(() => {
				const ids = tagRecordsDto.map((record: TagRecord) => record.taggedUserProfile.id);
				setPrevTaggedUserList(ids)
	}, [tagRecordsDto]);

  const handleCloseDeleteDialog = () => {
    setOPenDeleteDialog(false);
  }
  const handleCloseMenu = () => {
    setOpenMenu(false);
  };
  useEffect(() => {
    setProfileImageUrl(profilePicture ? `${process.env.REACT_APP_PICTURE_URL}${profilePicture}` : '')
  }, [profilePicture])

  useEffect(() => {
    setTotalLikeCount(parentCommentReactionCount)
    setLikeStatus(userReaction ? userReaction : false)
  }, [parentCommentReactionCount, userReaction])

  const convertTime = (createdAt: any) => {
    const originalTimestamp = new Date(createdAt);
    const hours = originalTimestamp.getHours();
    const minutes = originalTimestamp.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12;
    const NewTime = `${formattedHours}:${String(minutes).padStart(2, '0')} ${ampm}`;
    return NewTime
  }
  const formatTimeDifference = (createdAt: string) => {
    const currentDateTime: Date = new Date();
    const notificationDateTime = new Date(createdAt);
    const timeDiffInMilliseconds =
      currentDateTime.getTime() - notificationDateTime.getTime();

    const minutes = Math.floor(timeDiffInMilliseconds / (1000 * 60));
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    if (minutes === 0 || minutes === -1) {
      return "now";
    } else if (minutes < 60) {
      return `${minutes} minute${minutes === 1 ? "" : "s"} ago`;
    } else if (hours < 24) {
      return `${hours} hour${hours === 1 ? "" : "s"} ago`;
    } else if (days < 30) {
      return `${days} day${days === 1 ? "" : "s"} ago`;
    } else if (months < 12) {
      return `${months} month${months === 1 ? "" : "s"} ago`;
    } else {
      return `${years} year${years === 1 ? "" : "s"} ago`;
    }
  };

  const handleLike = (status: any) => {
    setLikeStatus(status)
    fetch(`${process.env.REACT_APP_BASE_URL}/api/comment-reaction/react`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        "comment": {
          "id": id
        }
      }),
    }).then((res) => {
      if(res.status === 502){
        navigate('/defaultPage')
      } else if (res.status >= 400) {
        ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
      } else {
        status ? setTotalLikeCount(totalLikeCount + 1) : setTotalLikeCount(totalLikeCount - 1)
        setLikeStatus(status)
        handlePostPrivacy(postPrivacy);
      }
    }).catch((err) => {
      setShowAlert(true);
      setAlertStatus(false);
      setAlertMsg("Network Error! Please try again..");
    });

  }
  const handlePostPrivacy = (newValue: any) => {
		setPostPrivacy(newValue);
		//setLoading(true);
		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/comments/allComments/tree?sortBy=${newValue}&page=0&size=${itemPerPage}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						postId: postId,
					}),
				}
			)
				.then((res) => {
					if (res.status === 502) {
						navigate("/defaultPage");
					} else if (res.status > 400) {
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length > 0) {
						//!isReplay && setTotalCommentCount(data[0].totalParentCommentCount);
						setMoreComments(data);
						var nextPageNumber = pageNumber + 1;
						setPageNumber(nextPageNumber);
					}
					if (data.length < itemPerPage) {
						setHasMore(false);
					}
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {
				//	setLoading(false);
				});
		} catch (error) {
			//setLoading(false);
		}
	};
  const handleViewReply = () => {
    setParentCommentUserName(userProfileName)
    setParentCommentId(id)
    setDisplayAddReply(true)
    setSelectedId(parentId)
    setExpandHere(!expandHere)
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `${process.env.REACT_APP_BASE_URL_FRONTEND}/link to comment`
    );
    // navigator.clipboard.writeText(`${process.env.REACT_APP_BASE_URL_FRONTEND}/postView/${post.id}/${post.userProfileId}`);
    updateAlertStatus(true, true, 'Link copied to clipboard');
    setOpenMenu(!openMenu)
    // setTimeout(() => {
    //     setShowTooltip(false);
    // }, 2000)
  }
  const isEnable = () => {
    if ((commentText.length > 0 && commentText.length < 600) || file !== '' || music !== '') {
      return false;
    } else {
      return true;
    }
  }
  const sendComment = () => {
    if (commentText.length > 600) {
      setShowAlert(true);
      setAlertStatus(false);
      setAlertMsg("Max 600 characters allowed!");
    } else {
      setAdding(true)
      var formData = new FormData();
      formData.append('commentType', imagePreviewUrl ? 'PHOTO' : music ? 'MUSIC' : 'TEXT')
      // formData.append("parentCommentId", parentCommentId);
      formData.append('userProfileId', `${user.userProfileId}`)
      // formData.append("postId", postId);
      formData.append("commentOnPost", commentText);
      formData.append("id",id);
      formData.append('musicId', music && music.id ? music.id : '');
      formData.append("file", file ? file : null);

      const taggedUserListPost : number[]  = findTaggedUsers(fullNameArray, commentText);

			//appended tagged user
			formData.append(
				"taggedUserProfileIds", taggedUserListPost && taggedUserListPost.length > 0	? taggedUserListPost.join(",") : ""
			);
      fetch(`${process.env.REACT_APP_BASE_URL}/api/updateComments`, {
        method: "PUT",
        headers: {
          // "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: formData
      }).then((res) => {
        if(res.status === 502){
          navigate('/defaultPage')
        } else if (res.status >= 400) {
          ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
          setAdding(false)
        } else {
          // handleCloseEditComment()
          const updatedProperty = {
            commentType: imagePreviewUrl ? 'PHOTO' : music ? 'MUSIC' : 'TEXT',
            commentOnPost: commentText,
            content: {
              mediaUrl:
                content.mediaUrl === imagePreviewUrl ? content.mediaUrl :
                  content.mediaUrl === music.musicUrl ? content.mediaUrl :
                    (imagePreviewUrl && content.mediaUrl !== imagePreviewUrl) ? imagePreviewUrl :
                      (music && content.mediaUrl !== music.musicUrl) ? music.musicUrl :
                        ''
            },
            // content : {mediaUrl : music ? music.musicUrl : imagePreviewUrl},

          }
          const updatedComments = updatePropertyByIdRecursively(moreComments, id, updatedProperty);
          setMoreComments(updatedComments);
          setImagePreviewUrl('');
          setMusic('')
          setFile('')
          setCommentText('')
          setOpenEdit(false)
          setAdding(false)
          handlePostPrivacy(postPrivacy)
        }
      }).catch((err) => {
        setShowAlert(true);
        setAlertStatus(false);
        setAlertMsg("Network Error! Please try again..");
        setAdding(false)
      });
    }
  }

    const handleEditClose = () => {
    setMusicOpen(false)
  }
  const handleOpenEditor = (e: any) => {
    e.preventDefault();
    const file = e.target.files[0];
    if (file.type.startsWith('image/')) {
    setResult(URL.createObjectURL(file))
    setFile(file);
    setOpen(true)
    if (inputFileRef.current) {
      inputFileRef.current.value = null; // Clear the input value
      const newInput = document.createElement('input');
      newInput.style.display = 'none';
      newInput.type = 'file';
      newInput.addEventListener('change', handleOpenEditor);
      inputFileRef.current.parentNode.replaceChild(newInput, inputFileRef.current);
      inputFileRef.current = newInput;
    }
  }
  else{
      updateAlertStatus(true, false, 'Invalid file type');
  }
  }
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleCloseEditComment = () => {
    setOpenEdit(false)
  }

  const handleClose = () => {
    setOpen(false);
  };
  const handleEditComment = () => {
    setCommentText(commentOnPost)
    commentType === 'PHOTO' && setImagePreviewUrl(content.mediaUrl.startsWith('data:image/png;base64') ? content.mediaUrl : content.mediaUrl.startsWith(`${process.env.REACT_APP_PICTURE_URL}`) ? content.mediaUrl : `${process.env.REACT_APP_PICTURE_URL}${content.mediaUrl}`)
    commentType === 'MUSIC' && setMusic({ musicUrl: content.mediaUrl, id: content.musicId })
    setOpenEdit(true)
    setOpenMenu(false)
  }
  const handleMusicChange = () => {
    setMusic('')
  }
  const handlePictureChange = () => {
    setImagePreviewUrl('')
    setFile('')
  }
  const removeCommentByIdRecursively = (commentArray: any, commentId: any) => {
    return commentArray.reduce((acc: any, comment: any) => {
      if (comment.id === commentId) {
        return acc;
      }
      if (comment.childComments) {
        const updatedChildComments = removeCommentByIdRecursively(
          comment.childComments,
          commentId
        );
        const filteredChildComments = updatedChildComments.filter(
          (childComment: any) => childComment !== null
        );
        return [...acc, { ...comment, childComments: filteredChildComments }];
      }
      return [...acc, comment];
    }, []);
  };

  const updateCommentsAfterDelete = () => {
    let newArray;
    if (isFirst) {
      newArray = moreComments.filter((obj: any) => obj.id !== id);
      setTotalCommentCount(totalCommentCount - 1)
      setMoreComments(newArray)
    } else {
      const updatedComments = removeCommentByIdRecursively(moreComments, id);
      setMoreComments(updatedComments);
    }
    setImagePreviewUrl('')
    setFile('')
    setMusic('')
    setCommentText('')
  }

  const handleDeleteComment = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/api/deleteComments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        id:id
      }),
    }).then((res) => {
      if(res.status === 502){
        navigate('/defaultPage')
      } else if (res.status >= 400) {
        ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
      } else {
        setOPenDeleteDialog(false);
        updateAlertStatus(true, true, 'Comment Deleted Successfully');
        updateCommentsAfterDelete()
        setOpenMenu(!openMenu)
        return res.json();
      }
    }).catch((err) => {
      // setShowAlert(true);
      // setAlertStatus(false);
      // setAlertMsg("Network Error! Please try again..");
    });
    setOpenMenu(!openMenu)
  }

  const updatePropertyByIdRecursively = (moreComments: any, commentId: any, updatedProperty: any) => {
    return moreComments.map((comment: any) => {
      if (comment.id === commentId) {
        return { ...comment, commentType: updatedProperty.commentType, commentOnPost: updatedProperty.commentOnPost, content: updatedProperty.content };
      }
      if (comment.childComments) {
        const updatedChildComments = updatePropertyByIdRecursively(
          comment.childComments,
          commentId,
          updatedProperty
        );
        return { ...comment, childComments: updatedChildComments };
      }
      return comment;
    });
  };


	const pauseAudioPlayer = (musicId:any) => {
		// Pause the currently playing audio player
		const audioPlayers = document.querySelectorAll("audio");

		audioPlayers.forEach((audioPlayer) => {

			const audioElement = audioPlayer as HTMLAudioElement;
			if (audioPlayer.id == musicId) {
			//	audioElement.play();
			}else{
				//audioPlayer.pause();
				audioElement.pause();
			}
		});
	};
  const handlePlay = useCallback(() => {
    pauseAudioPlayer(id);
   }, [id]);

   const viewTaggedUserProf =(nickname:string)=>{
    const encodedNickName = encodeURIComponent(nickname);

		navigate("/socialHome/" + encodedNickName);

	}

  const renderFormattedText = () => {
		const lines: string[] = commentOnPost?.split("\n") || [];
    const TaggedUser = (tagRecordsDto: any, nickname: string) => {

			if (!tagRecordsDto) {
				return null;
			}
			return tagRecordsDto.find(
				(item: any) =>  item?.taggedUserProfile?.user.firstName + " " + item?.taggedUserProfile?.user.lastName === nickname
			);
		};

		const taggedUserColor =(nickname:string)=>{

			const taggedUser = TaggedUser(tagRecordsDto, nickname)
			if(taggedUser?.approved === true){
				return true;
			}else{

				return false
			}
		};

		const taggedUserNickname = (username: string) => {
			const taggedUserProfile = tagRecordsDto?.find((item: any) => {
				const fullName = `${item.taggedUserProfile?.user.firstName} ${item.taggedUserProfile?.user.lastName}`;
				if (fullName === username) {
					return item.taggedUserProfile?.nickname;
				}
				return undefined; // If the condition doesn't match, return undefined
			});

			// If taggedUserProfile is found, return its nickname, otherwise return null
			return taggedUserProfile?.taggedUserProfile?.nickname || null;
		};


		const fullNameArray: string[] = [];
		const TaggedUserList = tagRecordsDto;


		if (TaggedUserList) {
			TaggedUserList.forEach((record: any) => {
				const firstName = record.taggedUserProfile.user.firstName;
				const lastName = record.taggedUserProfile.user.lastName;
				const fullName = `${firstName} ${lastName}`;
				fullNameArray.push(fullName);
			});
		}

		return lines.map((line, index) => (
      <React.Fragment key={index}>
      {line.split("**").map((chunk, chunkIndex) => {
        if (chunkIndex % 2 === 0) {
         // const chunks = chunk.split(/(@\w+\s\w+)/g);
          const chunks = chunk.split(/(@\w+\s\w+|\(|\))/g);
          let count = 0;
          chunks.forEach((chunk) => {
            if (/@\w+\s\w+/.test(chunk)) {
              count++;
            }
          });

          return chunks.map((part, partIndex) => {
            if (part.startsWith("@")) {

		const isPartInFullNameArray = fullNameArray.some(item => {
									// Assuming part is the substring of interest
									return item.includes(part.substring(1));
								  });
                   // const isAprroved = taggedUserColor(profileName);
              if (isPartInFullNameArray) {
									const isAprroved = taggedUserColor(part.substring(1));
									const profileName = taggedUserNickname(part.substring(1));

              return (
                <span key={partIndex}>

                  <a
                    onClick={() => {
                      if (isAprroved) {
                        viewTaggedUserProf(profileName);
                      }
                    }}
                    style={{
                      color: isAprroved ? '#0273E6' : 'black',
                      cursor: isAprroved ? 'pointer' : 'default',
                      fontWeight: isAprroved ? '600' : '600',
                    }}
                  >
                    {part.substring(1)}
                  </a> 	</span>
               					)
								  }
								 if(!isPartInFullNameArray){
									return (
											"@" + part.substring(1)
										)
								 }	            } else {

              return (
                <span style={{ fontWeight: "500" }} key={partIndex}>
                  {part.replace(/\([^()]*\)/g, "")}
                </span>
              );
            }
          });
        } else {
          return (
            <span style={{ fontWeight: "bold" }} key={chunkIndex}>
              {chunk}
            </span>
          );
        }
      })}
      {index < lines.length - 1 && <br />}{" "}
    </React.Fragment>
		));
	};


  // const renderFormattedText = () => {
	// 	const lines: string[] = commentOnPost?.split("\n") || [];
  //   const TaggedUser = (tagRecordsDto: any, nickname: string) => {

	// 		if (!tagRecordsDto) {
	// 			return null;
	// 		}
	// 		return tagRecordsDto.find((item: any) => item?.taggedUserProfile?.nickname === nickname);
	// 	};

	// 	const taggedUserColor =(nickname:string)=>{

	// 		const taggedUser = TaggedUser(tagRecordsDto, nickname)
	// 		if(taggedUser?.approved === true){
	// 			return true;
	// 		}else{

	// 			return false
	// 		}
	// 	}
  //   const nicknameArray: string[] = [];
	// 	const fullNameArray: string[] = [];
	// 	const TaggedUserList = tagRecordsDto;
	// 	if (TaggedUserList) {
	// 		TaggedUserList.forEach(
	// 			(
	// 				record: { taggedUserProfile: { nickname: string } },
	// 				index: number
	// 			) => {
	// 				const nickname = record.taggedUserProfile.nickname;
	// 				nicknameArray.push(nickname);
	// 			}
	// 		);
	// 	}

	// 	if (TaggedUserList) {
	// 		TaggedUserList.forEach((record: any) => {
	// 			const firstName = record.taggedUserProfile.user.firstName;
	// 			const lastName = record.taggedUserProfile.user.lastName;
	// 			const fullName = `${firstName} ${lastName}`;
	// 			fullNameArray.push(fullName);
	// 		});
	// 	}

	// 	return lines.map((line, index) => (
  //     <React.Fragment key={index}>
  //     {line.split("**").map((chunk, chunkIndex) => {
  //       if (chunkIndex % 2 === 0) {
  //        // const chunks = chunk.split(/(@\w+\s\w+)/g);
  //         const chunks = chunk.split(/(@\w+\s\w+|\(|\))/g);
  //         let count = 0;
  //         chunks.forEach((chunk) => {
  //           if (/@\w+\s\w+/.test(chunk)) {
  //             count++;
  //           }
  //         });
  //         let normalIndex = 0;
  //         return chunks.map((part, partIndex) => {
  //           if (part.startsWith("@")) {
  //             const profileName = nicknameArray[normalIndex];
  //             const taggedNames = fullNameArray[normalIndex];
  //             const isAprroved = taggedUserColor(profileName);
  //             if (normalIndex !== count) {
  //               normalIndex++;
  //             }
  //             return (
  //               <span key={partIndex}>
  //               	{fullNameArray.some(item => item.includes(part.substring(1))) ? (
  //                 <a
  //                   onClick={() => {
  //                     if (isAprroved) {
  //                       viewTaggedUserProf(profileName);
  //                     }
  //                   }}
  //                   style={{
  //                     color: isAprroved ? '#0273E6' : 'black',
  //                     cursor: isAprroved ? 'pointer' : 'default',
  //                     fontWeight: isAprroved ? '600' : '600',
  //                   }}
  //                 >
  //                   {part.substring(1)}
  //                 </a>
  //               ) : (
  //                "@"+ part.substring(1)
  //               )}
  //             </span>
  //             );
  //           } else {
  //             return (
  //               <span style={{ fontWeight: "500" }} key={partIndex}>
  //                 {part.replace(/\([^()]*\)/g, "")}
  //               </span>
  //             );
  //           }
  //         });
  //       } else {
  //         return (
  //           <span style={{ fontWeight: "bold" }} key={chunkIndex}>
  //             {chunk}
  //           </span>
  //         );
  //       }
  //     })}
  //     {index < lines.length - 1 && <br />}{" "}
  //   </React.Fragment>
	// 	));
	// };

	function findTaggedUsers(fullNameArray: { id: number, name: string }[], postText: string): number[] {
		// Initialize taggedUserList
		const taggedList: number[] = [];

		// Regular expression to find words starting with "@"
		const taggedUserRegex = /@(\w+(?:\s+\w+){1})/g;

		// Extract tagged user names from postText
		const taggedUserNames = postText?.match(taggedUserRegex) || [];
	//	taggedList.push(...prevtaggedUserList)
//prevtaggedUserList
		if (taggedUserNames) {
			// Loop through tagged user names
			taggedUserNames.forEach(taggedUserName => {
				// Remove "@" from the tagged user name
				const userName = taggedUserName.trim();
				// Find the corresponding user in fullNameArray
				const matchedUser = fullNameArray.find(user => user.name === userName);
				// If a match is found and there are at least two words after the tagged user name
				if (matchedUser && postText?.includes(taggedUserName)) {
					taggedList.push(matchedUser.id);
					const startIndex = postText?.indexOf(taggedUserName) + taggedUserName.length + 1;
					const remainingText = postText?.substring(startIndex);
					const nextTwoWords = remainingText.split(" ").slice(0, 2).join(" ");
					if (fullNameArray.find(user => user.name === nextTwoWords)) {
						//taggedList.push(matchedUser.id);
					}
				}
			});
		}
		// const uniqueArray = taggedList.filter((item, index) => taggedList.indexOf(item) === index);
		// return uniqueArray;
    return taggedList;

	}
	  const [searchTermt, setSearchTermt] = useState('');
	  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
		const { value } = e.target;
		setCommentText(value);
		setInputValue(value);
		//const splitInput = value.split(' ');
    const splitInput = value.split(/[\s\n]+/);
    const lastTypedWord = splitInput[splitInput.length - 1];
		if (lastTypedWord.startsWith('@') && lastTypedWord.length >= 3) {
		const searchTerm = lastTypedWord.substring(1);
		const fetchData = () => {
					if (searchTerm.trim() === "") {
						setAutocompleteOptions([]);
						return;
					}

					fetch(
						`${process.env.REACT_APP_BASE_URL}/api/getAllTaggedUserProfileList?search=${searchTerm}`,
						{
							method: "GET",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`,
							},
						}
					)
						.then((response) => {
							if (!response.ok) {
								throw new Error(`HTTP error! Status: ${response.status}`);
							}
							return response.json();
						})
						.then((data) => {
							setAutocompleteOptions(data);
						})
						.catch((error) => {
							console.error("Error fetching data:", error);
						});
				};
				setShowAutocomplete(true);
				const debounceTimeout = setTimeout(() => {
					fetchData();
				}, 300);
				return () => {
					clearTimeout(debounceTimeout);
					setAutocompleteOptions([]);
				};
			}
		if (lastTypedWord.startsWith('@') && lastTypedWord.length < 3) {
			setAutocompleteOptions([]);
			setShowAutocomplete(false);
		} else {
			setAutocompleteOptions([]);
		}
		if (lastTypedWord.startsWith('@') && lastTypedWord.length >= 3) {
			setShowAutocomplete(true);
		} else {
			setShowAutocomplete(false);
		}
	};
	const handleAutocompleteSelect = (user: any, id: any, nickname: any) => {
		const startIndex = inputValue.lastIndexOf("@");
		const newText =
			inputValue.substring(0, startIndex) +
			`@${user.firstName} ${user.lastName} `;
		setInputValue(newText);
		setCommentText(newText);
		setSearchTermt("");
		const selectedName = `@${user.firstName} ${user.lastName} `;
		const newEntry = { id: id, name: `@${user.firstName} ${user.lastName}` };
	   setFullNameArray([...fullNameArray, newEntry]);
		setShowAutocomplete(false);
		setIsAtTyped(false);

	};

  const [isHovered, setIsHovered] = useState(false);
    	// Function to handle mouse enter event
	const handleMouseEnter = () => {
		setIsHovered(true);
	};

	// Function to handle mouse leave event
	const handleMouseLeave = () => {
		setIsHovered(false);
	};

	const viewLikeUserList = () => {
		setViewUserModalOpen(true);

		try {
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/likedcomments/?page=${pageNumber}&size=${itemPerPage}`,
				// `${process.env.REACT_APP_BASE_URL}/api/getAllPosts`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ id: id }),
				}
			)
				.then((res) => {
					if (res.status > 400) {
						//setViewUserModalOpen(false);
						throw new Error("api_error");
					} else {
						//setViewUserModalOpen(true);
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {
						// setLikedDto(data);
						setLikedDto(
							data.sort(
								(a: any, b: any) =>
									new Date(b.createdAt).getTime() -
									new Date(a.createdAt).getTime()
							)
						);
						if (pageNumber === 0) {
							// setLikedDto(data);
							setLikedDto(
								data.sort(
									(a: any, b: any) =>
										new Date(b.createdAt).getTime() -
										new Date(a.createdAt).getTime()
								)
							);
						} else {
							setLikedDto((prev: any) =>
								[...prev, ...data].sort(
									(a, b) =>
										new Date(b.createdAt).getTime() -
										new Date(a.createdAt).getTime()
								)
							);
						}
					}

					if (data.length > 0) {
						setLikedDto(data);
					}

					setPageNumber(pageNumber + 1);
				})
				.catch((err) => {
					setHasMore(false);
				})
				.finally(() => {});
		} catch (error) {
		}
	};
	const handleCloseLikeDialog = () => {
		setLikedDto(null);
		setPageNumber(0);
		setHasMore(true);
		setViewUserModalOpen(false);
	};


  return (
    <>
      <Grid container columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }} columnGap={1}>
        <Grid item xs={isFirst ? 1.5 : 1}>
          <Avatar
            alt={userProfileName}
            src={profileImageUrl}
            sx={{ height: isFirst ? '50px' : '30px', width: isFirst ? '50px' : '30px' }} />
        </Grid>

        <Grid container direction={'column'} rowGap={1} item xs={9.5} sx={{ alignSelf: 'center', mb: 1 }}>
          <Grid item container >
            <Grid item xs={11}>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                {userProfileName}
                <Typography variant="caption" sx={{ color: '#888888', fontSize: '16px' }}>
                  &nbsp; - {convertTime(createdAt)}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={1}>
              {/* need to remove this condition while copy link to comment and report comment implementation */}
              {user.userProfileId === userProfileId || user.userProfileId === postCreateBy ?
                <IconButton
                  ref={buttonRef}
                  aria-label="close"
                  aria-expanded={openMenu ? 'true' : undefined}
                  onClick={() => {
                    setOpenMenu(!openMenu);
                  }}
                  disabled={user.userAccountStatus === "SUSPEND"}
                >
                  <img src={MoreIcon} alt='close' style={{ height: '24px', width: '24px' }} />
                </IconButton>
                : ''}

              <Menu
                id="basic-menu"
                anchorEl={buttonRef.current}
                open={openMenu}
                onClose={handleCloseMenu}
                aria-labelledby="basic-demo-button"
              >
                {/* <MenuItem onClick={() => handleCopyLink()}>
                  <IconButton><img src={CopyLinkToShareIcon} /></IconButton>
                  Copy link to comment
                </MenuItem> */}
                {user.userProfileId === userProfileId ?
                  <MenuItem onClick={() => handleEditComment()}>
                    <IconButton><img src={EditPostIcon} alt='' /></IconButton>
                    Edit Comment
                  </MenuItem>
                  : ''}
                {user.userProfileId === userProfileId || user.userProfileId === postCreateBy ?
                  <MenuItem onClick={() => setOPenDeleteDialog(true)}>
                    <IconButton><img src={DeletePostIcon} alt='' /></IconButton>
                    Delete Comment
                  </MenuItem>
                  : ''}
                {/* {user.userProfileId !== userProfileId ?
                  <MenuItem onClick={() => setOpenEdit(true)}>
                    <IconButton><img src={FlagIcon} /></IconButton>
                    Report
                  </MenuItem>
                  : ''} */}
              </Menu>

            </Grid>
          </Grid>

          {openEdit && !authorities?.includes('ROLE_ADMIN') ?
            <Grid item container>
              <Grid item xs={11} >
                <OutlinedInput
                  id="title"
                  className="social-textField"
                  sx={{
                    borderRadius: '25px', backgroundColor: 'white', borderColor: '#C5C5C5', pl: '20px', pr: '40px',
                    flexDirection: "column", minHeight: '50px', fontSize: '16px !important', fontWeight: 400, '& .MuiOutlinedInput-input': {
                      fontWeight: '400 !important',
                    }
                  }}
                  value={commentText}
                  onChange={handleInputChange}
                  placeholder='Comment on this'
                  required
                  multiline
                  error={
                    dirtyComment &&
                    (commentText?.length > 600)
                  }
                  onFocus={() => setDirtyComment(true)}
                  endAdornment={
                    <>


{showAutocomplete &&
									autocompleteOptions &&
									(autocompleteOptions ?? []) && (
										<Stack
											sx={{
												width: "99%",
												border: "1px solid #C2C2C3",
												boxShadow: "rgba(217, 231, 255, 0.77) 0px 1px 14px 0px",
												mt: "8px",
												zIndex: 1,
												maxHeight: "250px",
												overflowY: "auto",
											}}
										>
											{(autocompleteOptions ?? [])
												.filter((option) => typeof option !== "string")
												.map((option, index) => (
													<Stack
														flexDirection="column"
														alignItems="flex-start"
														sx={{ width: "100%", maxHeight: "250px" }}
													>
														<Stack
															flexDirection="row"
															alignItems="center"
															mt={1}
															sx={{
																width: "100%",
																marginTop: 0,
																maxHeight: "250px",
																padding: "8px",
															}}
													    onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  handleAutocompleteSelect(option.user, option.id, option.nickname);
                                }
                                }}
                              onClick={() =>
                                handleAutocompleteSelect(option.user, option.id, option.nickname)
                              }
                              onSelect={(selectedOption) => {
                                // Call handleTagging with the selected option value
                                handleAutocompleteSelect(option.user, option.id, option.nickname)
                                // Focus back on the post dialog input field after selecting an option
                                }}
                                tabIndex={0}
														>
															<Stack sx={{ mr: "10px" }}>
																<Avatar
																	alt={option.user.firstName}
																	src={
																		option.profilePhotoUrl
																			? `${process.env.REACT_APP_PICTURE_URL}${option.profilePhotoUrl}`
																			: ""
																	}
																	className="profile-img-style"
																	sx={{ width: "50px", height: "50px" }}
																/>
															</Stack>
															<Stack>
																<Stack
																	flexDirection="row"
																	alignItems="flex-start"
																>
																	<Typography
																		className="card-title-txt-sx"
																		sx={{
																			fontSize: "16px",
																			color: "#000000",
																			fontWeight: 500,
																			fontFamily: "Lexend",
																			lineHeight: "20px",
																			maxWidth: "12.125rem",
																			whiteSpace: "nowrap",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																		}}
																	>
																		{`${option.user.firstName} ${option.user.lastName} `}
																	</Typography>
																</Stack>
																{option.profileTitle !== null ? (
																	<Typography
																		className="card-sub-title-txt-sx"
																		sx={{
																			fontSize: "14px",
																			color: "#5B5B65",
																			fontWeight: 400,
																			fontFamily: "Lexend",
																			lineHeight: "17.5px",
																			maxWidth: "100%",
																			whiteSpace: "nowrap",
																			overflow: "hidden",
																			textOverflow: "ellipsis",
																			textWrap: "wrap",
																		}}
																	>
																		{`${
																			option.profileTitle &&
																			option.profileTitle?.length > 35
																				? option.profileTitle?.substring(
																						0,
																						35
																				  ) + "..."
																				: option.profileTitle
																		}`}
																	</Typography>
																) : (
																	""
																)}
															</Stack>
														</Stack>

														<hr
															style={{
																color: "red !important",
																width: "100%",
																margin: 0,
															}}
														/>
													</Stack>
												))}
										</Stack>
									)}


                      <Grid container>
                        {imagePreviewUrl &&
                          <>
                            <Grid item xs={7}>
                              <img
                                src={imagePreviewUrl}
                                alt="Caption this"
                                style={{ height: '200px', width: '100%' }}
                              />
                            </Grid>
                            <Grid item xs={5} sx={{ alignSelf: 'center' }}>
                              <IconButton sx={{ alignSelf: 'baseline' }}
                                onClick={() => handlePictureChange()}
                              >
                                <img src={CancelIcon} style={{ height: '20px', width: '20px' }} alt='' />
                              </IconButton>
                            </Grid>
                          </>
                        }
                        {music &&
                          <>
                            <Grid item xs={10}>
                              {/* <audio crossOrigin="anonymous" controls controlsList="nodownload noremoteplayback noplaybackrate" >
														<source
															src={deviceMusic ? `${music.musicUrl}` : music.musicUrl.includes('https://') ? music.musicUrl :  `${process.env.REACT_APP_PICTURE_URL}${music.musicUrl}`}
															type="audio/mpeg"
														></source>
													</audio> */}
                           <CustomAudioPlayer musicId={music.musicId} audioUrl={deviceMusic ? `${music.musicUrl}` : music.musicUrl.includes('https://') ? music.musicUrl :  `${process.env.REACT_APP_PICTURE_URL}${music.musicUrl}`} displayDirectly={true} activeMenu="" />

                            </Grid>
                            <Grid item xs={2} sx={{display:'flex',justifyContent:'end'}}>
                              <IconButton sx={{ alignSelf: 'baseline' }}
                                onClick={() => handleMusicChange()}
                              >
                                <img src={CancelIcon} style={{ height: '20px', width: '20px' }} alt='' />
                              </IconButton>
                            </Grid>
                          </>
                        }
                      </Grid>

                      <InputAdornment position="end" sx={{ alignSelf: 'self-end', py: 1, marginTop: '-20px', mr: '-7%' }}>
                        {adding ?
                        <IconButton
                        aria-label="comment"
                        // onClick={() => sendComment()}
                        edge="end"
                        aria-disabled
                      >
                        <img src={CreateCommentDisabled} alt='' />
                      </IconButton>
                        :
                        <IconButton
                          aria-label="comment"
                          onClick={() => sendComment()}
                          edge="end"
                          disabled={isEnable()}
                        >
                          <img src={isEnable() ? CreateCommentDisabled : CommentPostIcon} alt=''
                          style={{marginTop: isEnable() ? "6px" : "-12px", }}
                        />
                        </IconButton>
                  }
                      </InputAdornment>
                    </>
                  }
                />
                {dirtyComment && (
                  <FormHelperText sx={{ color: "#FD4B24" }}>
                    {commentText?.length >= 600
                      ? "Max characters should be 600"
                      : ""}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={1}>
                <IconButton sx={{ alignSelf: 'baseline' }}
                  onClick={() => setOpenEdit(false)}
                >
                  <img src={Close} style={{ height: '20px', width: '20px' }} alt='' />
                </IconButton>
              </Grid>
              <Grid item xs={11} container direction={'row-reverse'} >
                {imagePreviewUrl !== '' || music !== '' ? '' :
                  <>
                    <Grid item xs={3.5}>
                      <label>
                        <Button startIcon={<img src={Music} alt='Music' style={{ height: '24px', width: '24px' }} />}
                          onClick={() => setMusicOpen(true)}
                          sx={{ color: 'black', fontSize: '14px', pb: 0.5 }}>
                          <Typography sx={{ color: 'black', fontSize: '14px' }}> Music</Typography>
                        </Button>
                      </label>
                    </Grid>
                    <Grid item xs={3} sx={{ textAlign: 'right' }}>
                      <Button sx={{ pt: '12px', width: 'max-content' }}>
                        <label>
                          <Grid container>
                            <img src={Picture} alt='' style={{ height: '24px', width: '24px' }} />&nbsp;
                            <Typography sx={{ color: 'black', fontSize: '14px' }}>
                              Photo</Typography>
                            <input
                              id="photo-upload"
                              ref={inputFileRef}
                              type="file"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={(e) => handleOpenEditor(e)}
                            />
                          </Grid>
                        </label>
                      </Button>
                    </Grid>
                    {/* <Grid item xs={7}>
                    <Button onClick={()=>setOpenEdit(false)} variant="text"
											sx={{ color: "#0273E6", fontSize:'1rem', fontWeight:400 }}
                      >Cancel</Button>
                  </Grid> */}
                  </>
                }
              </Grid>
            </Grid>
            :
            <Grid item>
              <Box sx={{ backgroundColor: '#F8F8F8', borderRadius: '12px', p: 1, fontSize: '16px', fontWeight: 400, fontFamily: 'Lexend !important' }}>
                <Typography sx={{ overflowWrap: 'anywhere', fontSize: '16px', fontWeight: 400, fontFamily: 'Lexend !important' }}>
                  <span style={{ color: '#0273E6' }}>{isFirst ? '' : AuthorName}</span>&nbsp;
                  {/* {commentOnPost} */}
                  <Linkify
												componentDecorator={(
													decoratedHref,
													decoratedText,
													key
												) => (
													<a
														href={decoratedHref}
														key={key}
														target="_blank"
														rel="noopener noreferrer"
														style={{ color: "#0273E6" }}
													>
														{decoratedText}
													</a>
												)}
											>
												{renderFormattedText()}
												{/* igyugyu */}
											</Linkify>

                </Typography>

                {commentType === 'PHOTO' &&
                  <img alt='' src={content.mediaUrl.startsWith('data:image/png;base64') ? `${content.mediaUrl}` : content.mediaUrl.startsWith(`${process.env.REACT_APP_PICTURE_URL}`) ? `${content.mediaUrl}` : `${process.env.REACT_APP_PICTURE_URL}${content.mediaUrl}`} style={{ height: '200px', width: '200px' }} />
                }
                {commentType === 'MUSIC' &&
                  <Box sx={{ backgroundColor: '#ffff', borderRadius: '16px', p: 1, fontSize: '14px', fontWeight: 500, fontFamily: 'Lexend !important', color: '#1D2D53' }}>
                    <Grid container direction={'column'}>
                      {content.mediaName &&
                        <Grid item sx={{ ml: 3, mb: 0.5 }}>
                          <Typography sx={{ fontSize: '14px', fontWeight: 500, fontFamily: 'Lexend !important', color: '#1D2D53' }}>
                            {content.mediaName}
                          </Typography>
                        </Grid>
                      }
                      <Grid item>
                        <audio  id={id} crossOrigin="anonymous" controls controlsList="nodownload noremoteplayback noplaybackrate" onPlay={handlePlay}>
														<source
															src={content.mediaUrl.includes('https://') ? content.mediaUrl : `${process.env.REACT_APP_PICTURE_URL}${content.mediaUrl}`}
															type="audio/mpeg"
														></source>
													</audio>
                      </Grid>
                    </Grid>
                  </Box>
                }
              </Box>
            </Grid>
          }


          <Grid item container columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
            <Grid item xs={2.5}>
              {likeStatus ?
                <IconButton color="primary" size="small" disabled={user.userAccountStatus === "SUSPEND" ||  authorities?.includes('ROLE_ADMIN')} onClick={() => handleLike(false)} >
                  <ThumbUpIcon sx={{ color: '#3F77D7', height: '24px', width: '24px' }} />
                  <Typography variant="body2" sx={{ color: '#5D5D65', fontSize: '14px', marginLeft: "5px" }}>	{/* {totalLikeCount} */}
										Like</Typography>
                </IconButton> :
                <IconButton color="primary" size="small" disabled={user.userAccountStatus === "SUSPEND" || authorities?.includes('ROLE_ADMIN')} onClick={() => handleLike(true)}  >
                  <img src={user.userAccountStatus === "SUSPEND" ? LikeDisabled : Like} alt="Like" style={{ height: '24px', width: '24px' }} />
                  <Typography variant="body2" sx={{ color: '#5D5D65', fontSize: '14px', marginLeft: "5px" }}>	{/* {totalLikeCount} */}
										Like</Typography>
                </IconButton>
              }

            </Grid>


            {authorities?.includes('ROLE_ADMIN') ? null : (
            <Grid item xs={3.5}>
              <IconButton color="primary" size="small" onClick={() => handleViewReply()} >
                <img src={ReplyIcon} alt="Comment" style={{ height: '24px', width: '24px' }} />&nbsp;
                <Typography variant="body2" sx={{ color: '#5D5D65', fontSize: '14px' }}>Reply({childComments && childComments.length})</Typography>
              </IconButton>
            </Grid>
            )}

<Grid
							item
							xs={3}
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "flex-end",
							}}
						>
							{totalLikeCount > 0 ? (
								<div
									onMouseEnter={handleMouseEnter}
									onMouseLeave={handleMouseLeave}
								>
									<Typography
										variant="body2"
										sx={{
											color: isHovered ? "#0273E6" : "#5D5D65", // Change color when hovered
											borderBottom: isHovered ? "2px solid #0273E6" : "none", // Change color when hovered
											lineHeight: isHovered ? "17.6px" : "19.6px",
											fontSize: "14px",
											fontWeight: "500",
											marginLeft: "5px",
											marginRight: "20px",
											cursor: "pointer", // Optionally change cursor to pointer when hovered
										}}
										onClick={viewLikeUserList}
									>
										{totalLikeCount}
										{totalLikeCount === 1 ? " Like" : " Likes"}
									</Typography>{" "}
								</div>
							) : (
								""
							)}
						</Grid>

            <Grid item xs={3} sx={{ alignSelf: 'center' }}>
              <Typography variant="body2" sx={{ color: '#5D5D65', fontSize: '14px' }}>{formatTimeDifference(createdAt)}</Typography>
            </Grid>
          </Grid>
        </Grid>


        {expandHere ?
          <>
            <div className="child-comments" style={{ marginLeft: isFirst ? '64px' : '', width: 'inherit' }}>
              {childComments && childComments.map((child: any, index: any) => (
                <>
                  <Comment key={index} {...child} reply={true} totalCommentCount={totalCommentCount} setTotalCommentCount={setTotalCommentCount} setMoreComments={setMoreComments}
                    moreComments={moreComments} setPostPrivacy={setPostPrivacy} postPrivacy={postPrivacy}
                    setParentCommentUserName={setParentCommentUserName} setParentCommentId={setParentCommentId} setDisplayAddReply={setDisplayAddReply}
                    setSelectedId={setSelectedId} parentId={parentId} AuthorName={userProfileName} isFirst={false} postCreateBy={postCreateBy} handleRefreshComments={handleRefreshComments}
                  />
                </>
              ))}
            </div>
            {/* <AddComment postId={postId} sortComment={false} commentDto={childComments} parentCommentId={id}
              totalCommentCount={totalCommentCount} setTotalCommentCount={setTotalCommentCount}
              setMoreComments={setMoreComments}
              moreComments={moreComments} setPostPrivacy={setPostPrivacy} postPrivacy={postPrivacy} /> */}
          </>
          : ''}
      </Grid>

      {isViewUserModalOpen && (
				<ViewLikeUsers
					open={isViewUserModalOpen}
					likedDto={likedDto}
					handleClose={() => {
						handleCloseLikeDialog();
					}}
					//ImageUrl={`${process.env.REACT_APP_PICTURE_URL}${post.mediaUrl}`}
					postId={id}
					post={Comment}
					profileUrl={profileUrl}
				/>
			)}

      {openDeleteDialog &&
        <Dialog
          fullWidth
          maxWidth="sm"
          open={openDeleteDialog}
          onClose={() => handleCloseDeleteDialog()}
        >
          <DialogTitle
            className="edit-heading-name"
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
              }}
            >
              <IconButton
                aria-label="close dialogbox"
                onClick={() => handleCloseDeleteDialog()}
                onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
                  event.preventDefault()
                }
                edge="end"
                sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
              >
                <img src={CloseIcon} alt="close" />
              </IconButton>
            </Box>
            <Typography>Delete Comment</Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              Are you sure you want to remove comment?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={() => handleCloseDeleteDialog()}
            >
              Cancel
            </Button>
            <Button variant="contained"
              onClick={() => handleDeleteComment()}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      }
      <SelectMusic
        musicOpen={musicOpen}
        handleMusicClose={handleEditClose}
        comment={true}
        setMusic={setMusic}
        setDeviceMusic={setDeviceMusic}
        isSelectFromDevice={true}
        setMusicFromDevice={setMusicFromDevice}
        setFile={setFile}
      />
      <ImageCropper
        open={open}
        onClose={handleClose}
        imagePreviewUrl={result}
        setImagePreviewUrl={setImagePreviewUrl}
        setResult={setFile}
        post={true}
        photoUpload={handleClose}
        cover={false}
        comment={true}
      />
    </>
  );
};

export default Comment;
