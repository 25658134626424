import { useEffect } from "react";
import { useNavigate } from "react-router";
import clsx from "clsx";
import { Button, Tooltip } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { useWakeLock } from "react-screen-wake-lock";

import useVideoContext from "../../../hooks/useVideoContext/useVideoContext";
import useParticipantsContext from "../../../hooks/useParticipantsContext/useParticipantsContext";
import { useUser } from "../../../context/UserContext";
import WebsocketClient from "../../../services/WebsocketClient";
import Leave from "../../../images/phone-leave.svg";
import { useFullscreenModeContext } from "../../../context/FullscreenModeContext";
import { stopSessionRecording } from "../../../utils";
import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";
import { useBroadcastingStatusContext } from "../../../context/BroadcastingStatusContext";
import { UserInterface } from "../../../types/types";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			background: "#E11E1E",
			color: "white",
			"&:hover": {
				background: "#E11E1E",
			},
			padding: "5px 15px",
			borderRadius: "6px",
		},
	})
);

export default function EndCallButton(props: { className?: string }) {
	const twilioSessionId = localStorage.getItem("twilioSessionId");
	const navigate = useNavigate();
	const user = useUser();

	const classes = useStyles();
	const { room } = useVideoContext();
	const { galleryViewParticipants } = useParticipantsContext();
	const { setIsFullscreenMode, setIsTabHidden } = useFullscreenModeContext();
	const { release } = useWakeLock();
	const { isBroadcastingStarted, setIsBroadcastingStarted } =
		useBroadcastingStatusContext();

	useEffect(() => {
		const onMessageReceived = (payloadData: any) => {
			if (payloadData.created_by === user.email) {
				WebsocketClientUSerStatus.disconnectWebsocketConnection(payloadData, user);
			}
		};
		WebsocketClientUSerStatus.on("leaveSession", onMessageReceived);
		return () => {
			WebsocketClientUSerStatus.off("leaveSession", onMessageReceived);
		};
	}, []);

	const leaveRoom = () => {
		release();
		setIsFullscreenMode(false);
		setIsTabHidden(false);
		if (document.fullscreenElement) {
			document.exitFullscreen();
		}
		let message = {
			action: "session_stop",
			timestamp: new Date().toISOString(),
			session_id: twilioSessionId,
			created_by: user.id,
			unsubscribe:
				galleryViewParticipants && galleryViewParticipants.length === 0
					? true
					: false,
		};
		
		let requestMsg = {
			action: "session_broadcasting",
			timestamp: new Date(),
			session_id: twilioSessionId,
			created_by: user.id,
			broadcastingStatus: "STOP",
		};
		setIsBroadcastingStarted(false)
		stopSessionRecording(twilioSessionId, user);
		localStorage.removeItem("recordingStatus");
		WebsocketClientUSerStatus.handleBroadcasting(requestMsg);
		WebsocketClientUSerStatus.handleLeaveSession(message);
		WebsocketClientUSerStatus.onLeaveSession(twilioSessionId, user);
		room?.disconnect();
		navigate("/ratejamsession");
	};

	return (
		<Tooltip title="Leave Session">
			<Button
				onClick={() => leaveRoom()}
				className={clsx(classes.button, props.className)}
				data-cy-disconnect
				startIcon={<img src={Leave} alt="leave session" />}
			>
				Leave
			</Button>
		</Tooltip>
	);
}
