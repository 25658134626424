import { useState, useEffect, useRef, SyntheticEvent } from "react";
import {
	Typography,
	Grid,
    Paper,
    Button,
    Stack,
    Switch,
    Divider,
    Dialog,
    DialogContent,
    DialogContentText,
    Chip
} from "@mui/material";
import { createStyles, makeStyles, styled } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

import CenterCircularProgress from "../CenterCircularProgress";
import ArrowLeft from "../../images/arrow-left.svg";
import TickOrange from "../../images/tick-circle-orange.svg";
import { DeviceInterface } from "../../types/types";
// contexts
import { useUser } from "../../context/UserContext";
import SettingsAlert from "../../pages/SettingsAlert";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import Online from "../../images/online.svg";
import { useActiveSubscription } from "./SubscriptionProvider/useUserSubscription";
import DOMPurify from "dompurify";


const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		linearGradBtn: {
			width: "100%",
            // margin: "0.5em 0 !important",
            background: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
            borderRadius: "18px !important",
		},
        listing: {
            listStyleType: "none",
            "& li": {
                margin: "1em 0"
            }
        },
	})
);

const SubscriptionPlans = () => {
	const [loading, setLoading] = useState(true);
	const classes = useStyles();
    const user = useUser();
    const token = localStorage.getItem("sessionId");
    const [activeDevice, setActiveDevice] = useState<DeviceInterface | null>(null);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const { purchasedSubscriptions, isSubscribed, isDeviceAvailable } = useActiveSubscription()

    // Alert states
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const navigate = useNavigate();

  //   const getActiveDevice = async () => {
  //       setLoading(true);
	// 	fetch(`${process.env.REACT_APP_BASE_URL}/api/user-devices/${user.id}`, {
	// 		method: "GET",
	// 		headers: {
	// 			"Content-Type": "application/json",
	// 			Authorization: `Bearer ${token}`,
	// 		},
	// 	})
	// 		.then((res) => {
	// 			if (res.status > 400) {
	// 				throw new Error("api_error");
	// 			} else {
	// 				return res.json();
	// 			}
	// 		})
	// 		.then((data) => {
	// 			if (data !== null) {
	// 				setActiveDevice(data);
	// 				// getActiveUsers();
	// 			} else {
	// 				setActiveDevice(null);
	// 			}
	// 		})
	// 		.catch((err) => {
	// 			setActiveDevice(null);
	// 		})
	// 		.finally(() => {
	// 			setLoading(false);
	// 		});
	// };

    useEffect(() => {
        let user = localStorage.getItem('user');
     if (user) {
       try {
         const userDetails = JSON.parse(user);
         if (!userDetails.firstName || !userDetails.lastName || !userDetails.location) {
           navigate("/socialProfileNew", {
             state: {
               email: userDetails.email,
               password: "",
             },
           });
         }
       } catch (e) {
         console.error("Failed to parse user data:", e);
       }
     }
    }, [navigate]);

    const getSubscriptionPlans = async () => {
        setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/master-subscription-plans`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
                if(data.length > 0){
                    setSubscriptionPlans(data);
                }
			})
			.catch((err) => {
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		setLoading(false);
        // getActiveDevice();
        getSubscriptionPlans();
	}, []);

	if (loading) {
		return <CenterCircularProgress />;
	}

    const handleAlertClose = () => {
		setShowAlert(false);
	};

    const imageBullet = (item: string) => {
        return (<><img
            style={{
                width: "12px",
                height: "12px",
                paddingRight: "10px",
            }}
            src={Online}
            alt="online/offline"
        />
        <span>{item} </span></>
        )
    }

    const checkOut = (plan:any) => {
        let checkAlreadySubscribed = purchasedSubscriptions.find((activePlan:any) => activePlan.masterSubscriptionPlan.shopifyVariantId === plan.shopifyVariantId );

        if(checkAlreadySubscribed != undefined){
            setAlertMsg(plan.name + ' is already available, cannot purchase again until expired');
            setAlertStatus(false);
            setShowAlert(true);
        }else{
            setLoading(true);
            // fetch(`https://app.sealsubscriptions.com/shopify/merchant/api/quick-checkout-url`, {
            fetch(`${process.env.REACT_APP_BASE_URL}/api/public/checkout`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                    "X-Seal-Token": "seal_token_04gf07v4d5nbhanxzwrfaicre65bl3yiiindmhxq"
                },
                body: JSON.stringify({
                    action: "create",
                    items: [{
                        variant_id: plan.shopifyVariantId,
                        quantity: 1,
                        selling_plan: plan.shopifySellingPlanId
                    }],
                    attributes: {
                        checkout: {
                            email: user.email,
                            shipping_address:{
                                first_name: user.firstName,
                                last_name: user.lastName,
                                address1: user.location ? user.location : "",
                                phone: "123456789",
                                zip: "test",
                                city: "test",
                                province: "test",
                                country: "test"
                            }
                        }
                    }
                }),
            })
            .then((res) => {
                if (res.status > 400) {
                    throw new Error("api_error");
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                if(data.success){
                    // To Open in a new browsr window
                    // window.open(data.payload.quick_checkout_url, '_blank',"width=800,height=800");

                    window.location = data.payload.quick_checkout_url;
                }
            })
            .catch((err) => {
                // setActiveDevice(null);
            })
            .finally(() => {
                // setLoading(false);
            });
        }
    }

	return (
		<>
            <SettingsAlert />
			<ErrorAlertDisplay alertMsg={alertMsg} alertStatus={alertStatus} showAlert={showAlert} handleAlertClose={handleAlertClose} />
			{/* <Snackbar
				open={Boolean(fileSendError)}
				headline="Error"
				message={fileSendError || ""}
				variant="error"
				handleClose={() => setFileSendError(null)}
			/> */}
			<Grid xs={12} className="center-align" spacing={{ xs: 1 }}>
				<Grid
					container={true}
					className="column jam-session"
					style={{
						width: "80%",
						padding: "1em",
						margin: "1em 0"
					}}
				>
					<Grid container xs={12}>
                        <Grid item xs={2}>
                            <Button variant="outlined" startIcon={<img src={ArrowLeft} alt="Back" />} sx={{border: "none !important", color: "black", fontWeight: 500, fontSize: "1rem", fontFamily: "Lexend"}} onClick={() => { window.history.back(); }}>
                                Back
                            </Button>
                        </Grid>

                        <Grid item xs={10} className="center-align">
                            <Typography
                                sx={{
                                    fontSize: "36px !important",
                                    fontStyle: "normal",
                                    fontWeight: "500",
                                    lineHeight: "49px",
                                    color: "#000000",
                                    zIndex: "1",
                                }}
                            >
                                Subscription Pricing
                            </Typography>
                        </Grid>
					</Grid>

					<Grid container xs={12} marginY={2}>
                        <Grid item xs={12} className="center-align">
                            {
                                !isDeviceAvailable && !isSubscribed && <Typography variant="body1" >You can select one of the subscription and add-ons while you are purchasing the audio box. You cannot buy subscription without audio box</Typography>
                            }
                        </Grid>
                        {/* <Grid item xs={6}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography>Pricing</Typography>
                                <AntSwitch defaultChecked inputProps={{ 'aria-label': 'ant design' }} />
                                <Typography>Monthly</Typography>
                            </Stack>
                        </Grid> */}
                    </Grid>

                    {/* <Grid container xs={12} my={3}>
                        <Grid item xs={8} className="center-align">
                            <Typography variant="h4">Primary Subscription</Typography>
                        </Grid>
                        <Grid xs={0.1}>
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid item xs={3.9} className="center-align">
                            <Typography variant="h4">Add On Products</Typography>
                        </Grid>
                    </Grid> */}

					<Grid container xs={12} justifyContent="space-evenly">
						<Grid item xs={8} >
                            <Grid container xs={12} className="center-align" my={4}>
                                <Typography variant="h4" sx={{fontSize: "24px !important", fontWeight: 500}}>Primary Subscription</Typography>
                                {/* <Grid item xs={12} className="center-align">
                                    <Typography variant="h3"  sx={{borderBottom: "5px solid #19AF00"}}>Add On Products</Typography>
                                </Grid> */}
                            </Grid>
                            <Paper sx={{marginY: 2, height: "230px", border: "1px solid #D7D7D7", borderRadius: "12px"}} >
                                <Grid container xs={12} p={1} height="100%" >
                                    <Grid item xs={3} m="auto">
                                        <Typography variant="h4" className="center-align" sx={{borderBottom: "5px solid #0EA814", fontSize: "24px", fontWeight: 800,  color: "#7A7985" }}>Trial</Typography>

                                        <Typography variant="h2" marginY={4} textAlign="center" sx={{fontSize: "36px !important"}}>
                                            $0
                                        </Typography>

                                        <Typography textAlign="center" sx={{color: "#7A7985", fontSize: "14px"}}>Only Social account</Typography>
                                    </Grid>

                                    <Grid xs={0.1}>
                                        <Divider orientation="vertical" />
                                    </Grid>

                                    <Grid item xs={8} >
                                        <Grid container justifyContent="flex-end" >
                                            {
                                                isSubscribed ?
                                                    <Button variant="outlined">Downgrade to Free</Button>
                                                :
                                                    <Chip icon={<img src={TickOrange} /> } label="Your are on this plan" />
                                            }
                                        </Grid>
                                        {/* <ul className={classes.listing}>
                                            <li>{imageBullet('No Jam Sessions')}</li>
                                            <li>{imageBullet('10 clips of 60 sec')}</li>
                                            <li>{imageBullet('Access to Social profile, messages, Events, Gigs, Search musicians')}</li>
                                            <li>{imageBullet('Trial ends in 30 days')}</li>
                                        </ul> */}

                                        <ul>
                                            <li>No Jam Sessions</li>
                                            <li>10 clips of 60 sec</li>
                                            <li>Access to Social profile, messages, Events, Gigs, Search musicians</li>
                                            {/* <li>Trial ends in 30 days</li> */}
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Paper>


                            {/*<Paper sx={{margin: 1}} >
                                <Grid container xs={12} p={1} >
                                    <Grid item xs={3} m="auto">
                                        <Typography variant="h4" className="center-align" sx={{borderBottom: "5px solid #C6A600"}}>Subscriber</Typography>

                                        <Typography variant="h2" marginY={2} textAlign="center">
                                            $12.99
                                        </Typography>

                                        <Typography variant="h5" textAlign="center">monthly</Typography>
                                    </Grid>

                                    <Grid xs={0.1}>
                                        <Divider orientation="vertical" />
                                    </Grid>

                                    <Grid item xs={8} p={3} >
                                        <ul className={classes.listing}>
                                            <li>{imageBullet('Everything in Free')}</li>
                                            <li>{imageBullet('Up to 5 jame sessions')}</li>
                                            <li>{imageBullet('2 GB support')}</li>
                                            <li>{imageBullet('Premium Support')}</li>
                                        </ul>
                                    </Grid>
                                </Grid>
                            </Paper> */}
                            {
                                subscriptionPlans.length > 0 &&
                                subscriptionPlans.map((plan:any) => {
                                    return (plan.type == "Software" && plan.status) ? <Paper sx={{marginY: 2,  height: "230px", border: "1px solid #D7D7D7", borderRadius: "12px"}} >
                                        <Grid container xs={12} p={1} height="100%">
                                            <Grid item xs={3} m="auto">
                                                <Typography variant="h4" className="center-align" sx={{borderBottom: "5px solid #C6A600", fontSize: "24px", fontWeight: 800,  color: "#7A7985" }}>{plan.name}</Typography>

                                                <Typography variant="h2" marginY={4} textAlign="center" sx={{fontSize: "36px !important"}}>
                                                    ${plan?.cost}
                                                </Typography>

                                                <Typography variant="h5" textAlign="center" sx={{color: "#7A7985", fontSize: "18px"}}>{ (plan.timePeriod === 1) ? "day" : plan.timePeriod + " days"}</Typography>
                                            </Grid>

                                            <Grid xs={0.1}>
                                                <Divider orientation="vertical" />
                                            </Grid>

                                            <Grid item xs={8} >
                                                <Grid container justifyContent="flex-end" >
                                                    {
                                                        (purchasedSubscriptions.find((activePlan:any) => activePlan.masterSubscriptionPlan.shopifyVariantId === plan.shopifyVariantId )) ?
                                                            <Chip icon={<img src={TickOrange} /> } label="Your are on this plan" />
                                                        :
                                                            <Button variant="contained" onClick={() => checkOut(plan)} disabled>Buy Now</Button>
                                                    }
                                                </Grid>
                                                {/* <ul className={classes.listing}>
                                                    <li>{imageBullet('Everything in Free')}</li>
                                                    <li>{imageBullet('Up to 5 jame sessions')}</li>
                                                    <li>{imageBullet('2 GB support')}</li>
                                                    <li>{imageBullet('Premium Support')}</li>
                                                </ul> */}
                                                {plan?.subscriptionDescContentType && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(plan.subscriptionDescContentType) }}></div>}
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                    : "";
                                })
                            }
						</Grid>

                        <Grid item xs={0.1}>
                            <Divider orientation="vertical"  sx={{width: "50%", margin: "auto"}}/>
                        </Grid>

						<Grid item xs={3} >
                                <Grid container xs={12} className="center-align" my={4}>
                                    {/* <Grid item xs={12} className="center-align">
                                    <Typography variant="h3"  sx={{borderBottom: "5px solid #19AF00"}}>Add On Products</Typography>
                                    </Grid> */}
                                    <Typography variant="h4" sx={{fontSize: "24px !important", fontWeight: 500}}>Add On Products</Typography>
                                </Grid>

                                {
                                    subscriptionPlans.length > 0 &&
                                    subscriptionPlans.map((plan:any, key:number) => {
                                        return ((plan.type == "Software add-on" || plan.type == "Software add-on storage") && plan.status) ? <>
                                            <Paper sx={{width: "381px !important", height: "230px !important", marginY: 2, border: "1px solid #D7D7D7", borderRadius: "12px"}} >
                                                <Grid container xs={12} >
                                                    <Grid container xs={12} m={2}>
                                                        <Grid item xs={7} sx={{borderBottom: "4px solid #19AF00"}} >
                                                            <Typography sx={{ fontSize: "24px", fontWeight: 800,  color: "#7A7985" }}>
                                                                {plan.name}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item container xs={5} justifyContent="flex-end">
                                                            {
                                                                (purchasedSubscriptions.find((activePlan:any) => activePlan.masterSubscriptionPlan.shopifyVariantId === plan.shopifyVariantId )) ?
                                                                    <Chip icon={<img src={TickOrange} /> } label="Your are on this plan" />
                                                                :
                                                                    <Button variant="contained" onClick={() => checkOut(plan)} disabled>Buy Now</Button>
                                                            }
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container xs={12} className="center-align" my={3}>
                                                        {/* <Grid container xs={6} className="center-align"> */}
                                                            <Grid item xs={4} className="center-align">
                                                                <img src={plan.images} alt="Cloud Storage" />
                                                            </Grid>

                                                            <Grid container xs={8} >
                                                                <Grid item xs={12} >
                                                                    {plan?.subscriptionDescContentType && <Typography sx={{fontSize: "36px", fontFamily: "Lexend", fontWeight: 500}}><div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(plan.subscriptionDescContentType) }}></div></Typography>}
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography variant="h5" sx={{fontSize: "36px !important"}}>{'$' + plan.cost +  ' / month'}</Typography>
                                                                </Grid>
                                                            </Grid>
                                                        {/* </Grid> */}
                                                    </Grid>
                                                </Grid>
                                                {/* {key === 0 && <Divider />} */}
                                            </Paper>
                                        </>
                                        : "";
                                    })
                                }
						</Grid>


						{/* <Grid item xs={3} paddingX={1}>
                            <ShopRightSideBar />
						</Grid> */}

					</Grid>

                    {/* <Grid container xs={12} m={2} className="center-align">
                        <Grid item xs={2} >
                            <Button variant="contained" fullWidth className={classes.linearGradBtn} endIcon={<img src={RightArrow} alt="Buy the Audio box now" />} >Buy the Audio Box Now</Button>
                        </Grid>
                    </Grid> */}
				</Grid>

			</Grid>

            {/* <Dialog open={showPopup} >
                <DialogContent sx={{textAlign: "center"}}>
                    {plan.name} is already available. Can't process multiple purchase.
                    <DialogContentText>Loading...</DialogContentText>
                </DialogContent>
            </Dialog> */}
		</>
	);
};

export default SubscriptionPlans;
