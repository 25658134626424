import { useEffect, useState } from "react";
import {
    Card, CardContent, Grid, Typography, AlertTitle,
    Snackbar, Alert
} from "@mui/material";
import HelpAndTips from "./HelpAndTips";
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import i18n from "../l10n/strings.json";
import { makeStyles } from "@mui/styles";
import { useUser } from "../context/UserContext";
import ErrorIcon from "../images/danger.svg";
import SuccessIcon from "../images/tick-square.svg";
import CenterCircularProgress from "../components/CenterCircularProgress";
import ErrorAlert from "../components/Error/ErrorAlert";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";

function CircleRadio(props: RadioProps) {
    return (
        <Radio
            disableRipple
            color="primary"
            checkedIcon={<CheckCircleRoundedIcon />}
            {...props}
        />
    );
}
const useStyles = makeStyles((theme: any) => ({

    root: {
        '& .css-1x7uvzz-MuiTypography-root': {
            color: '#7A7985'
        }
    }
}));


const PrivacySetting = () => {


    const user = useUser();
    const classes = useStyles();
    const [viewProfile, setViewProfile] = useState('');
    const [identity, setIdentity] = useState('');
    const [connectionRequest, setConnectionRequest] = useState('');
    const [message, setMessage] = useState('');
    const [onlineStatus, setOnlineStatus] = useState('');
    const [shareMyProfile, setShareMyProfile] = useState('');
    const token = localStorage.getItem("sessionId");
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const [alertStatus, setAlertStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [updateId, setUpdateId] = useState('');
    const [shareMyTag, setTags] = useState('');

    const getUserPrivacySetting = () => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/get-user-privacy-settings`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                id:user.id
			}),
        })
            .then((res) => {
                if (res.status !== 200) {
                    ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
                } else {
                    return res.json();
                }
            })
            .then((data) => {
                setIdentity(data.identityPermission)
                setViewProfile(data.viewProfilePermission)
                setConnectionRequest(data.connectionRequestPermission)
                setMessage(data.messagePermission)
                setOnlineStatus(data.onlineStatusPermission)
                setShareMyProfile(data.shareMyProfilePermission)
                setTags(data.tagPermission)
                setUpdateId(data.id)
                setLoading(false);

            })
            .catch((err) => {
                // setShowAlert(true);
                //     setAlertStatus(false);
                //     setAlertMsg("Network Error! Please try again..");
                setLoading(false);
            });
    };

    useEffect(() => {
        getUserPrivacySetting();
        setLoading(true);
    }, [])

    const setViewProfilePermission = (viewProfileValue: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/user-privacy-settings/updateAllPrivacySettings/${updateId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                viewProfilePermission: viewProfileValue,
                id: updateId,
            }),
        }).then((res) => {
            if (res.status !== 200) {
                ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
            } else {
                setViewProfile(viewProfileValue)
                setShowAlert(true);
                setAlertStatus(true);
                setAlertMsg("Privacy setting updated successfully!");
            }
        })
            .catch((err) => {
                setShowAlert(true);
                setAlertStatus(false);
                setAlertMsg("Network Error! Please try again..");
            });
    };

    const setIdentityPermission = (identityValue: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/user-privacy-settings/updateAllPrivacySettings/${updateId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                identityPermission: identityValue,
                id: updateId,
            }),
        }).then((res) => {
            if (res.status !== 200) {
                ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
            } else {
                setIdentity(identityValue)
                setShowAlert(true);
                setAlertStatus(true);
                setAlertMsg("Privacy setting updated successfully!");
            }
        }).catch((err) => {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Network Error! Please try again..");
        });
    };

    const setConnectionRequestPermission = (connectionValue: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/user-privacy-settings/updateAllPrivacySettings/${updateId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                connectionRequestPermission: connectionValue,
                id: updateId,
            }),
        }).then((res) => {
            if (res.status !== 200) {
                ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
            } else {
                setConnectionRequest(connectionValue)
                setShowAlert(true);
                setAlertStatus(true);
                setAlertMsg("Privacy setting updated successfully!");
            }
        }).catch((err) => {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Network Error! Please try again..");
        });
    };

    const setMessagePermission = (messageValue: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/user-privacy-settings/updateAllPrivacySettings/${updateId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                messagePermission: messageValue,
                id: updateId,
            }),
        }).then((res) => {
            if (res.status !== 200) {
                ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
            } else {
                setMessage(messageValue)
                setShowAlert(true);
                setAlertStatus(true);
                setAlertMsg("Privacy setting updated successfully!");
            }
        }).catch((err) => {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Network Error! Please try again..");
        });
    };

    const setOnlineStatusPermission = (onlineStatusValue: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/user-privacy-settings/updateAllPrivacySettings/${updateId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                onlineStatusPermission: onlineStatusValue,
                id: updateId,
            }),
        }).then((res) => {
            if (res.status !== 200) {
                ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
            } else {
                setOnlineStatus(onlineStatusValue)
                setShowAlert(true);
                setAlertStatus(true);
                setAlertMsg("Privacy setting updated successfully!");
            }
        }).catch((err) => {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Network Error! Please try again..");
        });
    };

    const setShareMyProfilePermission = (shareMyProfileValue: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/user-privacy-settings/updateAllPrivacySettings/${updateId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                shareMyProfilePermission: shareMyProfileValue,
                id: updateId,
            }),
        }).then((res) => {
            if (res.status !== 200) {
                ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
            } else {
                setShareMyProfile(shareMyProfileValue)
                setShowAlert(true);
                setAlertStatus(true);
                setAlertMsg("Privacy setting updated successfully!");
            }
        }).catch((err) => {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Network Error! Please try again..");
        });
    };



    const setTagMePermission = (shareTagValue: any) => {
        fetch(`${process.env.REACT_APP_BASE_URL}/api/user-privacy-settings/updateAllPrivacySettings/${updateId}`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify({
                tagPermission: shareTagValue,
                id: updateId,
            }),
        }).then((res) => {
            if (res.status !== 200) {
                ErrorAlert(res.status, setAlertStatus, setShowAlert, setAlertMsg)
            } else {
                
                setTags(shareTagValue)                
                setShowAlert(true);
                setAlertStatus(true);
                setAlertMsg("Privacy setting updated successfully!");
            }
        }).catch((err) => {
            setShowAlert(true);
            setAlertStatus(false);
            setAlertMsg("Network Error! Please try again..");
        });
    };

    const handleAlertClose = () => {
        setShowAlert(false);
    };

    if (loading) {
        return <CenterCircularProgress />;
    }

    return (
        <>
            <Grid container>
                {showAlert && (
                    <Snackbar
                        sx={{
                            "&.MuiSnackbar-anchorOriginTopCenter": {
                                top: "70px",
                            },
                        }}
                        anchorOrigin={{ vertical: "top", horizontal: "center" }}
                        open={showAlert}
                        autoHideDuration={6000}
                        onClose={handleAlertClose}
                    >
                        <Alert
                            onClose={handleAlertClose}
                            icon={
                                alertStatus ? (
                                    <img src={SuccessIcon} alt="error-icon" />
                                ) : (
                                    <img
                                        src={ErrorIcon}
                                        alt="error-icon"
                                        style={{ marginRight: "10px" }}
                                    />
                                )
                            }
                            sx={{
                                backgroundColor: "#FFFFFF",
                                boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                                borderRadius: "6px",
                                fontSize: "1.125rem",
                                fontWeight: "500",
                                color: "black",
                            }}
                        >
                            <AlertTitle
                                sx={{
                                    fontSize: "1.125rem",
                                    fontWeight: "500",
                                    color: "#000000",
                                    display: "flex",
                                    margin: "auto",
                                    padding: "5px",
                                    justifyContent: "center !important",
                                }}
                            >
                                {alertMsg}
                            </AlertTitle>
                        </Alert>
                    </Snackbar>
                )}
                <Grid item={true} xs={9}  className="centerPanelWidth">
                    <Card
                        sx={{
                            background: "#FFFFFF",
                            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                            borderRadius: "12px",
                            mb: 3
                        }} >
                        <CardContent sx={{ padding: "24px" }}>
                            <Typography
                                className="card-title-custom-size"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",

                                    fontWeight: "500"
                                }}
                                color="#000000"
                            > View Profile
                            </Typography>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={viewProfile}
                                onChange={(event) => setViewProfilePermission(event.target.value)}
                                name="radio-buttons-group" classes={classes} >
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="No one can view profile. Only me" control={<CircleRadio />} label="No one can view profile. Only me" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Only my connections can view full profile" control={<CircleRadio />} label="Only my connections can view full profile" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Anyone can view profile" control={<CircleRadio />} label="Anyone can view profile" />
                            </RadioGroup>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{
                            background: "#FFFFFF",
                            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                            borderRadius: "12px",
                            //my: 1,
                           // mt: 2
                           mb: 3
                        }} >
                        <CardContent sx={{ padding: "24px" }}>
                            <Typography
                                className="card-title-custom-size"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",

                                    fontWeight: "500"
                                }}
                                color="#000000" >
                                Identity
                            </Typography>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={identity}
                                onChange={(event) => setIdentityPermission(event.target.value)}
                                name="radio-buttons-group" classes={classes}>
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Name is not visible. Instead just show nickname" control={<CircleRadio />} label="Name is not visible. Instead just show nickname" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Name is visible only to my connections. Other see nickname" control={<CircleRadio />} label="Name is visible only to my connections. Other see nickname" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Name is visible to all" control={<CircleRadio />} label="Name is visible to all" />
                            </RadioGroup>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{
                            background: "#FFFFFF",
                            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                            borderRadius: "12px",
                            mb: 3
                        }} >
                        <CardContent sx={{ padding: "24px" }}>
                            <Typography
                                className="card-title-custom-size"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",

                                    fontWeight: "500"
                                }}
                                color="#000000" >
                                Connection requests
                            </Typography>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                // defaultValue={connectionRequest}
                                value={connectionRequest}
                                onChange={(event) => setConnectionRequestPermission(event.target.value)}
                                name="radio-buttons-group" classes={classes}>
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="No one can send me connection request" control={<CircleRadio />} label="No one can send me connection request" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Only (1st level) friends of my connections can connect me" control={<CircleRadio />} label="Only (1st level) friends of my connections can connect me" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Anyone can send me connection request" control={<CircleRadio />} label="Anyone can send me connection request" />
                            </RadioGroup>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{
                            background: "#FFFFFF",
                            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                            borderRadius: "12px",
                            mb: 3
                        }} >
                        <CardContent sx={{ padding: "24px" }}>
                            <Typography
                                className="card-title-custom-size"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",

                                    fontWeight: "500"
                                }}
                                color="#000000" >
                                Messages
                            </Typography>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={message}
                                onChange={(event) => setMessagePermission(event.target.value)}
                                name="radio-buttons-group" classes={classes}>
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="No one can send me messages" control={<CircleRadio />} label="No one can send me messages" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Only connections can send me message" control={<CircleRadio />} label="Only connections can send me message" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Anyone can message me without being in connection" control={<CircleRadio />} label="Anyone can message me without being in connection" />
                            </RadioGroup>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{
                            background: "#FFFFFF",
                            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                            borderRadius: "12px",
                            mb: 3
                        }} >
                        <CardContent sx={{ padding: "24px" }}>
                            <Typography
                                className="card-title-custom-size"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",

                                    fontWeight: "500"
                                }}
                                color="#000000" >
                                Online Status
                            </Typography>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={onlineStatus}
                                onChange={(event) => setOnlineStatusPermission(event.target.value)}
                                name="radio-buttons-group" classes={classes}>
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Visible to non" control={<CircleRadio />} label="Visible to non" />
                                <Grid container>
                                    {/* <Grid> */}
                                    <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Visible only to my connections" control={<CircleRadio />} label="Visible only to my connections" />
                                    <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Hide last seen" control={<CircleRadio />} label="Hide last seen" />
                                </Grid>
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Visible to anyone" control={<CircleRadio />} label="Visible to anyone" />
                            </RadioGroup>
                        </CardContent>
                    </Card>

                    <Card
                        sx={{
                            background: "#FFFFFF",
                            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                            borderRadius: "12px",
                            mb: 3
                        }} >
                        <CardContent sx={{ padding: "24px" }}>
                            <Typography
                                className="card-title-custom-size"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",

                                    fontWeight: "500"
                                }}
                                color="#000000" >
                                Share My Profile
                            </Typography>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={shareMyProfile}
                                onChange={(event) => setShareMyProfilePermission(event.target.value)}
                                name="radio-buttons-group" classes={classes}>
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="No one can share profile" control={<CircleRadio />} label="No one can share profile" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Only my connections can share my profile" control={<CircleRadio />} label="Only my connections can share my profile" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Anyone can share profile" control={<CircleRadio />} label="Anyone can share profile" />
                            </RadioGroup>
                        </CardContent>
                    </Card>


                    <Card
                        sx={{
                            background: "#FFFFFF",
                            boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                            borderRadius: "12px",
                            mb: 3
                        }} >
                        <CardContent sx={{ padding: "24px" }}>
                            <Typography
                                className="card-title-custom-size"
                                sx={{
                                    display: "flex",
                                    alignItems: "center",

                                    fontWeight: "500"
                                }}
                                color="#000000" >
                               Tags
                            </Typography>
                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={shareMyTag}
                                onChange={(event) => setTagMePermission(event.target.value)}
                                name="radio-buttons-group" classes={classes}>
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="No one can Tag me" control={<CircleRadio />} label="No one can Tag me" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Only my connections can tag me. But I need to approve" control={<CircleRadio />} label="Only my connections can tag me. But I need to approve" />
                                <FormControlLabel disabled={user.userAccountStatus === "SUSPEND"} value="Only my connections can tag me. No approval required" control={<CircleRadio />} label="Only my connections can tag me. No approval required" />
                            </RadioGroup>
                        </CardContent>
                    </Card>
                </Grid>

                {/* <Grid item={true} xs={3}  className="rightPanelWidth">
                    <HelpAndTips
                        title={i18n.settings.accountSetup.instructions.helpAndTips.title}
                        text={i18n.settings.accountSetup.instructions.helpAndTips.text}
                    />
                    <MyActivitiesSidePanel />
                </Grid> */}
            </Grid>
        </>
    )
}

export default PrivacySetting;
