import { Box, Grid } from '@mui/material';
import { memo, useEffect, useState, useRef } from 'react'
import AssignRole from './AssignRole';
import AdminAvatarGeneration from './AdminAvatarGeneration';

const AdminDetails = (props: any) => {
    const [loading, setLoading] = useState(false);

    interface EnhancedTableToolbarProps {
        activeMenuTitle: string;
        menus: Array<any>;
    }

    return (
        <>
            <Grid container={true} spacing={2} columns={{ xs: 12 }} sx={{ width: "100%", }}>
                <Grid item={true} xs={12}>
                    {
                        props.activeMenu === "assignRole" ? <AssignRole /> : < AdminAvatarGeneration avatarPromptOpen={props.avatarPromptOpen} handleDialogClose={props.handleDialogClose}/>
                    }
                </Grid>
            </Grid>
        </>
    )
}

export default AdminDetails