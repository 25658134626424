import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";

export const SuspendAlert = () => {
    return (
			<Grid container xs={12} >
				<Grid item xs={12}>
					<Box
						sx={{ width: "100%", height: "70px", backgroundColor: "#FFF8D6", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}
					>
						<Typography
							variant="body1"
							sx={{
								color: "#EB2A00",
								fontFamily: "Lexend",
								fontSize: "20px",
								fontWeight: "500",
							}}
						>
							Your account is temporarily Suspended. You are in view mode
						</Typography>
						<Typography
							variant="body2"
							sx={{
								color: "#877E4C",
								fontFamily: "Lexend",
								fontSize: "1rem",
								fontWeight: "400",
							}}
						>
							Subscription dues are not paid. Please contact support to resolve the issue.
						</Typography>
					</Box>
				</Grid>
			</Grid>
		);
}
